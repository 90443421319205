import store from "../store/index";
import { setBreakpoint } from "../store/screen/actions";

class ScreenSizeManager {
  breakpoint;

  constructor() {
    this.breakpoint = getBreakpoint(window.innerWidth);
    this.initWindowEventListener();
  }

  setBreakpoint = (newInnerWidth) => {
    const newBreakpoint = getBreakpoint(newInnerWidth);
    if (this.breakpoint === getBreakpoint(newInnerWidth)) return;
    store.dispatch(setBreakpoint(newBreakpoint));
    this.breakpoint = getBreakpoint(newInnerWidth);
  };

  initWindowEventListener = () => {
    window.addEventListener(
      "resize",
      () => this.setBreakpoint(window.innerWidth),
      true
    );
  };
}

let screenSizeManager = null;

export const getBreakpoint = (innerWidth) => {
  if (innerWidth >= 800) return "LARGE";
  // if (innerWidth < 800 && innerWidth >= 500) return "MEDIUM"; Rimosso temporanemte
  if (innerWidth < 799) return "SMALL";
};

export const initScreenSizeManager = () => {
  try {
    screenSizeManager = new ScreenSizeManager();
    return screenSizeManager;
  } catch (error) {
    console.error(error);
  }
};

export const getScreenSizeManager = () => {
  if (!screenSizeManager) {
    initScreenSizeManager();
  }
  return screenSizeManager;
};
