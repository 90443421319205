import { Avatar, ButtonBase, Dialog, Modal, Slide, Typography } from "@mui/material";
import React from "react";
import { palette } from "../assets/styles/constant/palette";
import { profilePageStyle, styleConstant } from "../assets/styles/constant/styleConstants";
import profile_img from "../assets/images/profile_image.svg";
import CustomIcon from "../core/components/CustomIcon";
import { icons } from "../assets/styles/constant/general";
import { connect } from "react-redux";
import { coinsFormat, dateFormatting } from "../helpers/util";
import InputCrediti from "../core/components/CustomDrawer/InputCrediti";
import EditProfile from "../core/components/EditProfile";
import { logOut } from "../service/UserAuth";
import ContainedButton from "../core/components/buttons/ContainedButton";
import CustomToolTip from "../core/components/CustomToolTip";
import CustomIconButton from "../core/components/buttons/CustomIconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import { freeRewardPurchase } from "../service/userCredits";
import { updatePurchaseUserData } from "../store/actions";
import { getUserAppData } from "../service/userProfile";
import FreePurchaseModal from "../core/components/FreePurchaseModal";
import CreditPurchase from "../core/components/creditPurchase/CreditPurchase";

const ordersHistory = [
  { pacchetto: "Premium 2", data: "21/06/2022 - 17:31", crediti: "1.000", prezzo: "2.99", metodo: null },
  { pacchetto: "Premium 3", data: "12/04/2022 - 12:44", crediti: "2.000", prezzo: "4.99", metodo: null },
  { pacchetto: "Intermediate", data: "13/03/2022 - 21:54", crediti: "2.500", prezzo: "1.99", metodo: null },
  { pacchetto: "Starter", data: "04/12/2021 - 08:22", crediti: "1.000", prezzo: "0.99", metodo: null },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Profile = (props) => {
  const idFreePlan = "202205101121";
  const [editUserOpen, setEditUserOpen] = React.useState(false);
  const [purchaseCompleted, setPurchaseCompleted] = React.useState(false);
  const [loadingPurchase, setLoadingPurchase] = React.useState(false);
  const [purchaseSuccessful, setPurchaseSuccessful] = React.useState(false);
  const [isTimeLeft, setIsTimeLeft] = React.useState(false);
  const [transactionArray, setTransactionArray] = React.useState([]);
  const [creditPurchaseOpen, setCreditPurchaseOpen] = React.useState(false);

  React.useEffect(() => {
    const newTransactionArray = formatTransactionHistory();
    setTransactionArray(newTransactionArray);
  }, [props.transactionHistory]);

  const isExpired = () => {
    return props.noAdsTime - Date.now() < 0;
  };

  const getFreeReward = async () => {
    try {
      if (props.freeRewardTs > Date.now()) {
        setIsTimeLeft(true);
        setPurchaseCompleted(true);
        return;
      }
      let freePlan = null;
      for (let planId in props.plans) {
        if (props.plans[planId].title === "Free") {
          freePlan = props.plans[planId];
          break;
        }
      }
      setLoadingPurchase(true);
      const response = await freeRewardPurchase(props.uid, freePlan);
      if (response.data.outcome === "purchase success") {
        const userAppData = await getUserAppData(props.uid);
        props.updatePurchaseData(
          userAppData.credit,
          userAppData.noAdsTs,
          userAppData.payments_historycal,
          userAppData.freeRewardTs,
          userAppData.cumulativeFreeCoins,
          userAppData.oldestFreeRewardTs
        );
        setPurchaseSuccessful(true);
        setPurchaseCompleted(true);
        setLoadingPurchase(false);
      }
    } catch (error) {
      setPurchaseSuccessful(false);
      setPurchaseCompleted(true);
      setLoadingPurchase(false);
    }
  };

  const isFreeRewardPeriodPassed = () => {
    const { plans, oldestFreeRewardTs } = props;
    const freeCoinsPurchasePeriod = plans[idFreePlan].freeCoinsPurchasePeriod;
    return Date.now() - freeCoinsPurchasePeriod > oldestFreeRewardTs;
  };

  const isFreeCoinsButtonDisabled = () => {
    const { plans, cumulativeFreeCoins } = props;
    const maxPurchasableFreeCoins = plans[idFreePlan].maxPurchasableFreeCoins;
    const freeCoinsPurchasePeriod = plans[idFreePlan].freeCoinsPurchasePeriod;
    const planCoins = plans[idFreePlan].coins;
    if (typeof freeCoinsPurchasePeriod != "number" || typeof maxPurchasableFreeCoins != "number") return true;
    return !isFreeRewardPeriodPassed() && cumulativeFreeCoins + planCoins > maxPurchasableFreeCoins;
  };

  const getFreeCoinsButtonText = () => {
    if (loadingPurchase) return "Caricamento...";
    const { plans, oldestFreeRewardTs } = props;
    const freeCoinsPurchasePeriod = plans[idFreePlan].freeCoinsPurchasePeriod;
    const remainingDays = (freeCoinsPurchasePeriod - (Date.now() - oldestFreeRewardTs)) / 86400000;
    const formattedDays = Math.ceil(remainingDays);
    if (isFreeCoinsButtonDisabled()) return "Potrai riscattare i tuoi crediti tra " + formattedDays + " giorni";
    return "Riscatta pacchetto gratuito da " + props.plans[idFreePlan].coins + " crediti";
  };

  const closeModal = () => {
    setPurchaseCompleted(false);
    setPurchaseSuccessful(false);
    setIsTimeLeft(false);
  };

  const formatTransactionHistory = () => {
    let transactionArray = [];
    if (props.transactionHistory && Object.keys(props.transactionHistory).length > 0) {
      Object.keys(props.transactionHistory).map((method) => {
        if (method !== "free") {
          Object.values(props.transactionHistory[method]).map((record) => {
            let newRecordObj = { ...record };
            newRecordObj.paymentMethod = method;
            transactionArray.push(newRecordObj);
          });
        }
      });
    }
    transactionArray.sort((a, b) => b.tsTransaction - a.tsTransaction);
    return transactionArray;
  };

  const renderProfileInfo = () => (
    <div style={{}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: styleConstant.gap.big,
        }}
      >
        <Avatar alt={`${props.name} ${props.surname}`} style={{ width: "75px", height: "75px" }} src={props.profilePhoto} />
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography style={profilePageStyle.name}>
              {props.name} {props.surname}
            </Typography>
            <Typography style={profilePageStyle.email}>{props.email}</Typography>
            <Typography
              style={{
                ...profilePageStyle.creationDate,
                marginTop: "6px",
              }}
            >
              Data di creazione dell'account: {dateFormatting(new Date(props.creationTime))}
            </Typography>
          </div>
          <div>
            <CustomToolTip title="Logout">
              <CustomIconButton icon={<LogoutIcon fontSize="small" />} onClick={logOut} />
            </CustomToolTip>
          </div>
        </div>
      </div>
      {!props.mobile && (
        <InputCrediti
          open
          style={{ marginTop: styleConstant.margin.x4 }}
          credits={coinsFormat(props.credits)}
          label="Crediti"
          action={() => setCreditPurchaseOpen(true)}
        />
      )}
      <div style={{ display: "flex", width: "100%", marginTop: styleConstant.margin.x7 }}>
        <ButtonBase
          style={{
            display: "flex",
            flex: "1",
            justifyContent: "space-between",
            background: palette.grey.grey_300,
            borderRadius: styleConstant.borderRadius.x1,
            padding: `${styleConstant.padding.x2} ${styleConstant.padding.x3} ${styleConstant.padding.x2} ${styleConstant.padding.x4}`,
          }}
          onClick={() => setEditUserOpen(true)}
        >
          <Typography style={profilePageStyle.actionText}>Modifica utente </Typography>
          <CustomIcon icon={icons.ANGLE_RIGHT} size="xl" />
        </ButtonBase>
      </div>
    </div>
  );

  const renderCreditInfo = () => (
    <div
      style={{
        // padding: styleConstant.padding.x4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {props.noAdsTime && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography style={profilePageStyle.creditTitle}>
            {!isExpired() ? "Non vedrai pubblicità ancora per" : "Il tuo pacchetto per la rimozione della pubblicità è scaduto"}
          </Typography>
          <Typography
            style={{
              ...profilePageStyle.creditSubTitle,
              marginTop: styleConstant.margin.x1,
            }}
          >
            {!isExpired()
              ? `Scade il ${dateFormatting(props.noAdsTime)}`
              : `È scaduto il ${dateFormatting(props.noAdsTime)}. Acquista un pacchetto per la rimozione della pubblicità dell'app mobile.`}
          </Typography>
        </div>
      )}
      {props.noAdsTime && !isExpired() && (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-around", marginTop: styleConstant.margin.x6 }}>
              <Typography style={profilePageStyle.plan.timeLeft}>
                {Math.floor((props.noAdsTime - Date.now()) / 86400000)} GIORNI - {Math.floor(((props.noAdsTime - Date.now()) / 3600000) % 24)} ORE
              </Typography>
              {!props.mobile && <Typography style={profilePageStyle.plan.scadenza}>estendi</Typography>}
            </div>
            {props.mobile && <Typography style={{ ...profilePageStyle.plan.scadenza, textAlign: "center" }}>estendi</Typography>}
            <Typography style={{ ...profilePageStyle.plan.scadenza, marginTop: styleConstant.margin.x6 }}>
              SCADE IL {new Date(props.noAdsTime).getDate()}/{new Date(props.noAdsTime).getMonth() + 1}/{new Date(props.noAdsTime).getFullYear()} ore{" "}
              {new Date(props.noAdsTime).getHours()}:{new Date(props.noAdsTime).getMinutes()}:00
            </Typography>
          </div>
          {/* <ContainedButton
            text={getFreeCoinsButtonText()}
            onClick={() => getFreeReward()}
            style={{
              backgroundColor: palette.grey.grey_300,
              height: "70px",
              width: "100%",
              marginTop: styleConstant.margin.x4,
            }}
            textStyle={{
              color: palette.primary.main,
            }}
            noWrap={false}
            disabled={loadingPurchase || isFreeCoinsButtonDisabled()}
          /> */}
        </>
      )}
      {!props.noAdsTime && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography style={profilePageStyle.creditTitle}>Non è presente un pacchetto per la rimozione della pubblicità</Typography>
          <Typography
            style={{
              ...profilePageStyle.creditSubTitle,
              marginTop: styleConstant.margin.x1,
            }}
          >
            Acquista un pacchetto per la rimozione della pubblicità dell'app mobile
          </Typography>
        </div>
      )}
    </div>
  );

  const renderPurchaseTable = () => {
    if (transactionArray.length === 0) {
      return (
        <Typography style={{ ...profilePageStyle.ordersHistory.title, marginLeft: styleConstant.margin.x3 }}>
          Non sono presenti transazioni su questo account
        </Typography>
      );
    } else {
      return (
        <div style={{ display: "flex", alignItems: "stretch", flex: "1", position: "relative", marginTop: styleConstant.margin.x4 }}>
          <div style={{ overflowY: "auto", position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }} id="custom-scrollbar">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto auto auto auto auto auto",
                overflowY: "auto",
                padding: "0 32px",
              }}
            >
              <Typography style={profilePageStyle.ordersHistory.title}>Data</Typography>
              <Typography style={profilePageStyle.ordersHistory.title}>Pacchetto</Typography>
              <Typography style={profilePageStyle.ordersHistory.title}>Crediti</Typography>
              <Typography style={profilePageStyle.ordersHistory.title}>Prezzo</Typography>
              <Typography style={{ ...profilePageStyle.ordersHistory.title, marginBottom: styleConstant.margin.x1 }}>Metodo</Typography>
              <Typography style={{ ...profilePageStyle.ordersHistory.title, marginBottom: styleConstant.margin.x1 }}>Stato</Typography>
              <Typography style={profilePageStyle.ordersHistory.title}>Codice sconto</Typography>
              {transactionArray.map((order, index) => {
                let hour = new Date(order.tsTransaction).getHours();
                if (hour < 10) hour = "0" + hour;
                let minute = new Date(order.tsTransaction).getMinutes();
                if (minute < 10) minute = "0" + minute;
                // let formattedDay = day + "/" + month + "/" + year;
                let formattedDate = dateFormatting(order.tsTransaction);
                if (!props.mobile) formattedDate += " - " + hour + ":" + minute;
                let methodIcon = icons.PAYPAL;
                if (order.paymentMethod === "android") methodIcon = icons.GOOGLE_PAY;
                if (order.paymentMethod === "ios") methodIcon = icons.APPLE_PAY;

                let statusIcon = icons.CHECK;
                if (order.paymentState === "PENDING_STATE") statusIcon = icons.PENDING;
                if (order.paymentState === "CANCELLED_STATE") statusIcon = icons.TIMES;
                return (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderTop: "1px solid white",
                        padding: `${styleConstant.padding.x2} ${styleConstant.padding.x0}`,
                        paddingRight: "12px",
                      }}
                    >
                      <Typography style={profilePageStyle.actionText}>{formattedDate}</Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderTop: "1px solid white",
                        padding: `${styleConstant.padding.x2} ${styleConstant.padding.x0}`,
                        paddingRight: "12px",
                      }}
                    >
                      <Typography style={profilePageStyle.actionText}>{order.planName || order.packetName || "---"}</Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: styleConstant.gap.small,
                        borderTop: "1px solid white",
                        padding: `${styleConstant.padding.x2} ${styleConstant.padding.x0}`,
                        paddingRight: "12px",
                      }}
                    >
                      <Typography style={profilePageStyle.actionText}>{order.purchasedCredits || "---"}</Typography>
                      <CustomIcon icon={icons.COINS} color={palette.white.main} size="xs" />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderTop: "1px solid white",
                        padding: `${styleConstant.padding.x2} ${styleConstant.padding.x0}`,
                        paddingRight: "12px",
                      }}
                    >
                      <Typography style={{ ...profilePageStyle.actionText, whiteSpace: "nowrap" }}>
                        {order.planPrice || order.packetPrice || "---"} €
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderTop: "1px solid white",
                        padding: `${styleConstant.padding.x2} ${styleConstant.padding.x0}`,
                      }}
                    >
                      <CustomIcon icon={methodIcon} color={palette.white.main} fontSize={methodIcon == icons.PAYPAL ? "32px" : "32px"} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderTop: "1px solid white",
                        padding: `${styleConstant.padding.x2} ${styleConstant.padding.x0}`,
                      }}
                    >
                      <CustomIcon icon={statusIcon} color={palette.white.main} fontSize={"16px"} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        borderTop: "1px solid white",
                        padding: `${styleConstant.padding.x2} ${styleConstant.padding.x0}`,
                        paddingRight: "12px",
                      }}
                    >
                      <Typography style={{ ...profilePageStyle.actionText, whiteSpace: "nowrap" }}>
                        {order.promoCode?.promoCode || "---"}
                      </Typography>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
  };

  const renderPurchaseHistory = () => (
    <div style={{ display: "flex", flexDirection: "column", height: !props.mobile ? "100%" : "350px" }}>
      <Typography style={{ ...profilePageStyle.name, paddingLeft: "32px" }}>Storico degli acquisti</Typography>
      {renderPurchaseTable()}
    </div>
  );

  return (
    <>
      {!props.mobile && (
        <CreditPurchase
          open={creditPurchaseOpen}
          handleClose={() => setCreditPurchaseOpen(false)}
          title="Acquisto crediti"
          subtitle="Seleziona il piano e procedi con l'acquisto"
        />
      )}
      <FreePurchaseModal
        isOpen={purchaseCompleted}
        onClose={closeModal}
        isSuccess={purchaseSuccessful}
        freeCredits={"100"}
        nextFreeRewardTs={props.freeRewardTs}
        isTimeLeft={isTimeLeft}
        mobile={props.mobile}
      />
      {props.mobile && (
        <Dialog fullScreen open={editUserOpen} onClose={() => setEditUserOpen(false)} TransitionComponent={Transition}>
          <div style={{ display: "flex", height: "100vh", background: palette.grey.grey_500 }}>
            <EditProfile
              mobile={true}
              open={editUserOpen}
              onClose={() => setEditUserOpen(false)}
              userEmail={props.email}
              name={props.name ? props.name : ""}
              surname={props.surname ? props.surname : ""}
            />
          </div>
        </Dialog>
      )}
      <div
        style={{
          display: "flex",
          flex: 1,
          overflowY: "auto",
          flexDirection: "column",
          padding: !props.mobile ? `${styleConstant.padding.x7} ${styleConstant.padding.x4}` : `${styleConstant.padding.x3}`,
          background: props.mobile ? palette.grey.grey_600 : "",
        }}
      >
        {!props.mobile && (
          <EditProfile
            open={editUserOpen}
            onClose={() => setEditUserOpen(false)}
            userEmail={props.email}
            name={props.name ? props.name : ""}
            surname={props.surname ? props.surname : ""}
          />
        )}
        <div>
          <Typography style={profilePageStyle.title}>Profilo</Typography>
        </div>
        <div
          style={{
            flex: 1,
            marginTop: styleConstant.margin.x4,
            display: "flex",
            flexDirection: "row",
            columnGap: styleConstant.gap.big,
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              rowGap: styleConstant.gap.big,
            }}
          >
            <div
              style={{
                // flex: 1,
                background: palette.grey.grey_500,
                borderRadius: styleConstant.borderRadius.x2,
                overflow: "auto",
                padding: styleConstant.padding.x4,
              }}
            >
              {renderProfileInfo()}
            </div>
            <div
              style={{
                // flex: 1,
                background: palette.grey.grey_500,
                borderRadius: styleConstant.borderRadius.x2,
                overflow: "auto",
                padding: styleConstant.padding.x4,
              }}
            >
              {renderCreditInfo()}
            </div>
            {props.mobile && (
              <div
                style={{
                  background: palette.grey.grey_500,
                  borderRadius: styleConstant.borderRadius.x2,
                  marginBottom: styleConstant.margin.x4,
                  paddingTop: styleConstant.padding.x4,
                }}
              >
                {renderPurchaseHistory()}
              </div>
            )}
          </div>
          {!props.mobile && (
            <div
              style={{
                flex: 1,
                background: palette.grey.grey_500,
                borderRadius: styleConstant.borderRadius.x2,
                height: "100%",
                overflowY: "auto",
                paddingTop: styleConstant.padding.x4,
                // paddingRight: 0,
                // paddingBottom: 0,
              }}
            >
              {renderPurchaseHistory()}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ Profile, Calculation }) => ({
  name: Profile.userProfile.name,
  uid: Profile.userProfile.uid,
  freeRewardTs: Profile.userProfile.appData.freeRewardTs,
  surname: Profile.userProfile.surname,
  email: Profile.userProfile.email,
  credits: Profile.userProfile.appData.credit,
  noAdsTime: Profile.userProfile.appData.noAdsTs,
  paymentsHistory: Profile.userProfile.appData.payments_historycal,
  creationTime: Profile.userProfile.appData.tsCreated,
  profilePhoto: Profile.userProfile.photoURL,
  plans: Calculation.plans,
  oldestFreeRewardTs: Profile.userProfile.appData.oldestFreeRewardTs,
  cumulativeFreeCoins: Profile.userProfile.appData.cumulativeFreeCoins,
  transactionHistory: Profile.userProfile.appData.payments_historycal,
});

const mapDispatchToProps = (dispatch) => ({
  updatePurchaseData: (credit, noAdsTs, payments_historycal, freeRewardTs, cumulativeFreeCoins, oldestFreeRewardTs) =>
    dispatch(updatePurchaseUserData(credit, noAdsTs, payments_historycal, freeRewardTs, cumulativeFreeCoins, oldestFreeRewardTs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
