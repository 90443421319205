import { Collapse, Dialog, Typography } from "@mui/material";
import React from "react";
import { icons } from "../../../../assets/styles/constant/general";
import { palette } from "../../../../assets/styles/constant/palette";
import { calculatorDesktopSettings, styleConstant } from "../../../../assets/styles/constant/styleConstants";
import CustomIconButton from "../../../components/buttons/CustomIconButton";
import CustomIcon from "../../../components/CustomIcon";
import InputRadio from "../../../components/input/InputRadio";
import ContainedButton from "../../../components/buttons/ContainedButton";
import { connect } from "react-redux";
import { setSelectedSettings } from "../../../../store/actions";
import CustomToolTip from "../../../components/CustomToolTip";
import FormSkeleton from "../../../components/input/FormSkeleton";
import CustomDialog from "../../../components/CustomDialog";
import TextButton from "../../../components/buttons/TextButton";

const config = [
  [
    { label: "Probabilità", value: 1 },
    { label: "Media", value: 2 },
  ],
  [
    { label: "Giocatore", value: 1 },
    { label: "Squadra", value: 2 },
  ],
];

const CalculatorSettings = ({ radioItems = config, calculationsList, loadSettings, subjects, tools, selectedSettings, guides }) => {
  const confirmationText = "Sono gìà presenti dei calcoli, modificando le impostazioni potrebbero verificarsi perdite di dati, procedere comunque?";
  const [settingsOpen, setSettingsOpen] = React.useState(true);
  const [selectedCalculusType, setSelectedCalculusType] = React.useState(1);
  const [selectedCalculusSubject, setSelectedCalculusSubject] = React.useState(1);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const [isGuidesOpen, setIsGuidesOpen] = React.useState(false);
  // const [radioItemsSubjects, setRadioItemsSubjects] = React.useState(null);
  // const [radioItemsTools, setRadioItemsTools] = React.useState(null);

  const radioItemsSubjects = Object.values(subjects).map((subject) => {
    return { value: subject.id, label: subject.nome };
  });
  const radioItemsTools = Object.values(tools).map((tool) => {
    return { value: tool.id, label: tool.nome };
  });

  const onSubmit = () => {
    if (Object.values(calculationsList).length === 0) {
      loadSettings({ tool: selectedCalculusType, subject: selectedCalculusSubject });
      setSettingsOpen(false);
      return;
    }
    setConfirmationDialogOpen(true);
    // if (window.confirm(confirmationText) == true) {
    //   loadSettings({ tool: selectedCalculusType, subject: selectedCalculusSubject });
    //   setSettingsOpen(false);
    // }
  };

  const onDialogConfirmation = () => {
    setConfirmationDialogOpen(false);
    loadSettings({ tool: selectedCalculusType, subject: selectedCalculusSubject });
    setSettingsOpen(false);
  };

  const onSettingsClose = () => {
    setSettingsOpen(false);
    setSelectedCalculusSubject(selectedSettings.subject);
    setSelectedCalculusType(selectedSettings.tool);
  };

  const getGuideContent = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", padding: styleConstant.padding.x3, maxHeight: "50%", overflowY: "auto" }} id="custom-scrollbar">
        <Typography variant="title">Strumento</Typography>
        <Typography variant="smallTitle">{guides.strumenti[selectedCalculusType].descrizione}</Typography>
        <Typography variant="title" style={{ marginTop: styleConstant.margin.x3 }}>
          Oggetto
        </Typography>
        <Typography variant="smallTitle">{guides.oggetti[selectedCalculusSubject][selectedCalculusType].descrizione}</Typography>
        <TextButton text="Chiudi" action={() => setIsGuidesOpen(false)} style={{ marginTop: styleConstant.margin.x3 }} />
      </div>
    );
  };

  const InputSettings = ({ label, input = null }) => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: styleConstant.gap.small }}>
        <Typography variant="smallTitle" style={calculatorDesktopSettings.input}>
          {label}
        </Typography>
        {input}
      </div>
    );
  };

  if (!radioItemsSubjects || !radioItemsTools) {
    return <></>;
  }

  const header = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="title" style={calculatorDesktopSettings.title}>
          Impostare Calcolatore
        </Typography>
        <CustomIconButton icon={<CustomIcon icon={icons.QUESTION} color={palette.primary.main} />} onClick={() => setIsGuidesOpen(true)} />
      </div>
      <Typography variant="smallTitle" style={calculatorDesktopSettings.subtitle}>
        Imposta il calcolatore. Seleziona la tipologia e l'oggetto del calcolo per iniziare
      </Typography>
    </div>
  );

  const radios = () => (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: styleConstant.gap.medium,
      }}
    >
      <FormSkeleton style={{ marginTop: 0 }} label="Tipologia calcolo">
        <InputRadio radioItem={radioItemsTools} selectedValue={selectedCalculusType} handleSelect={(tool) => setSelectedCalculusType(tool)} />
      </FormSkeleton>
      <FormSkeleton style={{ marginTop: 0 }} label="Oggetto calcolo">
        <InputRadio radioItem={radioItemsSubjects} selectedValue={selectedCalculusSubject} handleSelect={(subject) => setSelectedCalculusSubject(subject)} />
      </FormSkeleton>
    </div>
  );

  const buttons = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        gap: styleConstant.gap.small,
      }}
    >
      <ContainedButton fullHeihgt={true} text="Applica" onClick={() => onSubmit()} />
      <ContainedButton fullHeihgt={true} text="Annulla" onClick={onSettingsClose} />
    </div>
  );

  const renderSettingsContent = () => {
    return (
      <div
        style={{
          margin: styleConstant.margin.x3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "flex-end",
            gap: styleConstant.margin.medium,
            width: "100%",
            maxWidth: "1436px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "25%",
            }}
          >
            {header()}
          </div>
          <div
            style={{
              height: "100%",
              flex: "1 1",
              marginLeft: styleConstant.margin.x2,
            }}
          >
            {radios()}
          </div>
          <div
            style={{
              height: "100%",
              marginLeft: styleConstant.margin.x2,
            }}
          >
            {buttons()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <CustomDialog
        open={confirmationDialogOpen}
        handleClose={() => setConfirmationDialogOpen(false)}
        style={{ zIndex: 10004 }}
        handleSecondaryAction={() => setConfirmationDialogOpen(false)}
        handlePrimaryAction={onDialogConfirmation}
        title={"Conferma per procedere"}
        secondaryActionText={"Annulla"}
        primaryActionText={"Procedi"}
        description={confirmationText}
        icon={icons.EXCLAMATION}
      />
      <CustomDialog
        open={isGuidesOpen}
        handleClose={() => setIsGuidesOpen(false)}
        backDropClickClose
        style={{ zIndex: 10004 }}
        title={"Serve aiuto?"}
        customContent
        icon={icons.LIFE_RING}
        titlePosition="center"
        roundCorners
      >
        {getGuideContent()}
      </CustomDialog>
      <div>
        <div>
          <CustomToolTip title="Modifica configurazione">
            <CustomIconButton icon={<CustomIcon icon={icons.PENCIL} />} onClick={() => setSettingsOpen(!settingsOpen)} />
          </CustomToolTip>
        </div>
        {settingsOpen && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              background: "#00000080",
              backdropFilter: "blur(2px)",
              zIndex: 10001,
            }}
            onClick={onSettingsClose}
          ></div>
        )}
        <Collapse
          in={settingsOpen}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            background: palette.grey.grey_300,
            width: "100%",
            zIndex: 10003,
          }}
        >
          {renderSettingsContent()}
        </Collapse>
      </div>
    </>
  );
};

const mapStateToProps = ({ CalculationWork, Calculation }) => ({
  calculationsList: CalculationWork.calculationList,
  subjects: Calculation.subjects,
  tools: Calculation.tools,
  selectedSettings: CalculationWork.settings,
  guides: Calculation.guides,
});

const mapDispatchToProps = (dispatch) => ({
  loadSettings: (settings) => dispatch(setSelectedSettings(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorSettings);
