import { convertArrayToObject, getPathWithParams } from "../helpers/util";
import { getFirebaseBackend } from "./common/firebase";
import { TIPSTER_EVENTS, TIPSTER_GROUPS, TIPSTER_MIGLIORI, TIPSTER_STATISTICS } from "./constant/refDb";

const firebaseBackend = getFirebaseBackend();

export function getTipsterGroups() {
  return firebaseBackend.dbOnceRead(getPathWithParams(TIPSTER_GROUPS))
		.then(snapshot => {
			const groups = snapshot.val();
			return convertArrayToObject(groups);
		}).catch(error => {
			console.log('ERRORE getTipsterGroups:', error);
			throw error;
		});
}

export function getTipsterStatistics() {
  return firebaseBackend.dbOnceRead(getPathWithParams(TIPSTER_STATISTICS))
		.then(snapshot => {
			const statistics = snapshot.val();
			return convertArrayToObject(statistics);
		}).catch(error => {
			console.log('ERRORE getTipsterStatistics:', error);
			throw error;
		});
}

export function getTipsterEvents() {
  return firebaseBackend.dbOnceRead(getPathWithParams(TIPSTER_EVENTS))
		.then(snapshot => {
			const events = snapshot.val();
			return convertArrayToObject(events);
		}).catch(error => {
			console.log('ERRORE getTipsterEvents:', error);
			throw error;
		});
}

export function getTipsterMigliori() {
  return firebaseBackend.dbOnceRead(getPathWithParams(TIPSTER_MIGLIORI))
		.then(snapshot => {
			const migliori = snapshot.val();
			return convertArrayToObject(migliori);
		}).catch(error => {
			console.log('ERRORE getTipsterMigliori:', error);
			throw error;
		});
}