import { Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../../assets/styles/constant/palette";
import { styleConstant } from "../../../assets/styles/constant/styleConstants";

const ContainedButton = ({
  text = "Esegui tutti 80",
  icon = null,
  adaptToParentContainer = false,
  disabled = false,
  onClick,
  large = false,
  id = null,
  style = null,
  iconSide = "right",
  fullHeihgt = false,
  textStyle = null,
  noWrap = true,
}) => {

  const getClassName = () => {
    let c = '';
    // if (disabled) c += " disable ";
    if (fullHeihgt) c += "fullHeihgt";
    return c;
  }

  return (
    <Button
      variant="containedButton"
      // className={fullHeihgt ? 'fullHeihgt' : null}
      className={getClassName()}
      fullWidth={adaptToParentContainer}
      onClick={onClick}
      disableTouchRipple={disabled}
      disabled={disabled}
      id={id}
      style={style}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: `${styleConstant.gap.small}`,
          padding: `${large ? styleConstant.gap.small : "0px"}`,
        }}
      >
        {iconSide === "left" ? icon : null}
        <Typography variant="smallTitle" style={textStyle} color={palette.black.main} noWrap={noWrap}>
          {text}
        </Typography>
        {iconSide === "right" ? icon : null}
      </div>
    </Button>
  );
};

export default ContainedButton;
