import { Stack, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { palette } from '../../../assets/styles/constant/palette';
import { brkRef, convertArrayToObject } from '../../../helpers/util';
import ContainedButton from '../../../core/components/buttons/ContainedButton';
import CustomTextField from '../../../core/components/input/CustomTextField';
import FormSkeleton from '../../../core/components/input/FormSkeleton';
import InputSelect from '../../../core/components/input/InputSelect';
import PlayerMultiselect from './PlayerMultiselect';
import { addPlayerToFantaTeam, createFantaTeam, editFantaTeamData, removePlayerFromFantaTeam } from '../../../service/fanta';
import { reloadUserFantaTeams } from '../../../store/profile/actions';
import { selectFantaTeam } from '../../../store/fanta/actions';

const mockedInputSelectData = [
	{value: 0, label: 'Classico'},
	{value: 1, label: 'Gazzetta'},
	{value: 2, label: 'Mantra'},
]

const TeamsConfigSection = (props) => {

	const { uid, userAppData, selectedFantaTeamId, fantaPlayers, fantaSettings } = props;
	const fantaTeams = userAppData?.fantaTeams;
	const teamNameFormLabel = fantaSettings['1'].nome;
	const fantaTypeFormLabel = fantaSettings['2'].nome;
	const leagueNameFormLabel = fantaSettings['3'].nome; 
	const fantaTypeInputSelectData = fantaSettings['2'].valori.map((el, idx) => ({value: idx, label: el}));

	// Form
	const [teamName, setTeamName] = React.useState('');
	const [leagueName, setLeagueName] = React.useState('');
	const [fantaType, setFantaType] = React.useState(fantaTypeInputSelectData[0]);
	const [enableEdit, setEnableEdit] = React.useState(false);
	// Selezione giocatori
	const [playerSectionDisabled, setPlayerSectionDisabled] = React.useState(true);
	const [selectedPlayers, setSelectedPlayers] = React.useState([]);
	const [nonSelectedPlayers, setNonSelectedPlayers] = React.useState([]);

	React.useEffect(() => {
		if (selectedFantaTeamId) initFantaTeamData(props.selectedFantaTeamId);
		else resetSectionData();
	}, [props.selectedFantaTeamId]);

	const initFantaTeamData = (selectedFantaTeamId) => {
		const teamObj = fantaTeams[selectedFantaTeamId];
		const { name, leagueName, fantaType, players } = teamObj;
		const _players = convertArrayToObject(players) || [];
		const _fantaType = fantaTypeInputSelectData.filter(el => (el.label === fantaType))[0];
		const { _selectedPlayers, _nonSelectedPlayers } = getSplittedPlayersData(_players, fantaPlayers);
		setNonSelectedPlayers(_nonSelectedPlayers);
		setSelectedPlayers(_selectedPlayers);
		setLeagueName(leagueName);
		setFantaType(_fantaType);
		setTeamName(name);
	}

	const getSplittedPlayersData = (alreadySelectedPlayersObj, allPlayersObj) => {
		let _selectedPlayers = [];
		let _nonSelectedPlayers = [];
		const alreadySelectedPlayersIds = Object.keys(alreadySelectedPlayersObj);
		Object.keys(allPlayersObj).map(playerId => {
			const isSelected = alreadySelectedPlayersIds.indexOf(playerId) >= 0;
			if (isSelected) _selectedPlayers.push(playerId);
			else _nonSelectedPlayers.push(playerId);
		});
		return { _selectedPlayers, _nonSelectedPlayers };
	}

	const resetSectionData = () => {
		setTeamName('');
		setLeagueName('');
		setFantaType(mockedInputSelectData[0]);
		setSelectedPlayers([]);
		setPlayerSectionDisabled(true);
		setEnableEdit(false);
	}

	/* SERVICE FUNCTIONS */
	const createNewFantaTeam = async() => {
		try {
			const _fantaType = fantaType.label
			const _fantaTeamId = await createFantaTeam(uid, teamName, _fantaType, leagueName);
			props.reloadUserFantaTeams(uid, _fantaTeamId);
			setPlayerSectionDisabled(false);
		} catch (error) {
			console.log('Erroe creating fanta team', error);
		}
	}

	const editFantaTeam = async() => {
		try {
			if (!selectedFantaTeamId) throw 'Tentativo di modifica di un fantaTeam inesistente';
			const newDataObj = {name: teamName, fantaType: fantaType.label, leagueName};
			await editFantaTeamData(uid, selectedFantaTeamId, newDataObj);
			props.reloadUserFantaTeams(uid);
			setEnableEdit(false);
		} catch (error) {
			console.log('Error editing fantaTeamData', error)
		}
	}

	const addPlayer = (playerId) => {
		if (!selectedFantaTeamId) throw 'Tentativo di aggiunta giocatore ad un fantaTeam inesistente';
		if (selectedPlayers.length >= 40) return;
		addPlayerToState(playerId);
		addPlayerToFantaTeam(uid, selectedFantaTeamId, playerId)
			.then(() => {
				props.reloadUserFantaTeams(uid);
			})
			.catch(error => {
				console.log('Errore aggiunta giocatore', error)
				removePlayerFromState(playerId);
			});
	}

	const removePlayer = (playerId) => {
		if (!selectedFantaTeamId) throw 'Tentativo di rimozione giocatore da un fantaTeam inesistente';
		removePlayerFromState(playerId);
		removePlayerFromFantaTeam(uid, selectedFantaTeamId, playerId).then(() => {
			props.reloadUserFantaTeams(uid);
		}).catch(error => {
			console.log('Errore rimozione di un giocatore', error);
			addPlayerToState(playerId);
		});
	}

	/* INTERNAL */
	const addPlayerToState = (playerId) => {
		const _playerId = playerId.toString();
		setSelectedPlayers(prev => {
			let newSelectedPlayers = brkRef(prev);
			newSelectedPlayers.push(_playerId);
			return newSelectedPlayers;
		});
		setNonSelectedPlayers(prev => {
			let newNonSelectedPlayers = brkRef(prev);
			const elementIndex = newNonSelectedPlayers.indexOf(_playerId);
			if (elementIndex < 0) return newNonSelectedPlayers;
			else newNonSelectedPlayers.splice(elementIndex, 1);
			return newNonSelectedPlayers;
		});
	}

	const removePlayerFromState = (playerId) => {
		const _playerId = playerId.toString();
		setSelectedPlayers(prev => {
			let newSelectedPlayers = brkRef(prev);
			const elementIndex = newSelectedPlayers.indexOf(_playerId);
			if (elementIndex < 0) return newSelectedPlayers;
			else newSelectedPlayers.splice(elementIndex, 1);
			return newSelectedPlayers;
		});
		setNonSelectedPlayers(prev => {
			let newNonSelectedPlayers = brkRef(prev);
			newNonSelectedPlayers.push(_playerId);
			return newNonSelectedPlayers;
		});
	}

	const onFantaTypeChange = (element) => {
		setFantaType(element);
		if (selectedFantaTeamId) setEnableEdit(true);
	}

	const onTeamNameChange = (val) => {
		setTeamName(val);
		if (selectedFantaTeamId) setEnableEdit(true);
	}

	const onLeagueNameChange = (val) => {
		setLeagueName(val);
		if (selectedFantaTeamId) setEnableEdit(true);
	}

	const getCreateEditButtonDisabled = () => {
		const emptyInputs = teamName.length === 0 || leagueName.length === 0;
		if (!selectedFantaTeamId) return emptyInputs;
		return !enableEdit || emptyInputs;
	}

	const renderLeftSection = () => (
		<Stack flex={1}
			paddingY={'24px'}
			paddingLeft={'24px'}
			borderRadius={'16px'}
			boxShadow={'rgb(0 0 0 / 20%) 4px 0px 22px 0px'}
			style={{background: palette.grey.grey_400}}>
				<Typography
          fontWeight={'600'}
          color={'#FFF'}
          fontSize={24}>
            Impostazioni
        </Typography>
					{renderForm()}
				<ContainedButton
					style={{marginTop: 'auto', marginRight: '24px', height: '50px'}}
					disabled={getCreateEditButtonDisabled()}
					text={`${selectedFantaTeamId ? 'Modifica' : 'Crea'} rosa`}
				 	onClick={!selectedFantaTeamId
						? () => createNewFantaTeam()
						: () => editFantaTeam()
					}/>
		</Stack>
	)
	
	const renderRightSection = () => (
		<Stack flex={1}
			paddingY={'24px'}
			paddingLeft={'24px'}
			borderRadius={'16px'}>
				<Typography
          fontWeight={'600'}
          color={'#FFF'}
          fontSize={24}>
            Giocatori Rosa
        </Typography>
				<Typography 
          variant='smallTitle'
          fontWeight={'600'}
          color={palette.grey.grey_100}>
						Totale giocatori: {selectedPlayers.length} / 40
        </Typography>
				<PlayerMultiselect
					onPlaterAdd={addPlayer}
					onPlayerDelete={removePlayer}
					disabled={!selectedFantaTeamId} 
					selectedPlayers={selectedPlayers}
					nonSelectedPlayers={nonSelectedPlayers} />
		</Stack>
	)

	const renderForm = () => (
		<Stack flex={1}
			marginY={'16px'}
			position={'relative'}>
				<div id='custom-scrollbar' style={{
					position: 'absolute',
					right: 0, left: 0, bottom: 0, top: 0,
					paddingRight: '24px',
					overflow: 'auto',
					flexDirection: 'column',
					gap: '8px',
					flex: 1
				}}>
					<FormSkeleton label={teamNameFormLabel}>
						<CustomTextField
							content={teamName}
							placeholder={teamNameFormLabel}
							onChange={onTeamNameChange}/>
					</FormSkeleton>
					<FormSkeleton label={fantaTypeFormLabel}>
						<InputSelect
							selectedValue={fantaType}
							onSelectChange={onFantaTypeChange}
							inputSelectItem={fantaTypeInputSelectData}/>
					</FormSkeleton>
					<FormSkeleton label={leagueNameFormLabel}>
						<CustomTextField
							content={leagueName}
							placeholder={leagueNameFormLabel}
							onChange={onLeagueNameChange}/>
					</FormSkeleton>
				</div>
		</Stack>
	)

	return (
		<Stack flex={1}
			height={'100%'}
			direction={'row'}
			borderRadius={'16px'}
			style={{background: palette.grey.grey_500}}>
				{renderLeftSection()}
				{renderRightSection()}
		</Stack>
	)
}

const mapStateToProps = ({ Fanta, Profile }) => ({
  uid: Profile.userProfile.uid,
  userAppData: Profile.userProfile.appData,
	selectedFantaTeamId: Fanta.selectedFantaTeamId,
  fantaStatistics: Fanta.fantaStatistics,
  fantaPlayers: Fanta.fantaPlayers,
  fantaSettings: Fanta.fantaSettings,
});

const mapDispatchToProps = dispatch => ({
  //loadFantaPlayers: () => dispatch(fetchFantaPlayers()),
  //loadFantaSatistics: () => dispatch(fetchFantaStatistics()),
	selectFantaTeamId: (fantaTeamId) => dispatch(selectFantaTeam(fantaTeamId)), 
  reloadUserFantaTeams: (uid, selectedId) => dispatch(reloadUserFantaTeams(uid, selectedId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamsConfigSection);