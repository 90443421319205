export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_USER_APPLE = "LOGIN_USER_APPLE"
export const LOGIN_USER_GOOGLE = "LOGIN_USER_GOOGLE"
export const LOGIN_USER_FACEBOOK = "LOGIN_USER_FACEBOOK"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const EMAIL_NOT_VERIFIED = "EMAIL_NOT_VERIFIED"
export const RESEND_EMAIL_VERIFICATION = "RESEND_EMAIL_VERIFICATION"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"
export const API_ERROR = "LOGIN_API_ERROR"
