import React from "react";
import CustomIconButton from "../components/buttons/CustomIconButton";
import { icons } from "../../assets/styles/constant/general";
import { Typography } from "@mui/material";
import CustomIcon from "../components/CustomIcon";
import { palette } from "../../assets/styles/constant/palette";
import { styleConstant } from "../../assets/styles/constant/styleConstants";

const Header = ({ credits = "", toggleDrawer = null, addCredits = null }) => {
  return (
    <div
      style={{
        display: "flex",
        background: "purple",
        justifyContent: "space-between",
        alignItems: "center",
        padding: styleConstant.padding.x3,
        backgroundColor: palette.grey.grey_600,
      }}
    >
      <CustomIconButton icon={<CustomIcon icon={icons.BARS} color={palette.white.main} />} onClick={toggleDrawer} />
      <div style={{ display: "flex", gap: styleConstant.gap.small, alignItems: "center" }}>
        <Typography variant="title">{credits}</Typography>
        <CustomIcon icon={icons.COINS} color={palette.white.main} />
        <CustomIconButton icon={<CustomIcon icon={icons.PLUS} />} onClick={addCredits} />
      </div>
    </div>
  );
};

export default Header;
