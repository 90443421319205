import { palette } from "./palette";

//TODO: usare queste misure per constanti di stile
const scale = {
  // default: {
  //   x0: "0px",
  //   x1: "7px",
  //   x2: "14px",
  //   x3: "21px",
  //   x4: "28px",
  //   x5: "35px",
  //   x6: "42px",
  // },
  default: {
    x0: "0px",
    x1: "8px",
    x2: "16px",
    x3: "24px",
    x4: "32px",
    x5: "40px",
    x6: "48px",
    x7: "56px",
    x8: "64px",
    x9: "72px",
  },
};

export const styleConstant = {
  padding: {
    ...scale.default,
    selectPadding: "8px 12px",
    buttonBaseSelect: "0px 40px 0px 0px",
    inputRadioItemContainer: "8px 8px 8px 0px",
    scenarioSection: "24px 0px 0px 24px",
    scenarioInfoCardContent: "0px 24px 24px 24px",
  },
  margin: {
    ...scale.default,
  },
  borderRadius: {
    ...scale.default,
  },
  fontWeight: {
    typographyDefaultFontWeight: "bold",
    bold: "bold",
    medium: "medium",
    thin: "thin",
  },
  fontSize: {
    ...scale.default,
    subtitleFontSize: "14px",
  },
  lineHeight: {
    ...scale.default,
  },
  position: {
    ...scale.default,
    cardPosition: "relative",
  },
  cursor: {
    cardCursor: "pointer",
  },
  gap: {
    small: "8px",
    medium: "16px",
    large: "24px",
    big: "32px",
    xl: "40px",
    xxl: "48px",
  },
  opacity: {
    ...scale.default,
  },
  top: {
    ...scale.default,
    playerInfoIcon: "10px",
  },
  right: {
    ...scale.default,
    playerInfoIcon: "16px",
  },
  minHeight: {
    ...scale.default,
  },
  maxHeight: {
    ...scale.default,
  },
  // ripple: {
  //   cardRipple,
  //   buttonRipple,
  // },
};

export const textSecondaryStyle = {
  color: palette.grey.grey_100,
};

export const creditLabelStyle = {
  fontSize: "14px",
  lineHeight: styleConstant.lineHeight.x2,
  fontWeight: styleConstant.fontWeight.bold,
};

export const drawerLinkItemStyle = {
  // fontWeight: styleConstant.fontWeight.bold,
  fontWeight: "700",
  letterSpacing: "0.3px",
};

export const drawerHelpBoxTextStyle = {
  title: {
    fontWeight: "700",
    lineHeight: "18px",
    fontSize: "15px",
  },
  content: {
    fontWeight: "500",
    fontSize: "15px",
    lineHeight: "16px",
    color: palette.grey.grey_100,
  },
  actions: {
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "14px",
  },
};

export const drawerProfileLabel = {
  name: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "19px",
  },
  email: {
    fontWeight: "700",
    fontSize: "13px",
    lineHeight: "15px",
    color: palette.grey.grey_100,
  },
};

export const calculatorDesktopSettings = {
  title: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    color: palette.white.main,
    fontFamily: "Roboto",
  },
  subtitle: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: palette.grey.grey_100,
    marginTop: "8px",
  },
  input: {
    fontWeight: "700",
    // fontSize: "20px",
    // lineHeight: "23px",
  },
};

export const calculatorMobileSettings = {
  title: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "32px",
    color: palette.white.main,
    fontFamily: "Roboto",
  },
  subtitle: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    color: palette.grey.grey_100,
  },
  closeButton: {
    fontWeight: "800",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.02em",
    color: palette.primary.main,
  },
};

export const calculatorDesktopHeader = {
  title: {
    fontWeight: "700",
    // fontSize: "32px",
    // lineHeight: "38px",
  },
  subtitle: {
    fontWeight: "800",
    // fontSize: "20px",
    // lineHeight: "23px",
    // letterSpacing: "0.02em",
    color: palette.grey.grey_100,
    // marginTop: "8px",
  },
};

export const skeletonLabelStyle = {
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "16px",
  color: palette.grey.grey_100,
};

export const calculationSectionStyle = {
  title: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.02em",
    color: palette.white.main,
  },
};

export const configSectionStyle = {
  title: {
    fontWeight: "00",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.015em",
    // color: palette.white.main,
    color: palette.grey.grey_100,
  },
  subtitle: {
    fontWeight: "700",
    fontSize: "16px",
    // lineHeight: "19px",
    letterSpacing: "0.02em",
    // color: palette.grey.grey_100,
  },
  formTitle: {
    fontWeight: "500",
    fontSize: "20px",
    // lineHeight: "23px",
    // letterSpacing: "0.02em",
    color: palette.white.main,
  },
};

export const scenarioSectionStyle = {
  title: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.02em",
    color: palette.grey.grey_100,
  },
  player: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.02em",
    color: palette.white.main,
  },
  team: {
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "23px",
    letterSpacing: "0.02em",
    color: palette.grey.grey_100,
  },
};

export const loginStyle = {
  fontWeight: "700",
  fontSize: "32px",
  lineHeight: "40px",
  color: "#FFFFFF",
  outerText: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#848E9C",
  },
};

export const creditPurchase = {
  title: {
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "40px",
    color: palette.white.main,
  },
  subtitle: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#848E9C",
  },
  fiatAmount: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#848E9C",
  },
  highlighted: {
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "16px",
    color: palette.primary.main,
  },
  discounted: {
    fontWeight: "700",
    fontSize: "12px",
    lineHeight: "16px",
    color: '#10CB80',
  },
  navMenu: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
  },
  paymentAccepted: {
    title: {
      fontWeight: "700",
      fontSize: "24px",
      lineHeight: "28px",
      color: palette.white.main,
    },
    subtitle: {
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "21px",
      color: palette.white.main,
      textAlign: "center",
    },
    coins: {
      fontWeight: "700",
      fontSize: "48px",
      lineHeight: "56px",
      color: palette.white.main,
      textAlign: "center",
    },
  },
};

export const filterMenu = {
  subItem: {
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#848E9C",
  },
};

export const scenarioInfo = {
  chiave: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#848E9C",
  },
  valore: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "16px",
    color: palette.white.main,
  },
};

export const profilePageStyle = {
  title: {
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38px",
    color: "#FFFFFF",
  },
  credits: {
    fontWeight: "700",
    fontSize: "40px",
    lineHeight: "32px",
    color: palette.white.main,
  },
  name: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "32px",
    color: "#FFFFFF",
  },
  creationDate: {
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#848E9C",
  },
  email: {
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#848E9C",
  },
  creditTitle: {
    fontWeight: "700",
    fontSize: scale.default.x2,
    lineHeight:  scale.default.x3,
    color: palette.white.main,
  },
  creditSubTitle: {
    fontWeight: "700",
    fontSize: '14px',
    color: palette.grey.grey_100,
  },
  actionText: {
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "16px",
    color: "#FFF",
  },
  plan: {
    timeLeft: {
      fontWeight: "700",
      fontSize: "40px",
      lineHeight: "32px",
      color: "#848E9C",
    },
    scadenza: {
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "24px",
      color: "#848E9C",
    },
  },
  ordersHistory: {
    title: {
      marginRight: '12px',
      fontWeight: "700",
      fontSize: "12px",
      lineHeight: "16px",
      color: "#848E9C",
    },
  },
};
