import { ButtonBase, Typography } from "@mui/material";
import React from "react";
import { icons } from "../../../assets/styles/constant/general";
import { palette } from "../../../assets/styles/constant/palette";
import { creditPurchase, styleConstant, textSecondaryStyle } from "../../../assets/styles/constant/styleConstants";
import CustomIcon from "../CustomIcon";

const PurchaseCard = ({
  type,
  fiatAmount,
  creditAmount,
  title,
  noAdsPeriod = null,
  style = null,
  selected = false,
  onSelect,
  highlighted = false,
  promoData = null,
  mobile = false,
  disabled,
  titleStyle = null,
  hideCredits = false,
}) => {
  return (
    <ButtonBase style={{ display: "flex", opacity: disabled ? "0.5" : "1", ...style }} onClick={onSelect} disabled={disabled}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderRadius: styleConstant.borderRadius.x2,
          border: selected ? `2px solid ${palette.primary.main}` : `2px solid transparent`,
          minHeight: "100px",
          background: palette.grey.grey_300,
          padding: `${styleConstant.padding.x4} ${styleConstant.padding.x5}`,
          cursor: "pointer",
          flex: 1,
          position: "relative",
        }}
      >
        {highlighted && (
          <div
            style={{
              position: "absolute",
              top: "8px",
              right: "16px",
              display: "flex",
              justifyContent: "center",
              borderRadius: styleConstant.borderRadius.x4,
              alignItems: "center",
              background: `${palette.primary.main}` + "2B",
              height: "25px",
              width: "110px",
            }}
          >
            <Typography style={{ ...creditPurchase.highlighted }}>Più acquistato</Typography>
          </div>
        )}
        {promoData && (
          <div
            style={{
              position: "absolute",
              top: "8px",
              left: "32px",
              display: "flex",
              justifyContent: "center",
              borderRadius: styleConstant.borderRadius.x4,
              alignItems: "center",
              background: "#10CB802B",
              height: "20px",
              width: "80px",
            }}
          >
            <Typography style={{ ...creditPurchase.discounted }}>SCONTATO</Typography>
          </div>
        )}
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
          <Typography style={{ ...creditPurchase.title, ...titleStyle }}>{title}</Typography>
          {promoData && promoData.promoType === 'price' ?
            <div style={{display:'flex', flexDirection: 'row', gap: 8}}>
              <Typography style={{
                textDecoration: 'line-through',
                textDecorationColor: '#10CB80', 
                alignItems: 'center',
                ...creditPurchase.fiatAmount
                }}>
                {fiatAmount}
              </Typography>
              <CustomIcon icon={icons.CHEVRON_RIGHT} style={{alignSelf: 'center'}} color='#10CB80'/>
              <Typography style={{...creditPurchase.fiatAmount, color: '#10CB80'}}>{promoData.price} €</Typography>
            </div>
          : <Typography style={creditPurchase.fiatAmount}>{fiatAmount}</Typography>
          }
          {type === "ads" && (
            <div style={{ display: "flex", marginTop: styleConstant.margin.x2, alignItems: "center", gap: "4px" }}>
              <CustomIcon icon={icons.CHECK} style={{ marginRight: "4px" }} />
              <Typography variant="smallTitle">{noAdsPeriod}</Typography>
              <Typography variant="smallTitle" style={textSecondaryStyle}>
                senza pubblicità
              </Typography>
            </div>
          )}
        </div>
        {!hideCredits && (
          promoData && promoData.promoType === 'credits' ? 
            <div style={{disply: 'flex', flexDirection: 'column'}}>
              <div style={{ display: "flex", gap: styleConstant.gap.small, alignItems: "center", position: 'relative'}}>
                <Typography variant="title" color={'#10CB80'}>{promoData.coins}</Typography>
                <CustomIcon icon={icons.COINS} color={'#10CB80'} size="xl" />
                <div style={{position: 'absolute', bottom: '-24px', right: 0, alignItems: 'center'}}>
                <Typography variant="title" style={{
                  fontSize: 16, 
                  color:'#848E9C',
                  textDecoration: 'line-through',
                }}>
                  {creditAmount}
                </Typography>
                <CustomIcon icon={icons.COINS} color={'#848E9C'} size="sm" />
                </div>
              </div>
            </div>
          : 
          <div style={{ display: "flex", gap: styleConstant.gap.small, alignItems: "center" }}>
          <Typography variant="title">{creditAmount}</Typography>
          <CustomIcon icon={icons.COINS} color={palette.white.main} size="xl" />
        </div>
        )}
      </div>
    </ButtonBase>
  );
};

export default PurchaseCard;
