import { convertArrayToObject } from "../helpers/util";
import { getTotalPriceCalculationTool1, getTotalPriceCalculationTool2 } from "./calculation";

export const createCalculationObject = async (configurationObject, scenariosObject, settings) => {
  let calcPrice;
  let newCalculationObject = {
    id: null,
    configuration: configurationObject,
    scenarios: scenariosObject,
    price: null,
    result: null,
  };
  let temporaryCalculationObject = JSON.parse(JSON.stringify(newCalculationObject));
  const calcObjsArray = [temporaryCalculationObject];
  if (settings.tool == 1) calcPrice = await getTotalPriceCalculationTool1(calcObjsArray, settings.subject);
  if (settings.tool == 2) calcPrice = await getTotalPriceCalculationTool2(calcObjsArray, settings.subject);
  newCalculationObject.price = calcPrice.amount;
  return newCalculationObject;
};

export const addCalculationInList = (calculationObject, calculationList) => {
  const newId = generateId(calculationList);
  calculationObject.id = newId;
  calculationList[newId] = calculationObject;
  return calculationList;
};

export const updateCalculationObject = async (calculationObject, configurationObject = null, scenariosObject, settings = null, allScenarios = null) => {
  let calcPrice;
  const idStatistic = calculationObject.configuration.statistic.value;
  if (configurationObject) calculationObject.configuration = configurationObject;
  let newScenariosObject = JSON.parse(JSON.stringify(scenariosObject));
  if (scenariosObject) {
    Object.values(scenariosObject).map((scenarios) => {
      const idDay = scenarios.day;
      const possibleScenariosValuesForMinutes = calculationObject.configuration.player.additional[idDay][idStatistic]["2"].id_valori_scenario;
      const selectedMinutes = scenarios.selected["2"];
      const selectedTitolarita = scenarios.selected["1"];
      if (selectedTitolarita.value == 1) {
        if (parseInt(selectedMinutes.value.label) < 45) {
          let newMinutesSource = { ...scenarios.source["2"], valori: {} };
          possibleScenariosValuesForMinutes.map((scenarioValue) => {
            if (parseInt(allScenarios["2"].valori[scenarioValue].nome) >= 45) {
              newMinutesSource.valori[scenarioValue] = allScenarios["2"].valori[scenarioValue];
            }
          });
          newScenariosObject[scenarios.day].source["2"] = newMinutesSource;
          const newSelectedLabel = Object.values(newMinutesSource.valori)[0].nome;
          const newSelectedValue = Object.values(newMinutesSource.valori)[0].id;
          newScenariosObject[scenarios.day].selected["2"].value = { label: newSelectedLabel, value: newSelectedValue };
        }
      } else {
        if (parseInt(selectedMinutes.value.label) >= 45) {
          let newMinutesSource = { ...scenarios.source["2"], valori: {} };
          possibleScenariosValuesForMinutes.map((scenarioValue) => {
            if (parseInt(allScenarios["2"].valori[scenarioValue].nome) < 45) {
              newMinutesSource.valori[scenarioValue] = allScenarios["2"].valori[scenarioValue];
            }
          });
          newScenariosObject[scenarios.day].source["2"] = newMinutesSource;
          const newSelectedLabel = Object.values(newMinutesSource.valori)[0].nome;
          const newSelectedValue = Object.values(newMinutesSource.valori)[0].id;
          newScenariosObject[scenarios.day].selected["2"].value = { label: newSelectedLabel, value: newSelectedValue };
        }
      }
    });
  }
  calculationObject.scenarios = newScenariosObject;
  if (!settings) return calculationObject;
  let temporaryCalculationObject = JSON.parse(JSON.stringify(calculationObject));
  const calcObjsArray = [temporaryCalculationObject];
  if (settings.tool == 1) calcPrice = await getTotalPriceCalculationTool1(calcObjsArray, settings.subject);
  if (settings.tool == 2) calcPrice = await getTotalPriceCalculationTool2(calcObjsArray, settings.subject);
  calculationObject.price = calcPrice.amount;
  calculationObject.result = null;
  return calculationObject;
};

export const calculateTotalPriceFromList = (calculationList) => {
  const calculationList_clone = JSON.parse(JSON.stringify(calculationList));
  const calcListToArray = Object.values(calculationList_clone);
  let newPrice = 0;
  for (let calcObj of calcListToArray) {
    if (calcObj.result) continue;
    newPrice += calcObj.price;
  }
  return newPrice;
};

export const updateResultInList = (calcList, calcId, result) => {
  const calcList_clone = JSON.parse(JSON.stringify(calcList));
  calcList_clone[calcId].result = result;
  return calcList_clone;
};

export const updateResultsInList = (calcList, results) => {
  const calcList_clone = JSON.parse(JSON.stringify(calcList));
  results.map((result) => {
    const calcId = result.calcId;
    const newRes = JSON.parse(JSON.stringify(result));
    delete newRes.calcId;
    calcList_clone[calcId].result = newRes;
    calcList_clone[calcId].price = 0;
  });
  return calcList_clone;
};

export const updateCalculationInList = (calculationList, updatedCalculationObject) => {
  calculationList[updatedCalculationObject.id] = updatedCalculationObject;
  return calculationList;
};

export const buildScenariosObject = (playerData, days, idStatistic, allScenarios) => {
  let scenariosObject = {};
  days.map((day) => {
    const idDay = day.value;
    const scenarios = convertArrayToObject(playerData[idDay][idStatistic]);
    scenariosObject[idDay] = { day: idDay, source: {}, selected: {} };
    Object.keys(scenarios).map((idScenario) => {
      const nomeScenario = allScenarios[idScenario].nome;
      scenariosObject[idDay].source[idScenario] = { id: idScenario, nome: nomeScenario, valori: {} };
      const playerScenarioValues = scenarios[idScenario].id_valori_scenario;
      // se minuti come inizializzazione prende solo valori minori di 45 (perchè titolarità di default = titolare)
      playerScenarioValues.map((playerScenarioValue) => {
        if (idScenario == 2) {
          const defaultSelectedTitolarita = scenarios[1].id_valori_scenario[0];
          if (defaultSelectedTitolarita == 1) {
            if (parseInt(allScenarios[idScenario].valori[playerScenarioValue].nome) >= 45) {
              scenariosObject[idDay].source[idScenario].valori[playerScenarioValue] = convertArrayToObject(
                allScenarios[idScenario].valori[playerScenarioValue]
              );
            }
          } else if (defaultSelectedTitolarita == 2) {
            if (parseInt(allScenarios[idScenario].valori[playerScenarioValue].nome) < 45) {
              scenariosObject[idDay].source[idScenario].valori[playerScenarioValue] = convertArrayToObject(
                allScenarios[idScenario].valori[playerScenarioValue]
              );
            }
          }
        } else {
          scenariosObject[idDay].source[idScenario].valori[playerScenarioValue] = convertArrayToObject(allScenarios[idScenario].valori[playerScenarioValue]);
        }
      });
      let defaultSelected = null;
      let defaultSelectedProvaTemp = null;
      for (let scenarioVal of Object.values(scenariosObject[idDay].source[idScenario].valori)) {
        if (scenarioVal.id == playerScenarioValues[0]) {
          defaultSelectedProvaTemp = scenarioVal;
          break;
        }
      }
      if (idScenario == 2 || idScenario == 3) {
        const value = defaultSelectedProvaTemp.id;
        const label = defaultSelectedProvaTemp.nome;
        defaultSelected = { value, label };
      } else if (idScenario == 1 || idScenario == 4) {
        defaultSelected = defaultSelectedProvaTemp.id;
      }
      scenariosObject[idDay].selected[idScenario] = { id: idScenario, nome: nomeScenario, value: defaultSelected };
    });
  });
  return scenariosObject;
};

export const deleteCalculationFromList = (id, calculationList) => {
  if (!calculationList[id]) {
    throw "L'OGGETTO NON ESISTE";
  }
  let end = Object.values(calculationList).length;
  for (let i = id; i < end; i++) {
    calculationList[i] = JSON.parse(JSON.stringify(calculationList[i + 1]));
    calculationList[i].id = i;
  }
  delete calculationList[end];
  return calculationList;
};

export const createDuplicatedCalculus = (duplicatedCalculus) => {
  let newDuplicated = JSON.parse(JSON.stringify(duplicatedCalculus));
  delete newDuplicated.id;
  return newDuplicated;
};

export const duplicateCalculusInList = (duplicate, calculationList) => {
  const newCalculationList = addCalculationInList(duplicate, calculationList);
  return [newCalculationList, duplicate];
};

export const newSelectedCalculusAfterDelete = (deletedId, oldList) => {
  const oldListLength = Object.values(oldList).length;
  if (deletedId == 1 && oldListLength > 1) return deletedId;
  if (deletedId == 1 && oldListLength <= 1) return -1;
  if (deletedId >= 2) return deletedId - 1;
};

const generateId = (calculationList) => {
  const newId = Object.values(calculationList).length + 1;
  return newId;
};
