import { Typography, Table } from '@mui/material';
import { TableHead } from '@mui/material';
import { TableContainer } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableBody } from '@mui/material';
import { TableCell } from '@mui/material';
import { Stack } from '@mui/material';
import React from 'react';
import { palette } from '../../assets/styles/constant/palette';

const ResultSection = ({activeMode, result = null, resultMessage = null}) => {

	const renderResultTable = () => (
		<TableContainer>
			<Table stickyHeader>
				<TableHead>
					<TableRow>
						<TableCell sx={{
							background: 'transparent',
							width: '80%'
						}}>
							{/* <Typography
								fontSize={'24px'}
								color={palette.grey.grey_100}
								fontWeight={'600'}>
									
							</Typography> */}
						</TableCell>
						<TableCell sx={{
							background: 'transparent',
							width: '20%'
						}}>
							<Typography
								fontSize={'24px'}
								color={palette.grey.grey_100}
								fontWeight={'600'}>
									Probabilità
							</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.keys(result).map((resId, idx) => {
						return <TableRow key={idx}>
						<TableCell>
							<Typography
								textAlign={'start'}
								fontSize={'18px'}
								color={'#FFF'}
								fontWeight={'600'}>
									{resId}
							</Typography>
						</TableCell>
						<TableCell>
							<Typography
								textAlign={'center'}
								fontSize={'18px'}
								color={'#FFF'}
								fontWeight={'600'}>
									{result[resId]}
							</Typography>
						</TableCell>
					</TableRow>
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)

	const ScrollContainer = ({children}) => (
		<div id='custom-scrollbar' style={{
			position: 'absolute',
			right: 0, left: 0, bottom: 0, top: 0,
			paddingRight: '24px',
			overflow: 'auto',
			flexDirection: 'column',
			gap: '8px',
			flex: 1
		}}>{children}</div>
	)

	return (
		<Stack flex={1}
			borderRadius={'16px'}
			p={'24px 0px 24px 24px'}
			style={{background: palette.grey.grey_500}}>
				{activeMode === 2 && <Typography color={'#FFF'}
					fontWeight={'500'}
					fontSize={'24px'}>
						Lista risultati
				</Typography>}
				{activeMode !== 2 
					? <Stack flex={1}
							justifyContent={'center'}
							alignItems={'center'}>
							<Typography
								fontWeight={'700'}
								fontSize={'50px'}
								color={'#FFF'}>
									{result || '-- %'}
							</Typography>
							<Typography
								fontWeight={'700'}
								fontSize={'24px'}
								color={'#FFF'}>
									Probabilità
							</Typography>
						</Stack>
					: <Stack mt={'8px'}
							overflow={'auto'}
							flex={1}
							position={'relative'}>
								<ScrollContainer>
									{result && renderResultTable()}
								</ScrollContainer>
					</Stack>
				}
		</Stack>
	)
}

export default ResultSection;