import React from "react";
import { palette } from "../assets/styles/constant/palette";
import main_logo from "../assets/images/main_logo.svg";
import { Alert, AlertTitle, Divider, Typography } from "@mui/material";
import { loginStyle, styleConstant } from "../assets/styles/constant/styleConstants";
import FormSkeleton from "../core/components/input/FormSkeleton";
import CustomTextField from "../core/components/input/CustomTextField";
import TextButton from "../core/components/buttons/TextButton";
import ContainedButton from "../core/components/buttons/ContainedButton";
import CustomIcon from "../core/components/CustomIcon";
import { regexEmail, regexPassword } from "../service/constant/regex";
import { icons } from "../assets/styles/constant/general";
import { useNavigate } from "react-router-dom";
import SocialButton from "../core/components/input/SocialButton";
import { connect } from "react-redux";
import { forgetPassword } from "../service/UserAuth";
import { loginUser, loginUserApple, loginUserFacebook, loginUserGoogle } from "../store/actions";

const Login = ({ mobile = false, onLoginGoogle, onLoginFacebook, onLoginApple, onLogin, errorCode }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailHelperText, setEmailHelperText] = React.useState(null);
  const [passwordHelperText, setPasswordHelperText] = React.useState(null);
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordForgot, setPasswordForgot] = React.useState(false);
  const [resetEmailSent, setResetEmailSent] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (errorCode && !disableButton()) {
      switch (errorCode) {
        case "auth/wrong-password":
          setPasswordError(true);
          setPasswordHelperText({ text: "Password invalida", color: "#f74848" });
          break;
        case "auth/user-not-found":
          setEmailError(true);
          setEmailHelperText({ text: "Email invalida", color: "#f74848" });
          break;
        default:
          break;
      }
    }
  }, [errorCode]);

  const isEmailValid = (email) => {
    if (regexEmail.test(email) && email.length !== 0) return true;
    return false;
  };

  const isPasswordValid = (password) => {
    if (regexPassword.test(password) && password.length !== 0) return true;
    return false;
  };

  const onSubmit = () => {
    const user = { email, password };
    onLogin(user);
  };

  const disableButton = () => {
    console.log(email.length === 0 || password.length === 0);
    return email.length === 0 || password.length === 0;
  };

  const resetPassword = () => {
    forgetPassword(email);
    setResetEmailSent(true);
  };

  const backToLogin = () => {
    setEmail("");
    setResetEmailSent(false);
    setPasswordForgot(false);
  };

  const gotoRegister = () => {
    setEmailError(false);
    setPasswordError(false);
    setEmailHelperText(null);
    setPasswordHelperText(null);
    navigate("/register");
  };

  const renderErrorLoginComponent = () => {
    if (errorCode == null || errorCode == '') return <></>
    return (
      <Alert variant="filled"
        severity="error"
        style={{ display: 'flex', marginTop: 16 }}>
        <AlertTitle>Ops.. si è verificato un errore durante il login</AlertTitle>
        Controlla che l'email o la password inserite siano corrette. Se non sei registrato ti invitiamo a creare un account.
      </Alert>
    )
  }

  return (
    <div
      style={{
        background: palette.grey.grey_600,
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        overflowY: "overlay",
      }}
      id="custom-scrollbar"
    >
      <div
        style={{
          maxHeight: "100vh",
          marginTop: "auto",
          marginBottom: "auto",
          width: mobile && "100vw",
        }}
      >
        <div style={mobile ? formContainerMobileStyle : formContainerDesktopStyle}>
          <img src={main_logo} style={{ maxHeight: "150px", maxWidth: "150px", alignSelf: "center" }} />
          <Typography style={{ ...loginStyle, marginTop: styleConstant.margin.x7 }}>Login</Typography>
          {renderErrorLoginComponent()}
          {passwordForgot ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography style={{ fontWeight: "700", color: "white", marginTop: styleConstant.margin.x2 }}>
                Inserisci la tua email. Ti verrà mandata un mail per reimpostare la tua password
              </Typography>
              <FormSkeleton label="Email" style={{ marginTop: styleConstant.margin.x1, flex: "0" }}>
                <CustomTextField content={email} onChange={(email) => setEmail(email)} type="email" />
              </FormSkeleton>
              {!resetEmailSent ? (
                <ContainedButton text="Invia" style={{ marginTop: styleConstant.margin.x5 }} onClick={resetPassword} />
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "center", marginTop: styleConstant.margin.x4, gap: styleConstant.gap.small, alignItems: "center" }}
                >
                  <Typography style={{ fontWeight: "500", color: "white" }}>Controlla la tua casella di posta per procedere </Typography>
                  <CustomIcon icon={icons.CHECK} />
                </div>
              )}
              <TextButton text={"Torna al login"} style={{ marginTop: styleConstant.margin.x2 }} action={backToLogin} />
            </div>
          ) : (
            <>
              <FormSkeleton label="Email" style={{ marginTop: styleConstant.margin.x4, flex: "0" }} helperText={emailHelperText}>
                <CustomTextField content={email} onChange={(email) => setEmail(email)} className={emailError ? "error" : ""} type="email" />
              </FormSkeleton>
              <FormSkeleton
                label="Password"
                style={{ marginTop: styleConstant.margin.x2, flex: "0" }}
                secondAction={() => setPasswordForgot(true)}
                secondActionLabel="Password dimenticata?"
                helperText={passwordHelperText}
              >
                <CustomTextField password content={password} onChange={(password) => setPassword(password)} className={passwordError ? "error" : ""} />
              </FormSkeleton>
              <ContainedButton text="Login" style={{ marginTop: styleConstant.margin.x3, height: "50px" }} onClick={onSubmit} disabled={disableButton()} />
              <Typography style={{ color: "grey", fontSize: 14, fontWeight: "bold", margin: "auto", marginTop: styleConstant.margin.x3 }}>Oppure</Typography>
              <SocialButton
                icon={<CustomIcon icon={icons.GOOGLE} color={palette.black.main} />}
                text={"Continua con Google"}
                style={{ marginTop: styleConstant.margin.x3, height: "50px" }}
                onClick={() => onLoginGoogle()}
              />
              <SocialButton
                icon={<CustomIcon icon={icons.FACEBOOK} color={palette.black.main} />}
                text={"Continua con Facebook"}
                style={{ marginTop: styleConstant.margin.x2, height: "50px" }}
                onClick={() => onLoginFacebook()}
              />
              <SocialButton
                icon={<CustomIcon icon={icons.APPLE} color={palette.black.main} />}
                text={"Continua con Apple"}
                style={{ marginTop: styleConstant.margin.x2, height: "50px" }}
                onClick={() => onLoginApple()}
              />
            </>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: styleConstant.margin.x1,
          }}
        >
          <Typography variant="smallTitle" style={loginStyle.outerText}>
            Non hai ancora un account?
          </Typography>
          <TextButton text={"Registrati"} action={gotoRegister} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Login }) => ({
  errorCode: Login.errorType,
});

const mapDispatchToProps = (dispatch) => ({
  onLogin: (user) => dispatch(loginUser(user)),
  onLoginGoogle: () => dispatch(loginUserGoogle()),
  onLoginFacebook: () => dispatch(loginUserFacebook()),
  onLoginApple: () => dispatch(loginUserApple()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const formContainerMobileStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "0px 20px",
};
const formContainerDesktopStyle = {
  minWidth: "500px",
  maxWidth: "500px",
  minheight: "600px",
  background: palette.grey.grey_500,
  display: "flex",
  flexDirection: "column",
  padding: styleConstant.padding.x5,
  borderRadius: styleConstant.borderRadius.x2,
};
