import { ButtonBase, Card, CardActionArea, Stack, Typography } from '@mui/material';
import React from 'react';
import { icons } from '../../assets/styles/constant/general';
import { palette } from '../../assets/styles/constant/palette';
import { convertArrayToObject } from '../../helpers/util'
import CustomIconButton from './buttons/CustomIconButton';
import CustomIcon from './CustomIcon';

const TeamCard = ({teamObject, onClick, isActive = true, onDelete = () => {} }) => {

	const { id, fantaType, leagueName, name, players } = teamObject;
	const playersNumber = players ? Object.values(convertArrayToObject(players)).length : 0;

	const renderActiveBanner = () => (
		<Stack 
			position={'absolute'}
			top={0} left={0} bottom={0}
			style={{background: palette.primary.main}}
			width={'6px'} />
	)

	const renderDeleteButton = () => (
		<Stack
			position={'absolute'}
			top={'16px'} right={'24px'}>
				<CustomIconButton 
					onClick={onDelete}
					icon={
						<CustomIcon icon={icons.TRASH_CAN} />
					} />
		</Stack>
	)

	const TeamInfo = ({infoName, infoValue, style}) => (
		<Stack style={{...style}}>
			<Typography color={palette.grey.grey_100}
				fontWeight={'700'}
				variant='smallTitle'>
				{infoName}
			</Typography>
			<Typography color={'#FFF'}
				overflow={'hidden'}
				textOverflow={'ellipsis'}
				alignItems={'flex-start'}
				variant='body'>
					{infoValue}
			</Typography>
		</Stack>
	)

	return (
		<Card variant='playerCard'>
			<CardActionArea 
				onClick={onClick}
				className='outer-content'>
				<Stack flex={1} alignItems={'start'}>
					<Typography variant='title' 
						color={'#FFF'}>
							{name}
					</Typography>
					<Stack gap={'16px'}
						width={'100%'}
						marginTop={'8px'}
						direction={'row'}>
						<TeamInfo 
							infoName={'Fantacalcio'}
							style={{maxWidth: '35%'}}
							infoValue={fantaType}/>
						<TeamInfo 
							infoName={'Giocatori'} 
							infoValue={playersNumber}/>
						<TeamInfo 
							infoName={'Lega'} 
							style={{maxWidth: '35%'}}
							infoValue={leagueName}/>	
					</Stack>
				</Stack>
			</CardActionArea>
			{renderDeleteButton()}
			{isActive && renderActiveBanner()}
		</Card>
	)
}

export default TeamCard;