import { CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import React from 'react';
import { palette } from '../../../assets/styles/constant/palette';
import InputSelect from '../../../core/components/input/InputSelect';
import CustomTextField from '../../../core/components/input/CustomTextField'
import FormSkeleton from '../../../core/components/input/FormSkeleton';
import ContainedButton from '../../../core/components/buttons/ContainedButton';
import CustomIcon from '../../../core/components/CustomIcon';
import { icons } from '../../../assets/styles/constant/general';
import CustomIconButton from '../../../core/components/buttons/CustomIconButton';
import { textSecondaryStyle } from '../../../assets/styles/constant/styleConstants';
import { connect } from 'react-redux';
import { reloadUserCredit } from '../../../store/profile/actions';
import CustomSearchBar from '../../../core/components/input/CustomSearchBar';
import { calculationPlayerEvaluation } from '../../../service/calculation';
import CalculusLoading from '../../../core/components/CalculatorSections/CalculusLoading'
import CustomDialog from '../../../core/components/CustomDialog'
import TextButton from '../../../core/components/buttons/TextButton';

const mockedResultObj = {
	primo: 'primo valore dell oggetto',
	secondo: 'secondo valore',
	terzo: 'ultimo valore della lista'
}

const FANTA_TYPE_ID = '1';
const LEAGUE_TEAMS_ID = '2';
const CREDITS_ID = '3';

const EvaluatePlayers = (props) => {

	const { loadingEvaluatePlayerPrice, loadingEvaluatePlayerSettings, loadingEvaluatePlayerGuide, fantaPlayers } = props;

	// Tipologia fantacalcio
	const [fantaType, setFantaType] = React.useState('');
	const [fantaTypeListItem, setFantaTypeListItem] = React.useState([]);
	// Squadre nella lega
	const [leagueTeams, setLeagueTeams] = React.useState('');
	// Crediti
	const [credits, setCredits] = React.useState('');
	// Giocatore
	const [player, setPlayer] = React.useState(null);
	// Risulato
	const [resultObject, setResultObject] = React.useState(null);
	const [loading, setLoading] = React.useState();
	const [helpModalVisible, setHelpModalVisible] = React.useState(false);

	React.useEffect(() => {
		init();
	}, []);

	const init = () => {
		const { fantaEvaluatePlayerSettings } = props;
		const _fantaTypeListItems = fantaEvaluatePlayerSettings[FANTA_TYPE_ID].valori.map((val, id) => {
			return { label: val, value: id};
		});
		setFantaTypeListItem(_fantaTypeListItems);
		setFantaType(_fantaTypeListItems[0]);
		const initialLeagueTeamsVal = fantaEvaluatePlayerSettings[LEAGUE_TEAMS_ID].valori;
		const initialCreditsVal = fantaEvaluatePlayerSettings[CREDITS_ID].valori;
		setLeagueTeams(initialLeagueTeamsVal);
		setCredits(initialCreditsVal);
	}

	const execCalc = async() => {
		try {
			setLoading(true);
			const cfData = getCfData();
			const cfResponse = await calculationPlayerEvaluation(cfData);
			console.log('190', cfResponse)
			if (cfResponse.outcome === 'error') throw cfResponse.error;
			const resultObj = cfResponse.data;
			setResultObject(resultObj.risultato);
			props.reloadUserCredits(props.uid);
			setLoading(false);
		} catch (error) {
			console.log('Exec calc error', error);
			setLoading(false)
		}
	}

	const getNonSelectedPlayers = () => {
		if (!player) return Object.keys(props.fantaPlayers);
		return Object.keys(props.fantaPlayers).filter(id => id !== player);
	}

	const onPlayerSelect = (playerId) => {
		setPlayer(playerId.toString());
	}

	const getSortedResultObject = () => {
		const sortedIds = Object.keys(resultObject).sort().map(id => ({
			name: id,
			value: resultObject[id]
		}));
		// console.log('sorted ids', sortedIds);
		// const sortedResultObject = sortedIds.map(id => {
		// 	const resName = props.fantaEvaluatePlayerGuide[id].nome;
		// 	const resValue = resultObject[id];
		// 	return { name: resName, value: resValue };
		// });
		// console.log('sorted result object', sortedResultObject);
		//return sortedResultObject;
		return sortedIds;
	}

	const getCfData = () => {
		const { fantaEvaluatePlayerPrice, uid } = props;
		const cfData = {
			uid: uid,
			price: fantaEvaluatePlayerPrice,
			payload: {
				idUtente: uid,
				app: 'web',
				idGiocatore: player,
				impostazioni: {
					'1': fantaType?.label,
					'2': leagueTeams,
					'3': credits,
				}
			}
		};
		return cfData;
	}

	const renderTitle = () => (
		<Stack>
			<Typography
				variant='bigTitle'>
					Valuta giocatore
			</Typography>
		</Stack>
	)

	const renderSettingsSection = () => (
		<Stack flex={1}
			maxWidth={'33%'}
			borderRadius={'16px'}
			p={'24px 0px 24px 24px'}
			boxShadow={'rgb(0 0 0 / 20%) 4px 0px 22px 0px'}
			style={{background: palette.grey.grey_400}}>
				{renderForm()}
			<ContainedButton
				style={{marginTop: 'auto', marginRight: '24px', height: '50px'}}
				disabled={!fantaType || credits.length === 0 || leagueTeams.length === 0 || !player}
				text={'Calcola ' + props.fantaEvaluatePlayerPrice}
				icon={<CustomIcon icon={icons.COINS} color={'#000'} />}
			 	onClick={execCalc} />
		</Stack>
	)

	const ResultSection = () => (
		<Stack flex={1}
			maxWidth={'40%'}
			borderRadius={'16px'}
			p={'24px'}
			style={{background: palette.grey.grey_500}}>
				<Stack direction={'row'} gap={'16px'}>
					<Typography color={'#FFF'}
						fontWeight={'500'}
						fontSize={'24px'}>
							Risultato valutazione
					</Typography>
					<CustomIconButton
						icon={<CustomIcon
							icon={icons.QUESTION} 
							color={palette.primary.main} 
						/>} 
						onClick={() => setHelpModalVisible(true)} />
				</Stack>
				{
					resultObject 
				 		? renderResultTable()
						: <Typography
								variant="smallTitle"
								style={{
									...textSecondaryStyle,
									maxWidth: "90%",
									textAlign: "center",
									paddingLeft: '24px',
									marginTop: 'auto',
									marginBottom: 'auto',
									fontWeight: 500
								}}>
									Esegui il calcolo per visualizzarne il risultato
							</Typography>
				} 
		</Stack>
	)

	const renderResultTable = () => (
		<TableContainer style={{marginTop: '16px'}}>
			<Table>
				<TableBody>
					{getSortedResultObject().map((resObj, idx) => {
						return (
							<TableRow key={idx}>
								<TableCell>
									<Typography
										textAlign={'start'}
										fontSize={'18px'}
										color={'#FFF'}
										fontWeight={'600'}>
											{resObj.name}
									</Typography>
								</TableCell>
								<TableCell>
									<Typography
										textAlign={'end'}
										fontSize={'18px'}
										color={'#FFF'}
										fontWeight={'600'}>
											{resObj.value}
									</Typography>
								</TableCell>
							</TableRow>
						)
					})}
			</TableBody>
			</Table>
		</TableContainer>
	)

	const renderForm = () => (
		<Stack flex={1}
			position={'relative'}>
				<div id='custom-scrollbar' style={{
					position: 'absolute',
					right: 0, left: 0, bottom: 0, top: 0,
					paddingRight: '24px',
					overflow: 'auto',
					flexDirection: 'column',
					gap: '8px',
					flex: 1
				}}>
					<FormSkeleton label={props.fantaEvaluatePlayerSettings[FANTA_TYPE_ID].nome}>
						<InputSelect selectedValue={fantaType}
							onSelectChange={setFantaType}
							inputSelectItem={fantaTypeListItem}/>
					</FormSkeleton>
					<FormSkeleton label={props.fantaEvaluatePlayerSettings[LEAGUE_TEAMS_ID].nome}>
						<CustomTextField
							content={leagueTeams || ''}
							placeholder={'Squadre nella lega'}
							onChange={setLeagueTeams}/>
					</FormSkeleton>
					<FormSkeleton label={props.fantaEvaluatePlayerSettings[CREDITS_ID].nome}>
						<CustomTextField
							content={credits || ''}
							placeholder={'Crediti'}
							onChange={setCredits}/>
					</FormSkeleton>
					<FormSkeleton label='Giocatore'>
						<CustomSearchBar
							multiple={false}
							showSearchIcon={false}
							selectedData={player ? [player] : []}
							nonSelectedData={getNonSelectedPlayers()}
							itemsData={fantaPlayers}
							onItemSelect={onPlayerSelect} />
					</FormSkeleton>
				</div>
			</Stack>
	)

	const getGuideContent = () => {
		const { fantaEvaluatePlayerGuide } = props;
		return (
			<Stack p={'24px'}>
				{Object.values(fantaEvaluatePlayerGuide).map((guideObj, idx) => {
					const { nome, descrizione } = guideObj;
					return (
						<React.Fragment>
							<Typography style={{marginTop: idx !== 0 ? '16px' : '0px'}}
								variant="title">{nome}</Typography>
							<Typography variant="smallTitle">{descrizione}</Typography>
						</React.Fragment>
					)
				})}
				<TextButton 
					text="Chiudi" 
					action={() => setHelpModalVisible(false)}
					style={{ marginTop: '24px' }} />
			</Stack>
		)
	}

	if (loadingEvaluatePlayerGuide || loadingEvaluatePlayerPrice || loadingEvaluatePlayerSettings) {
		return (
			<Stack flex={1}>
				<Stack margin={'auto'} alignItems={'center'}>
					<CircularProgress
						style={{marginBottom: '24px'}}
						size={'6em'}/>
					<Typography variant='smallTitle'>
						Caricamento dati...
					</Typography>
				</Stack>
			</Stack>
		)
	}

	return (
		<Stack style={{
			flex: 1, 
			marginTop: '24px',
		}}>
			{renderTitle()}
			<Stack mt={'24px'}
				height={'90%'}
				direction={'row'} 
				gap={'32px'}>
				{renderSettingsSection()}
				<ResultSection />
			</Stack>
			{loading && <CalculusLoading />}
			<CustomDialog
        open={helpModalVisible}
        handleClose={() => setHelpModalVisible(false)}
        backDropClickClose
        style={{ zIndex: 10004 }}
        title={"Serve aiuto?"}
        customContent
        icon={icons.LIFE_RING}
        titlePosition="center"
        roundCorners>
        	{getGuideContent()}
      </CustomDialog>
		</Stack>
	)
}

const mapStateToProps = ({ Fanta, Profile }) => ({
  uid: Profile.userProfile.uid,
  userAppData: Profile.userProfile.appData,
  fantaPlayers: Fanta.fantaPlayers,
	fantaEvaluatePlayerPrice: Fanta.evaluatePlayerPrice,
  fantaEvaluatePlayerGuide: Fanta.evaluatePlayerGuide,
  fantaEvaluatePlayerSettings: Fanta.evaluatePlayerSettings,
	loadingEvaluatePlayerPrice: Fanta.loadingEvaluatePlayerPrice,
	loadingEvaluatePlayerGuide: Fanta.loadingEvaluatePlayerGuide,
	loadingEvaluatePlayerGuide: Fanta.loadingEvaluatePlayerSettings,
});

const mapDispatchToProps = dispatch => ({
	reloadUserCredits: (uid) => dispatch(reloadUserCredit(uid)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EvaluatePlayers);