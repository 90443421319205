import { Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { icons } from "../../../../assets/styles/constant/general";
import { palette } from "../../../../assets/styles/constant/palette";
import { calculatorMobileSettings, styleConstant } from "../../../../assets/styles/constant/styleConstants";
import { setSelectedSettings } from "../../../../store/actions";
import ContainedButton from "../../../components/buttons/ContainedButton";
import CustomIconButton from "../../../components/buttons/CustomIconButton";
import TextButton from "../../../components/buttons/TextButton";
import CustomDialog from "../../../components/CustomDialog";
import CustomIcon from "../../../components/CustomIcon";
import FormSkeleton from "../../../components/input/FormSkeleton";
import InputRadio from "../../../components/input/InputRadio";

const config = [
  [
    { label: "Probabilità", value: 1 },
    { label: "Media", value: 2 },
  ],
  [
    { label: "Giocatore", value: 1 },
    { label: "Squadra", value: 2 },
  ],
];

const CalculatorSettingsMobile = ({
  onClose,
  // radioItems = config,
  calculationsList,
  loadSettings,
  settings,
  subjects,
  tools,
  guides,
}) => {
  const confirmationText = "Sono gìà presenti dei calcoli, modificando le impostazioni potrebbero verificarsi perdite di dati, procedere comunque?";
  const [selectedCalculusType, setSelectedCalculusType] = React.useState(1);
  const [selectedCalculusSubject, setSelectedCalculusSubject] = React.useState(1);
  const [confirmationDialogOpen, setConfirmationDialogOpen] = React.useState(false);
  const [isGuidesOpen, setIsGuidesOpen] = React.useState(false);

  const radioItemsSubjects = Object.values(subjects).map((subject) => {
    return { value: subject.id, label: subject.nome };
  });
  const radioItemsTools = Object.values(tools).map((tool) => {
    return { value: tool.id, label: tool.nome };
  });

  React.useEffect(() => {
    setSelectedCalculusSubject(settings.subject);
    setSelectedCalculusType(settings.tool);
  }, []);

  const onSubmit = () => {
    if (calculationsList && Object.values(calculationsList).length > 0) {
      setConfirmationDialogOpen(true);
      return;
    }
    loadSettings({ tool: selectedCalculusType, subject: selectedCalculusSubject });
    onClose();
  };

  const onDialogConfirmation = () => {
    setConfirmationDialogOpen(false);
    loadSettings({ tool: selectedCalculusType, subject: selectedCalculusSubject });
    onClose();
  };

  const getGuideContent = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", padding: styleConstant.padding.x3, maxHeight: "50%", overflowY: "auto" }} id="custom-scrollbar">
        <Typography variant="title">Strumento</Typography>
        <Typography variant="smallTitle">{guides.strumenti[selectedCalculusType].descrizione}</Typography>
        <Typography variant="title" style={{ marginTop: styleConstant.margin.x3 }}>
          Oggetto
        </Typography>
        <Typography variant="smallTitle">{guides.oggetti[selectedCalculusSubject][selectedCalculusType].descrizione}</Typography>
        <TextButton text="Chiudi" action={() => setIsGuidesOpen(false)} style={{ marginTop: styleConstant.margin.x3 }} />
      </div>
    );
  };

  return (
    <>
      <CustomDialog
        open={confirmationDialogOpen}
        handleClose={() => setConfirmationDialogOpen(false)}
        style={{ zIndex: 10004 }}
        handleSecondaryAction={() => setConfirmationDialogOpen(false)}
        handlePrimaryAction={onDialogConfirmation}
        title={"Conferma per procedere"}
        secondaryActionText={"Annulla"}
        primaryActionText={"Procedi"}
        description={confirmationText}
        icon={icons.EXCLAMATION}
        mobile={true}
      />
      <CustomDialog
        open={isGuidesOpen}
        handleClose={() => setIsGuidesOpen(false)}
        backDropClickClose
        style={{ zIndex: 10004 }}
        title={"Serve aiuto?"}
        customContent
        icon={icons.LIFE_RING}
        titlePosition="center"
        roundCorners
      >
        {getGuideContent()}
      </CustomDialog>
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          background: palette.grey.grey_600,
          flexDirection: "column",
          justifyContent: "space-between",
          padding: styleConstant.padding.x4,
        }}
      >
        <div>
          <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <CustomIconButton icon={<CustomIcon icon={icons.XMARK} size="1x" />} onClick={onClose} />
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginTop: styleConstant.margin.x2 }}>
            <div style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography style={calculatorMobileSettings.title}>Imposta Calcolatore</Typography>
                <Typography style={calculatorMobileSettings.subtitle}>Imposta il calcolatore. Seleziona la tipologia e l'oggetto del calcolo</Typography>
              </div>
              <CustomIconButton icon={<CustomIcon icon={icons.QUESTION} color={palette.primary.main} />} onClick={() => setIsGuidesOpen(true)} />
            </div>
            <FormSkeleton style={{ marginTop: styleConstant.margin.x3 }} label="Tipologia del calcolo">
              <InputRadio radioItem={radioItemsTools} selectedValue={selectedCalculusType} handleSelect={(type) => setSelectedCalculusType(type)} />
            </FormSkeleton>
            <FormSkeleton style={{ marginTop: styleConstant.margin.x3 }} label="Oggetto del calcolo">
              <InputRadio
                radioItem={radioItemsSubjects}
                selectedValue={selectedCalculusSubject}
                handleSelect={(subject) => setSelectedCalculusSubject(subject)}
              />
            </FormSkeleton>
          </div>
        </div>
        <ContainedButton text="Modifica impostazioni" style={{ height: "48px" }} onClick={onSubmit} />
      </div>
    </>
  );
};

const mapStateToProps = ({ CalculationWork, Calculation }) => ({
  calculationsList: CalculationWork.calculationList,
  settings: CalculationWork.settings,
  subjects: Calculation.subjects,
  tools: Calculation.tools,
  guides: Calculation.guides,
});

const mapDispatchToProps = (dispatch) => ({
  loadSettings: (settings) => dispatch(setSelectedSettings(settings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorSettingsMobile);
