import React from "react";
import { styleConstant, textSecondaryStyle } from "../../../../assets/styles/constant/styleConstants";
import { Typography } from "@mui/material";
import CustomIconButton from "../../../components/buttons/CustomIconButton";

const CalculatorMobileHeader = ({ title, subtitle, icon, action }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", gap: styleConstant.gap.medium }}>
          <Typography variant="title">{title}</Typography> <CustomIconButton icon={icon} onClick={action} />
        </div>
        <Typography variant="smallTitle" style={textSecondaryStyle}>
          {subtitle}
        </Typography>
      </div>
    </div>
  );
};

export default CalculatorMobileHeader;
