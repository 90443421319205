import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { icons } from '../../../assets/styles/constant/general';
import { palette } from '../../../assets/styles/constant/palette';
import CustomIconButton from '../../../core/components/buttons/CustomIconButton';
import CustomIcon from '../../../core/components/CustomIcon';
import EmptySection from '../../../core/components/EmptySection';
import CustomSearchBar from '../../../core/components/input/CustomSearchBar';
import CustomTextField from '../../../core/components/input/CustomTextField';

const PlayerMultiselect = ({
	disabled, selectedPlayers = [], 
	selectedFantaTeamId,
	nonSelectedPlayers = [], 
	fantaPlayers, 
	onPlayerDelete = () => {},
	onPlaterAdd = () => {},
}) => {
	const selectedPlayersNumber = selectedPlayers.length;
	const [searchQuery, setSearchQuery] = React.useState('');
	const [focusOnSearch, setFocusOnSearch] = React.useState(false);


	React.useEffect(() => {
		setSearchQuery('');
	}, [disabled]);

	const onSearchQueryChange = (val) => {
		setSearchQuery(val);
	}	

	/* RENDER */

	const PlayerCard = ({
		playerName = 'Nome Giocatore', 
		onDelete = () => {}, 
		isFirst = false
	}) => (
		<Stack height={'56px'}
			padding={'8px 16px'}
			borderRadius={'8px'}
			alignItems={'center'}
			direction={'row'}
			marginTop={isFirst ? '0px' : '16px'}
			style={{background: palette.grey.grey_300}}
			justifyContent='space-between'>
				<Typography fontSize={'16px'} 
					fontWeight={'600'}
					color={'#FFF'}>
					{playerName}
				</Typography>
				<CustomIconButton 
					icon={<CustomIcon 
						icon={icons.TRASH_CAN} 
						color={palette.primary.main} 
					/>}
					onClick={onDelete} />
		</Stack>
	)

	const renderSearchBar = () => (
		<Stack position={'relative'}
			style={{opacity: disabled ? 0.5 : 1}}
			paddingRight={'24px'}>
			<CustomTextField
				content={searchQuery}
				disabled={disabled}
				placeholder={disabled ? '' : 'Cerca giocatore'}
				onChange={onSearchQueryChange}
				style={{
          "& .MuiOutlinedInput-input": { paddingLeft: "48px" },
          fontWeight:'700'
        }} />
			<CustomIcon
				fontSize={'24px'}
				style={{position: 'absolute', left: 12, top: 12}}
				icon={icons.MAGNIFYING_GLASS}/>
		</Stack>
	)

	const renderEmptySection = () => (
		<EmptySection
			title='Nessun giocatore'
			style={{marginTop: 'auto', marginBottom: 'auto'}}
			subtitle='Cerca e aggiungi giocatori alla tua rosa'
			icon={<CustomIcon 
				icon={icons.PEOPLE_GROUP}
				fontSize={'64px'}
				color={'#FFF'} />
			}/>
	)

	const renderPlayersList = () => (
		<Stack flex={1}
			marginY={'16px'}
			position={'relative'}>
				<div id='custom-scrollbar' style={{
					opacity: focusOnSearch ? 0.2 : 1,
					position: 'absolute',
					right: 0, left: 0, bottom: 0, top: 0,
					paddingRight: '24px',
					overflow: 'auto',
					flexDirection: 'column',
					gap: '8px',
				}}>
					{selectedPlayers.map((playerId, idx) => {
						const playerObj = fantaPlayers[playerId];
						const { nome, id } = playerObj;
						return <PlayerCard key={idx}
							onDelete={() => onPlayerDelete(id)}
							playerName={nome}
							isFirst={idx === 0} />
					})}
				</div>
			</Stack>
	)

	return (
		<Stack flex={1}
			marginTop={'24px'}>
			{selectedFantaTeamId && 
			<CustomSearchBar
			onMenuOpen={(state)=>{
				setFocusOnSearch(state)
			}}
			selectedData={selectedPlayers}
			nonSelectedData={nonSelectedPlayers}
			itemsData={fantaPlayers}
			onItemAdd={onPlaterAdd}
			onItemRemove={onPlayerDelete}
			menuStyle={{marginRight: '24px'}}
			containerStyle={{paddingRight: '24px'}} />}
				{
					selectedPlayers.length > 0
						? renderPlayersList()
						: renderEmptySection()
				}
		</Stack>
	)
}

const mapStateToProps = ({ Fanta, Profile }) => ({
	selectedFantaTeamId: Fanta.selectedFantaTeamId,
  fantaPlayers: Fanta.fantaPlayers,
});

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(PlayerMultiselect);