import { Typography } from "@mui/material";
import React from "react";
import { icons } from "../../../assets/styles/constant/general";
import { palette } from "../../../assets/styles/constant/palette";
import { creditPurchase, styleConstant } from "../../../assets/styles/constant/styleConstants";
import ContainedButton from "../buttons/ContainedButton";
import CustomIcon from "../CustomIcon";

const PurchaseError = ({ onTerminate }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        marginRight: styleConstant.padding.x5,
      }}
    >
      <CustomIcon icon={icons.XMARK} size="7x" color={palette.primary.main} style={{ marginRight: "auto", marginLeft: "auto" }} />
      <div style={{ display: "flex", justifyContent: "center", marginTop: styleConstant.margin.x5 }}>
        <Typography style={{ ...creditPurchase.paymentAccepted.title }}>Errore</Typography>
      </div>
      <Typography style={{ ...creditPurchase.paymentAccepted.subtitle, marginTop: styleConstant.margin.x5 }}>
        Si è verificato un errore durante l'acquisto!
      </Typography>
      <ContainedButton text="TERMINA" style={{ marginTop: "auto", height: "50px" }} onClick={onTerminate} />
    </div>
  );
};

export default PurchaseError;
