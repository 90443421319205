import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  FETCH_USER_REGISTRATION_DATA_SUCCESS,
  FETCH_USER_REGISTRATION_DATA,
  FETCH_USER_REGISTRATION_DATA_FAILED,
} from "./actionTypes"

export const registerUser = (user) => {
  console.log("event ", "registerUser")
  return {
    type: REGISTER_USER,
    payload: { user },
  }
}

export const registerUserSuccessful = userAuthInfo => {
  return {
    type: REGISTER_USER_SUCCESSFUL,
    payload: userAuthInfo,
  }
}

export const registerUserFailed = error => {
  return {
    type: REGISTER_USER_FAILED,
    payload: error,
  }
}

export const fetchUserRegistrationData = idUserInvitation =>{
  return{
    type: FETCH_USER_REGISTRATION_DATA,
    payload: { idUserInvitation },
  }
}

export const fetchUserRegistrationDataSuccess = UserInvitation =>{
  return{
    type: FETCH_USER_REGISTRATION_DATA_SUCCESS,
    payload: UserInvitation,
  }
}

export const fetchUserRegistrationDataFailed = error =>{
  return{
    type: FETCH_USER_REGISTRATION_DATA_FAILED,
    payload: error,
  }
}



