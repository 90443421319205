import React from "react";
import { connect } from "react-redux";
import Login from "./Login";

const LoginPage = (props) => {
  return (
    <>
      {props.windowBreakpoint === "LARGE" && <Login />}
      {props.windowBreakpoint === "SMALL" && <Login mobile />}
    </>
  );
};

const mapStateToProps = ({ Screen }) => ({
  windowBreakpoint: Screen.breakpoint,
});

export default connect(mapStateToProps)(LoginPage);
