import { takeEvery, put, call, select } from "redux-saga/effects"
import { convertArrayToObject } from "../../helpers/util";
import { getFantaEvaluatePlayerGuide, getFantaEvaluatePlayerPrice, getFantaEvaluatePlayerSettings, getFantaPlayers, getFantasettings, getFantaStatistics } from "../../service/fantaData";
import { fetchFantaEvaluateGuideError, fetchFantaEvaluateGuideSuccess, fetchFantaEvaluatePriceError, fetchFantaEvaluatePriceSuccess, fetchFantaEvaluateSettingsError, fetchFantaEvaluateSettingsSuccess, fetchFantaPlayersError, fetchFantaPlayersSuccess, fetchFantaSettingsError, fetchFantaSettingsSuccess, fetchFantaStatisticsError, fetchFantaStatisticsSuccess, selectFantaTeamSuccess } from "./actions";
import { FETCH_FANTA_EVALUATE_GUIDE, FETCH_FANTA_EVALUATE_PRICE, FETCH_FANTA_EVALUATE_SETTINGS, FETCH_FANTA_PLAYERS, FETCH_FANTA_SETTINGS, FETCH_FANTA_STATISTICS, SELECT_FANTA_TEAM } from "./actionType";
import { getAppData } from '../../service/userProfile'

function* fetchFantaSettings() {
	try {
		console.log('In saga, fetching fanta settings');
		const _fantaSettings = yield call(getFantasettings);
		const fantaSettings = convertArrayToObject(_fantaSettings);
		yield put(fetchFantaSettingsSuccess(fantaSettings));
	} catch (error) {
		console.error('FANTA FETCH SETTINGS', error);
		yield put(fetchFantaSettingsError(error));
	}
}

function* fetchFantaPlayers() {
	try {
		console.log('In saga, fetching fanta players');
		const fantaPlayers = yield call(getFantaPlayers);
		yield put(fetchFantaPlayersSuccess(fantaPlayers));
	} catch (error) {
		console.error('FANTA FETCH PLAYERS', error);
		yield put(fetchFantaPlayersError(error));
	}
}

function* fetchFantaStatistics() {
	try {
		console.log('In saga, fetching fanta statistics');
		const fantaStatistics = yield call(getFantaStatistics);
		yield put(fetchFantaStatisticsSuccess(fantaStatistics));
	} catch (error) {
		console.error('FANTA FETCH STATISTICS', error);
		yield put(fetchFantaStatisticsError(error));
	}
}

function* fetchFantaEvaluateGuide() {
	try {
		console.log('In saga, fetching fanta evaluate player guide');
		const fantaEvaluateGuide = yield call(getFantaEvaluatePlayerGuide);
		yield put(fetchFantaEvaluateGuideSuccess(fantaEvaluateGuide));
	} catch (error) {
		console.log('error fetching evaluate player guide', error);
		yield put(fetchFantaEvaluateGuideError(error));
	}
}

function* fetchFantaEvaluateSettings() {
	try {
		console.log('In saga, fetching fanta evaluate player settings');
		const fantaEvaluateSettings = yield call(getFantaEvaluatePlayerSettings);
		yield put(fetchFantaEvaluateSettingsSuccess(fantaEvaluateSettings));
	} catch (error) {
		console.log('error fetching evaluate player settings', error)
		yield put(fetchFantaEvaluateSettingsError(error));
	}
}

function* fetchFantaEvaluatePrice() {
	try {
		console.log('In saga, fetching fanta evaluate player price');
		const fantaEvaluatePrice = yield call(getFantaEvaluatePlayerPrice);
		yield put(fetchFantaEvaluatePriceSuccess(fantaEvaluatePrice));
	} catch (error) {
		console.log('error fetching evaluate player price', error);
		yield put(fetchFantaEvaluatePriceError(error));
	}
}

function* fantaSaga() {
	yield takeEvery(FETCH_FANTA_SETTINGS, fetchFantaSettings);
	yield takeEvery(FETCH_FANTA_PLAYERS, fetchFantaPlayers);
	yield takeEvery(FETCH_FANTA_STATISTICS, fetchFantaStatistics);
	yield takeEvery(FETCH_FANTA_EVALUATE_SETTINGS, fetchFantaEvaluateSettings);
	yield takeEvery(FETCH_FANTA_EVALUATE_GUIDE, fetchFantaEvaluateGuide);
	yield takeEvery(FETCH_FANTA_EVALUATE_PRICE, fetchFantaEvaluatePrice);
}

export default fantaSaga;