import { FILTER_NONE } from "./constant/filters";
import { GROUP_BY_NONE } from "./constant/groupBy";

export const filterCalculationList = (calculationList, filter) => {
  const resultObject = {};
  if (filter == FILTER_NONE) return calculationList;
  Object.values(calculationList).map((calculationObject) => {
    const calculationObjectId = calculationObject.id;
    const calculationObjectResult = calculationObject.result;
    if (filter == "calculated" && calculationObjectResult) resultObject[calculationObjectId] = calculationObject;
    else if (filter == "not_calculated" && !calculationObjectResult) resultObject[calculationObjectId] = calculationObject;
  });
  return resultObject;
};

export const groupBySubjectCalculationList = (calculationList, subject) => {
  const resultObject = {};
  if (subject == GROUP_BY_NONE) return calculationList;
  Object.values(calculationList).map((calculationObject) => {
    const calculationObjectId = calculationObject.id;
    const calculationObjectSubject = calculationObject.configuration[subject].label;
    if (!resultObject[calculationObjectSubject]) resultObject[calculationObjectSubject] = {};
    resultObject[calculationObjectSubject][calculationObjectId] = calculationObject;
  });
  return resultObject;
};
