import { Typography } from "@mui/material";
import React from "react";
import { textSecondaryStyle } from "../../assets/styles/constant/styleConstants";

export default function LabelValueInfo({
  label,
  text,
  maxWidth,
  noWrap,
  divStyle,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: `${maxWidth}`,
        ...divStyle,
      }}
    >
      <Typography variant="subtitle" style={textSecondaryStyle}>
        {label}
      </Typography>
      <Typography variant="subtitle" noWrap={noWrap}>
        {text}
      </Typography>
    </div>
  );
}
