import { Button, CircularProgress, Divider, Typography } from "@mui/material";
import React from "react";
import { icons } from "../../../assets/styles/constant/general";
import { palette } from "../../../assets/styles/constant/palette";
import { creditPurchase, styleConstant, textSecondaryStyle } from "../../../assets/styles/constant/styleConstants";
import ContainedButton from "../buttons/ContainedButton";
import CustomIconButton from "../buttons/CustomIconButton";
import CustomIcon from "../CustomIcon";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import PaymentAccepted from "./PaymentAccepted";
import PurchaseError from "./PurchaseError";

const SumUp = ({
  onBack,
  packet,
  coinAmount,
  noAdsPeriod = null,
  fiatAmount,
  total,
  onClose,
  discardPromo = () => {},
  mobile = false,
  onPayAction,
  activePromoCode = null,
  createOrder,
  onApprove,
  purchaseError = false,
  purchaseCompleted = false,
  onError,
  loading = false,
  setNoError,
  setNoCompleted,
  setNoLoading,
}) => {
  const onTerminatePurchase = () => {
    setNoCompleted();
    setNoError();
    onClose();
  };

  const renderPromoCode = () => (
    <div style={{
      display: 'flex', 
      flexDirection: 'column', 
      gap: 2,
      width: '100%',
      marginTop: styleConstant.margin.x4,
      paddingRight: !mobile ? styleConstant.padding.x5 : styleConstant.padding.x2
      }}>
      <Typography color={'#848E9C'} fontWeight={'500'}>Codice sconto/coupon</Typography>
      <div style={{
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between', 
        background: palette.grey.grey_300,
        alignItems: 'center',
        borderRadius: 8,
        padding: '10px 16px 10px 8px', 
      }}>
        <div style={{display: 'flex', gap: 16}}>
          <CustomIconButton icon={<CustomIcon icon={icons.TRASH_CAN} size='xl' style={{alignSelf: 'center'}}/>} 
            onClick={discardPromo}/>
          <Typography style={{color: 'white', fontSize: 24, fontWeight: '600'}}>{activePromoCode.code}</Typography>
        </div>
        {activePromoCode.type === 'price'
          ? <Typography style={{color: 'white', fontSize: 24, fontWeight: '600'}}>-{activePromoCode.value}%</Typography>
          : <Typography style={{color: 'white', fontSize: 24, fontWeight: '600'}}>+{activePromoCode.value} Crediti</Typography>
        }
      </div>
    </div>
  )

  const PayPal = React.memo(() => {
    return <PayPalButtons
      style={{ layout: "horizontal", height: 55 }}
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
      onError={onError}
      onCancel={() => {
      console.log("CANCELLATO");
    }}/>
  }, [activePromoCode]);

  if (loading)
    return (
      <div style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress />
      </div>
    );
  if (purchaseError) return <PurchaseError onTerminate={onTerminatePurchase} />;
  if (purchaseCompleted) return <PaymentAccepted onTerminate={onTerminatePurchase} />;
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: mobile ? styleConstant.margin.x3 : styleConstant.margin.x5,
        }}
      >
        <Typography variant="title" style={{ ...creditPurchase.title }}>
          Riepilogo d'acquisto
        </Typography>
        <CustomIconButton icon={<CustomIcon icon={icons.XMARK} size="1x" />} onClick={onClose} />
      </div>
      <Typography
        style={{
          ...creditPurchase.subtitle,
          marginTop: styleConstant.margin.x1,
          marginRight: mobile ? styleConstant.margin.x3 : styleConstant.margin.x5,
        }}
      >
        Controlla il tuo acquisto prima di procedere con l'ordine
      </Typography>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: styleConstant.margin.x6,
          marginRight: mobile ? styleConstant.margin.x3 : styleConstant.margin.x5,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            flex: "1",
          }}
        >
          <Typography style={creditPurchase.subtitle}>Pacchetto</Typography>
          <Typography style={{ fontWeight: "700", fontSize: "24px", lineHeight: "32px", color: palette.white.main }}>{packet}</Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: styleConstant.gap.small,
              marginTop: styleConstant.margin.x1,
            }}
          >
            <Typography style={creditPurchase.title}>{coinAmount}</Typography>
            <CustomIcon icon={icons.COINS} color={palette.white.main} size="lg" />
          </div>
          {noAdsPeriod && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: styleConstant.gap.small,
              }}
            >
              <CustomIcon icon={icons.CHECK} />
              <Typography variant="smallTitle">{noAdsPeriod}</Typography>
              <Typography variant="smallTitle" style={textSecondaryStyle}>
                senza pubblicità
              </Typography>
            </div>
          )}
        </div>
        <Typography style={creditPurchase.title}>{total} €</Typography>
      </div>
      {activePromoCode && renderPromoCode()}
      <Divider
        style={{
          background: palette.grey.grey_200,
          height: "2px",
          marginRight: mobile ? styleConstant.margin.x3 : styleConstant.margin.x5,
          marginTop: activePromoCode ? styleConstant.margin.x2 : styleConstant.margin.x4,
        }}
      />
      <div
        style={{
          display: "flex",
          marginTop: styleConstant.margin.x4,
          justifyContent: "space-between",
          marginRight: mobile ? styleConstant.margin.x3 : styleConstant.margin.x5,
        }}
      >
        <Typography style={creditPurchase.subtitle}>Totale:</Typography>
        <Typography style={{ fontWeight: "700", fontSize: "24px", lineHeight: "32px", color: palette.white.main }}>{total} €</Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          gap: mobile ? "4px" : styleConstant.gap.medium,
          marginRight: mobile ? styleConstant.margin.x3 : styleConstant.margin.x5,
          marginTop: "auto",
          height: "55px",
        }}
      >
        <Button variant="outlined-empty" onClick={onBack} style={{ flex: "1" }}>
          <Typography variant="smallTitle" color={palette.primary.main} noWrap>
            Torna ai pacchetti
          </Typography>
        </Button>
        <PayPalScriptProvider
          options={{
            "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION,
            //"client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
            currency: "EUR",
          }}
        >
          <div style={{ flex: 1 }}>
            <PayPal />
          </div>
        </PayPalScriptProvider>
      </div>
    </>
  );
};

export default SumUp;
