import { Modal, TextField, Typography } from "@mui/material";
import { margin } from "@mui/system";
import React from "react";
import { connect } from "react-redux";
import { icons } from "../../assets/styles/constant/general";
import { palette } from "../../assets/styles/constant/palette";
import { profilePageStyle, skeletonLabelStyle, styleConstant } from "../../assets/styles/constant/styleConstants";
import { deleteUserAuth, forgetPassword, updateUserAuth } from "../../service/UserAuth";
import { loadProfile } from "../../store/actions";
import ContainedButton from "./buttons/ContainedButton";
import TextButton from "./buttons/TextButton";
import CustomIcon from "./CustomIcon";
import CustomTextField from "./input/CustomTextField";
import FormSkeleton from "./input/FormSkeleton";

const EditProfile = ({ open = false, onClose, userEmail, name = "", surname = "", onSave, userAuthInfo, loadProfile, providerId, mobile = false }) => {
  const [userName, setUserName] = React.useState(name);
  const [userSurname, setUserSurname] = React.useState(surname);
  const [confirm, setConfirm] = React.useState("");
  const [emailSent, setEmailSent] = React.useState(false);
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);

  const closeModal = () => {
    setUserName(name);
    setUserSurname(userSurname);
    setEmailSent(false);
    onClose();
  };

  const saveEdits = async () => {
    // onSave();
    const newDisplayName = userName + "/" + userSurname;
    const response = await updateUserAuth(newDisplayName);
    const userAuthInfo_clone = JSON.parse(JSON.stringify(userAuthInfo));
    userAuthInfo_clone.displayName = newDisplayName;
    loadProfile(userAuthInfo_clone);
    onClose();
  };

  const changePassword = (email) => {
    forgetPassword(email);
    setEmailSent(true);
  };

  const deleteProfile = async () => {
    await deleteUserAuth();
  };

  const ConfirmationModal = () => {
    return (
      <Modal open={confirmationOpen} onClose={() => setConfirmationOpen(false)} disableEnforceFocus>
        <div
          style={{
            background: palette.grey.grey_500,
            width: mobile ? "90%" : "600px",
            minWidth: !mobile ? "500px" : "",
            minHeight: "300px",
            height: "100px",
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            padding: `${styleConstant.padding.x5}`,
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            margin: "auto",
            borderRadius: styleConstant.borderRadius.x3,
            gap: styleConstant.gap.medium,
          }}
        >
          <CustomIcon
            icon={icons.EXCLAMATION}
            size="3x"
            style={{ marginTop: styleConstant.margin.x3, marginLeft: mobile ? "auto" : "", marginRight: mobile ? "auto" : "" }}
          />
          <div
            style={{
              display: "flex",
              gap: "8px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={profilePageStyle.name}>Conferma cancellazione account</Typography>
          </div>
          <div style={{ display: "flex", gap: styleConstant.gap.large, marginTop: "auto", flexDirection: mobile ? "column" : "row" }}>
            <ContainedButton
              text="ELIMINA ACCOUNT"
              icon={<CustomIcon icon={icons.TRASH_CAN} color={palette.black.main} />}
              style={{ flex: "1" }}
              onClick={() => deleteProfile()}
            />
            <ContainedButton text="Annulla" style={{ flex: "1" }} onClick={() => setConfirmationOpen(false)} />
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <ConfirmationModal />
      {!mobile ? (
        <>
          <Modal open={open} onClose={closeModal} disableEnforceFocus>
            <div
              style={{
                background: palette.grey.grey_500,
                width: "60%",
                minWidth: "600px",
                minHeight: "500px",
                height: "70%",
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                padding: `${styleConstant.padding.x5}`,
                top: "0",
                left: "0",
                right: "0",
                bottom: "0",
                margin: "auto",
                borderRadius: styleConstant.borderRadius.x3,
              }}
            >
              <div>
                <TextButton text={"Profilo"} icon={<CustomIcon icon={icons.ARROW_LEFT} />} iconLeft={true} action={closeModal} />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: styleConstant.margin.x3,
                }}
              >
                <Typography style={{ ...profilePageStyle.title }}>Modifica utente</Typography>
                <TextButton
                  text="SALVA LE MODIFICHE"
                  // onClick={() => onSave(userName, userSurname)}
                  style={{ height: "40px" }}
                  action={() => saveEdits()}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginTop: styleConstant.margin.x5 }}>
                <Typography variant="smallTitle" style={{ ...skeletonLabelStyle }}>
                  EMAIL
                </Typography>
                <div
                  style={{
                    display: "flex",
                    background: palette.grey.grey_200 + "66",
                    height: "50px",
                    borderRadius: styleConstant.borderRadius.x1,
                    marginTop: "4px",
                    alignItems: "center",
                    paddingLeft: "13px",
                  }}
                >
                  <Typography style={{ fontSize: "16px", lineHeight: "24px", color: palette.white.main }}>{userEmail}</Typography>
                </div>
                <FormSkeleton label="Nome">
                  <CustomTextField content={userName} onChange={(newName) => setUserName(newName)} />
                </FormSkeleton>
                <FormSkeleton label="Cognome">
                  <CustomTextField content={userSurname} onChange={(newSurname) => setUserSurname(newSurname)} />
                </FormSkeleton>
                {providerId == "password" && (
                  <>
                    {emailSent ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: styleConstant.gap.medium,
                            marginTop: styleConstant.margin.x2,
                            opacity: "0.8",
                          }}
                        >
                          <Typography style={{ ...profilePageStyle.actionText, fontWeight: "400" }}>
                            E' stata inviata una mail alla sua casella di posta
                          </Typography>
                          <CustomIcon icon={icons.CHECK} />
                        </div>
                      </>
                    ) : (
                      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                        <TextButton text={"Cambia password"} style={{ marginTop: styleConstant.margin.x3 }} action={() => changePassword(userEmail)} />
                      </div>
                    )}
                  </>
                )}
              </div>
              <TextButton
                text={"Cancella account"}
                icon={<CustomIcon icon={icons.TRASH_CAN} />}
                iconLeft
                style={{ marginTop: "auto" }}
                action={() => setConfirmationOpen(true)}
              />
            </div>
          </Modal>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "1",
            padding: styleConstant.padding.x3,
          }}
        >
          <div>
            <TextButton text={"Profilo"} icon={<CustomIcon icon={icons.ARROW_LEFT} />} iconLeft={true} action={closeModal} />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: styleConstant.margin.x3,
            }}
          >
            <Typography style={{ ...profilePageStyle.title }}>Modifica utente</Typography>
          </div>
          <div style={{ display: "flex", flexDirection: "column", marginTop: styleConstant.margin.x5 }}>
            <Typography variant="smallTitle" style={{ ...skeletonLabelStyle }}>
              EMAIL
            </Typography>
            <div
              style={{
                display: "flex",
                background: palette.grey.grey_200 + "66",
                height: "50px",
                borderRadius: styleConstant.borderRadius.x1,
                marginTop: "4px",
                alignItems: "center",
                paddingLeft: "13px",
              }}
            >
              <Typography style={{ fontSize: "16px", lineHeight: "24px", color: palette.white.main }}>{userEmail}</Typography>
            </div>
            <FormSkeleton label="Nome">
              <CustomTextField content={userName} onChange={(newName) => setUserName(newName)} />
            </FormSkeleton>
            <FormSkeleton label="Cognome">
              <CustomTextField content={userSurname} onChange={(newSurname) => setUserSurname(newSurname)} />
            </FormSkeleton>
            <TextButton
              text="SALVA LE MODIFICHE"
              // onClick={() => onSave(userName, userSurname)}
              style={{ height: "40px" }}
              action={() => saveEdits()}
            />
            {providerId == "password" && (
              <>
                {emailSent ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: styleConstant.gap.medium,
                        marginTop: styleConstant.margin.x2,
                        opacity: "0.8",
                      }}
                    >
                      <Typography style={{ ...profilePageStyle.actionText, fontWeight: "400" }}>E' stata inviata una mail alla sua casella di posta</Typography>
                      <CustomIcon icon={icons.CHECK} />
                    </div>
                  </>
                ) : (
                  <div style={{ marginLeft: "auto", marginRight: "auto" }}>
                    <TextButton text={"Cambia password"} style={{ marginTop: styleConstant.margin.x3 }} action={() => changePassword(userEmail)} />
                  </div>
                )}
              </>
            )}
          </div>
          <TextButton
            text={"Cancella account"}
            icon={<CustomIcon icon={icons.TRASH_CAN} />}
            iconLeft
            style={{ marginTop: "auto" }}
            action={() => setConfirmationOpen(true)}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ Profile }) => ({
  userAuthInfo: Profile.userAuthInfo,
  providerId: Profile.userAuthInfo.providerData[0].providerId,
});

const mapDispatchToProps = (dispatch) => ({
  loadProfile: (userAuthInfo) => dispatch(loadProfile(userAuthInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
