import { call, put, select, takeEvery } from "redux-saga/effects";
import {
  getAvailableStatisticsIds,
  getDays,
  getOperations,
  getSeasons,
  getStatistics,
  getStatisticsV2,
} from "../../service/calculationData";
import {
  addCalculationInList,
  createCalculationObject,
  updateCalculationObject,
  updateCalculationInList,
  deleteCalculationFromList,
  createDuplicatedCalculus,
  duplicateCalculusInList,
  newSelectedCalculusAfterDelete,
  getScenariosFromPlayer,
  buildScenariosObject,
  updateResultInList,
  updateResultsInList,
  calculateTotalPriceFromList,
} from "../../service/calculationList";
import {
  createCalculationObjectSuccess,
  createCalculationObjectError,
  setSelectedCalculus,
  updateCalculationObjectError,
  updateCalculationObjectSuccess,
  deleteCalculationObjectError,
  deleteCalculationObjectSuccess,
  duplicateCalculusError,
  duplicateCalculusSuccess,
  setSelectedSettingsError,
  setSelectedSettingsSuccess,
  resetCalculator,
  loadStatisticsSuccess,
  loadTotalPrice,
  setResultError,
  setResultSuccess,
  loadProfile,
} from "../actions";
import {
  CREATE_CALCULATION_OBJECT,
  CREATE_CALCULATION_OBJECT_ERROR,
  DELETE_CALCULATION_OBJECT,
  UPDATE_CALCULATION_OBJECT,
  UPDATE_CALCULATION_OBJECT_ERROR,
  DELETE_CALCULATION_OBJECT_ERROR,
  DUPLICATE_CALCULUS,
  SET_SELECTED_SETTINGS,
  UPDATE_CALCULATION_OBJECT_SCENARIOS,
  SET_RESULT_ERROR,
  SET_RESULT_SUCCESS,
  SET_RESULT,
} from "./actionType";
import { loadDays, loadOperations, loadSeasons, loadStatistics } from "../../store/calculation/actions";

function* createCalculation({ payload: { configurationObject } }) {
  try {
    const oldTotalPrice = yield select((state) => state.CalculationWork.totalPrice);
    const settings = yield select((state) => state.CalculationWork.settings);
    const allScenarios = yield select((state) => state.Calculation.scenarios);
    const calculationList = yield select((state) => state.CalculationWork.calculationList);
    const days = yield configurationObject.days;
    const idStatistic = yield configurationObject.statistic.value;
    let scenariosObject = null;
    if (settings.tool == 1 && settings.subject == 1) {
      const playerData = yield configurationObject.player.additional;
      scenariosObject = yield buildScenariosObject(playerData, days, idStatistic, allScenarios);
    }
    //calcolo del prezzo del nuovo calcolo nella funzione createCalculationObject
    const calculationObject = yield createCalculationObject(configurationObject, scenariosObject, settings);
    const calculationListToUpdate = yield JSON.parse(JSON.stringify(calculationList));
    const newCalculationList = yield addCalculationInList(calculationObject, calculationListToUpdate);
    const calculationPrice = calculationObject.price;
    const newTotalPrice = oldTotalPrice + calculationPrice;
    yield put(loadTotalPrice(newTotalPrice));
    yield put(createCalculationObjectSuccess(newCalculationList));
    yield put(setSelectedCalculus(JSON.parse(JSON.stringify(calculationObject))));
  } catch (error) {
    console.log(error);
    yield put(createCalculationObjectError(CREATE_CALCULATION_OBJECT_ERROR));
  }
}

function* deleteCalculation({ payload: { id } }) {
  try {
    const oldTotalPrice = yield select((state) => state.CalculationWork.totalPrice);
    const calculationList = yield select((state) => state.CalculationWork.calculationList);
    const calculationPrice = calculationList[id].price;
    const selectedCalculus = yield select((state) => state.CalculationWork.selectedCalculus);
    const calculationListToUpdate = yield JSON.parse(JSON.stringify(calculationList));
    const newCalculationList = yield deleteCalculationFromList(id, calculationListToUpdate);
    if (selectedCalculus.id == id) {
      const newSelectedId = yield newSelectedCalculusAfterDelete(id, calculationList);
      if (newSelectedId > 0) {
        yield put(setSelectedCalculus(JSON.parse(JSON.stringify(newCalculationList[newSelectedId]))));
      } else {
        yield put(setSelectedCalculus(JSON.parse(JSON.stringify(null))));
      }
    } else {
      // se il calcolo eliminato si trova sopra (id minore) al calcolo selezionato, allora idCalcoloSelezionato --
      if (id < selectedCalculus.id) {
        const newSelectedId = yield selectedCalculus.id - 1;
        yield put(setSelectedCalculus(JSON.parse(JSON.stringify(newCalculationList[newSelectedId]))));
      }
      // se il calcolo eliminato si trova sotto (id maggiore) al calcolo selezionato, allora idCalcoloSelezionato rimane invariato
    }
    const newTotalPrice = oldTotalPrice - calculationPrice;
    yield put(loadTotalPrice(newTotalPrice));
    yield put(deleteCalculationObjectSuccess(newCalculationList));
  } catch (error) {
    yield put(deleteCalculationObjectError(DELETE_CALCULATION_OBJECT_ERROR));
  }
}

function* updateCalculation({ payload: { id, configurationObject } }) {
  try {
    const oldTotalPrice = yield select((state) => state.CalculationWork.totalPrice);
    const settings = yield select((state) => state.CalculationWork.settings);
    const allScenarios = yield select((state) => state.Calculation.scenarios);
    const days = yield configurationObject.days;
    const idStatistic = yield configurationObject.statistic.value;
    let scenariosObject = null;
    if (settings.tool == 1 && settings.subject == 1) {
      const playerData = yield configurationObject.player.additional;
      scenariosObject = yield buildScenariosObject(playerData, days, idStatistic, allScenarios);
    }
    const calculationList = yield select((state) => state.CalculationWork.calculationList);
    if (!calculationList[id]) yield put(updateCalculationObjectError(UPDATE_CALCULATION_OBJECT_ERROR));
    const calculationObjectToUpdate = yield JSON.parse(JSON.stringify(calculationList[id]));
    const oldCalculationPrice = calculationObjectToUpdate.price;
    const calculationListToUpdate = yield JSON.parse(JSON.stringify(calculationList));
    const updatedCalculationObject = yield updateCalculationObject(
      calculationObjectToUpdate,
      configurationObject,
      scenariosObject,
      settings
    );
    const newCalculationPrice = updatedCalculationObject.price;
    const newCalculationList = yield updateCalculationInList(calculationListToUpdate, updatedCalculationObject);
    const delta = newCalculationPrice - oldCalculationPrice;
    const newTotalPrice = oldTotalPrice + delta;
    yield put(loadTotalPrice(newTotalPrice));
    yield put(setSelectedCalculus(updatedCalculationObject));
    yield put(updateCalculationObjectSuccess(newCalculationList));
  } catch (error) {
    yield put(updateCalculationObjectError(UPDATE_CALCULATION_OBJECT_ERROR));
  }
}

function* updateCalculationScenarios({ payload: { id, scenariosObject } }) {
  const allScenarios = yield select((state) => state.Calculation.scenarios);
  const oldTotalPrice = yield select((state) => state.CalculationWork.totalPrice);
  const settings = yield select((state) => state.CalculationWork.settings);
  const calculationList = yield select((state) => state.CalculationWork.calculationList);
  if (!calculationList[id]) yield put(updateCalculationObjectError(UPDATE_CALCULATION_OBJECT_ERROR));
  const calculationObjectToUpdate = yield JSON.parse(JSON.stringify(calculationList[id]));
  const calculationListToUpdate = yield JSON.parse(JSON.stringify(calculationList));
  const oldCalculationPrice = calculationObjectToUpdate.price;
  const updatedCalculationObject = yield updateCalculationObject(
    calculationObjectToUpdate,
    null,
    scenariosObject,
    settings,
    allScenarios
  );
  const newCalculationPrice = updatedCalculationObject.price;
  const delta = newCalculationPrice - oldCalculationPrice;
  const newTotalPrice = oldTotalPrice + delta;
  const newCalculationList = yield updateCalculationInList(calculationListToUpdate, updatedCalculationObject);
  yield put(loadTotalPrice(newTotalPrice));
  yield put(setSelectedCalculus(updatedCalculationObject));
  yield put(updateCalculationObjectSuccess(newCalculationList));
}

function* duplicateCalculation({ payload: { duplicatedCalculus } }) {
  try {
    const oldTotalPrice = yield select((state) => state.CalculationWork.totalPrice);
    const calculationList = yield select((state) => state.CalculationWork.calculationList);
    const duplicate = yield createDuplicatedCalculus(duplicatedCalculus);
    const calculationPrice = duplicate.price;
    const calculationListToUpdate = yield JSON.parse(JSON.stringify(calculationList));
    const newCalcListAndObject = yield duplicateCalculusInList(duplicate, calculationListToUpdate);
    const newTotalPrice = oldTotalPrice + calculationPrice;
    yield put(loadTotalPrice(newTotalPrice));
    yield put(duplicateCalculusSuccess(newCalcListAndObject[0]));
    yield put(setSelectedCalculus(JSON.parse(JSON.stringify(newCalcListAndObject[1]))));
  } catch (error) {
    console.log("errore");
    yield put(duplicateCalculusError(error));
  }
}

function* setResult({ payload: { result } }) {
  try {
    const selectedCalculus = yield select((state) => state.CalculationWork.selectedCalculus);
    const calculationList = yield select((state) => state.CalculationWork.calculationList);
    const userAuthInfo = yield select((state) => state.Profile.userAuthInfo);
    const calculationListToUpdate = yield JSON.parse(JSON.stringify(calculationList));
    // const updatedCalcList = yield updateResultInList(calculationListToUpdate, calcId, result);
    const updatedCalcList = yield updateResultsInList(calculationListToUpdate, result);
    console.log(updatedCalcList);
    const newSelectedCalculus = yield updatedCalcList[selectedCalculus.id];
    // ricalcolo il prezzo totale escludendo il prezzo di calcoli già eseguiti
    const newTotalPrice = calculateTotalPriceFromList(updatedCalcList);
    console.log("newTotalPrice ", newTotalPrice);
    yield put(loadTotalPrice(newTotalPrice));
    yield put(setSelectedCalculus(newSelectedCalculus));
    yield put(setResultSuccess(updatedCalcList));
    yield put(loadProfile(userAuthInfo));
  } catch (error) {
    yield put(setResultError(SET_RESULT_ERROR));
  }
}

function* setSettings({ payload: { settings } }) {
  try {
    yield put(resetCalculator());
    const newStatistics = yield getStatistics(settings.tool, settings.subject);
    const newSeasons = yield call(getSeasons, settings.tool);
    const newOperations = yield call(getOperations, settings.subject);
    yield put(loadStatisticsSuccess(newStatistics));
    yield put(loadSeasons(newSeasons));
    yield put(loadOperations(newOperations));
    yield put(setSelectedSettingsSuccess(settings));
  } catch (error) {
    yield put(setSelectedSettingsError(error));
  }
}

function* calculationWorkSaga() {
  yield takeEvery(CREATE_CALCULATION_OBJECT, createCalculation);
  yield takeEvery(DELETE_CALCULATION_OBJECT, deleteCalculation);
  yield takeEvery(UPDATE_CALCULATION_OBJECT, updateCalculation);
  yield takeEvery(DUPLICATE_CALCULUS, duplicateCalculation);
  yield takeEvery(SET_SELECTED_SETTINGS, setSettings);
  yield takeEvery(UPDATE_CALCULATION_OBJECT_SCENARIOS, updateCalculationScenarios);
  yield takeEvery(SET_RESULT, setResult);
}

export default calculationWorkSaga;
