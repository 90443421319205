import { FETCH_TIPSTER_DATA, FETCH_TIPSTER_DATA_ERROR, FETCH_TIPSTER_DATA_SUCCESS, FETCH_TIPSTER_EVENTI, FETCH_TIPSTER_EVENTI_ERROR, FETCH_TIPSTER_EVENTI_SUCCESS, FETCH_TIPSTER_GRUPPI, FETCH_TIPSTER_GRUPPI_ERROR, FETCH_TIPSTER_GRUPPPI_SUCCESS, FETCH_TIPSTER_STATISTICHE, FETCH_TIPSTER_STATISTICHE_ERROR, FETCH_TIPSTER_STATISTICHE_SUCCESS } from "./actionTypes";

const initialState = {
	events: null,
	loadingEvents: false,
	errorEvents: null,

	groups: null,
	loadingGroups: false,
	errorGroups: null,

	migliori: null,
	loadingMigliori: false,
	errorMigliori: null,

	statistics: null,
	loadingStatistics: false,
	errorStatistics: null,

	loadingAll: false,
	errorAll: null,
};

const Tipster = (state = initialState, action) => {
	switch(action.type) {
		case FETCH_TIPSTER_EVENTI:
			state = {
				...state,
				loadingEvents: true,
			}
			break;
		case FETCH_TIPSTER_EVENTI_SUCCESS:
			state = {
				...state,
				loadingEvents: false,
				errorEvents: null,
				events: action.payload.events,
			}
			break;
		case FETCH_TIPSTER_EVENTI_ERROR:
			state = {
				...state,
				loadingEvents: false,
				errorEvents: action.payload.error,
				events: null,
			}
			break;
		case FETCH_TIPSTER_GRUPPI:
			state = {
				...state,
				loadingGroups: true,
			}
			break;
		case FETCH_TIPSTER_GRUPPPI_SUCCESS:
			state = {
				...state,
				loadingGroups: false,
				errorGroups: null,
				groups: action.payload.groups,
			}
			break;
		case FETCH_TIPSTER_GRUPPI_ERROR:
			state = {
				...state,
				loadingGroups: false,
				errorGroups: action.payload.error,
				groups: null,
			}
			break;
		case FETCH_TIPSTER_STATISTICHE:
			state = {
				...state,
				loadingStatistics: true,
			}
			break;
		case FETCH_TIPSTER_STATISTICHE_SUCCESS:
			state = {
				...state,
				loadingStatistics: false,
				statistics: action.payload.statistics,
				errorStatistics: null,
			}
			break;
		case FETCH_TIPSTER_STATISTICHE_ERROR:
			state = {
				...state,
				loadingStatistics: false,
				errorStatistics: action.payload.error,
				statistics: null,
			}
			break;
		case FETCH_TIPSTER_DATA:
			state = {
				...state,
				loadingAll: true,
			}
			break;
		case FETCH_TIPSTER_DATA_SUCCESS:
			state = {
				...state,
				loadingAll: false,
				events: action.payload.events,
				groups: action.payload.groups,
				migliori: action.payload.migliori,
				statistics: action.payload.statistics,
				errorAll: null,
			}
			break;
		case FETCH_TIPSTER_DATA_ERROR:
			state = {
				...state,
				loadingAll: false,
				events: null,
				groups: null,
				migliori: null,
				statistics: null,
				errorAll: action.payload.error,
			}
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
}

export default Tipster;