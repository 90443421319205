import { Stack, Typography } from '@mui/material';
import React from 'react';
import TextButton from '../../../core/components/buttons/TextButton'
import { useNavigate } from 'react-router-dom';
import { palette } from '../../../assets/styles/constant/palette';
import InputSelect from '../../../core/components/input/InputSelect';
import ContainedButton from '../../../core/components/buttons/ContainedButton';
import CustomIcon from '../../../core/components/CustomIcon';
import { icons } from '../../../assets/styles/constant/general';
import TableComponent from './TableComponent';
import { fetchFantaEvaluateGuide, fetchFantaEvaluatePrice, fetchFantaEvaluateSettings, selectFantaTeam } from '../../../store/fanta/actions';
import { connect } from 'react-redux';
import { post } from '../../../service/common/webService' 
import { calculationFantaStatistics } from '../../../service/calculation';
import { reloadUserCredit } from '../../../store/profile/actions';
import InputRadio from '../../../core/components/input/InputRadio';
import EvaluatePlayers from '../EvaluatePlayers';
import CustomDialog from '../../../core/components/CustomDialog';
import CustomIconButton from '../../../core/components/buttons/CustomIconButton';

const radioItems = [
	{ label: 'Statistiche rosa', value: 0 },
	{ label: 'Valuta giocatore', value: 1 },
]

const SETTINGS_TEXT = 'Imposta il calcolatore. Seleziona la Rosa e le statistiche del calcio';
const FANTA_STATS_SECTION_TEXT = 'Calcola le statistiche delle tue rose';
const FANTA_VALUE_SECTION_TEXT = 'Scopri quanto investire su ogni giocatore';
const DEFAULT_FANTA_TEAM_VALUE = {value: -1, label: 'Nessuna rosa'};
const DEFAULT_STATISTIC_VALUE = {value: -1, label: ''};

const FantaStatistics = (props) => {
	const { userAppData, selectedFantaTeamId, fantaStatistics, uid } = props;
	const fantaTeams = userAppData?.fantaTeams;
	const players = (fantaTeams && selectedFantaTeamId && fantaTeams[selectedFantaTeamId].players) ? Object.values(fantaTeams[selectedFantaTeamId].players) : [];
	const _leagueName = (fantaTeams && selectedFantaTeamId) ? fantaTeams[selectedFantaTeamId].leagueName : '';
	const _fantaTeamName = (fantaTeams && selectedFantaTeamId) ? fantaTeams[selectedFantaTeamId].name : '';
	const _fantaType = (fantaTeams && selectedFantaTeamId) ? fantaTeams[selectedFantaTeamId].fantaType : '';
	const navigate = useNavigate();

	const [fantaTeam, setFantaTeam] = React.useState(DEFAULT_FANTA_TEAM_VALUE);
	const [statistic, setStatistic] = React.useState([]);
	const [inputSelectFantaTeams, setInputSelectFantaTeams] = React.useState([]);
	const [inputSelectStatistic, setInputSelectStatistic] = React.useState([]);
	const [statisticPrice, setStatisticPrice] = React.useState(0);
	const [resultObject, setResultObject] = React.useState(null);
	const [calcLoading, setCalcLoading] = React.useState(false);
	const [section, setSection] = React.useState(0);
	const [helpModalVisible, setHelpModalVisible] = React.useState(false);

	React.useEffect(() => {
		// Caricamento dai fanta_value
		if (!props.fantaEvaluatePlayerPrice) props.loadFantaEvaluatePrice();
    if (!props.fantaEvaluatePlayerGuide) props.loadFantaEvaluateGuide();
    if (!props.fantaEvaluatePlayerSettings) props.loadFantaEvaluateSettings();
		// init componenti
		initFantaTeamInputSelect();
		initStatisticInputSelect();
		if (fantaTeams && !selectedFantaTeamId) {
      const firstFantaTeam = Object.values(fantaTeams)[0];
      props.selectFantaTeamId(firstFantaTeam.id)
    }
	}, []);

	React.useEffect(() => {
		if (statistic) calculateStatisticsPrice();
	}, [statistic]);

	const calculateStatistics = async() => {
		try {
			setCalcLoading(true);
			const bodyRequestObj = generateBodyRequest();
			const cfResponse = await calculationFantaStatistics(bodyRequestObj, uid, statisticPrice);
			if (cfResponse.outcome === 'error') throw cfResponse.error;
			const resultObject = cfResponse.data;
			setResultObject(resultObject);
			setCalcLoading(false);
			props.reloadUserCredits(uid);
		} catch (error) {
			console.log('calculateStatistic error', error);
			setCalcLoading(false);
		}
	}

	const generateBodyRequest = () => {
		const { leagueName, fantaType, name, players } = fantaTeams[selectedFantaTeamId];
		const statisticsIds = statistic.map(stat => stat.value);
		const bodyRequestObj = {
			app: 'web',
			idUtente: uid,
			impostazioni: {
				'1': name,
				'2': fantaType,
				'3': leagueName
			},
			idGiocatori: Object.values(players),
			idStatistiche: statisticsIds,
		}
		console.log(bodyRequestObj);
		return bodyRequestObj;
	}

	const calculateStatisticsPrice = () => {
		let totPrice = 0;
		statistic.map((stat) => {
			const { value } = stat;
			const statisticObject = fantaStatistics[value];
			const { prezzo } = statisticObject;
			totPrice += parseInt(prezzo);
		});
		setStatisticPrice(totPrice);
	}

	const initFantaTeamInputSelect = () => {
		const fantaTeamInputSelect = createFantaTeamInputSelectItem();
		let defaultFantaTeamValue = DEFAULT_FANTA_TEAM_VALUE;
		if (fantaTeamInputSelect.length > 0) {
			defaultFantaTeamValue = fantaTeamInputSelect[0];
			if (selectedFantaTeamId) {
				const newSelectedFantaTeam = fantaTeamInputSelect.filter(el => el.value === selectedFantaTeamId)[0];
				if (newSelectedFantaTeam) defaultFantaTeamValue = newSelectedFantaTeam;
			}
		}
		setInputSelectFantaTeams(fantaTeamInputSelect);
		setFantaTeam(defaultFantaTeamValue);
	}

	const initStatisticInputSelect = () => {
		const statisticInputSelect = createStatisticsInputSelect();
		let defaultStatisticValue = [];
		if (statisticInputSelect.length > 0) 
			defaultStatisticValue.push(statisticInputSelect[0]);
		setInputSelectStatistic(statisticInputSelect);
		setStatistic(defaultStatisticValue);
	}

	const createFantaTeamInputSelectItem = () => {
		if (!fantaTeams) return [];
		return Object.values(fantaTeams).map(teamObj => {
			const { id, name } = teamObj;
			return { value: id, label: name};
		});
	}

	const createStatisticsInputSelect = () => {
		return Object.values(fantaStatistics).map(statisticsObj => {
			const { id, nome, short } = statisticsObj;
			return {value: id, label: nome, short};
		});
	}

	const onFantaTeamChange = (value) => {
		setFantaTeam(value);
		const teamId = value.value;
		props.selectFantaTeamId(teamId);
		setResultObject(null)
	}

	const onStatisticChange = (value) => {
		setStatistic(value);
		calculateStatisticsPrice();
	}

	const renderHeader = () => (
		<Stack 
			direction={'row'}
			alignItems={'center'}>
				<Stack style={{maxWidth: '25%'}}>
					<Stack gap={'8px'}
						direction={'row'}
						alignItems={'center'}>
						<Typography
							color={'#FFF'}
							variant='title'>
								Fantacalcio
						</Typography>
						{section === 0 && 
						<CustomIconButton
							icon={<CustomIcon
							icon={icons.QUESTION} 
								color={palette.primary.main} 
							/>} 
							onClick={() => setHelpModalVisible(true)} />}
					</Stack>
					<Typography
						fontWeight={'500'}
						color={palette.grey.grey_100}
						variant='smallTitle'>
						{
							section === 0
								? FANTA_STATS_SECTION_TEXT
								: FANTA_VALUE_SECTION_TEXT
						}
					</Typography>
				</Stack>
			{renderRadioButtons()}
		</Stack>
	)

	const renderOldHeader = () => (
		<Stack 
			direction={'row'}
			alignItems={'center'}
			justifyContent={'space-between'}>
				<Stack style={{maxWidth: '25%'}}>
					<Stack direction={'row'} gap={'4px'} alignItems={'center'}>
					<Typography
						color={'#FFF'}
						variant='title'>
							Fantacalcio
					</Typography>
					<CustomIconButton
						icon={<CustomIcon
						icon={icons.QUESTION} 
							color={palette.primary.main} 
						/>} 
						onClick={() => setHelpModalVisible(true)} />
					</Stack>
					<Typography
						fontWeight={'500'}
						color={palette.grey.grey_100}
						variant='smallTitle'>
						{SETTINGS_TEXT}
					</Typography>
				</Stack>
				<Stack gap={'24px'}
					direction={'row'}>
					<Stack minWidth={'240px'}>
						<Typography
							color={'#FFF'}
							fontWeight={'500'}
							fontSize={'20px'}>
								Rosa
						</Typography>
						<InputSelect 
							style={{
								height: '48px',
								'& .MuiSelect-select.MuiSelect-standard.MuiInput-input.MuiInputBase-input': {
									paddingRight: '40px',
								}
							}}
							selectedValue={fantaTeam}
							onSelectChange={onFantaTeamChange}
							inputSelectItem={inputSelectFantaTeams}/>
					</Stack>
					<Stack minWidth={'240px'}>
						<Typography
							color={'#FFF'}
							fontWeight={'600'}
							fontSize={'20px'}>
								Statistiche
						</Typography>
						<InputSelect 
							multiple
							style={{
								height: '48px',
								width: '280px',
								'& .MuiSelect-select.MuiSelect-standard.MuiInput-input.MuiInputBase-input': {
									paddingRight: '40px',
								}
							}}
							showTextContent
							chipTextValue={'short'}
							selectedValue={statistic}
							menuStyle={{width: '280px'}}
							onSelectChange={onStatisticChange}
							inputSelectItem={inputSelectStatistic}/>
					</Stack>
					<ContainedButton 
						style={{marginTop: 'auto', height: '48px'}}
						iconSide='right'
						onClick={calculateStatistics}
						disabled={players.length === 0 || statistic.length === 0 || calcLoading}
						icon={!calcLoading && <CustomIcon icon={icons.COINS} color={'#000'} />}
						text={!calcLoading ? `Calcola ${statisticPrice}` : 'Sto calcolando...'} />
				</Stack>
				<TextButton 
					action={() => navigate('/fantacalcio_rose')}
					style={{marginTop: 'auto', marginBottom: '4px'}}
					text={'Vai a gestione Rose'}/>
		</Stack>
	)

	const renderOldTitle = () => (
		<Stack marginTop={'40px'}>
			<Typography
				variant='bigTitle'>
					Statistiche Rose
				</Typography>
				{selectedFantaTeamId &&
					<Typography 
						variant='smallTitle'
						fontWeight={'800'}
						color={palette.grey.grey_100}>
							{`${_fantaTeamName} - ${_fantaType} - ${_leagueName}`}
					</Typography>
				}
		</Stack>
	)

	const renderRadioButtons = () => (
		<Stack pr={'24px'}
			marginLeft={'40px'}
			minWidth={400}>
			<Typography fontWeight={'700'}
				color={palette.grey.grey_100}>
					Sezione
				</Typography>
				<InputRadio
					selectedValue={section}
					handleSelect={setSection}
					radioItem={radioItems} />
		</Stack>
	)

	const getGuideContent = () => {
    return (
			<span style={{display: 'flex', flexDirection: 'column'}}>
      <div style={{ display: "flex", flexDirection: "column", padding: '24px', maxHeight: "50vh", overflowY: "auto", minWidth: '20vw' }} id="custom-scrollbar">
        {Object.keys(fantaStatistics).map((statId, idx) => {
					const statObj = fantaStatistics[statId];
					const { nome_descrizione, descrizione } = statObj;
					return (
						<React.Fragment key={statId}>
							<Typography variant="title" style={{marginTop: idx !== 0 ? '8px' : '0px'}}>{nome_descrizione}</Typography>
							<Typography variant="smallTitle">{descrizione}</Typography>
						</React.Fragment>
					)
				})}
				
      </div>
			<TextButton 
				text="Chiudi" action={() => setHelpModalVisible(false)} 
				style={{ 
					marginTop: '24px', 
					marginBottom: '24px', 
					marginRight: 'auto', 
					marginLeft: 'auto' 
				}} />
			</span>
    );
  };

	const renderSettings = () => (
		<Stack gap={'24px'}
			direction={'row'}>
			<Stack minWidth={'240px'} ml={'48px'}>
				<Typography
					color={'#FFF'}
					fontWeight={'500'}
					fontSize={'20px'}>
						Rosa
				</Typography>
				<InputSelect 
					style={{
						height: '48px',
						'& .MuiSelect-select.MuiSelect-standard.MuiInput-input.MuiInputBase-input': {
							paddingRight: '40px',
						}
					}}
					selectedValue={fantaTeam}
					onSelectChange={onFantaTeamChange}
					inputSelectItem={inputSelectFantaTeams}/>
			</Stack>
			<Stack minWidth={'240px'}>
				<Typography
					color={'#FFF'}
					fontWeight={'600'}
					fontSize={'20px'}>
						Statistiche
				</Typography>
				<InputSelect 
					multiple
					style={{
						height: '48px',
						width: '280px',
						'& .MuiSelect-select.MuiSelect-standard.MuiInput-input.MuiInputBase-input': {
							paddingRight: '40px',
						}
					}}
					showTextContent
					chipTextValue={'short'}
					selectedValue={statistic}
					menuStyle={{width: '280px'}}
					onSelectChange={onStatisticChange}
					inputSelectItem={inputSelectStatistic}/>
			</Stack>
			<ContainedButton 
				style={{marginTop: 'auto', height: '48px'}}
				iconSide='right'
				onClick={calculateStatistics}
				disabled={players.length === 0 || statistic.length === 0 || calcLoading}
				icon={!calcLoading && <CustomIcon icon={icons.COINS} color={'#000'} />}
				text={!calcLoading ? `Calcola ${statisticPrice}` : 'Sto calcolando...'} />
		</Stack>
	)

	const ScrollContainer = ({children}) => (
		<Stack flex={1}
			marginTop={'24px'}
			position={'relative'}> 
				<div id='custom-scrollbar' style={{
					right: 0, left: 0, top: 0, bottom: 0,
					position: 'absolute',
					overflowY: 'auto',
				}}>{children}</div>
		</Stack>
	)

	const renderTitle = () => (
		<Stack>
			<Typography
				variant='bigTitle'>
					Statistiche Rose
				</Typography>
				{selectedFantaTeamId &&
					<Typography 
						variant='smallTitle'
						fontWeight={'800'}
						color={palette.grey.grey_100}>
							{`${_fantaTeamName} - ${_fantaType} - ${_leagueName}`}
					</Typography>
				}
		</Stack>
	)

	const renderFantaStatisticsSection = () => (
		<React.Fragment>
			<Stack
				direction={'row'} 
				marginTop={'40px'}
				justifyContent={'flex-start'}
				alignItems={'center'}>
					{renderTitle()}
					{renderSettings()}
					<TextButton 
						action={() => navigate('/fantacalcio_rose')}
						style={{marginTop: 'auto', marginBottom: '4px', marginLeft: 'auto'}}
						text={'Vai a gestione Rose'}/>
			</Stack> 
			<ScrollContainer>
				<TableComponent 
					resultObject={resultObject}
					selectedStatistics={statistic}
					teamObject={(fantaTeams && selectedFantaTeamId) ? fantaTeams[selectedFantaTeamId] : {}}/>
			</ScrollContainer>
		</React.Fragment>
	)

	return (
		<Stack flex={1}
			paddingY={'24px'}
			height={'100%'}
			paddingX={'32px'}>
				{renderHeader()}
				{
					section === 0
						? renderFantaStatisticsSection()
						: <EvaluatePlayers />
				}
			<CustomDialog
        open={helpModalVisible}
        handleClose={() => setHelpModalVisible(false)}
        backDropClickClose
      	style={{ zIndex: 10004 }}
      	title={"Serve aiuto?"}
        customContent
      	icon={icons.LIFE_RING}
      	titlePosition="center"
       	roundCorners>
      		{getGuideContent()}
      </CustomDialog>
		</Stack>
	)
}

const mapStateToProps = ({ Fanta, Profile }) => ({
  uid: Profile.userProfile.uid,
  userAppData: Profile.userProfile.appData,
	selectedFantaTeamId: Fanta.selectedFantaTeamId,
  fantaStatistics: Fanta.fantaStatistics,
  fantaPlayers: Fanta.fantaPlayers,
  fantaSettings: Fanta.fantaSettings,
	fantaEvaluatePlayerPrice: Fanta.evaluatePlayerPrice,
  fantaEvaluatePlayerGuide: Fanta.evaluatePlayerGuide,
  fantaEvaluatePlayerSettings: Fanta.evaluatePlayerSettings,
});

const mapDispatchToProps = dispatch => ({
  //loadFantaPlayers: () => dispatch(fetchFantaPlayers()),
  //loadFantaSatistics: () => dispatch(fetchFantaStatistics()),
	reloadUserCredits: (uid) => dispatch(reloadUserCredit(uid)),
	selectFantaTeamId: (fantaTeamId) => dispatch(selectFantaTeam(fantaTeamId)),
	loadFantaEvaluateGuide: () => dispatch(fetchFantaEvaluateGuide()),
  loadFantaEvaluatePrice: () => dispatch(fetchFantaEvaluatePrice()),
  loadFantaEvaluateSettings: () => dispatch(fetchFantaEvaluateSettings()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FantaStatistics);