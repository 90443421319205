import React from "react";
import { connect, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { icons } from "../../assets/styles/constant/general";
import { palette } from "../../assets/styles/constant/palette";
import CustomDrawer from "../components/CustomDrawer/CustomDrawer";
import Header from "./Header";
import { useNavigate, useLocation } from "react-router";
import { Dialog, Slide } from "@mui/material";
import CreditPurchase from "../components/creditPurchase/CreditPurchase";
import { coinsFormat } from "../../helpers/util";

const navigationObj = [
  { label: "Dashboard", to: "/dashboard", icon: icons.HOME },
  { label: "Calcolatore", to: "/", icon: icons.PLAY },
  { label: "Profilo", to: "/profilo", icon: icons.USER },
  { label: "Supporto", to: "/supporto", icon: icons.LIFE_RING },
];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AppLayout = (props) => {
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [smallDrawer, setSmallDrawer] = React.useState(false);
  const [lastChoosenState, setLastChoosenState] = React.useState(false);
  const [creditPurchaseOpen, setCreditPurchaseOpen] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
    setLastChoosenState(!lastChoosenState);
  };

  React.useEffect(() => {
    switch (props.windowBreakpoint) {
      case "LARGE":
        if (creditPurchaseOpen) setCreditPurchaseOpen(false);
        if (smallDrawer) setSmallDrawer(false);
        setDrawerOpen(true);
        if (location.pathname === "/calculator/scenarios") navigate("/calculator");
        break;
      case "MEDIUM":
        if (creditPurchaseOpen) setCreditPurchaseOpen(false);
        if (smallDrawer) setSmallDrawer(false);
        if (!drawerOpen) break;
        setDrawerOpen(false);
        break;
      case "SMALL":
        setSmallDrawer(true);
        setDrawerOpen(false);
        break;
      default:
        break;
    }
  }, [props.windowBreakpoint]);

  return (
    <>
      <Dialog fullScreen open={creditPurchaseOpen} onClose={() => setCreditPurchaseOpen(false)} TransitionComponent={Transition}>
        <div style={{ display: "flex", height: "100vh" }}>
          <CreditPurchase
            mobile
            title={"Acquisto crediti"}
            subtitle={"Seleziona il piano e procedi con l'acquisto"}
            handleClose={() => setCreditPurchaseOpen(false)}
          />
        </div>
      </Dialog>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: props.windowBreakpoint === "SMALL" && "column",
          background: palette.grey.grey_600,
        }}
      >
        <CustomDrawer open={drawerOpen} action={toggleDrawer} navigationObject={navigationObj} isSmall={smallDrawer} mobile={smallDrawer} />
        {props.windowBreakpoint === "SMALL" && (
          <Header credits={coinsFormat(props.credits)} toggleDrawer={toggleDrawer} addCredits={() => setCreditPurchaseOpen(true)} />
        )}
        <div
          style={{
            display: "flex",
            position: "relative",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            overflowY: props.windowBreakpoint === "SMALL" ? "auto" : "",
          }}
        >
          <div
            style={{
              maxWidth: "1500px",
              display: "flex",
              flex: 1,
              margin: "0 auto",
              overflowY: "auto",
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ Screen, Profile }) => ({
  windowBreakpoint: Screen.breakpoint,
  credits: Profile.userProfile.appData.credit,
});

export default connect(mapStateToProps)(AppLayout);
