import { combineReducers } from "redux";

/* IMPORT ALL REDUCER-FILE HERE */

import Login from "./auth/login/reducer";
import Register from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./profile/reducer";
import Screen from "./screen/reducer";
import Calculation from "./calculation/reducer";
import CalculationWork from "./calculationWork/reducer";
import Fanta from './fanta/reducer';
import Tipster from "./tipster/reducer";

const rootReducer = combineReducers({
  /* COMBINE ALL REDUCERS */
  Login,
  Register,
  ForgetPassword,
  Profile,
  Screen,
  Calculation,
  CalculationWork,
  Fanta,
  Tipster,
});

export default rootReducer;
