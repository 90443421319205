import { takeEvery, put, call } from "redux-saga/effects";
import { FETCH_USER_REGISTRATION_DATA, REGISTER_USER } from "./actionTypes";
import {
  registerUserSuccessful,
  registerUserFailed,
  fetchUserRegistrationDataFailed,
  fetchUserRegistrationDataSuccess,
} from "./actions";
import { getFirebaseBackend } from "../../../service/common/firebase";
import * as UserAuth from "../../../service/UserAuth";
import { logError } from "../../../service/handleError";
import { FETCH_REGISTRATION_DATA_ERROR, REGISTRATION_ERROR } from "../../../service/constant/errorCodes";
import { loadProfile } from "../../actions";

const fireBaseBackend = getFirebaseBackend();

function* registerUser({ payload: { user } }) {
  try {
    const userAuthInfo = yield call(UserAuth.createUserEmailPassword, user);
    if (userAuthInfo) {
      //repeat load Profile here for update display Name
      yield put(loadProfile(userAuthInfo));
      yield put(registerUserSuccessful(userAuthInfo));
    } else {
      logError(REGISTRATION_ERROR);
      yield put(registerUserFailed(REGISTRATION_ERROR));
    }
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

function* fetchUserRegistrationData({ payload: { idUserInvitation } }) {
  try {
    const response = yield call(fireBaseBackend.fetchUserRegistrationData, idUserInvitation);
    if (response) {
      yield put(fetchUserRegistrationDataSuccess(response));
    } else {
      logError(FETCH_REGISTRATION_DATA_ERROR);
      yield put(fetchUserRegistrationDataFailed(FETCH_REGISTRATION_DATA_ERROR));
    }
  } catch (error) {
    yield put(fetchUserRegistrationDataFailed(FETCH_REGISTRATION_DATA_ERROR));
  }
}

function* accountSaga() {
  yield takeEvery(REGISTER_USER, registerUser);
  yield takeEvery(FETCH_USER_REGISTRATION_DATA, fetchUserRegistrationData);
}

export default accountSaga;
