import {
  CREATE_CALCULATION_OBJECT,
  CREATE_CALCULATION_OBJECT_ERROR,
  CREATE_CALCULATION_OBJECT_SUCCESS,
  DELETE_CALCULATION_OBJECT,
  DELETE_CALCULATION_OBJECT_ERROR,
  DELETE_CALCULATION_OBJECT_SUCCESS,
  DUPLICATE_CALCULUS,
  DUPLICATE_CALCULUS_SUCCESS,
  DUPLICATE_CALCULUS_ERROR,
  EMPTY_CALCULATION_LIST,
  SET_SELECTED_CALCULUS,
  SET_SELECTED_SETTINGS,
  UPDATE_CALCULATION_OBJECT,
  UPDATE_CALCULATION_OBJECT_ERROR,
  UPDATE_CALCULATION_OBJECT_SUCCESS,
  RESET_CALCULATOR,
  SET_SELECTED_SETTINGS_ERROR,
  SET_SELECTED_SETTINGS_SUCCESS,
  UPDATE_CALCULATION_OBJECT_SCENARIOS,
  UPDATE_CALCULATION_OBJECT_SCENARIOS_SUCCESS,
  UPDATE_CALCULATION_OBJECT_SCENARIOS_ERROR,
  SET_TOTAL_PRICE,
  SET_RESULT,
  SET_RESULT_SUCCESS,
  SET_RESULT_ERROR,
  SET_FILTER,
  SET_GROUP_BY,
  SET_EMPTY_SCREEN,
} from "./actionType";

export function createCalculationObject(configurationObject) {
  return {
    type: CREATE_CALCULATION_OBJECT,
    payload: { configurationObject },
  };
}

export function createCalculationObjectSuccess(calculationList) {
  return {
    type: CREATE_CALCULATION_OBJECT_SUCCESS,
    payload: { calculationList },
  };
}

export function createCalculationObjectError(error) {
  return {
    type: CREATE_CALCULATION_OBJECT_ERROR,
    payload: { error },
  };
}

export function deleteCalculationObject(id) {
  return {
    type: DELETE_CALCULATION_OBJECT,
    payload: { id },
  };
}

export function deleteCalculationObjectSuccess(calculationList) {
  return {
    type: DELETE_CALCULATION_OBJECT_SUCCESS,
    payload: { calculationList },
  };
}

export function deleteCalculationObjectError(error) {
  return {
    type: DELETE_CALCULATION_OBJECT_ERROR,
    payload: { error },
  };
}

export function updateCalculationObject(id, configurationObject) {
  return {
    type: UPDATE_CALCULATION_OBJECT,
    payload: { id, configurationObject },
  };
}

export function updateCalculationObjectSuccess(calculationList) {
  return {
    type: UPDATE_CALCULATION_OBJECT_SUCCESS,
    payload: { calculationList },
  };
}

export function updateCalculationObjectError(error) {
  return {
    type: UPDATE_CALCULATION_OBJECT_ERROR,
    payload: { error },
  };
}

export function updateCalculationObjectScenarios(id, scenariosObject) {
  return {
    type: UPDATE_CALCULATION_OBJECT_SCENARIOS,
    payload: { id, scenariosObject },
  };
}

export function updateCalculationObjectScenariosSuccess(calculationList) {
  return {
    type: UPDATE_CALCULATION_OBJECT_SCENARIOS_SUCCESS,
    payload: { calculationList },
  };
}

export function updateCalculationObjectScenariosError(error) {
  return {
    type: UPDATE_CALCULATION_OBJECT_SCENARIOS_ERROR,
    payload: { error },
  };
}

export function setSelectedCalculus(calculationObject) {
  return {
    type: SET_SELECTED_CALCULUS,
    payload: { calculationObject },
  };
}

export function setSelectedSettings(settings) {
  return {
    type: SET_SELECTED_SETTINGS,
    payload: { settings },
  };
}

export function setSelectedSettingsSuccess(settings) {
  return {
    type: SET_SELECTED_SETTINGS_SUCCESS,
    payload: { settings },
  };
}

export function setSelectedSettingsError(error) {
  return {
    type: SET_SELECTED_SETTINGS_ERROR,
    payload: { error },
  };
}

export function emptyCalculationList() {
  return {
    type: EMPTY_CALCULATION_LIST,
    payload: null,
  };
}

export function duplicateCalculus(duplicatedCalculus) {
  return {
    type: DUPLICATE_CALCULUS,
    payload: { duplicatedCalculus },
  };
}

export function duplicateCalculusSuccess(newCalculationList) {
  return {
    type: DUPLICATE_CALCULUS_SUCCESS,
    payload: { newCalculationList },
  };
}

export function duplicateCalculusError(error) {
  return {
    type: DUPLICATE_CALCULUS_ERROR,
    payload: { error },
  };
}

export function resetCalculator() {
  return {
    type: RESET_CALCULATOR,
    payload: null,
  };
}

export function loadTotalPrice(totalPrice) {
  return {
    type: SET_TOTAL_PRICE,
    payload: { totalPrice },
  };
}

export const setResult = (result) => {
  return {
    type: SET_RESULT,
    payload: { result },
  };
};

export const setResultSuccess = (calcList) => {
  return {
    type: SET_RESULT_SUCCESS,
    payload: { calcList },
  };
};

export const setResultError = (error) => {
  return {
    type: SET_RESULT_ERROR,
    payload: { error },
  };
};

export const setFilter = (filter) => {
  return {
    type: SET_FILTER,
    payload: { filter },
  };
};

export const setGroupBy = (groupBy) => {
  return {
    type: SET_GROUP_BY,
    payload: { groupBy },
  };
};

export const setEmptyScreen = (value) => {
  return {
    type: SET_EMPTY_SCREEN,
    payload: { value },
  };
};
