import { createTheme } from "@mui/material";
import { palette } from "./constant/palette";
import { styleConstant } from "./constant/styleConstants";

export const formControlLabel = createTheme({
  components: {
    MuiFormControlLabel: {
      variants: [
        {
          props: { variant: "radio-control" },
          style: {
            color: palette.white.main,
            background: palette.grey.grey_200,
            borderRadius: styleConstant.borderRadius.x1,
            padding: "4px",
            flex: 1,
            margin: "0px",
          },
        },
      ],
    },
  },
});
