import { ButtonBase, Dialog, Slide } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { palette } from "../../../../assets/styles/constant/palette";
import CalculationSection from "../../../components/CalculatorSections/CalculationSection";
import ConfigSection from "../../../components/CalculatorSections/ConfigSection";
import CalculatorMobileFooter from "./CalculatorMobileFooter";
import CalculatorMobileHeader from "./CalculatorMobileHeader";
import { useNavigate } from "react-router";
import CustomIcon from "../../../components/CustomIcon";
import { icons } from "../../../../assets/styles/constant/general";
import { styleConstant } from "../../../../assets/styles/constant/styleConstants";
import CalculatorSettingsMobile from "./CalculatorSettingsMobile";
import ScenariosSection from "../../../components/CalculatorSections/ScenariosSection";
import CustomIconButton from "../../../components/buttons/CustomIconButton";
import { connect } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CalculatorMobileLayout = (props) => {
  const [configOpen, setConfigOpen] = React.useState(false);
  const [settingsOpen, setSettingsOpen] = React.useState(false);
  const [isScenarioOpen, setIsScenarioOpen] = React.useState(false);
  const [isCalcEditable, setIsCalcEditable] = React.useState(false);
  const navigate = useNavigate();

  const handleConfigOpen = () => {
    setConfigOpen(true);
  };

  const handleConfigOpenForEdit = () => {
    setIsCalcEditable(true);
    setConfigOpen(true);
  };

  const handleConfigClose = () => {
    setConfigOpen(false);
    setIsCalcEditable(false);
  };

  const onGoBack = () => {
    navigate("/calculator");
    setIsScenarioOpen(false);
  };

  return (
    <>
      <Dialog fullScreen open={configOpen} onClose={handleConfigClose} TransitionComponent={Transition}>
        <div style={{ display: "flex", height: "100vh" }}>
          <ConfigSection mobile closeMobileDialog={handleConfigClose} isCalcEditable={isCalcEditable} />
        </div>
      </Dialog>

      <Dialog fullScreen open={settingsOpen} onClose={() => setSettingsOpen(false)} TransitionComponent={Transition}>
        <CalculatorSettingsMobile onClose={() => setSettingsOpen(false)} />
      </Dialog>

      {/* <Dialog
        fullScreen
        open={isScenarioOpen}
        onClose={() => setIsScenarioOpen(false)}
        TransitionComponent={Transition}
        style={{ height: "calc(100vh - 84px)", marginTop: "auto" }}
      >
        <div style={{ display: "flex", height: "100%" }}>
          <ScenariosSection mobile />
        </div>
      </Dialog> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: palette.grey.grey_600,
          flexDirection: "column",
          padding: `${styleConstant.padding.x3} 0px ${styleConstant.padding.x3} ${styleConstant.padding.x3}`,
          position: "relative",
          flex: 1,
        }}
      >
        {!isScenarioOpen ? (
          <CalculatorMobileHeader
            title={"Calcolatore"}
            subtitle={props.tools[props.settings.tool].nome + " - " + props.subjects[props.settings.subject].nome}
            icon={<CustomIcon icon={icons.PENCIL} />}
            action={() => setSettingsOpen(true)}
          />
        ) : (
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <CustomIconButton icon={<CustomIcon icon={icons.ARROW_LEFT} color={palette.primary.main} size="2xl" />} onClick={onGoBack} />
          </div>
        )}
        <Routes>
          <Route
            index
            element={<CalculationSection mobile openScenarioSectionMobile={() => setIsScenarioOpen(true)} onMobileCardEdit={handleConfigOpenForEdit} />}
          />
          <Route path="/scenarios" element={<ScenariosSection mobile />} />
        </Routes>
        {!isScenarioOpen && <CalculatorMobileFooter action={handleConfigOpen} />}
      </div>
    </>
  );
};

const mapStateToProps = ({ CalculationWork, Calculation }) => ({
  settings: CalculationWork.settings,
  subjects: Calculation.subjects,
  tools: Calculation.tools,
});

export default connect(mapStateToProps, null)(CalculatorMobileLayout);
