import { createTheme } from "@mui/material";
import { palette } from "./constant/palette";
import { styleConstant } from "./constant/styleConstants";

export const menuItem = createTheme({
  components: {
    MuiMenuItem: {
      variants: [
        {
          props: { variant: "filter-menu" },
          style: {
            // background: palette.grey.grey_300,
            borderRadius: styleConstant.borderRadius.x1,
            height: styleConstant.minHeight.x5,
            display: "flex",
            justifyContent: "space-between",
            color: palette.white.main,

            "&:hover": {
              background: palette.grey.grey_300,
            },
          },
        },
        {
          props: { variant: "filter-menu", className: "sub-item" },
          style: {
            background: "transparent",
            borderRadius: styleConstant.borderRadius.x1,
            height: styleConstant.minHeight.x5,
            display: "flex",
            justifyContent: "space-between",
            color: palette.white.main,
            "&:hover": {
              background: palette.grey.grey_300,
            },
          },
        },
        {
          props: { variant: "filter-menu", className: "selected" },
          style: {
            background: palette.grey.grey_300,
            borderRadius: styleConstant.borderRadius.x1,
            height: styleConstant.minHeight.x5,
            display: "flex",
            justifyContent: "space-between",
            color: palette.white.main,
            "&:hover": {
              background: palette.grey.grey_300,
            },
          },
        },
        {
          props: { variant: "filter-menu", className: "header" },
          style: {
            borderRadius: styleConstant.borderRadius.x1,
            height: styleConstant.minHeight.x5,
            display: "flex",
            justifyContent: "space-between",
            cursor: "auto",
            color: palette.white.main,
            paddingRight: "0px",
            paddingLeft: "0px",
            "&:hover": {
              background: "transparent",
            },
          },
        },
      ],
    },
  },
});
