import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { icons } from '../../../assets/styles/constant/general';
import { palette } from '../../../assets/styles/constant/palette';
import CustomIconButton from '../../../core/components/buttons/CustomIconButton';
import CustomIcon from '../../../core/components/CustomIcon';
import { brkRef } from '../../../helpers/util';

const DEFAULT_ORDER_DIRECTION = { direction: 'asc', idStatistic: null };

const TableComponent = ({teamObject, fantaPlayers, fantaStatistics, resultObject, selectedStatistics}) => {

	const { players } = teamObject;
	const _players = players ? Object.values(players) : [];
	const statisticsNumber = Object.keys(fantaStatistics).length;

	const [sortedPlayers, setSortedPlayers] = React.useState(_players);
	const [orderDirection, setOrderDirection] = React.useState(DEFAULT_ORDER_DIRECTION);

	React.useEffect(() => {
		console.log('orderDirection', orderDirection)
		if (resultObject && resultObject['risultati'] && orderDirection.idStatistic) { 
			const idStat = orderDirection.idStatistic;
			const direction = orderDirection.direction;
			const newSortedPlayers = sortPlayersByStatistic(idStat, direction);
			console.log(newSortedPlayers);
			setSortedPlayers(newSortedPlayers);
		}
	}, [orderDirection]);

	const sortPlayersByStatistic = (idStatistic, direction) => {
		const _resultObject = resultObject['risultati'];
    const res =  brkRef(sortedPlayers).sort((a,b) => {
      const valA = transformToFloat(_resultObject[a][idStatistic]);
      const valB = transformToFloat(_resultObject[b][idStatistic]);
      return (direction === 'asc') ?  valA - valB : valB - valA
    });
    return res;
  }

	const onOrderDirectionChange = (direction, idStat) => {
		console.log('onOrderDirectionChange', direction, idStat);
		if (!sortedPlayers || sortedPlayers.length === 0) return;
		if (!resultObject || !resultObject['risultati']) return;
		if (!resultObject['risultati'][sortedPlayers[0]][idStat]) return;
		let newOrderDirection = brkRef(orderDirection);
		newOrderDirection.direction = direction;
		newOrderDirection.idStatistic = idStat;
		setOrderDirection(newOrderDirection);
	}

  const transformToFloat = (val) => {
    let res =  val.replace(/~0/g, "0");
    res = res.replace('%','')
    return  val.includes("~") ? res+0.00001 : res;
  }

	const getOrderIcon = (idStat, direction) => {
		if (orderDirection.idStatistic !== idStat) return icons.SORT;
		if (direction === 'asc') return icons.SORT_UP;
		return icons.SORT_DOWN;
	}

	const TableHeaderText = ({text, isFirstColumn = false, selected = false}) => (
		<Typography
			fontSize={isFirstColumn ? '24px' : '16px'}
			color={selected ? '#FFF' : palette.grey.grey_100}
			fontWeight={'600'}>
				{text}
		</Typography>
	)

	const TableRowText = ({text, isFirstColumn = false, selected = false}) => (
		<Typography
			textAlign={!isFirstColumn ? 'center' : 'start'}
			fontSize={'18px'}
			color={!selected ? palette.grey.grey_100 : '#FFF'}
			fontWeight={'600'}>
				{text}
		</Typography>
	)

	const TableHeader = () => (
		<TableHead>
			<TableRow>
				<TableCell 
					style={{...firstColumnStyle, ...firstColumnsHeaderStyle}}>
					<TableHeaderText 
						isFirstColumn
						text={'Giocatori'}/>
				</TableCell>
				{Object.values(fantaStatistics).map((statisticObject, idx) => {
					const { nome, id, nome_descrizione, descrizione } = statisticObject;
					const columnSelected = Object.values(selectedStatistics).filter(el => el.value === id).length > 0;
					const _direction = orderDirection.direction;
					return (
						<TableCell key={id}
							style={{...commonCellStyle, ...(idx === statisticsNumber - 1 ? lastColumnHeaderStyle : {}), ...headerRowStyle}}>
							<Stack justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
								<TableHeaderText text={nome_descrizione} selected={columnSelected}/>
								<CustomIconButton
									onClick={() => onOrderDirectionChange(_direction === 'asc' ? 'desc' : 'asc', id)}
									icon={<CustomIcon icon={getOrderIcon(id, _direction)}/>}/>
							</Stack>
						</TableCell>
					)
				})}
			</TableRow>
		</TableHead>
	)

/* 	<TableHeaderText text={nome_descrizione} selected={columnSelected}/>
							<CustomIconButton 
								icon={<CustomIcon icon={icons.FACEBOOK}/>}/> */

	const Row = ({playerObj}) => {
		if (!playerObj) return <></>;
		const { nome, id } = playerObj;
		const _playerId = id.toString();
		return (
			<>
				<TableRow>
					<TableCell style={{...firstColumnStyle}}>
						<TableRowText 
							text={nome}
							selected
							isFirstColumn />
					</TableCell>
					{Object.values(fantaStatistics).map((statisticObject, idx) => {
						const { nome, id } = statisticObject;
						let result = '--';
						const columnSelected = Object.values(selectedStatistics).filter(el => el.value === id).length > 0;
						if (resultObject) result = resultObject['risultati'][_playerId][id] || '--';
						return (
							<TableCell style={{...commonCellStyle}} key={idx}>
								<TableRowText text={result} selected={columnSelected} />
							</TableCell>
						)		
					})}
				</TableRow>
			</>
		)
 }

  return (
		<Stack style={{width:'100%', height: '100%'}}>
				<TableContainer>
					<Table stickyHeader>
						<TableHeader />
						<TableBody>
							{sortedPlayers.map(playerId => {
								const playerObj = fantaPlayers[playerId];
								return <Row key={playerId} playerObj={playerObj} />
							})}
						</TableBody>
					</Table>
				</TableContainer>
		</Stack>
	)
}

const mapStateToProps = ({ Fanta }) => ({
  fantaPlayers: Fanta.fantaPlayers,
	fantaStatistics: Fanta.fantaStatistics,
});

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TableComponent);

const firstColumnStyle = {
	background: '#1D2025',
	borderBottom: '2px solid #15191D',
	borderRight: '4px solid #15191D',
	position: 'sticky',
	left: 0,
	zIndex: 9,
	minWidth: '250px',
};

const lastColumnHeaderStyle = {
	borderTopRightRadius: '16px',
};

const lastColumnRowStyle = {
	borderBottomRightRadius: '16px',
};

const firstColumnsHeaderStyle = {
	borderTopLeftRadius: '16px',
};

const lastColumnStyle = {
	borderBottomLeftRadius: '16px',
};

const commonCellStyle = {
	background: '#282A30',
	minWidth: '170px',
	borderBottom: '2px solid #15191D',
	borderRight: '2px solid #15191D',
};

const headerRowStyle = {
	background: '#1D2025',
};

const selectedColumnsStyle = {
	background: '#47431E',
};

/* const tableContainerScrollBarStyle = {
	"&::-webkit-scrollbar": {
	  height: 8
  },
  "&::-webkit-scrollbar-track": {
		backgroundColor: "orange"
  },
  "&::-webkit-scrollbar-thumb": {
	  backgroundColor: "red",
	  borderRadius: 2
  }
} */
