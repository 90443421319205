import React from "react";
import { creditPurchase, styleConstant } from "../../../assets/styles/constant/styleConstants";
import CustomIcon from "../CustomIcon";
import { icons } from "../../../assets/styles/constant/general";
import { Typography } from "@mui/material";
import { palette } from "../../../assets/styles/constant/palette";
import ContainedButton from "../buttons/ContainedButton";
import { connect } from "react-redux";
import { coinsFormat } from "../../../helpers/util";

const PaymentAccepted = ({ creditPurchased, onTerminate, userCredits }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        marginRight: styleConstant.padding.x5,
      }}
    >
      <CustomIcon icon={icons.CHECK} size="3x" color={palette.primary.main} style={{ marginLeft: "auto", marginRight: "auto" }} />
      <div style={{ display: "flex", justifyContent: "center", marginTop: styleConstant.margin.x5 }}>
        <Typography style={{ ...creditPurchase.paymentAccepted.title }}>Acquisto avvenuto con successo!</Typography>
      </div>
      <Typography style={{ ...creditPurchase.paymentAccepted.subtitle, marginTop: styleConstant.margin.x5 }}>
        Hai acquistato {creditPurchased} crediti.
      </Typography>
      <Typography style={creditPurchase.paymentAccepted.subtitle}>Il tuo saldo attuale è di</Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: styleConstant.gap.large,
          marginTop: styleConstant.margin.x7,
        }}
      >
        <Typography style={creditPurchase.paymentAccepted.coins}>{coinsFormat(userCredits)}</Typography>
        <CustomIcon icon={icons.COINS} color={palette.white.main} size="2x" />
      </div>
      <ContainedButton text="TERMINA PAGAMENTO" style={{ marginTop: "auto", height: "50px" }} onClick={onTerminate} />
    </div>
  );
};

const mapStateToProps = ({ Profile }) => ({
  userCredits: Profile.userProfile.appData.credit,
});

export default connect(mapStateToProps, null)(PaymentAccepted);
