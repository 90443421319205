import React from "react";
import Chip from "@mui/material/Chip";
import CustomIcon from "../CustomIcon";
import CustomIconButton from "../buttons/CustomIconButton";
import { icons } from "../../../assets/styles/constant/general";

const CustomChip = ({ onClick, label }) => {
  return (
    <Chip
      label={label}
      onDelete={onClick}
      // onClick={onClick}
      variant="input-select"
      deleteIcon={
        <CustomIconButton
          icon={
            <CustomIcon
              icon={icons.XMARK}
              fontSize={"0.7em"}
              // style={{ position: "absolute", top: "0", bottom: "0", left: "0", right: "0", zIndex: "5" }}
              // size="sm"
            />
          }
          onMouseDown={(e) => e.stopPropagation()}
          onClick={onClick}
          // style={{ postion: "relative" }}
        />
      }
    />
  );
};

export default CustomChip;
