import React from "react";
import { Typography } from "@mui/material";
import { creditLabelStyle, textSecondaryStyle } from "../../../assets/styles/constant/styleConstants";
import { palette } from "../../../assets/styles/constant/palette";
import CustomIconButton from "../buttons/CustomIconButton";
import { styleConstant } from "../../../assets/styles/constant/styleConstants";
import CustomIcon from "../CustomIcon";
import { icons } from "../../../assets/styles/constant/general";
import CustomToolTip from "../CustomToolTip";

const InputCrediti = ({ label, credits, action, open = true, style = null }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: open ? "space-between" : "center",
        alignItems: "center",
        padding: `14px ${styleConstant.padding.x2}`,
        background: palette.grey.grey_300,
        borderRadius: styleConstant.borderRadius.x2,
        ...style,
      }}
    // className={vertical ? "go-vertical" : "go-horizontal"}
    >
      {open && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="smallTitle" style={{ ...textSecondaryStyle, ...creditLabelStyle }}>
            {label}
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: styleConstant.gap.small,
            }}
          >
            <Typography variant="title">{credits}</Typography>
            <CustomIcon icon={icons.COINS} color={palette.white.main} />
          </div>
        </div>
      )}
      <CustomToolTip title="Acquista crediti">
        <CustomIconButton icon={<CustomIcon icon={icons.PLUS} />} onClick={action} />
      </CustomToolTip>
    </div>
  );
};

export default InputCrediti;

//PREVIUOS STYLE
// transformOrigin: "0 0",
// position: "absolute",
// top: "24px",
// transform: vertical
//   ? "rotate(-90deg) translateX(-100%)"
//   : "rotate(0deg)",
// left: vertical ? "6px" : "16px",
// width: !vertical && "208px",

//PREVIOUS ANIMATION
// const injectRotationAnimation = () => (
//   <style>{`

// .go-vertical {
//   animation: goVertical 0.5s linear, shrinkWidth 0.5s linear;
//   animation-fill-mode: forwards;
// }

// .go-horizontal {
//   animation: goHorizontal 0.5s linear, extendWidth 0.5s linear;
//   animation-fill-mode: forwards;
// }

// @keyframes goHorizontal {
//   0% {
//     transform: rotate(-90deg) translateX(-100%);
//     left: 16px;
//   }
//   100% {
//     transform: rotate(0deg);
//     left: 16px;
//   }
// }

// @keyframes goVertical {
//   0% {
//     transform: rotate(0deg);
//     left: 16px;

//   }
//   100% {
//     transform: rotate(-90deg) translateX(-100%);
//     left: 16px;
//   }
// }

// @keyframes shrinkWidth {
//   0% {
//     width:208px;
//     height:88px;
//   }
//   100% {
//     width:180px;
//     height:64px;
//   }
// }
// @keyframes extendWidth {
//   0% {
//     width:180px;
//     height:64px;
//   }
//   100% {
//     width:208px;
//     height:88px;
//   }
// }

// `}</style>
// );
