export const LOAD_PROFILE = "LOAD_PROFILE";
export const LOAD_PROFILE_SUCCESS = "LOAD_PROFILE_SUCCESS";
export const LOAD_PROFILE_ERROR = "LOAD_PROFILE_ERROR";

export const EDIT_PROFILE = "EDIT_PROFILE";
export const EDIT_PROFILE_SUCCESS = "EDIT_PROFILE_SUCCESS";
export const EDIT_PROFILE_ERROR = "EDIT_PROFILE_ERROR";

export const DELETE_PROFILE = "DELETE_PROFILE";
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS";
export const DELETE_PROFILE_ERROR = "DELETE_PROFILE_ERROR";

export const UPDATE_PURCHASE_DATA = "UPDATE_PURCHASE_DATA";

export const RELOAD_USER_FANTA_TEAMS = 'RELOAD_USER_FANTA_TEAMS';
export const RELOAD_USER_FANTA_TEAMS_SUCCESS = 'RELOAD_USER_FANTA_TEAMS_SUCECSS';
export const RELOAD_USER_FANTA_TEAMS_ERROR = 'RELOAD_USER_FANTA_TEAMS_ERROR';

export const RELOAD_USER_CREDITS = 'RELOAD_USER_CREDITS';
export const RELOAD_USER_CREDITS_SUCCESS = 'RELOAD_USER_CREDITS_SUCCESS';
export const RELOAD_USER_CREDITS_ERROR = 'RELOAD_USER_CREDITS_ERROR';