import React from "react";
import { Typography } from "@mui/material";
import { palette } from "../../../assets/styles/constant/palette";
import { skeletonLabelStyle, textSecondaryStyle } from "../../../assets/styles/constant/styleConstants";
import TextButton from "../buttons/TextButton";
import CustomIconButton from "../buttons/CustomIconButton";
import { icons } from "../../../assets/styles/constant/general";

const FormSkeleton = ({
  label = "Label",
  action = null,
  actionIcon = null,
  actionButtonStyle = {},
  actionLabel = "",
  children,
  style = null,
  helperText = null,
  secondAction = null,
  secondActionLabel = "",
  actionIconAction = () => {},
}) => {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        marginTop: "15px",
        ...style,
      }}
    >
      <div
        onClick={() => console.log("SKELETON CLICKED")}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "5px",
        }}
      >
        <Typography variant="smallTitle" style={{ ...skeletonLabelStyle }}>
          {label}
        </Typography>
        {/* {helperText ? (
          <Typography
            variant="smallTitle"
            style={{ ...skeletonLabelStyle, color: `${helperText ? helperText.color : null}` }}
          >
            {helperText.text}
          </Typography>
        ) : (
          <Typography variant="smallTitle" style={{ ...skeletonLabelStyle }}>
            {label}
          </Typography>
        )} */}
        {action && <TextButton text={actionLabel} action={action} style={actionButtonStyle} />}
        {actionIcon && <CustomIconButton icon={actionIcon} onClick={actionIconAction} style={{ height: "16px", marginRight: "auto", marginLeft: "8px" }} />}
      </div>
      {children}
      <div style={{ display: "flex", flex: 1 }}>
        {helperText && (
          <Typography variant="subtitle" style={{ ...skeletonLabelStyle, color: `${helperText ? helperText.color : null}` }}>
            {helperText.text}
          </Typography>
        )}
        {secondAction && <TextButton text={secondActionLabel} action={secondAction} style={{ marginLeft: "auto" }} />}
      </div>
    </div>
  );
};

export default FormSkeleton;
