import { Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { palette } from "../assets/styles/constant/palette";
import ContainedButton from "../core/components/buttons/ContainedButton";
import { setAcceptTermsAndConditions } from "../service/userProfile";
import { loadProfile } from "../store/actions";

const TermsAndConditionsPage = (props) => {
  const accept = () => {
    setAcceptTermsAndConditions(props.userProfile.uid)
      .then(() => {
        props.loadProfile(props.userAuthInfo);
      })
      .catch((error) => {
        console.log("generic error");
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        background: palette.grey.grey_500,
        height: "100vh",
      }}
    >
      <Typography color="primary">Accetto termini e condizioni</Typography>
      <ContainedButton text="Accetto" onClick={accept} />
    </div>
  );
};

const mapStateToProps = ({ Profile }) => ({
  userProfile: Profile.userProfile,
  userAuthInfo: Profile.userAuthInfo,
});

const mapDispatchToProps = (dispatch) => ({
  loadProfile: (userAuthInfo) => dispatch(loadProfile(userAuthInfo)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditionsPage);
