// generic error: FE_00
export const UI_ERROR = "FE_001";
export const STARTUP_ERROR = "FE_002";
// auth: FE_01
export const AUTH_LOGIN_ERROR = "FE_010";
export const APPLE_LOGIN_ERROR = "FE_041";
export const GOOGLE_LOGIN_ERROR = "FE_011";
export const FACEBOOK_LOGIN_ERROR = "FE_012";
export const RESEND_EMAIL_VERIFICATION_ERROR = "FE_013";
export const LOGOUT_ERROR = "FE_014";
export const REGISTRATION_ERROR = "FE_015";
export const FETCH_REGISTRATION_DATA_ERROR = "FE_016";
export const FORGET_PSW_ERROR = "FE_017";
// profile: FE_02
export const LOAD_PROFILE_ERROR = "FE_020";
export const EDIT_PROFILE_ERROR = "FE_021";
export const DELETE_PROFILE_ERROR = "FE_022";