import { FETCH_FANTA_EVALUATE_GUIDE, FETCH_FANTA_EVALUATE_GUIDE_ERROR, FETCH_FANTA_EVALUATE_GUIDE_SUCCESS, FETCH_FANTA_EVALUATE_PRICE, FETCH_FANTA_EVALUATE_PRICE_ERROR, FETCH_FANTA_EVALUATE_PRICE_SUCCESS, FETCH_FANTA_EVALUATE_SETTINGS, FETCH_FANTA_EVALUATE_SETTINGS_ERROR, FETCH_FANTA_EVALUATE_SETTINGS_SUCCESS, FETCH_FANTA_PLAYERS, FETCH_FANTA_PLAYERS_ERROR, FETCH_FANTA_PLAYERS_SUCCESS, FETCH_FANTA_SETTINGS, FETCH_FANTA_SETTINGS_ERROR, FETCH_FANTA_SETTINGS_SUCCESS, FETCH_FANTA_STATISTICS, FETCH_FANTA_STATISTICS_ERROR, FETCH_FANTA_STATISTICS_SUCCESS, SELECT_FANTA_TEAM, SELECT_FANTA_TEAM_SUCCESS } from "./actionType";

const initialState = {
  fantaSettings: null,
	loadingFantaSettings: false,
	errorFantaSettings: null,

	fantaPlayers: null,
	loadingFantaPlayers: false,
	errorFantaPlayers: null,

	fantaStatistics: null,
	loadingFantaStatistics: false,
	errorFantaStatistics: null,

	selectedFantaTeamId: null,

	evaluatePlayerPrice: null,
	loadingEvaluatePlayerPrice: false,
	errorEvaluatePlayerPrice: null,

	evaluatePlayerGuide: null,
	loadingEvaluatePlayerGuide: false,
	errorEvaluatePlayerGuide: null,

	evaluatePlayerSettings: null,
	loadingEvaluatePlayerSettings: false,
	errorEvaluatePlayerSettings: null,
};

const Fanta = (state = initialState, action) => {
	switch(action.type) {
		case FETCH_FANTA_SETTINGS:
			state = {
				...state,
				loadingFantaSettings: true,
			}
			break;
		case FETCH_FANTA_SETTINGS_SUCCESS:
			state = {
				...state,
				loadingFantaSettings: false,
				errorFantaSettings: null,
				fantaSettings: action.payload.fantaSettings,
			}
			break;
		case FETCH_FANTA_SETTINGS_ERROR:
			state = {
				...state,
				loadingFantaSettings: false,
				errorFantaSettings: action.payload.error,
				fantaSettings: null,
			}
			break;
		case FETCH_FANTA_PLAYERS:
			state = {
				...state,
				loadingFantaPlayers: true,
			}
			break;
		case FETCH_FANTA_PLAYERS_SUCCESS:
			state = {
				...state,
				loadingFantaPlayers: false,
				errorFantaPlayers: null,
				fantaPlayers: action.payload.fantaPlayers,
			}
			break;
		case FETCH_FANTA_PLAYERS_ERROR:
			state = {
				...state,
				loadingFantaPlayers: false,
				errorFantaPlayers: action.payload.error,
				fantaPlayers: null,
			}
			break;
		case FETCH_FANTA_STATISTICS:
			state = {
				...state,
				loadingFantaStatistics: true,
			}
			break;
		case FETCH_FANTA_STATISTICS_SUCCESS:
			state = {
				...state,
				loadingFantaStatistics: false,
				errorFantaStatistics: null,
				fantaStatistics: action.payload.fantaStatistics,
			}
			break;
		case FETCH_FANTA_STATISTICS_ERROR:
			state = {
				...state,
				loadingFantaStatistics: false,
				errorFantaStatistics: action.payload.error,
				fantaStatistics: null,
			}
			break;
		case SELECT_FANTA_TEAM:
			state = {
				...state,
				selectedFantaTeamId: action.payload.fantaTeamId,
			}
			break;
		case FETCH_FANTA_EVALUATE_GUIDE: 
			state = {
				...state,
				loadingEvaluatePlayerGuide: true,
			}
			break;
		case FETCH_FANTA_EVALUATE_GUIDE_SUCCESS: 
			state = {
				...state,
				loadingEvaluatePlayerGuide: false,
				errorEvaluatePlayerGuide: null,
				evaluatePlayerGuide: action.payload.evaluatePlayerGuide,
			}
			break;
		case FETCH_FANTA_EVALUATE_GUIDE_ERROR:
			state = {
				...state,
				loadingEvaluatePlayerGuide: false,
				errorEvaluatePlayerGuide: action.payload.error,
				evaluatePlayerGuide: null,
			}
			break;
		case FETCH_FANTA_EVALUATE_PRICE: 
			state = {
				...state,
				loadingEvaluatePlayerPrice: true,
			}
			break;
		case FETCH_FANTA_EVALUATE_PRICE_SUCCESS: 
			state = {
				...state,
				loadingEvaluatePlayerPrice: false,
				errorEvaluatePlayerPrice: null,
				evaluatePlayerPrice: action.payload.evaluatePlayerPrice,
			}
			break;
		case FETCH_FANTA_EVALUATE_PRICE_ERROR:
			state = {
				...state,
				loadingEvaluatePlayerPrice: false,
				errorEvaluatePlayerPrice: action.payload.error,
				evaluatePlayerPrice: null,
			}
			break;
		case FETCH_FANTA_EVALUATE_SETTINGS: 
			state = {
				...state,
				loadingEvaluatePlayerSettings: true,
			}
			break;
		case FETCH_FANTA_EVALUATE_SETTINGS_SUCCESS: 
			state = {
				...state,
				loadingEvaluatePlayerSettings: false,
				errorEvaluatePlayerSettings: null,
				evaluatePlayerSettings: action.payload.evaluatePlayerSettings,
			}
			break;
		case FETCH_FANTA_EVALUATE_SETTINGS_ERROR:
			state = {
				...state,
				loadingEvaluatePlayerSettings: false,
				errorEvaluatePlayerSettings: action.payload.error,
				evaluatePlayerSettings: null,
			}
			break;
		default:
			state = {...state};
			break;
	}
	return state;
}

export default Fanta;