import { FETCH_TIPSTER_DATA, FETCH_TIPSTER_DATA_ERROR, FETCH_TIPSTER_DATA_SUCCESS, FETCH_TIPSTER_EVENTI, FETCH_TIPSTER_EVENTI_ERROR, FETCH_TIPSTER_EVENTI_SUCCESS, FETCH_TIPSTER_GRUPPI, FETCH_TIPSTER_GRUPPI_ERROR, FETCH_TIPSTER_GRUPPPI_SUCCESS, FETCH_TIPSTER_STATISTICHE, FETCH_TIPSTER_STATISTICHE_ERROR, FETCH_TIPSTER_STATISTICHE_SUCCESS } from "./actionTypes"

export const fetchTipsterEventi = () => {
  return {
		type: FETCH_TIPSTER_EVENTI,
		payload: {},
	}
}

export const fetchTipsterEventiSuccess = (events) => {
  return {
		type: FETCH_TIPSTER_EVENTI_SUCCESS,
		payload: { events },
	}
}

export const fetchTipsterEventiError = (error) => {
  return {
		type: FETCH_TIPSTER_EVENTI_ERROR,
		payload: { error },
	}
}

export const fetchTipsterGruppi = () => {
  return {
		type: FETCH_TIPSTER_GRUPPI,
		payload: {},
	}
}

export const fetchTipsterGruppiSuccess = (groups) => {
  return {
		type: FETCH_TIPSTER_GRUPPPI_SUCCESS,
		payload: { groups },
	}
}

export const fetchTipsterGruppiError = (error) => {
  return {
		type: FETCH_TIPSTER_GRUPPI_ERROR,
		payload: { error },
	}
}

export const fetchTipsterStatistiche = () => {
  return {
		type: FETCH_TIPSTER_STATISTICHE,
		payload: {},
	}
}

export const fetchTipsterStatisticheSuccess = (statistics) => {
  return {
		type: FETCH_TIPSTER_STATISTICHE_SUCCESS,
		payload: { statistics },
	}
}

export const fetchTipsterStatisticheError = (error) => {
  return {
		type: FETCH_TIPSTER_STATISTICHE_ERROR,
		payload: { error },
	}
}

export const fetchTipsterData = () => {
  return {
		type: FETCH_TIPSTER_DATA,
		payload: {},
	}
}

export const fetchTipsterDataSuccess = (groups, statistics, events, migliori) => {
  return {
		type: FETCH_TIPSTER_DATA_SUCCESS,
		payload: { groups, statistics, events, migliori },
	}
}

export const fetchTipsterDataError = (error) => {
  return {
		type: FETCH_TIPSTER_DATA_ERROR,
		payload: { error },
	}
}