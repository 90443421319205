import { ERROR_TOAST, showToast } from '../helpers/util';
import * as codes from './constant/errorCodes';
import { errorMessages } from './constant/errorMessages';

function getLogSepartor(length) {
  return ''.concat(new Array(length).fill('=')).replaceAll(',', '');
}

export function getErrorMessage(code) {
  return errorMessages[code];
}

function showErrorToast(message) {
  showToast(
    'Errore',
    message,
    'bottom',
    ERROR_TOAST,
    null
  )
}

function errorActionByCode(code, errorMessage) {
  switch (code) {
    case codes.UI_ERROR:
    case codes.STARTUP_ERROR:
      throw new Error('');
    default:
      showErrorToast(errorMessage);
      break;
  }
}

export function logError(code) {
  const errorMessage = getErrorMessage(code);
  const logSeparator = getLogSepartor(errorMessage.length);
  errorActionByCode(code, errorMessage);
  console.log(logSeparator, '\n', errorMessage);
}