import { Backdrop, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../../assets/styles/constant/palette";
import drawer_logo from "../../../assets/images/main_logo.svg";

const CalculusLoading = () => {
  return (
    <Backdrop
      open={true}
      style={{
        zIndex: "10003",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "48px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
        }}
      >
        <img
          src={drawer_logo}
          style={{
            minWidth: "140px",
            width: "10%",
          }}
        />
        <Typography style={{ fontWeight: "700", fontSize: "24px", lineHeight: "32px", color: "#FFF" }}>Esecuzione del calcolo in corso...</Typography>
      </div>
      <CircularProgress />
    </Backdrop>
  );
};

export default CalculusLoading;
