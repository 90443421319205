export const errorMessages = {
  // generic error
  FE_001: "Errore visualizzazione elementi",
  FE_002: "Errore startup applicazione",
  // auth
  FE_010: "Errore login",
  FE_011: "Errore login Google",
  FE_012: "Errore login Facebook",
  FE_013: "Errore invio email per verifica",
  FE_014: "Errore logout",
  FE_015: "Errore registrazione",
  FE_016: "Errore recupero dati di registrazione",
  FE_017: "Errore recuper password dimenticata",
  // profile
  FE_020: "Errore caricamento dati profilo",
  FE_021: "Errore modifica dati profilo",
  FE_022: "Errore eliminazione dati profilo",
}