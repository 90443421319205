import React, { Component } from "react";
/* Redux */
import { connect } from "react-redux";
import { loadProfile } from "./store/actions";
/* Error */
import { logError } from "./service/handleError";
import { LOAD_PROFILE_ERROR } from "./service/constant/errorCodes";
/* Generic import */
import { initFirebaseBackend } from "./service/common/firebase";
import Home from "./screens/Home";
import Temp from "./screens/Temp";
import RoutingTemp from "./screens/RoutingTemp";
import { loadBasicCalcData } from "./store/calculation/actions";
import drawer_logo from "./assets/images/main_logo.svg";
import { CircularProgress, Typography } from "@mui/material";
import { styleConstant, textSecondaryStyle } from "./assets/styles/constant/styleConstants";
import { palette } from "./assets/styles/constant/palette";
import { dateFormatting } from "./helpers/util";
import { fetchFantaPlayers, fetchFantaSettings, fetchFantaStatistics } from "./store/fanta/actions";
import { fetchTipsterData } from "./store/tipster/actions";

class Startup extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.startupProcess = true;
    this.last_userAuthInfo = undefined;
  }

  componentDidMount = () => {
    this.firebaseBK = initFirebaseBackend();

    // this.firebaseBK.signInWithEmailAndPassword("sviluppatoribluni@gmail.com", "teamSviluppo22");

    this.firebaseBK.onAuthStateChanged((userAuthInfo) => {
      if (JSON.stringify(this.last_userAuthInfo) !== JSON.stringify(userAuthInfo)) {
        this.startupProcess = true;
        this.last_userAuthInfo = userAuthInfo;
        this.props.loadProfile(userAuthInfo);

        if (userAuthInfo !== null) {
          this.initAppFunctionalities();
          this.props.loadFantaSettings();
          this.props.loadFantaPlayers();
          this.props.loadFantaSatistics();
          this.props.loadTipsterData();
        }
      }
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    this.handleLoadProfileError();
  };

  componentWillUnmount = () => {};

  initAppFunctionalities = () => {
    /* Inserisci qui le funzioni da chiamare se l'utente è autenticato */
    this.props.loadBasicCalcData();
  };

  handleLoadProfileError = () => {
    if (this.props.loadProfileError) {
      logError(LOAD_PROFILE_ERROR);
    }
  };

  startupProcessingEnd = () => {
    if (this.startupProcess) {
      const { loadingProfile, loadingCalculation, loadingFantaSettings, loadingFantaPlayers, loadingFantaStatistics, loadingTipsterData } = this.props;
      if (!loadingProfile && !loadingCalculation && !loadingFantaSettings && !loadingFantaPlayers && !loadingFantaStatistics && !loadingTipsterData) {
        this.startupProcess = false;
        return true;
      }
      return false;
    }
    return true;
  };

  // logLoadingProcess = (appIsReady) => {
  //   console.log("startupProcess: ", this.startupProcess);
  //   console.log("loadingProfile: ", this.props.loadingProfile);
  //   console.log("loadingCalculation: ", this.props.loadingCalculation);
  //   console.log("appIsRead: ", appIsReady);
  //   console.log("--------------------------------");
  // };

  logLoadingProcess = (appIsReady) => {
    const symbol = (v) => (!v ? "✅" : "🔃");
    let logToPrint = `
    ⚙️ STARTUP PROCESS:  ${symbol(this.startupProcess)}
    loadingProfile:       ${symbol(this.props.loadingProfile)}
    loadingCalculation:   ${symbol(this.props.loadingCalculation)}
    ⚙️ APP IS READY:     ${appIsReady ? "✅" : "❌"}
    --------------------------------
    `;
    console.log(logToPrint);
  };

  renderStartupProcess = () => (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: palette.grey.grey_600,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: 0,
          left: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          style={{
            ...textSecondaryStyle,
            fontSize: "10px",
            fontWeight: 700,
          }}
        >
          v{process.env.REACT_APP_VERSION}
        </Typography>
      </div>
      <img
        src={drawer_logo}
        style={{
          minWidth: "200px",
          width: "15%",
        }}
      />
      <CircularProgress style={{ marginTop: styleConstant.margin.x5 }} />
    </div>
  );

  render() {
    const appIsReady = this.startupProcessingEnd();
    this.logLoadingProcess(appIsReady);
    return (
      <>
        {!appIsReady && this.renderStartupProcess()}
        {appIsReady && <RoutingTemp />}
      </>
    );
  }
}

const mapStateToProps = ({ Profile, Calculation, Fanta, Tipster }) => ({
  loadingProfile: Profile.loadingProfile,
  loadProfileError: Profile.loadProfileError,
  loadingFantaSettings: Fanta.loadingFantaSettings,
  loadingFantaStatistics: Fanta.loadingFantaStatistics,
  loadingFantaPlayers: Fanta.loadingFantaPlayers,
  loadingCalculation: Calculation.loading,
  loadingTipsterData: Tipster.loadingAll,
});

const mapDispatchToProps = (dispatch) => ({
  loadProfile: (userAuthInfo) => dispatch(loadProfile(userAuthInfo)),
  loadFantaSettings: () => dispatch(fetchFantaSettings()),
  loadFantaPlayers: () => dispatch(fetchFantaPlayers()),
  loadFantaSatistics: () => dispatch(fetchFantaStatistics()),
  loadBasicCalcData: () => dispatch(loadBasicCalcData()),
  loadTipsterData: () => dispatch(fetchTipsterData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Startup);
