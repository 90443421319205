import { palette } from "../../../assets/styles/constant/palette";
import React from "react";
import drawer_logo from "../../../assets/images/main_logo.svg";
import closed_logo from "../../../assets/images/closed_drawer_logo.png";
import LinkToPageItem from "./LinkToPageItem";
import { ButtonBase, Dialog, Slide, Typography } from "@mui/material";
import InputCrediti from "./InputCrediti";
import DrawerHelpBox from "./DrawerHelpBox";
import DrawerProfileItem from "./DrawerProfileItem";
import drawerProfileImage from "../../../assets/images/drawerProfileImage.png";
import { styleConstant, textSecondaryStyle } from "../../../assets/styles/constant/styleConstants";
import { useNavigate, useLocation } from "react-router-dom";
import CustomIcon from "../CustomIcon";
import { icons } from "../../../assets/styles/constant/general";
import CreditPurchase from "../creditPurchase/CreditPurchase";
import { logOut } from "../../../service/UserAuth";
import { connect } from "react-redux";
import { coinsFormat, dateFormatting } from "../../../helpers/util";

const mobileStyle = {
  position: "absolute",
  top: "0px",
  left: "0px",
  bottom: "0px",
  zIndex: "100",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDrawer = ({
  open = false,
  action,
  mobile = false,
  navigationObject = null,
  isSmall = false,
  credits,
  profilePhoto,
  nome,
  cognome,
  email,
  tsCreatedData,
}) => {
  const [selected, setSelected] = React.useState("Statistiche");
  const [creditPurchaseOpen, setCreditPurchaseOpen] = React.useState(false);
  const [isHelperBox, setIsHelperBox] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    switch (location.pathname) {
      case "/dashboard":
        setSelected("Dashboard");
        break;
      case "/calculator":
        setSelected("Statistiche");
        break;
      case '/fantacalcio':
        setSelected("Fantacalcio");
        break;
      case '/tipster':
        setSelected("Tipster");
        break;
      case "/profilo":
        setSelected("Profilo");
        break;
      case "/informazioni":
        setSelected("Informazioni");
        break;
      default:
        break;
    }
  }, [location]);

  const injectMobileStyle = () => {
    return mobile ? mobileStyle : null;
  };

  const onLinkClick = (to, newSelected) => {
    navigate(to);
    setSelected(newSelected);
    if (mobile) action();
  };

  const Drawer = ({ children }) => {
    return (
      <div
        style={{
          display: mobile && !open ? "none" : "flex",
          minHeight: "100vh",
          maxHeight: "100vh",
          flexDirection: "column",
          maxWidth: open ? "350px" : "100px",
          width: open ? "25%" : "100px",
          minWidth: open ? "300px" : "100px",
          background: palette.grey.grey_500,
          padding: styleConstant.padding.x2,
          // visibility: mobile && !open && "hidden",
          ...injectMobileStyle(),
        }}
      >
        {children}
      </div>
    );
  };

  const DrawerHeader = ({ children }) => {
    return (
      <div
        style={{
          display: "flex",
          minHeight: "120px",
          background: palette.grey.grey_500,
          flexDirection: "column",
          gap: styleConstant.gap.medium,
        }}
      >
        {children}
      </div>
    );
  };

  const DrawerBody = ({ children }) => {
    return (
      <div
        style={{
          display: "flex",
          background: palette.grey.grey_500,
          flexDirection: "column",
          flex: 1,
          position: "relative",
          gap: styleConstant.gap.xxl,
          overflow: "auto",
          marginTop: styleConstant.margin.x6,
        }}
      >
        {children}
      </div>
    );
  };

  const DrawerFooter = ({ children }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: styleConstant.gap.medium,
          background: palette.grey.grey_500,
          // marginBottom: styleConstant.margin.x2,
        }}
      >
        {children}
        <Typography
          style={{
            ...textSecondaryStyle,
            fontSize: "10px",
            fontWeight: 700,
          }}
        >
          v{process.env.REACT_APP_VERSION} {open ? " | " : <br />}Aggiornamento DB {dateFormatting(tsCreatedData)}
        </Typography>
      </div>
    );
  };

  const DrawerButton = () => {
    return (
      <ButtonBase onClick={action}>
        <CustomIcon icon={icons.BARS} color={palette.white.main} />
      </ButtonBase>
    );
  };
  const getDraweButtonContainerStyle = () => ({
    display: "flex",
    justifyContent: open ? "flex-end" : "center",
  });

  const renderObscuredPanel = () => {
    return mobile && open ? (
      <div
        style={{
          position: "absolute",
          width: "100vw",
          height: "100vh",
          zIndex: "50",
          background: "black",
          opacity: "0.7",
        }}
        onClick={action}
      ></div>
    ) : null;
  };

  return (
    <>
      {mobile && (
        <Dialog fullScreen open={creditPurchaseOpen} onClose={() => setCreditPurchaseOpen(false)} TransitionComponent={Transition}>
          <div style={{ display: "flex", height: "100vh" }}>
            <CreditPurchase
              mobile
              title={"Acquisto crediti"}
              subtitle={"Seleziona il piano e procedi con l'acquisto"}
              handleClose={() => setCreditPurchaseOpen(false)}
            />
          </div>
        </Dialog>
      )}
      {!mobile && (
        <CreditPurchase
          open={creditPurchaseOpen}
          handleClose={() => setCreditPurchaseOpen(false)}
          title="Acquisto crediti"
          subtitle="Seleziona il piano e procedi con l'acquisto"
        />
      )}
      {renderObscuredPanel()}
      <Drawer>
        <DrawerHeader>
          <div style={getDraweButtonContainerStyle()}>
            <DrawerButton />
          </div>
          <img
            src={open ? drawer_logo : closed_logo}
            style={{
              maxWidth: "150px",
              MaxHeight: "150px",
              alignSelf: "center",
            }}
          />
        </DrawerHeader>
        <DrawerBody>
          <InputCrediti open={open} label={"Crediti"} credits={coinsFormat(credits)} action={() => setCreditPurchaseOpen(true)} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LinkToPageItem
              icon={<CustomIcon icon={icons.HOME} />}
              label={"Dashboard"}
              open={open}
              action={() => onLinkClick("/dashboard", "Dashboard")}
              selected={selected === "Dashboard"}
            />
            <LinkToPageItem
              icon={<CustomIcon icon={icons.PLAY} />}
              label={"Statistiche"}
              open={open}
              action={() => onLinkClick("/calculator", "Statistiche")}
              selected={selected === "Statistiche"}
            />
            <LinkToPageItem
              icon={<CustomIcon icon={icons.FOOTBALL} />}
              label={"Fantacalcio"}
              open={open}
              action={() => onLinkClick("/fantacalcio", "Fantacalcio")}
              selected={selected === "Fantacalcio"}
            />
            <LinkToPageItem
              icon={<CustomIcon icon={icons.RECTANGLE_LIST} />}
              label={"Tipster"}
              open={open}
              action={() => onLinkClick("/tipster", "Tipster")}
              selected={selected === "Tipster"}
            />
            <LinkToPageItem
              icon={<CustomIcon icon={icons.USER} />}
              label={"Profilo"}
              open={open}
              action={() => onLinkClick("/profilo", "Profilo")}
              selected={selected === "Profilo"}
            />
            <LinkToPageItem
              icon={<CustomIcon icon={icons.INFORMATION} />}
              label={"Informazioni"}
              open={open}
              action={() => onLinkClick("/informazioni", "Informazioni")}
              selected={selected === "Informazioni"}
            />
          </div>
        </DrawerBody>
        <DrawerFooter>
          {open && isHelperBox && (
            <DrawerHelpBox
              title={"Hai bisogno di aiuto?"}
              text={"Segui il nostro tutorial che ti guiderà passo passo nell'utilizzo del nostro strumento di calcolo."}
              showAgainAction={() => setIsHelperBox(false)}
            />
          )}
          <DrawerProfileItem profileImage={profilePhoto} name={nome + " " + cognome} email={email} open={open} onIconClick={logOut} />
        </DrawerFooter>
      </Drawer>
    </>
  );
};

const mapStateToProps = ({ Profile, Calculation }) => ({
  credits: Profile.userProfile.appData.credit,
  profilePhoto: Profile.userProfile.photoURL,
  email: Profile.userProfile.email,
  nome: Profile.userProfile.name,
  cognome: Profile.userProfile.surname,
  tsCreatedData: Calculation.tsCreatedData,
});

export default connect(mapStateToProps, null)(CustomDrawer);
