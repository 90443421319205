/* TOAST */
export const SUCCESS_TOAST = "success";
export const ERROR_TOAST = "error";
export const INFO_TOAST = "info";

/**
 * Utils for show a toast from `react-native-toast-message`
 * @param text1 main text,
 * @param text2 secondary text. Default = 'null'
 * @param position position of toast. Default = `bottom`
 * @param type type of toast. Default = `SUCCESS_TOAST`. other: ERROR_TOAST | INFO_TOAST
 * @param onHide function to call onHide toast.
 */
export function showToast(text1, text2 = null, position = "bottom", type = SUCCESS_TOAST, onHide) {
  console.log("TODO: Implementare il Toast");
  /*   return Toast.show({
    text1,
    text2,
    position,
    type,
    onHide,
    visibilityTime: 4000,
    autoHide: true,
    topOffset: 30,
    bottomOffset: 40,
    onShow: () => { },
    onPress: () => { }
  }); */
}

/**
 * NOTA: se la utility diventa complessa usare: momentJS
 */
export function dateFormatting(date, format = null, separator = null) {
  if (typeof date == 'number') {
    date = new Date(date);
  }
  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  let endDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

  if (separator && separator !== "/" && separator !== "-") {
    throw "separator not recognized ";
  }

  if (!format) {
    return endDate + "/" + month + "/" + date.getFullYear();
  }

  switch (format) {
    case "YYYMMDD":
      return date.getFullYear() + separator + month + separator + endDate;
    default:
      throw "format not recognized sss" + date + format;
  }
}

/* Format price and coins */
export function currencyFormat(num, noDecimal = false) {
  const numFixed = num?.toFixed(2);
  if (noDecimal) {
    return num
      ?.toString()
      .replace(".", ",")
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }
  return numFixed?.replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") + " €";
}

export function getPathWithParams(endPoint) {
  var args = Array.prototype.slice.call(arguments, 1);
  var count = -1;
  return endPoint.replace(/:[a-zA-Z?]+/g, (match) => {
    count += 1;
    return args[count] !== undefined ? args[count] : match;
  });
}

export function convertArrayToObject(input) {
  if (Array.isArray(input)) {
    var obj = {};
    for (let i = 0; i < input.length; i++) {
      if (input[i]) {
        obj[i] = input[i];
      }
    }
    return obj;
  } else {
    //TODO: migliorare: ritornare l'input solo se è un oggetto
    return input;
  }
}

export function removeDuplicateFromArray(array) {
  return [...new Set(array)];
}

export function brkRef(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function getSplittedDisplayName(displayName, CUSTOM_SPLITTER) {
  if (displayName.includes(CUSTOM_SPLITTER)) {
    return displayName.split(CUSTOM_SPLITTER);
  }
  return displayName.split(" ");
}

export const generateSelectListValues = (unformatted_obj, key_label, key_value, key_additional = null) => {
  if (!unformatted_obj) return [{ label: "", value: "", additional: undefined }];
  if (!key_label && !key_value) throw "KEY_LABEL & KEY_VALUE not defined";
  let formatted_obj = Object.values(unformatted_obj).map((item) => {
    return getItemList(item[key_label], item[key_value], item[key_additional]);
  });
  return formatted_obj;
};

// export const formatDays = (unformattedDays, idTool, idSeason) => {
//   let formattedDays = Object.values(unformattedDays).map((giornata) => {
//     console.log(!giornata.squadre[idTool][6]);
//     if ((idSeason == 6 && idTool == 2) || !giornata.squadre[idTool][idSeason])
//       // dato problema della struttura dati vengono fissate squadre diponibile come empty array
//       return { id: giornata.id[0], numero: giornata.nome[0], squadre_disponibili: [3157, 3158] };
//     const availableTeams = giornata.squadre[idTool][idSeason].squadre_disponibili;
//     //Workaround temporaneo per rimediare a struttura dati non corretta
//     return { id: giornata.id[0], numero: giornata.nome[0], squadre_disponibili: availableTeams };
//   });
//   return formattedDays;
// };

export const formatDays = (unformattedDays, idTool, idSeason) => {
  const formattedDays = [];
  for (let giornata of Object.values(unformattedDays)) {
    if (!giornata.squadre[idTool]) continue;
    if (!giornata.squadre[idTool][idSeason]) continue;
    const availableTeams = giornata.squadre[idTool][idSeason].squadre_disponibili;
    formattedDays.push({ id: giornata.id[0], numero: giornata.nome[0], squadre_disponibili: availableTeams });
  }
  return formattedDays;
};

export const generateSelectAvailableListFromArray = (unformatted_list) => {
  unformatted_list.map();
};

export const getStatisticsFromPlayer = (playerObj, days) => {
  if (!playerObj) return null;
  let res = days.map((day) => {
    let availableStatistics = playerObj[day.value];
    return availableStatistics;
  });
  return res[0];
};

const getItemList = (label, value, additional) => {
  return { label, value, additional };
};

export const coinsFormat = (coins) => {
  return coins.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};
