import { Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { icons } from "../../../../assets/styles/constant/general";
import { calculatorDesktopHeader, styleConstant } from "../../../../assets/styles/constant/styleConstants";
import { loadTotalPrice, resetCalculator, setSelectedCalculus } from "../../../../store/actions";
import TextButton from "../../../components/buttons/TextButton";
import CustomIcon from "../../../components/CustomIcon";
import CalculatorSettings from "./CalculatorSettings";

const CalculatorDesktopHeader = ({ title, subtitle, action, onSettingsSet, resetCalculus }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: styleConstant.gap.large,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="title" style={calculatorDesktopHeader.title}>
          {title}
        </Typography>
        <Typography variant="smallTitle" style={calculatorDesktopHeader.subtitle}>
          {subtitle}
        </Typography>
      </div>
      <CalculatorSettings />
      <div style={{ display: "flex", marginLeft: "auto" }}>
        <TextButton
          text={"Reset calcolatore"}
          icon={<CustomIcon icon={icons.ARROW_ROTATE_LEFT} />}
          action={() => resetCalculus()}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  resetCalculus: () => dispatch(resetCalculator()),
});

export default connect(null, mapDispatchToProps)(CalculatorDesktopHeader);
