import { FILTER_NONE } from "../../service/constant/filters";
import { GROUP_BY_NONE } from "../../service/constant/groupBy";
import {
  CREATE_CALCULATION_OBJECT,
  CREATE_CALCULATION_OBJECT_ERROR,
  CREATE_CALCULATION_OBJECT_SUCCESS,
  DELETE_CALCULATION_OBJECT,
  DELETE_CALCULATION_OBJECT_ERROR,
  DELETE_CALCULATION_OBJECT_SUCCESS,
  DUPLICATE_CALCULUS,
  DUPLICATE_CALCULUS_ERROR,
  DUPLICATE_CALCULUS_SUCCESS,
  EMPTY_CALCULATION_LIST,
  RESET_CALCULATOR,
  SET_EMPTY_SCREEN,
  SET_FILTER,
  SET_GROUP_BY,
  SET_RESULT,
  SET_RESULT_ERROR,
  SET_RESULT_SUCCESS,
  SET_SELECTED_CALCULUS,
  SET_SELECTED_SEASON,
  SET_SELECTED_SETTINGS,
  SET_SELECTED_SETTINGS_ERROR,
  SET_SELECTED_SETTINGS_SUCCESS,
  SET_TOTAL_PRICE,
  UPDATE_CALCULATION_OBJECT,
  UPDATE_CALCULATION_OBJECT_ERROR,
  UPDATE_CALCULATION_OBJECT_SCENARIOS,
  UPDATE_CALCULATION_OBJECT_SCENARIOS_ERROR,
  UPDATE_CALCULATION_OBJECT_SCENARIOS_SUCCESS,
  UPDATE_CALCULATION_OBJECT_SUCCESS,
} from "./actionType";

const initialState = {
  selectedCalculus: null,
  calculationList: {},
  settings: {
    tool: 1,
    subject: 1,
  },
  filter: "none",
  groupBy: "none",
  showEmptyScreen: false,
  totalPrice: 0,
  loading: false,
  error: null,
};

const CalcuationWork = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CALCULATION_OBJECT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case CREATE_CALCULATION_OBJECT_SUCCESS:
      state = {
        ...state,
        calculationList: action.payload.calculationList,
        error: null,
        loading: false,
      };
      break;
    case CREATE_CALCULATION_OBJECT_ERROR:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case SET_SELECTED_CALCULUS:
      state = {
        ...state,
        selectedCalculus: action.payload.calculationObject,
      };
      break;
    case UPDATE_CALCULATION_OBJECT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_CALCULATION_OBJECT_SUCCESS:
      state = {
        ...state,
        calculationList: action.payload.calculationList,
        error: null,
        loading: false,
      };
      break;
    case UPDATE_CALCULATION_OBJECT_ERROR:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case DELETE_CALCULATION_OBJECT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DELETE_CALCULATION_OBJECT_SUCCESS:
      state = {
        ...state,
        error: null,
        calculationList: action.payload.calculationList,
        loading: false,
      };
      break;
    case DELETE_CALCULATION_OBJECT_ERROR:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case SET_SELECTED_SETTINGS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_SELECTED_SETTINGS_SUCCESS:
      state = {
        ...state,
        settings: action.payload.settings,
        loading: false,
        error: null,
      };
      break;
    case SET_SELECTED_SETTINGS_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case EMPTY_CALCULATION_LIST:
      state = { ...state, calculationList: {} };
      break;
    case DUPLICATE_CALCULUS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case DUPLICATE_CALCULUS_SUCCESS:
      state = {
        ...state,
        calculationList: action.payload.newCalculationList,
        loading: false,
      };
      break;
    case DUPLICATE_CALCULUS_ERROR:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case RESET_CALCULATOR:
      state = {
        ...state,
        totalPrice: 0,
        calculationList: {},
        selectedCalculus: null,
        filter: FILTER_NONE,
        groupBy: GROUP_BY_NONE,
      };
      break;
    case UPDATE_CALCULATION_OBJECT_SCENARIOS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case UPDATE_CALCULATION_OBJECT_SCENARIOS_SUCCESS:
      state = {
        ...state,
        calculationList: action.payload.calculationList,
        error: null,
        loading: false,
      };
      break;
    case UPDATE_CALCULATION_OBJECT_SCENARIOS_ERROR:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case SET_TOTAL_PRICE:
      state = {
        ...state,
        totalPrice: action.payload.totalPrice,
      };
      break;
    case SET_RESULT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_RESULT_SUCCESS:
      state = {
        ...state,
        loading: false,
        calculationList: action.payload.calcList,
        error: null,
      };
      break;
    case SET_RESULT_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case SET_FILTER:
      state = {
        ...state,
        filter: action.payload.filter,
      };
      break;
    case SET_GROUP_BY:
      state = {
        ...state,
        groupBy: action.payload.groupBy,
      };
      break;
    case SET_EMPTY_SCREEN:
      state = {
        ...state,
        showEmptyScreen: action.payload.value,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CalcuationWork;
