import { createTheme } from "@mui/material";
import { palette } from "./constant/palette";
import { styleConstant } from "./constant/styleConstants";

export const buttonStyle = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        style: {
          minWidth: "max-content",
        },
      },
      variants: [
        {
          props: { variant: "containedButton", className: "fullHeihgt" },
          style: {
            minHeight: "48px",
          },
        },
        {
          props: { variant: "containedButton" },
          style: {
            background: palette.primary.main,
            borderRadius: styleConstant.borderRadius.x1,
            "&:hover": {
              backgroundColor: palette.primary.hover,
            },
          },
        },
        {
          props: { variant: "containedButton", disabled: true },
          style: {
            background: palette.primary.main,
            borderRadius: styleConstant.borderRadius.x1,
            cursor: "context-menu",
            opacity: 0.4,
            animation: "none !important",
          },
        },
        {
          props: { variant: "textButton" },
          style: {
            borderRadius: styleConstant.borderRadius.x1,
            textTransform: "none",
            padding: styleConstant.padding.x1,
            // "&:hover": {
            //   animation: "fadeColor 0.5s ease-out",
            //   animationFillMode: "forwards",
            // },
            // "@keyframes fadeColor": {
            //   "0%": {
            //     background: "transparent",
            //   },
            //   "100%": {
            //     background: palette.grey.grey_300,
            //   },
            // },
          },
        },
        {
          props: { variant: "outlined-empty" },
          style: {
            background: "transparent",
            borderRadius: styleConstant.borderRadius.x1,
            border: `2px solid ${palette.primary.main}`,
            color: palette.primary.main,
            transition: "transform .2s",
            "&:hover": {
              background: "rgba(254, 212, 43, 0.25)",
            },
          },
        },
        {
          props: { variant: "outlined-empty", className: "disabled" },
          style: {
            background: "transparent",
            borderRadius: styleConstant.borderRadius.x1,
            cursor: "context-menu",
            opacity: 0.4,
            animation: "none !important",
          },
        },
      ],
    },
    MuiIconButton: {
      defaultProps: {
        style: {
          padding: styleConstant.padding.x1,
        },
      },
      variants: [
        {
          props: { variant: "icon-button", size: "small" },
          style: {
            padding: styleConstant.padding.x0,
            color: palette.primary.main,
          },
        },
        {
          props: { variant: "icon-button", size: "medium" },
          style: {
            padding: styleConstant.padding.x1,
            color: palette.primary.main,
            // height: "100%",
            height: "36px",
            aspectRatio: "1 / 1",
            // minHeight: "32px",
            "&:hover": {
              backgroundColor: palette.grey.grey_200
            }
          },
        },
        {
          props: { variant: "icon-button", size: "large" },
          style: {
            padding: styleConstant.padding.x2,
            color: palette.primary.main,
          },
        },
      ],
    },
    MuiButtonBase: {
      styleOverrides: {
        root: { fontFamily: "Roboto" },
      },
    },
  },
});
