import { Button, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../../assets/styles/constant/palette";
import { styleConstant } from "../../../assets/styles/constant/styleConstants";

const SocialButton = ({
  text = "Text",
  icon = null,
  adaptToParentContainer = false,
  disabled = false,
  onClick,
  large = false,
  id = null,
  style = null,
}) => {
  return (
    <Button
      variant="outlined-empty"
      className={disabled ? "disabled" : null}
      fullWidth={adaptToParentContainer}
      onClick={onClick}
      disableTouchRipple={disabled}
      disabled={disabled}
      id={id}
      style={style}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: `${styleConstant.gap.small}`,
          padding: `${large ? styleConstant.gap.small : "0px"}`,
        }}
      >
        {React.cloneElement(icon, { color: palette.primary.main })}
        <Typography variant="smallTitle" color={palette.primary.main} noWrap>
          {text}
        </Typography>
      </div>
    </Button>
  );
};

export default SocialButton;
