import {
  LOGIN_USER,
  LOGIN_USER_GOOGLE,
  LOGIN_USER_FACEBOOK,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  EMAIL_NOT_VERIFIED,
  RESEND_EMAIL_VERIFICATION,
  LOGIN_USER_APPLE,
} from "./actionTypes";

const initialState = {
  uid: null,
  loadingLogin: false,
  errorLogin: null,
  error: "",
  errorType: "",
  loading: false,
  emailNotVerified: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
    case LOGIN_USER_GOOGLE:
    case LOGIN_USER_FACEBOOK:
    case LOGIN_USER_APPLE:
      state = {
        ...state,
        loadingLogin: true,
        errorLogin: null,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loadingLogin: false,
        uid: action.payload.uid,
        emailNotVerified: true,
        error: "",
        errorType: null,
      };
      break;
    case LOGIN_ERROR:
      state = {
        ...state,
        loadingLogin: false,
        errorLogin: action.payload.error,
        errorType: action.payload.errorType,
      };
      break;
    case EMAIL_NOT_VERIFIED:
      state = {
        ...state,
        loading: false,
        emailNotVerified: action.payload,
      };
      break;
    case RESEND_EMAIL_VERIFICATION:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = {
        ...state,
        uid: null,
      };
      break;
    case LOGOUT_USER_SUCCESS:
      state = {
        ...state,
        emailNotVerified: false,
        uid: null,
        error: "",
        errorType: null,
      };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false, uid: null };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
