import { convertArrayToObject, getPathWithParams } from "../helpers/util";
import { getFirebaseBackend } from "./common/firebase";
import {
  CALCULATION_SUBJECTS,
  CALCULATION_METHOD,
  CALCULATION_DAYS,
  CALCULATION_STATISTICS,
  CALCULATION_GREATER_THAN,
  CALCULATION_DATA_TS_CREATED,
  CALCULATION_TEAM,
  CALCULATION_PLAYERS,
  CALCULATION_STATISTIC_SCENARIOS,
  CALCULATION_SCENARIOS,
  CALCULATION_PLAYERS_V2,
  CALCULATION_TEAM_V2,
  CALCULATION_STATISTICS_V2,
  CALCULATION_AVAILABLE_DAYS_v2,
  CALCULATION_DAY_V2,
  CALCULATION_SEASONS_V2,
  CALCULATION_TOOLS_V2,
  CALCULATION_SUBJECTS_V2,
  CALCULATION_AVAILABLE_STATISTICS,
  CALCULATION_AVAILABLE_SEASONS,
  CALCULATION_STATISTIC_V2,
  CALCULATION_SEASON_V2,
  CALCULATION_SCENARIOS_V2,
  CALCULATION_STATISTIC_SCENARIOS_V2,
  CALCULATION_OPERATION,
  CALCULATION_AVAILABLE_OPERATIONS,
  CALCULATION_OPERATIONS,
  CALCULATION_PLANS,
  USER_CREDITS,
  CALCULATION_GREATER_THAN_V2,
  CALCULATION_DEFAULT_SELECTED_DAYS,
  CALCULATION_GUIDES,
  CALCULATION_METHOD_V2,
} from "./constant/refDb";

const fireBaseBackend = getFirebaseBackend();

//Adattata a nuova struttura
export function getSubjects() {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_SUBJECTS_V2))
    .then((snapshot) => {
      return convertArrayToObject(snapshot.val());
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export function getMethods() {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_METHOD_V2))
    .then((snapshot) => {
      let methods = convertArrayToObject(snapshot.val());
      for (let idObject in methods) {
        methods[idObject] = convertArrayToObject(methods[idObject]);
      }
      return methods;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export const getUserCredits = (uid) => {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(USER_CREDITS, uid))
    .then((snapshot) => {
      return snapshot.val();
    })
    .catch((error) => {
      throw error;
    });
};

export const getGuides = () => {
  return fireBaseBackend
    .dbOnceRead(CALCULATION_GUIDES)
    .then((snapshot) => {
      return snapshot.val();
    })
    .catch((error) => {
      throw error;
    });
};

// export function getDays() {
//   return fireBaseBackend
//     .dbOnceRead(getPathWithParams(CALCULATION_DAYS))
//     .then((snapshot) => {
//       let days = snapshot.val();
//       console.log(days);
//       return days;
//     })
//     .catch((error) => {
//       throw _handleError(error);
//     });
// }

export const getAvailableOperations = (idSubject) => {
  return fireBaseBackend.dbOnceRead(getPathWithParams(CALCULATION_AVAILABLE_OPERATIONS, idSubject)).then((snapshot) => {
    const operations = snapshot.val();
    return operations;
  });
};

export const getUnformattedOperations = (idsOperations) => {
  let promiseArray = idsOperations.map((id) => {
    return fireBaseBackend
      .dbOnceRead(getPathWithParams(CALCULATION_OPERATIONS, id))
      .then((snapshot) => {
        console.log(snapshot.val());
        return snapshot.val();
      })
      .catch((err) => {
        return err;
      });
  });
  return Promise.all(promiseArray).catch((error) => {
    throw _handleError(error);
  });
};

export const getOperations = async (idSubject) => {
  const idsOperations = await getAvailableOperations(idSubject);
  return getUnformattedOperations(idsOperations);
};

export function getAvailableSeasonsIds(idTool) {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_AVAILABLE_SEASONS, idTool))
    .then((snapshot) => {
      return snapshot.val();
    })
    .catch((err) => {
      throw _handleError(err);
    });
}

//Adattata a nuova struttura
export async function getDays(idTool, idSeason) {
  const idGiornateDisponibili = await getAvailableDaysV2(idTool, idSeason);
  const selectedIds = await getDefaultSelectedDays(idTool, idSeason);
  return getUnformattedDaysV2(idGiornateDisponibili, selectedIds).then((res) => convertArrayToObject(res));
}

export async function getDaysWithSelected(idTool, idSeason) {
  const idGiornateDisponibili = await getAvailableDaysV2(idTool, idSeason);
  const selectedIds = await getDefaultSelectedDays(idTool, idSeason);
  return getUnformattedDaysWithSelected(idGiornateDisponibili, selectedIds);
}

function getDefaultSelectedDays(idTool, idSeason) {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_DEFAULT_SELECTED_DAYS, idTool, idSeason))
    .then((snapshot) => {
      let days = snapshot.val();
      return days.giornate;
    })
    .catch((err) => {
      throw _handleError(err);
    });
}

//Ritorna lista di id delle giornate presenti
function getAvailableDaysV2(idTool, idSeason) {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_AVAILABLE_DAYS_v2, idTool, idSeason))
    .then((snapshot) => {
      let days = snapshot.val();
      return days.giornate;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

function getUnformattedDaysWithSelected(idGiornateDisponibili, idsSelected) {
  let promiseArray = idGiornateDisponibili.map((idDay) => {
    return fireBaseBackend
      .dbOnceRead(getPathWithParams(CALCULATION_DAY_V2, idDay))
      .then((snapshot) => {
        const day = snapshot.val();
        const isSelected = idsSelected.includes(day.id[0]);
        const dayObj = { ...day, defaultSelected: isSelected };
        return dayObj;
      })
      .catch((err) => {
        return err;
      });
  });
  return Promise.all(promiseArray).catch((error) => {
    throw _handleError(error);
  });
}

function getUnformattedDaysV2(idGiornateDisponibili, idsSelected) {
  let promiseArray = idGiornateDisponibili.map((idDay) => {
    return fireBaseBackend
      .dbOnceRead(getPathWithParams(CALCULATION_DAY_V2, idDay))
      .then((snapshot) => {
        const day = snapshot.val();
        const isSelected = idsSelected.includes(day.id[0]);
        const dayObj = { ...day, defaultSelected: isSelected };
        return dayObj;
      })
      .catch((err) => {
        return err;
      });
  });
  return Promise.all(promiseArray).catch((error) => {
    throw _handleError(error);
  });
}

// Sostituita con getStatisticsV2
// export function getStatistics() {
//   return fireBaseBackend
//     .dbOnceRead(getPathWithParams(CALCULATION_STATISTICS_V2))
//     .then((snapshot) => {
//       let statistics = snapshot.val();
//       statistics.lista_statistiche = convertArrayToObject(statistics);
//       return statistics;
//     })
//     .catch((error) => {
//       throw _handleError(error);
//     });
// }

export const getStatisticsFromIds = (statisticsIds) => {
  let promiseArray = statisticsIds.map((idStatistic) => {
    return fireBaseBackend
      .dbOnceRead(getPathWithParams(CALCULATION_STATISTIC_V2, idStatistic))
      .then((snapshot) => {
        return snapshot.val();
      })
      .catch((err) => {
        return err;
      });
  });
  return Promise.all(promiseArray).catch((error) => {
    throw _handleError(error);
  });
};

//Adattata a nuova struttura
export async function getStatistics(idTool, idSubject) {
  const idSatitisticheDisponibili = await getAvailableStatisticsIds(idTool, idSubject);
  console.log(idSatitisticheDisponibili);
  let promiseArray = idSatitisticheDisponibili.map((idStatistic) => {
    return fireBaseBackend
      .dbOnceRead(getPathWithParams(CALCULATION_STATISTIC_V2, idStatistic))
      .then((snapshot) => {
        return snapshot.val();
      })
      .catch((err) => {
        return err;
      });
  });
  return Promise.all(promiseArray).catch((error) => {
    throw _handleError(error);
  });
}

export function getAvailableStatisticsIds(idTool, idSubject) {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_AVAILABLE_STATISTICS, idTool, idSubject))
    .then((snapshot) => {
      return snapshot.val();
    })
    .catch((err) => {
      throw _handleError(err);
    });
}

// export function getSeasons(idStagioniDisponibili) {
//   return fireBaseBackend
//     .dbOnceRead(getPathWithParams(CALCULATION_SEASONS_V2))
//     .then((snapshot) => {
//       let seasons = snapshot.val();
//       console.log(convertArrayToObject(seasons));
//       return convertArrayToObject(seasons);
//     })
//     .catch((error) => {
//       throw _handleError(error);
//     });
// }

export async function getSeasons(idTool) {
  const idStagioniDisponibili = await getAvailableSeasonsIds(idTool);
  let promiseArray = idStagioniDisponibili.map((idSeason) => {
    return fireBaseBackend
      .dbOnceRead(getPathWithParams(CALCULATION_SEASON_V2, idSeason))
      .then((snapshot) => {
        return snapshot.val();
      })
      .catch((err) => {
        return err;
      });
  });
  return Promise.all(promiseArray)
    .then((res) => {
      return convertArrayToObject(res);
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export const getDefaultSeasonId = () => {
  return fireBaseBackend
    .dbOnceRead(CALCULATION_SEASONS_V2)
    .then((snapshot) => {
      const seasons = snapshot.val();
      const last_season = seasons[seasons.length - 1];
      return last_season.id;
    })
    .catch((err) => {
      throw _handleError(err);
    });
};

export function getTools() {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_TOOLS_V2))
    .then((snapshot) => {
      let tools = snapshot.val();
      console.log(convertArrayToObject(tools));
      return convertArrayToObject(tools);
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export function getPlans() {
  return fireBaseBackend
    .dbOnceRead(CALCULATION_PLANS)
    .then((snapshot) => {
      return snapshot.val();
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export function getGreaterThanDescription() {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_GREATER_THAN, "descrizione"))
    .then((snapshot) => {
      let description = snapshot.val();
      return description;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export function getTsCreatedData() {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_DATA_TS_CREATED))
    .then((snapshot) => {
      const tsCreatedData = snapshot.val();
      return tsCreatedData;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

//Adattata a nuova struttura
export function getTeams(ids, idSeason) {
  let promiseArray = ids.map((id) =>
    fireBaseBackend
      .dbOnceRead(getPathWithParams(CALCULATION_TEAM_V2, id))
      .then((snapshot) => {
        return snapshot.val();
      })
      .catch((error) => {
        return error;
      })
  );
  return Promise.all(promiseArray).catch((error) => {
    throw _handleError(error);
  });
}

export function getPlayers(idTool, idSeason, idTeam) {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_PLAYERS_V2, idTool, idSeason, idTeam))
    .then((snapshot) => {
      let players = snapshot.val();
      // console.log(players);
      for (let idPlayer in players) {
        players[idPlayer].statistiche = convertArrayToObject(players[idPlayer].statistiche);
        for (let idScenario in players[idPlayer].statistiche) {
          players[idPlayer].statistiche[idScenario] = convertArrayToObject(players[idPlayer].statistiche[idScenario]);
        }
      }
      return players;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export function getPlayersV2(idTool, idSeason, idTeam, idDays, idStatistic) {
  return fireBaseBackend.dbOnceRead(getPathWithParams(CALCULATION_PLAYERS_V2, idTool, idSeason, idTeam)).then((snapshot) => {
    let players = snapshot.val();
    let actualPlayers = {};
    for (let idPlayer in players) {
      let flag = 0;
      players[idPlayer].statistiche = convertArrayToObject(players[idPlayer].statistiche);
      if (idTool == 1) {
        for (let i = 0; i < idDays.length; i++) {
          // se il giocatore è presente almeno in una giornata selezionata e presenta la statistica selezionata
          if (players[idPlayer].statistiche[idDays[i]][idStatistic]) flag++;
          break;
        }
        if (flag > 0) actualPlayers[idPlayer] = players[idPlayer];
      } else {
        const availableStatisticsIds = Object.values(players[idPlayer].statistiche);
        if (availableStatisticsIds.includes(idStatistic)) actualPlayers[idPlayer] = players[idPlayer];
      }
    }
    return actualPlayers;
  });
}

//Adattata a v2
export function getGreaterThan(idStatistic) {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_GREATER_THAN_V2, idStatistic))
    .then((snapshot) => {
      let greaterThan = snapshot.val();
      for (let idx in greaterThan) {
        if (!greaterThan[idx]) {
          delete greaterThan[idx];
          continue;
        }
        greaterThan[idx].max = convertArrayToObject(greaterThan[idx].max);
      }
      return greaterThan;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

//Adattata a V2
export function getStatisticScenarios() {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_STATISTIC_SCENARIOS_V2))
    .then((snapshot) => {
      const res = convertArrayToObject(snapshot.val());
      return res;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

//Adattata a V2
export function getScenarios() {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(CALCULATION_SCENARIOS_V2))
    .then((snapshot) => {
      const res = convertArrayToObject(snapshot.val());
      return res;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

function _handleError(error) {
  var errorObj = {};
  if (typeof error === "object" && error !== null) {
    errorObj = {
      code: error?.code,
      message: error?.message,
    };
  } else {
    errorObj = {
      code: error,
      message: error,
    };
  }

  console.log("errorObj", errorObj);
  return errorObj;
}
