import {
  LOAD_BASIC_CALC_DATA,
  LOAD_BASIC_CALC_DATA_ERROR,
  LOAD_BASIC_CALC_DATA_SUCCESS,
  LOAD_DAYS,
  LOAD_DAYS_ERROR,
  LOAD_DAYS_ERROR_V2,
  LOAD_DAYS_SUCCESS,
  LOAD_DAYS_SUCCESS_V2,
  LOAD_DAYS_V2,
  LOAD_GREATER_THAN,
  LOAD_GREATER_THAN_ERROR,
  LOAD_GREATER_THAN_SUCCESS,
  LOAD_OPERATION,
  LOAD_OPERATIONS,
  LOAD_PLAYERS,
  LOAD_PLAYERS_ERROR,
  LOAD_PLAYERS_SUCCESS,
  LOAD_SEASONS,
  LOAD_STATISTICS,
  LOAD_STATISTICS_ERROR,
  LOAD_STATISTICS_SUCCESS,
  LOAD_TEAMS,
  LOAD_TEAMS_ERROR,
  LOAD_TEAMS_SUCCESS,
} from "./actionTypes";

const initialState = {
  subjects: null,
  methods: null,
  statistics: null,
  days: null,
  greaterThanDescription: null,
  tsCreatedData: null,
  greaterThan: null,
  teams: null,
  players: null,
  scenarios: null,
  statisticScenarios: null,
  seasons: null,
  tools: null,
  operations: null,
  guides: null,
  plans: null,

  selectedData: null, //TODO: DEFINIRE LA STRUTTURA DEI DATI SELEZIONABILI

  error: null,
  loading: false,
  loadingGraterThan: false,
  errorGreaterThan: null,
  loadingTeams: false,
  errorTeams: null,
  loadingPlayers: false,
  errorPlayers: null,
  loadingScenarios: false,
  errorScenarios: null,
  errorScenariStatistica: null,
  loadgingScenariStatistica: false,
};

const Calculation = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_BASIC_CALC_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOAD_BASIC_CALC_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        subjects: action.payload.subjects,
        methods: action.payload.methods,
        statistics: action.payload.statistics,
        days: action.payload.days,
        greaterThanDescription: action.payload.greaterThanDescription,
        tsCreatedData: action.payload.tsCreatedData,
        scenarios: action.payload.scenarios,
        statisticScenarios: action.payload.statisticScenarios,
        seasons: action.payload.seasons,
        tools: action.payload.tools,
        operations: action.payload.operations,
        guides: action.payload.guides,
        plans: action.payload.plans,
        error: null,
      };
      break;
    case LOAD_BASIC_CALC_DATA_ERROR:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;
    case LOAD_TEAMS:
      state = {
        ...state,
        loadingTeams: true,
      };
      break;
    case LOAD_TEAMS_SUCCESS:
      state = {
        ...state,
        teams: action.payload.teams,
        errorTeams: null,
        loadingTeams: false,
      };
      break;
    case LOAD_TEAMS_ERROR:
      state = {
        ...state,
        errorTeams: action.payload.error,
        loadingTeams: false,
      };
      break;
    case LOAD_PLAYERS:
      state = {
        ...state,
        loadingPlayers: true,
      };
      break;
    case LOAD_PLAYERS_SUCCESS:
      state = {
        ...state,
        players: action.payload.players,
        errorPlayers: null,
        loadingPlayers: false,
      };
      break;
    case LOAD_PLAYERS_ERROR:
      state = {
        ...state,
        errorPlayers: action.payload.error,
        loadingPlayers: false,
      };
      break;
    case LOAD_GREATER_THAN:
      state = {
        ...state,
        loadingGraterThan: true,
      };
      break;
    case LOAD_GREATER_THAN_SUCCESS:
      state = {
        ...state,
        greaterThan: action.payload.greaterThan,
        errorGreaterThan: null,
        loadingGraterThan: false,
      };
      break;
    case LOAD_GREATER_THAN_ERROR:
      state = {
        ...state,
        errorGreaterThan: action.payload.error,
        loadingGraterThan: false,
      };
      break;
    case LOAD_DAYS:
      state = {
        ...state,
        days: action.payload.days,
      };
      break;
    case LOAD_STATISTICS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOAD_STATISTICS_SUCCESS:
      state = {
        ...state,
        statistics: action.payload.statistics,
        error: null,
        loading: false,
      };
      break;
    case LOAD_STATISTICS_ERROR:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case LOAD_SEASONS:
      state = {
        ...state,
        seasons: action.payload.seasons,
      };
      break;
    case LOAD_DAYS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOAD_DAYS_SUCCESS:
      state = {
        ...state,
        days: action.payload.days,
        error: null,
        loading: false,
      };
      break;
    case LOAD_DAYS_ERROR:
      state = {
        ...state,
        error: action.payload.error,
        loading: false,
      };
      break;
    case LOAD_OPERATIONS:
      state = {
        ...state,
        operations: action.payload.operations,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Calculation;
