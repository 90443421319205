import { createTheme } from "@mui/material";
import { palette } from "./constant/palette";
import { styleConstant } from "./constant/styleConstants";

export const playerInfo = createTheme({
  components: {
    MuiCard: {
      defaultProps: {
        style: {
          padding: styleConstant.padding.x0,
        },
      },
      variants: [
        {
          props: { variant: "playerCard" },
          style: {
            background: palette.grey.grey_300,
            borderRadius: styleConstant.borderRadius.x2,
            position: styleConstant.position.cardPosition,
            height: "auto",
            marginBottom: styleConstant.margin.x2,
            cursor: styleConstant.cursor.cardCursor,
            "& .outer-content": {
              padding: `${styleConstant.padding.x2} ${styleConstant.padding.x3} !important`,
            },
            "& .active-banner": {
              position: "absolute",
              top: "0",
              bottom: "0",
              left: "0",
              width: "6px",
              background: palette.primary.main,
            },
            "&.is-last": {
              marginBottom: styleConstant.margin.x3,
            },
            "& .price": {
              fontWeight: 700,
            },
            "& .calc-object-name": {
              fontSize: "20px",
              lineHeight: styleConstant.lineHeight.x3,
            },
            "& .team-name": {
              fontWeight: 700,
              lineHeight: styleConstant.lineHeight.x3,
            }
          },
        },
        {
          props: { variant: "scenarioInfo" },
          style: {
            background: palette.grey.grey_300,
            borderRadius: styleConstant.borderRadius.x2,
            position: styleConstant.position.cardPosition,
            cursor: styleConstant.cursor.cardCursor,
            marginBottom: styleConstant.margin.x2,
            "& .inner-field": {
              backgroundColor: palette.grey.grey_200,
              borderRadius: styleConstant.borderRadius.x1,
            },
            "& .MuiCardActionArea-focusHighlight.css-1v2exvi-MuiCardActionArea-focusHighlight": {
              opacity: "0",
              transition: "none !important",
            },
            "&.is-last": {
              marginBottom: styleConstant.margin.x3,
            },
          },
        },
      ],
    },
  },
});
