import { Backdrop, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { icons } from "../../../assets/styles/constant/general";
import { palette } from "../../../assets/styles/constant/palette";
import { useNavigate } from "react-router";
import { deleteCalculationObject, duplicateCalculus, emptyCalculationList, setResult, setSelectedCalculus } from "../../../store/actions";
import { PlayerInfo } from "..";
import ContainedButton from "../buttons/ContainedButton";
import CustomIconButton from "../buttons/CustomIconButton";
import CustomIcon from "../CustomIcon";
import "../../../assets/styles/scrollbar.css";
import NestedMenu from "../NestedMenu";
import EmptySection from "../EmptySection";
import { styleConstant } from "../../../assets/styles/constant/styleConstants";
import {
  createSelectedDataFromCalculusTool1,
  doCalculations,
  getTotalPriceCalculationTool1,
  getTotalPriceCalculationTool2,
} from "../../../service/calculation";
import { createSelectedDataTool1, createSelectedDataTool2 } from "../../../service/calculation";
import CalculusLoading from "./CalculusLoading";
import { filterCalculationList, groupBySubjectCalculationList } from "../../../service/filter";
import { FILTER_NONE } from "../../../service/constant/filters";
import { GROUP_BY_NONE } from "../../../service/constant/groupBy";

let formattedLoading = false;

const CalculationSection = (props) => {
  const [calculationList, setCalculationList] = React.useState([]);
  const [selectedCalculus, setSelectedCalculus] = React.useState(null);
  const [calculusLoading, setCalculusLoading] = React.useState(false);
  const [formattedCalculationList, setFormattedCalculationList] = React.useState({});
  const [listaAssoluta, setListaAssoluta] = React.useState(null);
  // const [formattedLoading, setFormattedLoading] = React.useState(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    const newCalcList = Object.values(props.calculationList);
    setCalculationList(JSON.parse(JSON.stringify(newCalcList)));
    if (Object.keys(props.calculationList).length > 0) {
      const newAssoluta = {};
      newAssoluta.formatted = { selectedFilter: props.selectedFilter, selectedGroupBy: props.selectedGroupBy };
      newAssoluta.formattedCalculationList = getFormattedList();
      setListaAssoluta(newAssoluta);
    } else {
      setListaAssoluta(null);
    }
  }, [props.calculationList]);

  React.useEffect(() => {
    // controllo se il calcolo selezionato è presente nella lista formattata.
    if (props.selectedCalculus) setSelectedCalculus(props.selectedCalculus.id);
    handleNewSelectedCalculus();
  }, [props.selectedCalculus]);

  React.useEffect(() => {
    if (Object.keys(props.calculationList).length > 0) {
      const newAssoluta = {};
      newAssoluta.formatted = { selectedFilter: props.selectedFilter, selectedGroupBy: props.selectedGroupBy };
      newAssoluta.formattedCalculationList = getFormattedList();
      setListaAssoluta(newAssoluta);
    }
  }, [props.selectedFilter, props.selectedGroupBy]);

  React.useEffect(() => {
    handleNewSelectedCalculus();
  }, [listaAssoluta]);

  const handleNewSelectedCalculus = () => {
    if (listaAssoluta?.formattedCalculationList && Object.keys(listaAssoluta.formattedCalculationList).length > 0) {
      let calcList = listaAssoluta.formattedCalculationList;
      if (listaAssoluta.formatted.selectedGroupBy !== GROUP_BY_NONE) calcList = removeGroupByFormat();
      for (let calcObject of Object.values(calcList)) {
        if (props.selectedCalculus?.id == calcObject.id) {
          props.setSelectedCalculus(calcObject);
          return;
        }
      }
      props.setSelectedCalculus(Object.values(calcList)[0]);
    } else {
      if (props.selectedCalculus) props.setSelectedCalculus(null);
    }
  };

  const executeCalculus = async (calcList) => {
    try {
      setCalculusLoading(true);
      let calcList_clone = calcList;
      if (getObjectDepth(calcList_clone) > getObjectDepth(props.calculationList)) calcList_clone = removeGroupByFormat();
      //escludo i calcoli che sono già stati eseguiti e non sono stati modificati (quelli che non hanno già un valore utile nel risultato)

      let calcObjsArray = [];
      const calcListToArray = Object.values(calcList_clone);
      for (let calcObj of calcListToArray) {
        if (calcObj.result) continue;
        calcObjsArray.push(calcObj);
      }
      let prc;
      if (props.settings.tool == 1) prc = await getTotalPriceCalculationTool1(calcObjsArray, props.settings.subject);
      if (props.settings.tool == 2) prc = await getTotalPriceCalculationTool2(calcObjsArray, props.settings.subject);
      let selectedData;
      if (props.settings.tool == 1) {
        selectedData = createSelectedDataTool1(calcObjsArray, props.settings.subject);
      } else {
        selectedData = createSelectedDataTool2(calcObjsArray, props.settings.subject, props.userAuthInfo.uid);
      }
      const calculationResult = await doCalculations(selectedData, prc.amount, props.userAuthInfo.uid, props.settings.tool);
      handleResult(calculationResult);
      setCalculusLoading(false);
    } catch (error) {
      setCalculusLoading(false);
      throw error;
    }
  };

  const handleResult = (response) => {
    const actualResponse = [];
    Object.keys(response).map((key) => {
      const obj = { ...response[key], calcId: key };
      actualResponse.push(obj);
    });
    props.setResult(actualResponse);
  };

  const onSelectCalculus = (calculationObject) => {
    if (!selectedCalculus) {
      return;
    }
    props.loadSelectedCalculus(calculationObject);
  };

  const onDeleteCalculus = (id) => {
    if (!id) return;
    props.deleteCalculus(id);
  };

  const onEditCalculus = () => {
    if (!props.mobile) return;
    props.onMobileCardEdit();
  };

  const onDuplicateCalculus = (calculationObject) => {
    if (!calculationObject) return;
    props.duplicateCalculus(calculationObject);
  };

  const disableExeButton = () => {
    if (!listaAssoluta || !listaAssoluta.formattedCalculationList) return true;
    if (Object.keys(listaAssoluta.formattedCalculationList).length == 0) return true;
    let calcList = listaAssoluta.formattedCalculationList;
    if (getObjectDepth(calcList) > getObjectDepth(props.calculationList)) calcList = removeGroupByFormat();
    for (let calcObj of Object.values(calcList)) {
      if (calcObj.price > 0) return false;
    }
    return true;
  };

  const onMobileCardClick = () => {
    navigate("/calculator/scenarios");
    props.openScenarioSectionMobile();
  };

  const getFilteredList = (calcList) => {
    return filterCalculationList(calcList, props.selectedFilter);
  };

  const getGroupedList = (calcList) => {
    return groupBySubjectCalculationList(calcList, props.selectedGroupBy);
  };

  const getFormattedList = () => {
    let formattedList = {};
    // prima applico i filtri selezionati alla lista come viene presa dallo store
    formattedList = getFilteredList(props.calculationList);
    // poi la raggruppo per soggetto
    if (Object.values(formattedList).length === 0) {
      return formattedList;
    } else {
      formattedList = getGroupedList(formattedList);
      return formattedList;
    }
  };

  const removeGroupByFormat = () => {
    let listaFormatta = {};
    Object.keys(listaAssoluta.formattedCalculationList).map((soggetto) => {
      Object.values(listaAssoluta.formattedCalculationList[soggetto]).map((calcObject) => {
        if (calcObject) listaFormatta[calcObject.id] = calcObject;
      });
    });
    return listaFormatta;
  };

  const calculateTotalPrice = (calcList) => {
    if (listaAssoluta === null || !listaAssoluta.formattedCalculationList) return 0;
    if (Object.keys(listaAssoluta.formattedCalculationList).length === 0) return 0;
    let calcList_clone = calcList;
    if (getObjectDepth(calcList_clone) > getObjectDepth(props.calculationList)) calcList_clone = removeGroupByFormat();
    const initialPrice = 0;
    const totalPrice = Object.values(calcList_clone).reduce((acc, current) => acc + current.price, initialPrice);
    return totalPrice;
  };

  const getObjectDepth = (object) => {
    var level = 1;
    for (var key in object) {
      if (!object.hasOwnProperty(key)) continue;
      if (typeof object[key] == "object") {
        var depth = getObjectDepth(object[key]) + 1;
        level = Math.max(depth, level);
      }
    }
    return level;
  };

  //RENDER
  const CalculationHeader = ({ title, buttonDisabled = false, buttonAction }) => {
    const totalPrice = calculateTotalPrice(listaAssoluta?.formattedCalculationList);
    return (
      <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap", marginRight: styleConstant.margin.x3 }}>
        {!props.mobile && <Typography variant="title">{title}</Typography>}
        {props.mobile ? (
          <>
            <CustomIconButton icon={<CustomIcon icon={icons.LIST} color={palette.primary.main} />} />
            <ContainedButton
              text={`Esegui tutti ${totalPrice}`}
              icon={<CustomIcon icon={icons.COINS} color={palette.black.main} />}
              disabled={buttonDisabled || totalPrice === 0}
              onClick={buttonAction}
            />
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: styleConstant.gap.medium,
              justifyContent: `${props.mobile && "space-between"}`,
            }}
          >
            <NestedMenu />
            <ContainedButton
              text={`Esegui tutti ${totalPrice}`}
              icon={<CustomIcon icon={icons.COINS} color={palette.black.main} />}
              disabled={buttonDisabled || totalPrice === 0}
              onClick={buttonAction}
            />
          </div>
        )}
      </div>
    );
  };

  const GroupByHeader = ({ subject, gruppoPerSoggetto, gruppoTotalPrice }) => (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
      <Typography variant="title" style={{ color: palette.grey.grey_100 }}>
        {subject}
      </Typography>
      <ContainedButton
        style={{ background: palette.grey.grey_300 }}
        text={"Esegui gruppo " + gruppoTotalPrice}
        textStyle={{ color: palette.primary.main }}
        onClick={() => executeCalculus(gruppoPerSoggetto)}
        disabled={calculateTotalPrice(gruppoPerSoggetto) === 0}
        iconSide="right"
        icon={<CustomIcon icon={icons.COINS} color={palette.primary.main} />}
      />
    </div>
  );

  const PlayersList = ({ children }) => (
    <div style={{ display: "flex", flex: 1, position: "relative" }}>
      <div
        style={{
          overflowY: "overlay",
          marginTop: styleConstant.margin.x4,
          paddingRight: styleConstant.padding.x3,
          flex: `${props.mobile && "1"}`,
          position: "absolute",
          top: "0px",
          bottom: props.mobile ? "-24px" : "0px",
          right: "0px",
          left: "0px",
        }}
        id="custom-scrollbar"
      >
        {children}
      </div>
    </div>
  );

  const renderPlayerInfoCard = (calcuationObject, isFirst = null, subject = null) => {
    const calcResult = calcuationObject.result ? calcuationObject.result.risultato : null;
    let giorni = [];
    calcuationObject.configuration.days.map((giorno) => {
      giorni.push(giorno.label);
    });
    let gruppoPerSoggetto = {};
    let gruppoTotalPrice = 0;
    if (subject) {
      gruppoPerSoggetto = listaAssoluta.formattedCalculationList[subject];
      gruppoTotalPrice = calculateTotalPrice(gruppoPerSoggetto);
    }
    if (props.settings.tool == 1) {
      if (props.settings.subject == 1) {
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }} key={calcuationObject.id}>
            {isFirst && <GroupByHeader subject={subject} gruppoPerSoggetto={gruppoPerSoggetto} gruppoTotalPrice={gruppoTotalPrice} />}
            <PlayerInfo
              key={calcuationObject.id}
              calcNumber={calcuationObject.id}
              calcDays={giorni}
              calcPerc={calcResult}
              calcGreaterThan={calcuationObject.configuration.greaterThan.label}
              calcStat={calcuationObject.configuration.statistic.label}
              teamName={calcuationObject.configuration.team.label}
              calcObjectName={calcuationObject.configuration.player.label}
              isActive={calcuationObject.id === selectedCalculus}
              onClick={() => onSelectCalculus(calcuationObject)}
              onDelete={() => onDeleteCalculus(calcuationObject.id)}
              onDuplicate={() => onDuplicateCalculus(calcuationObject)}
              onEdit={onEditCalculus}
              onCardClik={onMobileCardClick}
              calcPrice={calcuationObject.price}
              mobile={props.mobile}
            />
          </div>
        );
      } else {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }} key={calcuationObject.id}>
              {isFirst && <GroupByHeader subject={subject} gruppoPerSoggetto={gruppoPerSoggetto} gruppoTotalPrice={gruppoTotalPrice} />}
              <PlayerInfo
                key={calcuationObject.id}
                calcNumber={calcuationObject.id}
                isActive={calcuationObject.id === selectedCalculus}
                calcObjectName={calcuationObject.configuration.team.label}
                calcStat={calcuationObject.configuration.statistic.label}
                calcGreaterThan={calcuationObject.configuration.greaterThan.label}
                calcDays={giorni}
                calcPerc={calcResult}
                calcPrice={calcuationObject.price}
                subject={2}
                onClick={() => onSelectCalculus(calcuationObject)}
                onDelete={() => onDeleteCalculus(calcuationObject.id)}
                onEdit={onEditCalculus}
                onDuplicate={() => onDuplicateCalculus(calcuationObject)}
                onCardClik={onMobileCardClick}
                mobile={props.mobile}
              />
            </div>
          </>
        );
      }
    } else {
      if (props.settings.subject == 1) {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }} key={calcuationObject.id}>
              {isFirst && <GroupByHeader subject={subject} gruppoPerSoggetto={gruppoPerSoggetto} gruppoTotalPrice={gruppoTotalPrice} />}
              <PlayerInfo
                key={calcuationObject.id}
                calcNumber={calcuationObject.id}
                isActive={calcuationObject.id === selectedCalculus}
                calcDays={giorni}
                calcPerc={calcResult}
                calcPrice={calcuationObject.price}
                calcObjectName={calcuationObject.configuration.player.label}
                calcSeason={calcuationObject.configuration.season.label}
                calcStat={calcuationObject.configuration.statistic.label}
                teamName={calcuationObject.configuration.team.label}
                calcOperation={calcuationObject.configuration.operation.label}
                tool={2}
                onClick={() => onSelectCalculus(calcuationObject)}
                onDelete={() => onDeleteCalculus(calcuationObject.id)}
                onEdit={onEditCalculus}
                onDuplicate={() => onDuplicateCalculus(calcuationObject)}
                onCardClik={onMobileCardClick}
                mobile={props.mobile}
              />
            </div>
          </>
        );
      } else {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px" }} key={calcuationObject.id}>
              {isFirst && <GroupByHeader subject={subject} gruppoPerSoggetto={gruppoPerSoggetto} gruppoTotalPrice={gruppoTotalPrice} />}
              <PlayerInfo
                key={calcuationObject.id}
                calcNumber={calcuationObject.id}
                calcPrice={calcuationObject.price}
                calcObjectName={calcuationObject.configuration.team.label}
                calcStat={calcuationObject.configuration.statistic.label}
                calcSeason={calcuationObject.configuration.season.label}
                isActive={calcuationObject.id === selectedCalculus}
                calcOperation={calcuationObject.configuration.operation.label}
                calcDays={giorni}
                calcPerc={calcResult}
                subject={2}
                tool={2}
                onClick={() => onSelectCalculus(calcuationObject)}
                onDelete={() => onDeleteCalculus(calcuationObject.id)}
                onEdit={onEditCalculus}
                onDuplicate={() => onDuplicateCalculus(calcuationObject)}
                onCardClik={onMobileCardClick}
                mobile={props.mobile}
              />
            </div>
          </>
        );
      }
    }
  };

  return (
    <>
      {calculusLoading && <CalculusLoading />}
      <div
        style={{
          background: `${props.mobile ? "transparent" : palette.grey.grey_400}`,
          borderRadius: styleConstant.borderRadius.x2,
          flex: 1,
          padding: `${props.mobile ? `${styleConstant.padding.x3} 0px 0px 0px` : `${styleConstant.padding.x3} 0px 0px ${styleConstant.padding.x3}`}`,
          boxShadow: `${props.mobile ? null : "rgb(0 0 0 / 20%) 4px 0px 22px 0px"}`,
          display: "flex",
          flexDirection: "column",
          // maxHeight: `${props.mobile ? "calc(100vh - 160px)" : "100%"}`,
          maxHeight: "100%",
          position: "relative",
        }}
      >
        <CalculationHeader
          title={props.settings.subject === 1 ? "Giocatori" : "Squadre"}
          buttonDisabled={disableExeButton()}
          buttonAction={() => executeCalculus(listaAssoluta.formattedCalculationList)}
        />
        {calculationList.length === 0 ? (
          <EmptySection
            icon={<CustomIcon icon={icons.PEOPLE_GROUP} size="4x" fontSize={styleConstant.fontSize.x8} color={palette.white.main} />}
            title="Nessun giocatore"
            subtitle={`Per iniziare configura ${props.settings.subject == 1 ? "un nuovo giocatore" : "una nuova squadra"}`}
            style={{ flex: 1, marginRight: styleConstant.margin.x3 }}
          />
        ) : (
          <>
            {listaAssoluta.formattedCalculationList == null ||
            !(listaAssoluta.formattedCalculationList && Object.keys(listaAssoluta.formattedCalculationList).length > 0) ? (
              <EmptySection
                icon={<CustomIcon icon={icons.PEOPLE_GROUP} size="4x" fontSize={styleConstant.fontSize.x8} color={palette.white.main} />}
                title="Nessun giocatore"
                subtitle={"Non è presente nessun giocatore nella lista filtrata"}
                style={{ flex: 1, marginRight: styleConstant.margin.x3 }}
              />
            ) : (
              <PlayersList>
                {Object.keys(listaAssoluta.formattedCalculationList) === 0 || listaAssoluta.formatted.selectedGroupBy === GROUP_BY_NONE
                  ? Object.values(listaAssoluta.formattedCalculationList).map((calcuationObject, idx) => {
                      if (calcuationObject) return <React.Fragment key={idx}>{renderPlayerInfoCard(calcuationObject)}</React.Fragment>;
                    })
                  : Object.keys(listaAssoluta.formattedCalculationList).map((subject) => {
                      return Object.values(listaAssoluta.formattedCalculationList[subject]).map((calcuationObject, index) => {
                        const isFirst = index === 0;
                        if (calcuationObject) return <React.Fragment key={index}>{renderPlayerInfoCard(calcuationObject, isFirst, subject)}</React.Fragment>;
                      });
                    })}
              </PlayersList>
            )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ CalculationWork, Profile }) => ({
  calculationList: CalculationWork.calculationList,
  selectedCalculus: CalculationWork.selectedCalculus,
  settings: CalculationWork.settings,
  userAuthInfo: Profile.userAuthInfo,
  totalPrice: CalculationWork.totalPrice,
  selectedFilter: CalculationWork.filter,
  selectedGroupBy: CalculationWork.groupBy,
  emptyScreen: CalculationWork.showEmptyScreen,
});

const mapDispatchToProps = (dispatch) => ({
  loadSelectedCalculus: (calculationObject) => dispatch(setSelectedCalculus(calculationObject)),
  deleteCalculus: (id) => dispatch(deleteCalculationObject(id)),
  duplicateCalculus: (calculationObject) => dispatch(duplicateCalculus(calculationObject)),
  setSelectedCalculus: (newCalculus) => dispatch(setSelectedCalculus(newCalculus)),
  emptyCalculationList: () => dispatch(emptyCalculationList()),
  setResult: (result) => dispatch(setResult(result)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculationSection);
