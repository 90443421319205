import { getBreakpoint } from "../../service/screenSize";
import {
  SET_BREAKPOINT,
  SET_LARGE_BREAKPOINT,
  SET_MEDIUM_BREAKPOINT,
  SET_SMALL_BREAKPOINT,
} from "./actionTypes";

const initialState = {
  breakpoint: getBreakpoint(window.innerWidth),
};

const screen = (state = initialState, action) => {
  switch (action.type) {
    case SET_BREAKPOINT:
      state = {
        ...state,
        breakpoint: action.payload,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default screen;
