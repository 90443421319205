import { convertArrayToObject, getPathWithParams } from "../helpers/util";
import { getFirebaseBackend } from "./common/firebase";
import { FANTA_EVALUATE_GUIDE, FANTA_EVALUATE_PRICE, FANTA_EVALUATE_SETTINGS, FANTA_PLAYERS, FANTA_SETTINGS, FANTA_STATISTICS } from "./constant/refDb";

const firebaseBackend = getFirebaseBackend();

export function getFantasettings() {
  return firebaseBackend.dbOnceRead(getPathWithParams(FANTA_SETTINGS))
		.then(snapshot => {
			const fantaSettings = snapshot.val();
			return fantaSettings;
		}).catch(error => {
			console.log('ERRORE getFantasettings:', error);
			throw error;
		});
}

export function getFantaPlayers() {
  return firebaseBackend.dbOnceRead(getPathWithParams(FANTA_PLAYERS))
		.then(snapshot => {
			const fantaPlayers = snapshot.val();
			return fantaPlayers;
		}).catch(error => {
			console.log('ERRORE getFantaPlayers:', error);
			throw error;
		});
}

export function getFantaStatistics() {
	return firebaseBackend.dbOnceRead(getPathWithParams(FANTA_STATISTICS))
		  .then(snapshot => {
			  const fantaStatistics = snapshot.val();
			  return convertArrayToObject(fantaStatistics);
		  }).catch(error => {
			  console.log('ERRORE getFantaPlayers:', error);
			  throw error;
		  });
  }

	export function getFantaEvaluatePlayerGuide() {
		return firebaseBackend.dbOnceRead(getPathWithParams(FANTA_EVALUATE_GUIDE))
			.then(snapshot => {
				const fantaEvaluateGuide = snapshot.val();
				return convertArrayToObject(fantaEvaluateGuide);
			}).catch(error => {
				console.log('ERRORE getFantaEvaluatePlayerGuide:', error);
				throw error;
			});
	}
	
	export function getFantaEvaluatePlayerPrice() {
		return firebaseBackend.dbOnceRead(getPathWithParams(FANTA_EVALUATE_PRICE))
			.then(snapshot => {
				const fantaEvaluatePrice = snapshot.val();
				return convertArrayToObject(fantaEvaluatePrice);
			}).catch(error => {
				console.log('ERRORE getFantaEvaluatePlayerPrice:', error);
				throw error;
			});
	}
	
	export function getFantaEvaluatePlayerSettings() {
		return firebaseBackend.dbOnceRead(getPathWithParams(FANTA_EVALUATE_SETTINGS))
			.then(snapshot => {
				const fantaEvaluateSettings = snapshot.val();
				return convertArrayToObject(fantaEvaluateSettings);
			}).catch(error => {
				console.log('ERRORE getFantaEvaluatePlayerSettings:', error);
				throw error;
			});
	}

/* export const generateBodyRequestObject = (teamObject) => {
	
}

const _getSettingsObject = () => {

}

const _getIdPlayersObject = () => {

}

const _getIdStatisticsObject = () => {

} */