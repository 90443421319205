import React from "react";
import { connect } from "react-redux";
import Info from "./Info";

const InfoPage = (props) => {
  return (
    <>
      {props.windowBreakpoint === "LARGE" && <Info />}
      {props.windowBreakpoint === "SMALL" && <Info mobile />}
    </>
  );
};

const mapStateToProps = ({ Screen }) => ({
  windowBreakpoint: Screen.breakpoint,
});

export default connect(mapStateToProps)(InfoPage);
