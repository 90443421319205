import { Button, ButtonBase, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../../assets/styles/constant/palette";
import { styleConstant } from "../../../assets/styles/constant/styleConstants";
import CustomCheckBox from "./CustomCheckBox";

const config = [
  { label: "Uno", value: 1 },
  { label: "Due", value: 2 },
];

const InputRadio = ({ row = true, radioItem = config, selectedValue = 1, handleSelect }) => {
  const getRadioContainerStyle = () => {
    return {
      display: "flex",
      flexDirection: row ? "row" : "column",
      justifyContent: "space-between",
      gap: styleConstant.gap.small,
    };
  };

  const getSingleItemContainerStyle = () => {
    return {
      background: palette.grey.grey_200,
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: styleConstant.padding.inputRadioItemContainer,
      flex: 1,
      borderRadius: styleConstant.borderRadius.x1,
      maxHeight: styleConstant.maxHeight.x6,
    };
  };

  return (
    <div style={getRadioContainerStyle()}>
      {radioItem.map((item, index) => {
        return (
          <ButtonBase style={{ flex: 1 }} onClick={() => handleSelect(item.value)} key={index}>
            <div style={getSingleItemContainerStyle()}>
              <CustomCheckBox checked={item.value === selectedValue} />
              <Typography variant="smallTitle">{item.label}</Typography>
            </div>
          </ButtonBase>
        );
      })}
    </div>
  );
};

export default InputRadio;
