export const CREATE_CALCULATION_OBJECT = "CREATE_CALCULATION_OBJECT";
export const CREATE_CALCULATION_OBJECT_SUCCESS = "CREATE_CALCULATION_OBJECT_SUCCESS";
export const CREATE_CALCULATION_OBJECT_ERROR = "CREATE_CALCULATION_OBJECT_ERROR";

export const DELETE_CALCULATION_OBJECT = "DELETE_CALCULATION_OBJECT";
export const DELETE_CALCULATION_OBJECT_SUCCESS = "DELETE_CALCULATION_OBJECT_SUCCESS";
export const DELETE_CALCULATION_OBJECT_ERROR = "DELETE_CALCULATION_OBJECT_ERROR";

export const UPDATE_CALCULATION_OBJECT = "UPDATE_CALCULATION_OBJECT";
export const UPDATE_CALCULATION_OBJECT_SUCCESS = "UPDATE_CALCULATION_OBJECT_SUCCESS";
export const UPDATE_CALCULATION_OBJECT_ERROR = "UPDATE_CALCULATION_OBJECT_ERROR";

export const UPDATE_CALCULATION_OBJECT_SCENARIOS = "UPDATE_CALCULATION_OBJECT_SCENARIOS";
export const UPDATE_CALCULATION_OBJECT_SCENARIOS_SUCCESS = "UPDATE_CALCULATION_OBJECT_SCENARIOS_SUCCESS";
export const UPDATE_CALCULATION_OBJECT_SCENARIOS_ERROR = "UPDATE_CALCULATION_OBJECT_SCENARIOS_ERROR";

export const SET_SELECTED_CALCULUS = "SET_SELECTED_CALCULUS";

export const SET_SELECTED_SETTINGS = "SET_SELECTED_SETTINGS";
export const SET_SELECTED_SETTINGS_SUCCESS = "SET_SELECTED_SETTINGS_SUCCESS";
export const SET_SELECTED_SETTINGS_ERROR = "SET_SELECTED_SETTINGS_ERROR";

export const EMPTY_CALCULATION_LIST = "EMPTY_CALCULATION_LIST";

export const DUPLICATE_CALCULUS = "DUPLICATE_CALCULUS";
export const DUPLICATE_CALCULUS_SUCCESS = "DUPLICATE_CALCULUS_SUCCESS";
export const DUPLICATE_CALCULUS_ERROR = "DUPLICATE_CALCULUS_ERROR";

export const RESET_CALCULATOR = "RESET_CALCULATOR";

export const SET_SELECTED_SEASON = "SELECTED_SEASON";

export const SET_TOTAL_PRICE = "SET_TOTAL_PRICE";

export const SET_RESULT = "SET_RESULT";
export const SET_RESULT_SUCCESS = "SET_RESULT_SUCCESS";
export const SET_RESULT_ERROR = "SET_RESULT_ERROR";

export const SET_FILTER = "SET_FILTER";
export const SET_GROUP_BY = "SET_GROUP_BY";

export const SET_EMPTY_SCREEN = "SET_EMPTY_SCREEN";
