export const palette = {
  primary: {
    main: "#FED42B",
    hover: "#FED42BA6",
  },
  white: {
    main: "#FFFFFF",
  },
  black: {
    main: "#000000",
  },
  grey: {
    grey_100: "#848E9C",
    grey_200: "#484B56",
    grey_300: "#32343B",
    grey_400: "#282A30",
    grey_500: "#212328",
    grey_600: "#15191D",
  },
  ripple: {},
};
