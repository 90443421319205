import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  getDays,
  getDefaultSeasonId,
  getGreaterThan,
  getGreaterThanDescription,
  getGuides,
  getMethods,
  getOperations,
  getPlans,
  getPlayers,
  getPlayersV2,
  getScenarios,
  getSeasons,
  getStatistics,
  getStatisticScenarios,
  getStatisticsFromIds,
  getSubjects,
  getTeams,
  getTools,
  getTsCreatedData,
} from "../../service/calculationData";
import { logError } from "../../service/handleError";
import { loadSelectedSeason } from "../actions";
import {
  loadBasicCalcDataError,
  loadBasicCalcDataSuccess,
  loadDaysError,
  loadDaysErrorV2,
  loadDaysSuccess,
  loadDaysSuccessV2,
  loadGreaterThanError,
  loadGreaterThanSuccess,
  loadPlayersError,
  loadPlayersSuccess,
  loadStatisticsError,
  loadStatisticsSuccess,
  loadTeamsError,
  loadTeamsSuccess,
} from "./actions";
import {
  LOAD_BASIC_CALC_DATA,
  LOAD_BASIC_CALC_DATA_ERROR,
  LOAD_DAYS,
  LOAD_DAYS_ERROR,
  LOAD_DAYS_ERROR_V2,
  LOAD_DAYS_V2,
  LOAD_GREATER_THAN,
  LOAD_GREATER_THAN_ERROR,
  LOAD_PLAYERS,
  LOAD_PLAYERS_ERROR,
  LOAD_STATISTICS,
  LOAD_STATISTICS_ERROR,
  LOAD_TEAMS,
  LOAD_TEAMS_ERROR,
} from "./actionTypes";

function* loadBasicCalcData() {
  try {
    const seasons = yield call(getSeasons, "1");
    const defaultSeasonId = Object.values(seasons)[Object.values(seasons).length - 1].id;
    const subjects = yield call(getSubjects);
    const methods = yield call(getMethods);
    const statistics = yield call(getStatistics, "1", "1");
    const days = yield call(getDays, "1", defaultSeasonId);
    const greaterThanDescription = null; /* yield call(getGreaterThanDescription) */
    const tsCreatedData = yield call(getTsCreatedData);
    const scenarios = yield call(getScenarios);
    const statisticScenarios = yield call(getStatisticScenarios);
    const tools = yield call(getTools);
    const operations = yield call(getOperations, "1");
    const guides = yield call(getGuides);
    const plans = yield call(getPlans);
    console.log(guides);
    if (!subjects || !methods || !statistics || !days) {
      logError(LOAD_BASIC_CALC_DATA_ERROR);
      yield put(loadBasicCalcDataError(LOAD_BASIC_CALC_DATA_ERROR));
    } else {
      const result = {
        subjects,
        methods,
        statistics,
        days,
        greaterThanDescription,
        tsCreatedData,
        scenarios,
        statisticScenarios,
        seasons,
        tools,
        operations,
        guides,
        plans,
      };
      yield put(loadBasicCalcDataSuccess(result));
    }
  } catch (error) {
    yield put(loadBasicCalcDataError(LOAD_BASIC_CALC_DATA_ERROR));
  }
}

function* loadDays({ payload: { idSeason } }) {
  try {
    const settings = yield select((state) => state.CalculationWork.settings);
    const newDays = yield call(getDays, settings.tool, idSeason);
    yield put(loadDaysSuccess(newDays));
  } catch (error) {
    yield put(loadDaysError(LOAD_DAYS_ERROR));
  }
}

function* loadTeams({ payload: { idsTeams } }) {
  try {
    const teams = yield call(getTeams, idsTeams);
    if (teams === null) {
      logError(LOAD_TEAMS_ERROR);
      yield put(loadTeamsError(LOAD_TEAMS_ERROR));
    } else {
      yield put(loadTeamsSuccess(teams));
    }
  } catch (error) {
    yield put(loadTeamsError(LOAD_TEAMS_ERROR));
  }
}

function* loadPlayers({ payload: { idSeason, idTeam, idDays, idStatistic } }) {
  try {
    const selectedToolId = yield select((state) => state.CalculationWork.settings.tool);
    const players = yield call(getPlayersV2, selectedToolId, idSeason, idTeam, idDays, idStatistic);
    if (players === null) {
      logError(LOAD_PLAYERS_ERROR);
      yield put(loadPlayersError(LOAD_PLAYERS_ERROR));
    } else {
      yield put(loadPlayersSuccess(players));
    }
  } catch (error) {
    yield put(loadPlayersError(LOAD_PLAYERS_ERROR));
  }
}

function* loadGreaterThan({ payload: { idStatistic } }) {
  try {
    const greaterThan = yield call(getGreaterThan, idStatistic);
    if (greaterThan == null) {
      logError(LOAD_GREATER_THAN_ERROR);
      yield put(loadGreaterThanError(LOAD_GREATER_THAN_ERROR));
    } else {
      yield put(loadGreaterThanSuccess(greaterThan));
    }
  } catch (error) {
    yield put(loadGreaterThanError(LOAD_GREATER_THAN_ERROR));
  }
}

function* loadStatistics({ payload: { ids } }) {
  try {
    const newStatistics = yield getStatisticsFromIds(ids);
    yield put(loadStatisticsSuccess(newStatistics));
  } catch (error) {
    yield put(loadStatisticsError(LOAD_STATISTICS_ERROR));
  }
}

function* calculationSaga() {
  yield takeEvery(LOAD_BASIC_CALC_DATA, loadBasicCalcData);
  yield takeEvery(LOAD_TEAMS, loadTeams);
  yield takeEvery(LOAD_DAYS, loadDays);
  yield takeEvery(LOAD_PLAYERS, loadPlayers);
  yield takeEvery(LOAD_GREATER_THAN, loadGreaterThan);
  yield takeEvery(LOAD_STATISTICS, loadStatistics);
}

export default calculationSaga;
