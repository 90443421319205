import {
  REGISTER_USER,
  REGISTER_USER_SUCCESSFUL,
  REGISTER_USER_FAILED,
  FETCH_USER_REGISTRATION_DATA_SUCCESS,
  FETCH_USER_REGISTRATION_DATA,
  FETCH_USER_REGISTRATION_DATA_FAILED,
} from "./actionTypes"

const initialState = {
  insertData: false, //TODO: NON MI CONVINCE
  registrationSuccess: false,
  registrationError: null,
  message: null,
  loading: false,
}

const account = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER:
      state = {
        ...state,
        user: null,
        loading: true,
        insertData: true,
        registrationSuccess: false,
        registrationError: null
      }
      break
    case REGISTER_USER_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        insertData: false,
        user: action.payload,
        registrationSuccess: true,
        registrationError: null,
      }
      break
    case REGISTER_USER_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        insertData: false,
        registrationSuccess: false,
        registrationError: action.payload,
      }
      break
    case FETCH_USER_REGISTRATION_DATA:
      state = {
        ...state,
        loading: true,
      }
      break
    case FETCH_USER_REGISTRATION_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        userInvitation: action.payload,
      }
      break
    case FETCH_USER_REGISTRATION_DATA_FAILED:
      state = {
        ...state,
        loading: false,
        registrationError: action.payload
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default account
