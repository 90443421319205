import React from "react";
import { icons } from "../../assets/styles/constant/general";
import CustomIconButton from "./buttons/CustomIconButton";
import CustomIcon from "./CustomIcon";
import "../../assets/styles/nestedMenu.css";
import Fade from "@mui/material/Fade";
import { Badge, Divider, Menu, MenuItem, Typography } from "@mui/material";
import { filterMenu, styleConstant, textSecondaryStyle } from "../../assets/styles/constant/styleConstants";
import { palette } from "../../assets/styles/constant/palette";
import { GROUP_BY_NONE, GROUP_BY_PLAYER, GROUP_BY_STATISTIC, GROUP_BY_TEAM } from "../../service/constant/groupBy";
import { connect } from "react-redux";
import { setFilter, setGroupBy } from "../../store/actions";
import { FILTER_CALCULATED, FILTER_NONE, FILTER_NOT_CALCULATED } from "../../service/constant/filters";

const NestedMenu = (props) => {
  const [anchore, setAnchore] = React.useState(null);

  const handleSelect = (section, value) => {
    if (section === "groupby") {
      setAnchore(null);
      props.loadGroupBy(value);
      return;
    }
    setAnchore(null);
    props.loadFilter(value);
  };

  const getBadgeContent = () => {
    let amount = 0;
    if (props.selectedFilter !== FILTER_NONE) amount++;
    if (props.selectedGroupBy !== GROUP_BY_NONE) amount++;
    if (amount === 0) return null;
    return amount;
  };

  return (
    //VERSIONE 3
    <>
      <CustomIconButton
        icon={
          <Badge badgeContent={getBadgeContent()} color="primary">
            <CustomIcon icon={icons.LIST} />
          </Badge>
        }
        onClick={(e) => setAnchore(e.currentTarget)}
      />
      <Menu
        open={!!anchore}
        onClose={() => setAnchore(null)}
        anchorEl={anchore}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            marginTop: styleConstant.margin.x1,
            background: palette.grey.grey_600,
            borderRadius: styleConstant.borderRadius.x1,
            minWidth: "350px",
            padding: styleConstant.padding.x2,
          },
        }}
        MenuListProps={{
          style: {
            padding: "0px",
          },
        }}
      >
        <MenuItem variant="filter-menu" className="header" disableRipple>
          <div style={{ display: "flex", alignItems: "center", gap: styleConstant.gap.small }}>
            <CustomIcon icon={icons.OBJECT_GROUP} color={palette.white.main} />
            <Typography>Raggruppa per</Typography>
          </div>
        </MenuItem>
        <MenuItem
          variant="filter-menu"
          className={props.selectedGroupBy === GROUP_BY_NONE ? "selected" : "sub-item"}
          onClick={() => handleSelect("groupby", GROUP_BY_NONE)}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
            <Typography style={filterMenu.subItem}>Nessuno</Typography>
            {props.selectedGroupBy === GROUP_BY_NONE && <CustomIcon icon={icons.CHECK} />}
          </div>
        </MenuItem>
        <MenuItem
          variant="filter-menu"
          className={props.selectedGroupBy === GROUP_BY_STATISTIC ? "selected" : "sub-item"}
          style={{ marginTop: styleConstant.margin.x1 }}
          onClick={() => handleSelect("groupby", GROUP_BY_STATISTIC)}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
            <Typography style={filterMenu.subItem}>Statistica</Typography>
            {props.selectedGroupBy === GROUP_BY_STATISTIC && <CustomIcon icon={icons.CHECK} />}
          </div>
        </MenuItem>
        <MenuItem
          variant="filter-menu"
          className={props.selectedGroupBy === GROUP_BY_TEAM ? "selected" : "sub-item"}
          style={{ marginTop: styleConstant.margin.x1 }}
          onClick={() => handleSelect("groupby", GROUP_BY_TEAM)}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
            <Typography style={filterMenu.subItem}>Squadra</Typography>
            {props.selectedGroupBy === GROUP_BY_TEAM && <CustomIcon icon={icons.CHECK} />}
          </div>
        </MenuItem>
        {props.settings.subject == 1 && (
          <MenuItem
            variant="filter-menu"
            className={props.selectedGroupBy === GROUP_BY_PLAYER ? "selected" : "sub-item"}
            style={{ marginTop: styleConstant.margin.x1 }}
            onClick={() => handleSelect("groupby", GROUP_BY_PLAYER)}
          >
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
              <Typography style={filterMenu.subItem}>Giocatore</Typography>
              {props.selectedGroupBy === GROUP_BY_PLAYER && <CustomIcon icon={icons.CHECK} />}
            </div>
          </MenuItem>
        )}
        <Divider style={{ background: palette.grey.grey_300 }} />
        <MenuItem variant="filter-menu" style={{ marginTop: styleConstant.margin.x1 }} className="header" disableRipple>
          <div style={{ display: "flex", alignItems: "center", gap: styleConstant.gap.small }}>
            <CustomIcon icon={icons.FILTER} color={palette.white.main} />
            <Typography>Filtra per</Typography>
          </div>
        </MenuItem>
        <MenuItem
          variant="filter-menu"
          className={props.selectedFilter === FILTER_NONE ? "selected" : "sub-item"}
          onClick={() => handleSelect("filterby", FILTER_NONE)}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
            <Typography style={filterMenu.subItem}>Nessuno</Typography>
            {props.selectedFilter === FILTER_NONE && <CustomIcon icon={icons.CHECK} />}
          </div>
        </MenuItem>
        <MenuItem
          variant="filter-menu"
          className={props.selectedFilter === FILTER_CALCULATED ? "selected" : "sub-item"}
          style={{ marginTop: styleConstant.margin.x1 }}
          onClick={() => handleSelect("filterby", FILTER_CALCULATED)}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
            <Typography style={filterMenu.subItem}>Calcolati</Typography>
            {props.selectedFilter === FILTER_CALCULATED && <CustomIcon icon={icons.CHECK} />}
          </div>
        </MenuItem>
        <MenuItem
          variant="filter-menu"
          className={props.selectedFilter === FILTER_NOT_CALCULATED ? "selected" : "sub-item"}
          style={{ marginTop: styleConstant.margin.x1 }}
          onClick={() => handleSelect("filterby", FILTER_NOT_CALCULATED)}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flex: "1" }}>
            <Typography style={filterMenu.subItem}>Non calcolati</Typography>
            {props.selectedFilter === FILTER_NOT_CALCULATED && <CustomIcon icon={icons.CHECK} />}
          </div>
        </MenuItem>
      </Menu>
    </>
  );
};

const mapStateToProps = ({ CalculationWork }) => ({
  selectedFilter: CalculationWork.filter,
  selectedGroupBy: CalculationWork.groupBy,
  settings: CalculationWork.settings,
});

const mapDispatchToProps = (dispatch) => ({
  loadFilter: (filter) => dispatch(setFilter(filter)),
  loadGroupBy: (groupBy) => dispatch(setGroupBy(groupBy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NestedMenu);
