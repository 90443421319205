import React, { Component, Profiler } from "react"
import { connect } from "react-redux"
import { loginUser, logoutUser, registerUser } from "../store/actions"
import { getDisplayNameSplitter } from '../service/userProfile';

class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      name: 'Samir',
      surname: 'Legdah',
      email: 'samlegdah@gmail.com',
      password: 'ciaociao22',
      confirmPassword: ''
    }
  }

  componentDidMount = () => {
  }

  componentDidUpdate = (prevProps, prevState) => {
  }

  componentWillUnmount = () => { }

  onRegisterPress = () => {
    const { email, password, name, surname } = this.state;
    const displayName = `${name}${getDisplayNameSplitter()}${surname}`;
    const user = { email, password, displayName };
    console.log("REGISTRAZIONE ", user)
    debugger
    this.props.onRegister(user);
  }

  onLoginPress = () => {
    const { email, password } = this.state;
    const user = { email, password };
    this.props.onLogin(user);
  };

  onLogoutPress = () => {
    this.props.onLogout();
  };

  render() {
    const { name, surname, email, password } = this.state;
    const { userProfile } = this.props;
    return (
      <div style={{
        backgroundColor: '#f5f5f5',
        height: '100vh',
        padding: '100px'

      }}>
        <h1>Ciao sei appena approdato nel BoilerPlate del <strong>Blunì Team</strong>.</h1>
        <br /><br />
        {
          !userProfile &&
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h1>Registrazione</h1>
            <input
              style={styles.inputField}
              placeholder="Nome"
              value={name}
              onChange={(value) => this.setState({ name: value.target.value })} />
            <input
              style={styles.inputField}
              placeholder="Cognome"
              value={surname}
              onChange={(value) => this.setState({ surname: value.target.value })} />
            <input
              style={styles.inputField}
              placeholder="Indirizzo posta"
              value={email}
              onChange={(value) => this.setState({ email: value.target.value })} />
            <input
              type={'password'}
              style={styles.inputField}
              placeholder="Password"
              value={password}
              onChange={(value) => this.setState({ password: value.target.value })} />
            <button
              style={styles.inputField} onClick={this.onRegisterPress}>
              ISCRIVITI
            </button>
            {
              this.props.registrationError &&
              <p style={{ color: 'red' }}>{this.props.registrationError}</p>
            }
          </div>}
        {
          !userProfile &&
          <>
            <hr style={{ margin: '80px 0' }} />
            <div style={{ display: 'flex', flexDirection: 'column', fontSize: '50px' }}>
              <h1>Login</h1>
              <input
                style={styles.inputField}
                placeholder="Indirizzo posta"
                value={email}
                onChange={(value) => this.setState({ email: value.target.value })} />
              <input
                type={'password'}
                style={styles.inputField}
                placeholder="Password"
                value={password}
                onChange={(value) => this.setState({ password: value.target.value })} />
              <button
                style={styles.inputField} onClick={this.onLoginPress}>
                ACCEDI
              </button>
            </div>
          </>
        }
        {
          userProfile &&
          <>
            <hr style={{ margin: '80px 0' }} />
            <div style={styles.fileds}>
              <h1>Account</h1>
              <span><p>Display Name: {userProfile.name + " " + userProfile.surname} </p></span>
              <span><p>Email: {userProfile.email} </p></span>
              <button
                style={styles.inputField} onClick={this.onLogoutPress}>
                LOGOUT
              </button>
            </div>
          </>
        }
      </div>
    )
  }
}

const styles = {
  inputField: {
    fontSize: '50px',
    marginTop: '50px'
  },
  fileds: {
    fontSize: '50px'
  }
}

const mapStateToProps = ({ Login, Register, Profile }) => ({
  loading: Login.loadingLogin,
  error: Login.errorLogin,
  registrationError: Register.registrationError,
  userProfile: Profile.userProfile
});

const mapDispatchToProps = dispatch => ({
  onRegister: (user) => dispatch(registerUser(user)),
  onLogin: (user) => dispatch(loginUser(user)),
  onLogout: (user) => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
