import { takeEvery, put, call, select } from "redux-saga/effects";

import {
  loadProfileError,
  loadProfileSuccess,
  editProfileSuccess,
  editProfileError,
  deleteProfileSuccess,
  deleteProfileError,
  updatePurchaseUserData,
  reloadUserAppDataSuccess,
  reloadUserFantaTeamsSuccess,
  reloadUserCreditSuccess,
} from "./actions";
import { DELETE_PROFILE, EDIT_PROFILE, LOAD_PROFILE, RELOAD_USER_APP_DATA, RELOAD_USER_CREDITS, RELOAD_USER_FANTA_TEAMS } from "./actionTypes";
import * as Profile from "../../service/userProfile";
import { logoutUser } from "../auth/login/actions";
import { DELETE_PROFILE_ERROR, EDIT_PROFILE_ERROR, LOAD_PROFILE_ERROR } from "../../service/constant/errorCodes";
import { logError } from "../../service/handleError";
import { selectFantaTeam } from "../fanta/actions";

function* loadProfile({ payload: { userAuthInfo } }) {
  try {
    let userProfile = userAuthInfo ? yield call(Profile.getUserProfile, userAuthInfo) : null;

    /*   TODO: capire se è necessario una gestione errore
    if (userProfile == null) {
        logError(LOAD_PROFILE_ERROR);
        yield put(loadProfileError(LOAD_PROFILE_ERROR))
        yield put(logoutUser())
      } else {
        yield put(loadProfileSuccess(userProfile))
      }
   */
    yield put(loadProfileSuccess(userProfile));
  } catch (error) {
    logError(LOAD_PROFILE_ERROR);
    yield put(loadProfileError(LOAD_PROFILE_ERROR));
    yield put(logoutUser());
  }
}

function* editProfile({ payload: { userProfile } }) {
  try {
    const success = yield call(Profile.editUserProfile, userProfile);
    if (!success) {
      logError(EDIT_PROFILE_ERROR);
      yield put(editProfileError(EDIT_PROFILE_ERROR));
    } else {
      yield put(editProfileSuccess(userProfile));
    }
  } catch (error) {
    yield put(editProfileError(EDIT_PROFILE_ERROR));
  }
}

function* deleteProfile({ payload: { userProfile } }) {
  try {
    const success = yield call(Profile.deleteUserProfile, userProfile.uid);
    if (!success) {
      logError(DELETE_PROFILE_ERROR);
      yield put(deleteProfileError(DELETE_PROFILE_ERROR));
    } else {
      yield put(deleteProfileSuccess(userProfile));
    }
  } catch (error) {
    yield put(deleteProfileError(DELETE_PROFILE_ERROR));
  }
}

function* reloadUserFantaTeams({ payload: { uid, selectedId } }) {
  try {
    const newUserAppData = yield call(Profile.getAppData, uid);
    const fantaTeams = newUserAppData?.fantaTeams;
    yield put(reloadUserFantaTeamsSuccess(fantaTeams));
    if (selectedId) yield put(selectFantaTeam(selectedId));
  } catch (error) {
    console.log('Errore in SAGA reloadUserFantaTeams', error)
  }
}

function* reloadUserCredits({payload: { uid }}) {
  try {
    const newUserAppData = yield call(Profile.getAppData, uid);
    console.log(newUserAppData)
    const credit = newUserAppData?.credit;
    console.log('credit', credit)
    yield put(reloadUserCreditSuccess(credit));
  } catch (error) {
    console.log('Errore in SAGA reloadUserCredit', error)
  }
}

function* profileSaga() {
  yield takeEvery(LOAD_PROFILE, loadProfile);
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(DELETE_PROFILE, deleteProfile);
  yield takeEvery(RELOAD_USER_FANTA_TEAMS, reloadUserFantaTeams);
  yield takeEvery(RELOAD_USER_CREDITS, reloadUserCredits)
}

export default profileSaga;
