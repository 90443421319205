import React from "react";
import { ButtonBase, Typography } from "@mui/material";
import { palette } from "../../../assets/styles/constant/palette";
import {
  drawerHelpBoxTextStyle,
  textSecondaryStyle,
} from "../../../assets/styles/constant/styleConstants";
import { styleConstant } from "../../../assets/styles/constant/styleConstants";

const DrawerHelpBox = ({ title, text, goToAction, showAgainAction }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: styleConstant.padding.x2,
        gap: styleConstant.gap.large,
        borderRadius: styleConstant.borderRadius.x2,
        background: palette.grey.grey_300,
        maxHeight: "208px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: styleConstant.gap.small,
          overflow: "hidden",
          maxHeight: "80%",
        }}
      >
        <Typography variant="smallTitle" style={drawerHelpBoxTextStyle.title}>
          {title}
        </Typography>
        <Typography variant="smallTitle" style={drawerHelpBoxTextStyle.content}>
          {text}
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: styleConstant.gap.medium,
        }}
      >
        <ButtonBase color="white" onClick={showAgainAction}>
          <Typography
            variant="smallTitle"
            style={drawerHelpBoxTextStyle.actions}
          >
            Non mostrare più
          </Typography>
        </ButtonBase>

        <ButtonBase color="primary" onClick={goToAction}>
          <Typography
            variant="smallTitle"
            style={{
              ...drawerHelpBoxTextStyle.actions,
              color: palette.primary.main,
            }}
          >
            Vai al tutorial
          </Typography>
        </ButtonBase>
      </div>
    </div>
  );
};

export default DrawerHelpBox;
