import { Typography } from "@mui/material";
import React from "react";
import { styleConstant, textSecondaryStyle } from "../../assets/styles/constant/styleConstants";

const EmptySection = ({ icon = null, title = "title", subtitle = "subtitle", style = null }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", ...style }}>
      {icon}
      <Typography variant="smallTitle" style={{ marginTop: styleConstant.margin.x1 }}>
        {title}
      </Typography>
      <Typography
        variant="subtitle"
        style={{
          ...textSecondaryStyle,
          maxWidth: "90%",
          textAlign: "center",
          marginTop: styleConstant.margin.x1,
          fontWeight: 500
        }}
      >
        {subtitle}
      </Typography>
    </div>
  );
};

export default EmptySection;
