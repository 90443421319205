import React from "react";
import { Card, CardActionArea, CardContent, Collapse, Typography } from "@mui/material";
import { LabelValueInfo } from "../index";
import ExpandMoreIcon from "../icons/ExpandMoreIcon";
import FormSkeleton from "../input/FormSkeleton";
import InputRadio from "../input/InputRadio";
import InputSelect from "../input/InputSelect";
import { styleConstant, scenarioInfo } from "../../../assets/styles/constant/styleConstants";

const ScenarioInfo = ({
  scenariosObject = null,
  selectedValues = null,
  scenarioGiornata,
  scenarioGolSegnati = null,
  scenarioTitolarita = null,
  scenarioMinutes = null,
  scenarioRuolo = null,
  scenarioRigorista = null,
  isExpanded,
  onExpandClick,
  isLast,
  standard = true,
  results = null,
  mobile = false,
  onScenarioSelect,
  setOpenScenario = () => {},
  openScenarioObj = {},
}) => {
  const [expanded, setExpanded] = React.useState(openScenarioObj["day"] == scenarioGiornata);

  // React.useEffect(() => {
  //   if (expanded) setOpenScenario(scenarioGiornata);
  //   if (!expanded) setOpenScenario(-1);
  // }, [expanded]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
    if (!expanded) setOpenScenario(scenarioGiornata);
    if (expanded) setOpenScenario(-1);
  };

  return (
    <Card variant="scenarioInfo" className={isLast ? "is-last" : ""}>
      {standard && (
        <div
          style={{
            position: "absolute",
            top: styleConstant.top.x2,
            right: styleConstant.right.x2,
            zIndex: 1,
          }}
        >
          <ExpandMoreIcon isExpanded={expanded} onClick={toggleExpanded} />
        </div>
      )}
      <CardActionArea
        onClick={toggleExpanded}
        style={{
          padding: !standard ? styleConstant.padding.x2 : `20px ${styleConstant.padding.x2} ${expanded ? "20px" : "20px"}`,
          // : `${styleConstant.padding.x3} ${styleConstant.padding.x3} ${styleConstant.padding.x2} ${styleConstant.padding.x3}`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="title">Giornata {scenarioGiornata}</Typography>
          </div>
          {standard && (
            <Collapse in={!expanded}>
              {!mobile ? (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    gap: "20px",
                    marginTop: "15px",
                  }}
                >
                  {scenarioTitolarita && <LabelValueInfo label={"Titolarità"} text={scenarioTitolarita} />}
                  {scenarioMinutes && <LabelValueInfo label={"Minuti"} text={scenarioMinutes} />}
                  {scenarioRuolo && <LabelValueInfo label={"Ruolo"} text={scenarioRuolo} maxWidth="32%" noWrap={true} />}
                  {scenarioRigorista && <LabelValueInfo label={"Rigorista"} text={scenarioRigorista} />}
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flex: "1",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    <LabelValueInfo label={"Titolarità"} text={scenarioTitolarita} />
                    <LabelValueInfo label={"Ruolo"} text={scenarioRuolo} maxWidth="32%" noWrap={true} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: "12px",
                    }}
                  >
                    {scenarioMinutes && <LabelValueInfo label={"Minuti"} text={scenarioMinutes} />}
                    {scenarioRigorista && <LabelValueInfo label={"Rigorista"} text={scenarioRigorista} />}
                  </div>
                </div>
              )}
            </Collapse>
          )}
          {!standard && results && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: styleConstant.gap.medium,
                marginTop: styleConstant.margin.x1,
              }}
            >
              {results.map((result, index) => {
                return (
                  <div style={{ display: "flex", justifyContent: "space-between", flex: "1" }} key={index}>
                    <Typography style={scenarioInfo.chiave}>{result.key}</Typography>
                    <Typography style={scenarioInfo.valore}>{result.value}</Typography>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </CardActionArea>
      {standard && (
        <Collapse in={expanded} style={{ position: "relative" }}>
          <CardContent style={{ padding: styleConstant.padding.scenarioInfoCardContent, position: "relative" }}>
            <div style={{ display: "flex", flexFlow: "row wrap" }}>
              {Object.values(scenariosObject).map((scenario, index) => {
                let isNextSelect = false;
                let isCurrentSelect = false;
                const nextScenario = Object.values(scenariosObject)[index + 1];
                if (scenario.id == 2 || scenario.id == 3 || scenario.id == 5) isCurrentSelect = true;
                if (nextScenario && (nextScenario?.id == 2 || nextScenario?.id == 3 || nextScenario?.id == 5)) isNextSelect = true;
                if (isCurrentSelect && !isNextSelect)
                  return (
                    <React.Fragment key={index}>
                      <FormSkeleton key={index} label={scenario.nome}>
                        <InputSelect
                          inputSelectItem={scenario.listValues}
                          selectedValue={selectedValues[scenario.id].value}
                          onSelectChange={(newValue) => onScenarioSelect(scenarioGiornata, scenario.id, newValue)}
                        />
                      </FormSkeleton>
                      <div style={{ flexBasis: "100%", height: "0px" }}></div>
                    </React.Fragment>
                  );
                if (isCurrentSelect && isNextSelect) {
                  return (
                    <React.Fragment key={index}>
                      <FormSkeleton label={scenario.nome} style={{ marginRight: styleConstant.margin.x1 }}>
                        <InputSelect
                          inputSelectItem={scenario.listValues}
                          selectedValue={selectedValues[scenario.id].value}
                          onSelectChange={(newValue) => onScenarioSelect(scenarioGiornata, scenario.id, newValue)}
                        />
                      </FormSkeleton>
                    </React.Fragment>
                  );
                }
                if (!isCurrentSelect) {
                  return (
                    <React.Fragment key={index}>
                      <FormSkeleton label={scenario.nome}>
                        <InputRadio
                          radioItem={scenario.listValues}
                          selectedValue={selectedValues[scenario.id].value}
                          handleSelect={(newValue) => onScenarioSelect(scenarioGiornata, scenario.id, newValue)}
                        />
                      </FormSkeleton>
                      <div style={{ flexBasis: "100%", height: "0px" }}></div>
                    </React.Fragment>
                  );
                }
              })}
            </div>
          </CardContent>
        </Collapse>
      )}
    </Card>
  );
};

export default ScenarioInfo;

{
  /* <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: styleConstant.gap.small,
                position: "relative",
              }}
            >
              <FormSkeleton label="Titolarità">
                <InputRadio />
              </FormSkeleton>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: styleConstant.gap.small,
                }}
              >
                <FormSkeleton label="Minuti">
                  <InputSelect />
                </FormSkeleton>
                <FormSkeleton label="Ruolo">
                  <InputSelect />
                </FormSkeleton>
              </div>
              <FormSkeleton label="Rigorista">
                <InputRadio />
              </FormSkeleton>
            </div> */
}
