import {
  LOGIN_USER,
  LOGIN_USER_GOOGLE,
  LOGIN_USER_FACEBOOK,
  LOGIN_SUCCESS,
  EMAIL_NOT_VERIFIED,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  RESEND_EMAIL_VERIFICATION,
  LOGIN_ERROR,
  LOGIN_USER_APPLE,
} from "./actionTypes";

export const loginUser = (user) => {
  return {
    type: LOGIN_USER,
    payload: { user },
  };
};

export const loginUserApple = () => {
  return {
    type: LOGIN_USER_APPLE,
    payload: {},
  };
};

export const loginUserGoogle = () => {
  return {
    type: LOGIN_USER_GOOGLE,
    payload: {},
  };
};

export const loginUserFacebook = () => {
  return {
    type: LOGIN_USER_FACEBOOK,
    payload: {},
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginError = (error, errorType) => {
  return {
    type: LOGIN_ERROR,
    payload: { error, errorType },
  };
};

export const emailNotVerified = (emailNotVerified) => {
  return {
    type: EMAIL_NOT_VERIFIED,
    payload: emailNotVerified,
  };
};

export const resendEmailVerification = () => {
  return {
    type: RESEND_EMAIL_VERIFICATION,
    payload: {},
  };
};

export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
    payload: null,
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: error,
  };
};
