import React from 'react';
import { Stack } from '@mui/material';
import CustomTextField from './CustomTextField';
import CustomIcon from '../CustomIcon';
import { icons } from '../../../assets/styles/constant/general';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import { palette } from '../../../assets/styles/constant/palette';
import { Menu } from '@mui/material';
import { ClickAwayListener } from '@mui/material';
import { Fade } from '@mui/material';
import { Typography } from '@mui/material';
import DoneIcon from "@mui/icons-material/Done";
import CustomCheckBox from './CustomCheckBox';
import { brkRef } from '../../../helpers/util';
import { connect } from 'react-redux';
import { List, AutoSizer, CellMeasurerCache, CellMeasurer } from 'react-virtualized';

const CustomSearchBar = ({
	selectedData = [],
	nonSelectedData = [],
	containerStyle = {},
	onClose = () => {},
	onItemRemove = () => {},
	onItemAdd = () => {},
	onItemSelect = () => {},
	disabled = false,
	menuStyle = {},
	itemsData = {},
	multiple = true,
	showSearchIcon = true,
	selectedFantaTeamId,
	height = 50,
	onMenuOpen = ()=>{}
}) => {
		const cache = React.useRef(new CellMeasurerCache({
			fixedWidth: true,
			defaultHeight: 500,
		}));
		// Text input
		const [searchQuery, setSearchQuery] = React.useState('');
		const [textInputFocused, setTextInputFocued] = React.useState(false);
		// Menu
		const [menuOpen, setMenuOpen] = React.useState(false);
		const [filteredData, setFilteredData] = React.useState([]);
		const [nonFilteredData, setNonFilteredData] = React.useState([]);
		const [filteredSelected, setFilteredSelected] = React.useState(selectedData);
		const [filteredNonSelected, setFilteredNonSelected] = React.useState(nonSelectedData);
		const iconTopOffset = parseInt((height - 24) / 2);

		React.useEffect(() => {
			setFilteredSelected(selectedData);
		}, [selectedData]);

		React.useEffect(() => {
			setFilteredNonSelected(nonSelectedData)
		}, [nonSelectedData]);

		React.useEffect(() => {
			let sortedData = Object.values(itemsData).sort((a, b) => {
				return a.nome < b.nome ? -1 : 1;
			});
			sortedData = sortedData.map(el => el.id);
			setNonFilteredData(sortedData);
			setFilteredData(sortedData);
			if (!multiple) {
				let initSearchQuery = '';
				if (selectedData.length > 0) initSearchQuery = itemsData[selectedData[0]].nome;
				setSearchQuery(initSearchQuery);
			}
		}, [selectedData, nonSelectedData]);

		const applySearchQuery = (query) => {
			if (query === '') {
				setFilteredData(brkRef(nonFilteredData));
				return;
			}
			let newFilteredData = brkRef(nonFilteredData) || [];
			newFilteredData = newFilteredData.filter(id => {
				const label = itemsData[id].nome;
				const result = label.toLowerCase().trim().includes(query.toString().toLowerCase());
				return result;
			});
			setFilteredData(newFilteredData);
		}

		const onItemClick = (id, selected) => {
			if (multiple) {
				if (selected) onItemRemove(id);
				else onItemAdd(id);
				setSearchQuery('');
			} else {
				onItemSelect(id);
				handleClose();
			}
		}

		const onSearchQueryChange = (query) => {
			setSearchQuery(query);
			applySearchQuery(query);
		}

		const onFocus = () => {
			setTextInputFocued(true);
			handleOpen();
		}

		const handleOpen = (event) => {
			setMenuOpen(true);
			onMenuOpen(true);
		}

		const handleClose = () => {
			if (!textInputFocused) {
				setMenuOpen(false);
				onMenuOpen(false);
				if (!multiple) {
					let initSearchQuery = '';
					if (selectedData.length > 0) initSearchQuery = itemsData[selectedData[0]].nome;
					setSearchQuery(initSearchQuery);
					setFilteredData(brkRef(nonFilteredData));
				}
			}
		}

		const Item = ({
			data,
			style
		}) => {
			const text = data.nome;
			const isSelected = selectedData.indexOf(data.id.toString()) >= 0;
			return <div style={style}>
				<MenuItem onClick={() => onItemClick(data.id, isSelected)} disabled={!isSelected && selectedData.length >= 40}
					sx={{...commonMenuItemStyle, ...(isSelected ? selectedMenuItemStyle : {})}}>
					<Typography color={'#FFF'}>{text}</Typography>
					{
						multiple 
						? <CustomCheckBox squared checked={isSelected} onClick={() => {}} />
						: isSelected && <DoneIcon color="primary" className="icon-item" />
					}
				</MenuItem>
			</div>
		}

    return (
			<span style={{position: 'relative', ...containerStyle}}>
				<Stack height={height}
					position={'relative'}
					style={{opacity: disabled ? 0.5 : 1}}>
					<CustomTextField
						className={'custom-serach-bar'}
						content={searchQuery}
						disabled={disabled}
						autoComplete={'off'}
						onFocus={onFocus}
						onBlur={() => setTextInputFocued(false)}
						placeholder={disabled ? '' : 'Cerca giocatore'}
						onChange={onSearchQueryChange}
						style={{
							position: 'aboluste',
							top: 0, bottom: 0, right: 0, left: 0,
          		"& .MuiOutlinedInput-input": { paddingLeft: showSearchIcon ? "48px" : '13px' },
          			fontWeight:'700'
        		}} />
					{showSearchIcon && 
						<CustomIcon
							fontSize={'24px'}
							style={{position: 'absolute', left: 12, top: iconTopOffset}}
							icon={icons.MAGNIFYING_GLASS}/>}
				</Stack>
				<ClickAwayListener onClickAway={handleClose}>
					<Fade in={menuOpen} timeout={150}>
						<Stack zIndex={1}
							sx={{...menuStyle}}
							padding={'16px 0px 0px 8px'}
							borderRadius={'8px'}
							maxHeight={'300px'}
							height={'300px'}
							style={{background: palette.grey.grey_600, boxShadow:'0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'}}
							overflow='auto'
							top={height} right={0} left={0}
							position={'absolute'}>
								<AutoSizer>
									{({width, height}) => (
										<List 
											width={width}
											height={height}
											rowCount={filteredData.length}
											rowHeight={cache.current.rowHeight}
											deferredMeasurementCache={cache.current}
											rowRenderer={({key, index, parent, style}) => {
												//console.log('aa data', {key, index, parent, style})
												const playerId = filteredData[index];
												const playerObj = itemsData[playerId];
												return (
													<CellMeasurer key={key} 
														cache={cache.current} 
														columnIndex={0}
														rowIndex={index}
														parent={parent}>
														<Item data={playerObj} style={style} />
													</CellMeasurer>
												)
										}}
										/>
									)}
								</AutoSizer>
								{/* {filteredData.map((el, idx) => {
									const selected = selectedData.indexOf(el.toString()) >= 0;
									return <Item key={idx}
										fantaTeamRef={selectedFantaTeamId}
										id={el} 
										text={itemsData[el].nome} 
										onClick={() => onItemClick(el, selected)} 
										isSelected={selected}/>
								})} */}
						</Stack>
					</Fade>
				</ClickAwayListener>
			</span>
		)
}

const mapStateToProps = ({ Fanta }) => ({
	selectedFantaTeamId: Fanta.selectedFantaTeamId,
});

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CustomSearchBar);

/* const Item = React.memo(({
	id,
	isSelected = false,
	fantaTeamRef = null,
	text = '--',
	onClick = () => {},
}) => {
	//console.log(fantaTeamRef)
	return	<MenuItem onClick={() => onClick()}
			sx={{...commonMenuItemStyle, ...(isSelected ? selectedMenuItemStyle : {})}}>
			<Typography color={'#FFF'}>{text}</Typography>
			<CustomCheckBox squared checked={isSelected} onClick={() => {}}/>
		</MenuItem>
}, (prev, next) => {
	const idCondition = prev.id === next.id;
	const isSelectedCondition = prev.isSelected === next.isSelected;
	const teamRefCondition = prev.fantaTeamRef === next.fantaTeamRef
	return idCondition && isSelectedCondition && teamRefCondition;
}); */

const commonMenuItemStyle = {
	background: 'transparent',
	justifyContent: 'space-between',
	maxHeight: '40px',
	alignItems: 'center',
	margin: '4px',
	borderRadius: '8px',
	display: 'flex',
	'&:hover': {
		background: 'transparent',
	}
};

const selectedMenuItemStyle = {
	background: palette.grey.grey_400,
	'&:hover': {
		background: palette.grey.grey_500,
	}
};