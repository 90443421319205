import { Typography } from "@mui/material";
import React from "react";
import { icons } from "../../../assets/styles/constant/general";
import { palette } from "../../../assets/styles/constant/palette";
import { scenarioSectionStyle, styleConstant, textSecondaryStyle } from "../../../assets/styles/constant/styleConstants";
import { LabelValueInfo, ScenarioInfo } from "..";
import ContainedButton from "../buttons/ContainedButton";
import CustomIconButton from "../buttons/CustomIconButton";
import CustomIcon from "../CustomIcon";
import "../../../assets/styles/scrollbar.css";
import EmptySection from "../EmptySection";
import { connect } from "react-redux";
import { generateSelectListValues } from "../../../helpers/util";
import { loadProfile, setResult, updateCalculationObjectScenarios } from "../../../store/actions";
import {
  createSelectedDataTool1,
  createSelectedDataTool2,
  doCalculations,
  getTotalPriceCalculationTool1,
  getTotalPriceCalculationTool2,
} from "../../../service/calculation";
import CalculusLoading from "./CalculusLoading";
import CustomDialog from "../CustomDialog";
import TextButton from "../buttons/TextButton";

let _OPEN_SCENARIO = {};

const ScenariosSection = ({
  mobile,
  settings,
  selectedCalculus,
  updateCalculationScenarios,
  userAuthInfo,
  setResult,
  loadProfile,
  emptyScreen,
  allScenarios,
  scenariosGuides,
}) => {
  const [scenariosObject, setScenariosObject] = React.useState(null);
  const [calculusLoading, setCalculusLoading] = React.useState(false);
  const [isGuidesOpen, setIsGuidesOpen] = React.useState(false);

  React.useEffect(() => {
    if (selectedCalculus) {
      if (selectedCalculus.scenarios) {
        if (_OPEN_SCENARIO["calcId"] != selectedCalculus.id) _OPEN_SCENARIO = {};
        startupProcess(selectedCalculus.scenarios);
      } else {
        _OPEN_SCENARIO = {};
        startupProcessTool2(selectedCalculus.configuration);
      }
    } else {
      _OPEN_SCENARIO = {};
      setScenariosObject(null);
    }
  }, [selectedCalculus]);

  const setOpenScenario = (day) => {
    if (day > 0) {
      _OPEN_SCENARIO["calcId"] = selectedCalculus.id;
      _OPEN_SCENARIO["day"] = day;
      return;
    }
    _OPEN_SCENARIO = {};
  };

  const executeCalculus = async (calc) => {
    try {
      setCalculusLoading(true);
      const calcList = {};
      calcList[calc.id] = calc;
      let calcObjsArray = [];
      Object.values(calcList).map((calcObj) => {
        calcObjsArray.push(calcObj);
      });
      let prc;
      if (settings.tool == 1) prc = await getTotalPriceCalculationTool1(calcObjsArray, settings.subject);
      if (settings.tool == 2) prc = await getTotalPriceCalculationTool2(calcObjsArray, settings.subject);
      let selectedData;
      if (settings.tool == 1) {
        selectedData = createSelectedDataTool1(calcObjsArray, settings.subject);
      } else {
        selectedData = createSelectedDataTool2(calcObjsArray, settings.subject, userAuthInfo.uid);
      }
      const calculationResult = await doCalculations(selectedData, prc.amount, userAuthInfo.uid, settings.tool);
      handleResult(calculationResult);
      setCalculusLoading(false);
    } catch (error) {
      setCalculusLoading(false);
      throw error;
    }
  };

  const handleResult = (response) => {
    const actualResponse = [];
    Object.keys(response).map((key) => {
      const obj = { ...response[key], calcId: key };
      actualResponse.push(obj);
    });
    setResult(actualResponse);
  };

  const startupProcess = (calculusScenarios) => {
    let newScenariosObject = {};
    Object.values(calculusScenarios).map((day) => {
      const idDay = day.day;
      newScenariosObject[idDay] = { day: idDay, source: {}, selected: {} };
      //impostazione degli oggetti di configurazione degli oggetti di input
      const source = day.source;
      Object.values(source).map((scenario) => {
        const scenarioId = scenario.id;
        const scenarioNome = scenario.nome;
        let scenarioConfiguration = null;
        if (scenarioId == 1) scenarioConfiguration = setSelectorTitolarita(scenario.valori);
        if (scenarioId == 2) scenarioConfiguration = setSelectorMinuti(scenario.valori);
        if (scenarioId == 3) scenarioConfiguration = setSelectorRouolo(scenario.valori);
        if (scenarioId == 4) scenarioConfiguration = setSelectorRigorista(scenario.valori);
        if (scenarioId == 5) scenarioConfiguration = setSelectorGolSegnati(scenario.valori);
        if (!scenarioConfiguration) throw "SCENARIO NON VALIDO";
        const newSelected = getInitValues(scenarioConfiguration, scenarioId, calculusScenarios[idDay].selected);
        newScenariosObject[idDay].source[scenarioId] = {
          id: scenarioId,
          nome: scenarioNome,
          listValues: scenarioConfiguration,
        };
        newScenariosObject[idDay].selected[scenarioId] = { id: scenarioId, nome: scenarioNome, value: newSelected };
      });
    });
    setScenariosObject(newScenariosObject);
  };

  //MOMENTANEA PER MOSTRARE CARD GIORNATE
  const startupProcessTool2 = (calculusConfig) => {
    const calculusDays = calculusConfig.days;
    let newScenariosObject = calculusDays.map((day) => day.label);
    setScenariosObject(newScenariosObject);
  };

  const setSelectorMinuti = (scenarioMinuti) => {
    const newListValues = generateSelectListValues(scenarioMinuti, "nome", "id");
    if (!newListValues) return [];
    return newListValues;
  };

  const setSelectorRouolo = (scenarioRuolo) => {
    const newListValues = generateSelectListValues(scenarioRuolo, "nome", "id");
    if (!newListValues) return [];
    return newListValues;
  };

  const setSelectorGolSegnati = (scenarioGolSegnati) => {
    const newListValues = generateSelectListValues(scenarioGolSegnati, "nome", "id");
    if (!newListValues) return [];
    return newListValues;
  };

  const setSelectorTitolarita = (scenarioTitolarita) => {
    const newListValues = generateSelectListValues(scenarioTitolarita, "nome", "id");
    if (!newListValues) return [];
    return newListValues;
  };

  const setSelectorRigorista = (scenarioRigorista) => {
    const newListValues = generateSelectListValues(scenarioRigorista, "nome", "id");
    if (!newListValues) return [];
    return newListValues;
  };

  const getInitValues = (listValues, scenarioId, selectedValue) => {
    //Di default sono selezionati i primi valori della lista
    if (scenarioId == 2 || scenarioId == 3 || scenarioId == 5) {
      if (listValues.length == 0) return "";
      let newSelectedvalue = listValues[0];
      if (selectedValue && selectedValue[scenarioId]) {
        const valoreSelezionato = selectedValue[scenarioId];
        for (let val of listValues) {
          if (val.value == valoreSelezionato.value.value) {
            newSelectedvalue = val;
            break;
          }
        }
      }
      return newSelectedvalue;
    }
    if (listValues.length == 0) return 1;
    if (!selectedValue || !selectedValue[scenarioId]) return 1;
    return selectedValue[scenarioId].value;
  };

  const onScenarioSelect = (idDay, idScenario, newValue) => {
    const calculationObjectId = selectedCalculus.id;
    //! JSON.parse(JSON.stringify) elimina le proprietà dell'oggetto che sono undefinded. In questo caso la copia non avrà più le proprietà "additional"
    const newScenariosObject = JSON.parse(JSON.stringify(scenariosObject));
    const selectedCalculus_clone = JSON.parse(JSON.stringify(selectedCalculus));
    if (idScenario == 1 || idScenario == 4) {
      newScenariosObject[idDay].selected[idScenario].value = newValue;
      selectedCalculus_clone.scenarios[idDay].selected = JSON.parse(JSON.stringify(newScenariosObject[idDay].selected));
      // setScenariosObject(newScenariosObject);
      updateCalculationScenarios(calculationObjectId, selectedCalculus_clone.scenarios);
      return;
    }
    //per fare in modo che l'oggetto selezionato sia lo stesso presente dentro i listValues
    const scenariosListValues = newScenariosObject[idDay].source[idScenario].listValues;
    let valueFromListValues = null;
    for (let value of scenariosListValues) {
      if (value.value !== newValue.value) continue;
      valueFromListValues = value;
      break;
    }
    newScenariosObject[idDay].selected[idScenario].value = valueFromListValues;
    selectedCalculus_clone.scenarios[idDay].selected = JSON.parse(JSON.stringify(newScenariosObject[idDay].selected));
    updateCalculationScenarios(calculationObjectId, selectedCalculus_clone.scenarios);
    //setScenariosObject(newScenariosObject);
  };

  const disableExeButton = () => {
    if (selectedCalculus.result) return true;
    return false;
  };

  const ScenarioHeader = ({ title }) => (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: styleConstant.margin.x3 }}>
      <Typography variant="title" style={scenarioSectionStyle.title}>
        <CustomIcon icon={icons.HASHTAG} color={scenarioSectionStyle.title.color} fontSize={styleConstant.fontSize.x3} /> {title}
      </Typography>
      <div style={{ display: "flex", gap: styleConstant.gap.medium }}>
        {settings.subject == 1 && settings.tool == 1 && (
          <CustomIconButton icon={<CustomIcon icon={icons.QUESTION} color={palette.primary.main} />} onClick={() => setIsGuidesOpen(true)} />
        )}
        <ContainedButton
          text={`Esegui ${selectedCalculus.price}`}
          icon={<CustomIcon icon={icons.COINS} color={palette.black.main} />}
          onClick={() => executeCalculus(selectedCalculus)}
          disabled={disableExeButton()}
        />
      </div>
    </div>
  );

  const PlayerData = ({ playerName, playerTeam, statistic, greaterThan, operation = null, season = null }) => (
    <div style={{ display: "flex", flexDirection: "column", marginRight: styleConstant.margin.x3 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography variant="title" style={scenarioSectionStyle.player}>
            {playerName}
          </Typography>
          <Typography variant="smallTitle" style={scenarioSectionStyle.team}>
            {playerTeam}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {selectedCalculus?.result != null ? (
            <Typography variant={selectedCalculus?.result ? "title" : "bigTitle"} style={selectedCalculus?.result != null ? null : textSecondaryStyle}>
              {selectedCalculus?.result.risultato}
            </Typography>
          ) : (
            <div>
              <CustomIcon color={textSecondaryStyle.color} fontSize={styleConstant.fontSize.x3} icon={icons.MINUS} />
            </div>
          )}
          {settings.tool == 1 ? (
            <CustomIcon
              style={{
                marginLeft: styleConstant.margin.x1,
              }}
              color={selectedCalculus?.result != null ? null : textSecondaryStyle.color}
              fontSize={styleConstant.fontSize.x3}
              icon={icons.PERCENT}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: styleConstant.margin.x2,
        }}
      >
        {season ? (
          <>
            <LabelValueInfo label={"Statistica"} text={statistic} />
            <LabelValueInfo label={"Operazione"} text={operation} />
            <LabelValueInfo label={"Stagione"} text={season} />
          </>
        ) : (
          <>
            <LabelValueInfo label={"Statistica"} text={statistic} />
            <LabelValueInfo label={"Maggiore di"} text={greaterThan} />
          </>
        )}
      </div>
    </div>
  );

  const TeamData = ({ teamName, statistic, greaterThan, season = null, operation = null }) => (
    <div style={{ display: "flex", flexDirection: "column", marginRight: styleConstant.margin.x3 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          alignItems: "center",
        }}
      >
        <Typography variant="title" style={scenarioSectionStyle.player}>
          {teamName}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {selectedCalculus?.result != null ? (
            <Typography variant={selectedCalculus?.result ? "title" : "bigTitle"} style={selectedCalculus?.result != null ? null : textSecondaryStyle}>
              {selectedCalculus?.result.risultato}
            </Typography>
          ) : (
            <div>
              <CustomIcon color={textSecondaryStyle.color} fontSize={styleConstant.fontSize.x3} icon={icons.MINUS} />
            </div>
          )}
          {settings.tool == 1 ? (
            <CustomIcon
              style={{
                marginLeft: styleConstant.margin.x1,
              }}
              color={selectedCalculus?.result != null ? null : textSecondaryStyle.color}
              fontSize={styleConstant.fontSize.x3}
              icon={icons.PERCENT}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: styleConstant.margin.x2,
        }}
      >
        {season ? (
          <>
            <LabelValueInfo label={"Statistica"} text={statistic} />
            <LabelValueInfo label={"Operazione"} text={operation} />
            <LabelValueInfo label={"Stagione"} text={season} />
          </>
        ) : (
          <>
            <LabelValueInfo label={"Statistica"} text={statistic} />
            <LabelValueInfo label={"Maggiore di"} text={greaterThan} />
          </>
        )}
      </div>
    </div>
  );

  const ScenariosList = ({ children }) => (
    <div style={{ display: "flex", flex: 1, position: "relative" }}>
      <div
        style={{
          marginTop: styleConstant.margin.x3,
          overflowY: "overlay",
          paddingRight: styleConstant.padding.x3,
          flex: `${mobile && "1"}`,
          position: "absolute",
          top: "0px",
          bottom: mobile ? "-24px" : "0px",
          right: "0px",
          left: "0px",
        }}
        id="custom-scrollbar"
      >
        {children}
      </div>
    </div>
  );

  const getGuideContent = () => {
    const firstScenario = Object.values(scenariosObject)[0];
    const idScenarios = Object.keys(firstScenario.source);
    return (
      <div style={{ display: "flex", flexDirection: "column", padding: styleConstant.padding.x3, maxHeight: "50%", overflowY: "auto" }} id="custom-scrollbar">
        {idScenarios.map((id, idx) => (
          <React.Fragment key={idx}>
            <Typography variant="title" style={{ marginTop: idx !== 0 ? styleConstant.margin.x2 : 0 }}>
              {allScenarios[id].nome}
            </Typography>
            <Typography variant="smallTitle">{scenariosGuides[id].descrizione}</Typography>
          </React.Fragment>
        ))}
        <TextButton text="Chiudi" action={() => setIsGuidesOpen(false)} style={{ marginTop: styleConstant.margin.x2 }} />
      </div>
    );
  };

  return (
    <>
      {calculusLoading && <CalculusLoading />}
      {scenariosObject && settings.subject == 1 && settings.tool == 1 && (
        <CustomDialog
          open={isGuidesOpen}
          handleClose={() => setIsGuidesOpen(false)}
          backDropClickClose
          style={{ zIndex: 10004 }}
          title={"Serve aiuto?"}
          customContent
          icon={icons.LIFE_RING}
          titlePosition="center"
          roundCorners
        >
          {getGuideContent()}
        </CustomDialog>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          borderRadius: styleConstant.borderRadius.x2,
          flex: 1,
          padding: !mobile && styleConstant.padding.scenarioSection,
          marginTop: mobile && styleConstant.margin.x2,
        }}
      >
        {scenariosObject && selectedCalculus && !emptyScreen ? (
          <>
            {settings.tool == 1 ? (
              <>
                {settings.subject == 1 ? (
                  <>
                    <ScenarioHeader title={selectedCalculus.id} />
                    <PlayerData
                      playerName={selectedCalculus.configuration.player.label}
                      playerTeam={selectedCalculus.configuration.team.label}
                      statistic={selectedCalculus.configuration.statistic.label}
                      greaterThan={selectedCalculus.configuration.greaterThan.label}
                    />
                    <ScenariosList>
                      {Object.values(scenariosObject).map((scenario, index) => {
                        let scenarioTitolarita, scenarioRigorista;
                        let titolaritaSelectedId, rigoristaSelectedId;
                        if (scenario.selected["1"]?.value) titolaritaSelectedId = scenario.selected["1"].value;
                        if (scenario.selected["4"]?.value) rigoristaSelectedId = scenario.selected["4"].value;
                        if (scenario.selected["1"]?.value) {
                          for (let value of scenario.source["1"].listValues) {
                            if (titolaritaSelectedId == value.value) {
                              scenarioTitolarita = value.label;
                              break;
                            }
                          }
                        }
                        if (scenario.selected["4"]?.value) {
                          for (let value of scenario.source["4"].listValues) {
                            if (rigoristaSelectedId == value.value) {
                              scenarioRigorista = value.label;
                              break;
                            }
                          }
                        }
                        return (
                          <ScenarioInfo
                            key={index}
                            scenarioTitolarita={scenarioTitolarita}
                            scenarioRigorista={scenarioRigorista}
                            scenarioMinutes={scenario.selected["2"]?.value?.label}
                            scenarioRuolo={scenario.selected["3"]?.value?.label}
                            scenarioGiornata={scenario.day}
                            scenariosObject={scenario.source}
                            selectedValues={scenario.selected}
                            standard={settings.subject === 1 && settings.tool === 1}
                            mobile={mobile}
                            onScenarioSelect={onScenarioSelect}
                            setOpenScenario={(day) => setOpenScenario(day)}
                            openScenarioObj={_OPEN_SCENARIO}
                          />
                        );
                      })}
                    </ScenariosList>
                  </>
                ) : (
                  <>
                    <ScenarioHeader title={"1"} />
                    <TeamData
                      teamName={selectedCalculus.configuration.team.label}
                      statistic={selectedCalculus.configuration.statistic.label}
                      greaterThan={selectedCalculus.configuration.greaterThan.label}
                    />
                    <ScenariosList>
                      {Object.values(scenariosObject).map((scenario, index) => {
                        return <ScenarioInfo key={index} scenarioGiornata={scenario} standard={false} mobile={mobile} results={[]} />;
                      })}
                    </ScenariosList>
                  </>
                )}
              </>
            ) : (
              <>
                {settings.subject == 1 ? (
                  <>
                    <ScenarioHeader title={"1"} />
                    <PlayerData
                      playerName={selectedCalculus?.configuration?.player?.label}
                      playerTeam={selectedCalculus?.configuration?.team?.label}
                      statistic={selectedCalculus?.configuration?.statistic?.label}
                      season={selectedCalculus.configuration.season.label}
                      operation={selectedCalculus.configuration.operation.label}
                    />
                    <ScenariosList>
                      {Object.values(scenariosObject).map((scenario, index) => {
                        // scenariosObject in questo caso è una lista di giornate
                        let objArray = [];
                        if (selectedCalculus.result) {
                          objArray = {};
                          Object.keys(selectedCalculus.result.giornate).map((day) => {
                            objArray[day] = [];
                            const res = selectedCalculus.result.giornate[day].risultato;
                            objArray[day].push({ key: selectedCalculus.configuration.statistic.label, value: res });
                            Object.values(selectedCalculus.result.giornate[day].dettagli).map((detail) => {
                              objArray[day].push({ key: detail.scenario, value: detail.valore });
                            });
                          });
                        }
                        return <ScenarioInfo key={index} scenarioGiornata={scenario} standard={false} mobile={mobile} results={objArray[scenario]} />;
                      })}
                    </ScenariosList>
                  </>
                ) : (
                  <>
                    <ScenarioHeader title={"1"} />
                    <TeamData
                      teamName={selectedCalculus?.configuration?.team?.label}
                      statistic={selectedCalculus?.configuration?.statistic?.label}
                      season={selectedCalculus.configuration.season.label}
                      operation={selectedCalculus.configuration.operation.label}
                    />
                    <ScenariosList>
                      {Object.values(scenariosObject).map((scenario, index) => {
                        let objArray = [];
                        if (selectedCalculus.result) {
                          objArray = {};
                          Object.keys(selectedCalculus.result.giornate).map((day) => {
                            objArray[day] = [];
                            const res = selectedCalculus.result.giornate[day].risultato;
                            objArray[day].push({ key: selectedCalculus.configuration.statistic.label, value: res });
                            Object.values(selectedCalculus.result.giornate[day].dettagli).map((detail) => {
                              objArray[day].push({ key: detail.scenario, value: detail.valore });
                            });
                          });
                        }
                        return <ScenarioInfo key={index} scenarioGiornata={scenario} standard={false} mobile={mobile} results={objArray[scenario]} />;
                      })}
                    </ScenariosList>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <EmptySection
            icon={<CustomIcon icon={icons.PERSON_RUNNING} size="4x" fontSize={styleConstant.fontSize.x8} color={palette.white.main} />}
            title="Nessuno scenario"
            subtitle={`Seleziona ${settings.subject == 1 ? "un giocatore " : "una squadra "} della lista per visualizzare e impostare i suoi scenari`}
            style={{ flex: 1, marginRight: styleConstant.margin.x3 }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ CalculationWork, Profile, Calculation }) => ({
  settings: CalculationWork.settings,
  userAuthInfo: Profile.userAuthInfo,
  selectedCalculus: CalculationWork.selectedCalculus,
  emptyScreen: CalculationWork.showEmptyScreen,
  allScenarios: Calculation.scenarios,
  scenariosGuides: Calculation.guides.scenari,
});

const mapDispatchToProps = (dispatch) => ({
  updateCalculationScenarios: (id, scenariosObject) => dispatch(updateCalculationObjectScenarios(id, scenariosObject)),
  setResult: (result) => dispatch(setResult(result)),
  loadProfile: () => dispatch(loadProfile()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScenariosSection);
