import { createTheme, ThemeProvider } from "@mui/material";
import { buttonStyle } from "../../assets/styles/buttonStyle";
import { chip } from "../../assets/styles/chip";
import { formControlLabel } from "../../assets/styles/formControlLabel";
import { palette } from "../../assets/styles/constant/palette";
import { playerInfo } from "../../assets/styles/playerInfo";
import { rippleEffect } from "../../assets/styles/rippleEffect";
import { select } from "../../assets/styles/select";
import { Typography } from "../../assets/styles/typography";
import { textField } from "../../assets/styles/textField";
import { menuItem } from "../../assets/styles/menuItem";

const CustomThemeProvider = ({ children }) => {
  const theme = createTheme({
    palette: {
      ...palette,
    },
    components: {
      ...playerInfo.components,
      ...Typography.components,
      ...buttonStyle.components,
      ...rippleEffect.components,
      ...select.components,
      ...chip.components,
      ...formControlLabel.components,
      ...textField.components,
      ...menuItem.components,
    },
    typography: {
      fontFamily: ["Roboto"].join(","),
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
