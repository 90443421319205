import { DELETE_FANTA_TEAM, FETCH_FANTA_EVALUATE_GUIDE, FETCH_FANTA_EVALUATE_GUIDE_ERROR, FETCH_FANTA_EVALUATE_GUIDE_SUCCESS, FETCH_FANTA_EVALUATE_PRICE, FETCH_FANTA_EVALUATE_PRICE_ERROR, FETCH_FANTA_EVALUATE_PRICE_SUCCESS, FETCH_FANTA_EVALUATE_SETTINGS, FETCH_FANTA_EVALUATE_SETTINGS_ERROR, FETCH_FANTA_EVALUATE_SETTINGS_SUCCESS, FETCH_FANTA_PLAYERS, FETCH_FANTA_PLAYERS_ERROR, FETCH_FANTA_PLAYERS_SUCCESS, FETCH_FANTA_SETTINGS, FETCH_FANTA_SETTINGS_ERROR, FETCH_FANTA_SETTINGS_SUCCESS, FETCH_FANTA_STATISTICS, FETCH_FANTA_STATISTICS_ERROR, FETCH_FANTA_STATISTICS_SUCCESS, SELECT_FANTA_TEAM, SELECT_FANTA_TEAM_ERROR, SELECT_FANTA_TEAM_SUCCESS } from "./actionType";

export const fetchFantaSettings = () => {
  return {
		type: FETCH_FANTA_SETTINGS,
		payload: {}
	};
}

export const fetchFantaSettingsSuccess = (fantaSettings) => {
	return {
		type: FETCH_FANTA_SETTINGS_SUCCESS,
		payload: { fantaSettings }
	};
}

export const fetchFantaSettingsError = (error) => {
	return {
		type: FETCH_FANTA_SETTINGS_ERROR,
		payload: { error }
	};
}

export const fetchFantaPlayers = () => {
  return {
		type: FETCH_FANTA_PLAYERS,
		payload: {}
	};
}

export const fetchFantaPlayersSuccess = (fantaPlayers) => {
	return {
		type: FETCH_FANTA_PLAYERS_SUCCESS,
		payload: { fantaPlayers }
	};
}

export const fetchFantaPlayersError = (error) => {
	return {
		type: FETCH_FANTA_PLAYERS_ERROR,
		payload: { error }
	};
}

export const fetchFantaStatistics = () => {
	return {
		type: FETCH_FANTA_STATISTICS,
		payload: {}
	};
}
  
export const fetchFantaStatisticsSuccess = (fantaStatistics) => {
	return {
		type: FETCH_FANTA_STATISTICS_SUCCESS,
		payload: { fantaStatistics }
	};
}
  
export const fetchFantaStatisticsError = (error) => {
	return {
		type: FETCH_FANTA_STATISTICS_ERROR,
		payload: { error }
	};
}

export const selectFantaTeam = (fantaTeamId) => {
	return {
		type: SELECT_FANTA_TEAM,
		payload: { fantaTeamId }
	}
}

export const fetchFantaEvaluateGuide = () => {
	return {
		type: FETCH_FANTA_EVALUATE_GUIDE,
		payload: {}
	}
}

export const fetchFantaEvaluateGuideSuccess = (evaluatePlayerGuide) => {
	return {
		type: FETCH_FANTA_EVALUATE_GUIDE_SUCCESS,
		payload: { evaluatePlayerGuide }
	}
}

export const fetchFantaEvaluateGuideError = (error) => {
	return {
		type: FETCH_FANTA_EVALUATE_GUIDE_ERROR,
		payload: { error }
	}
}

export const fetchFantaEvaluatePrice = () => {
	return {
		type: FETCH_FANTA_EVALUATE_PRICE,
		payload: {}
	}
}

export const fetchFantaEvaluatePriceSuccess = (evaluatePlayerPrice) => {
	return {
		type: FETCH_FANTA_EVALUATE_PRICE_SUCCESS,
		payload: { evaluatePlayerPrice }
	}
}

export const fetchFantaEvaluatePriceError = (error) => {
	return {
		type: FETCH_FANTA_EVALUATE_PRICE_ERROR,
		payload: { error }
	}
}

export const fetchFantaEvaluateSettings = () => {
	return {
		type: FETCH_FANTA_EVALUATE_SETTINGS,
		payload: {}
	}
}

export const fetchFantaEvaluateSettingsSuccess = (evaluatePlayerSettings) => {
	return {
		type: FETCH_FANTA_EVALUATE_SETTINGS_SUCCESS,
		payload: { evaluatePlayerSettings }
	}
}

export const fetchFantaEvaluateSettingsError = (error) => {
	return {
		type: FETCH_FANTA_EVALUATE_SETTINGS_ERROR,
		payload: { error }
	}
}

