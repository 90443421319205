import { IconButton } from "@mui/material";
import React from "react";
import { palette } from "../../../assets/styles/constant/palette";
import { styleConstant } from "../../../assets/styles/constant/styleConstants";

const CustomIconButton = ({ icon, onClick, style = null, onMouseDown = null }) => {
  return (
    <IconButton size="medium" onClick={onClick} variant="icon-button" style={{ ...style }} onMouseDown={onMouseDown}>
      {icon}
    </IconButton>
  );
};

export default CustomIconButton;
