import { Modal, Typography } from "@mui/material";
import React from "react";
import { icons } from "../../assets/styles/constant/general";
import { palette } from "../../assets/styles/constant/palette";
import { styleConstant } from "../../assets/styles/constant/styleConstants";
import ContainedButton from "./buttons/ContainedButton";
import CustomIcon from "./CustomIcon";

const FreePurchaseModal = ({ isSuccess = false, isOpen, freeCredits = 0, nextFreeRewardTs = Date.now(), onClose, isTimeLeft = false, mobile = false }) => {
  const getDiffTime = () => {
    const diffTime = new Date(nextFreeRewardTs).getTime() - Date.now();
    const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
    return diffHours;
  };

  return (
    <Modal open={isOpen} style={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClose={onClose}>
      <div
        style={{
          maxWidth: !mobile ? "500px" : "80%",
          maxHeight: !mobile ? "500px" : "40%",
          minWidth: !mobile ? "400px" : "70%",
          minHeight: !mobile ? "300px" : "40%",
          position: "absolute",
          background: palette.grey.grey_400,
          borderRadius: styleConstant.borderRadius.x2,
          padding: styleConstant.padding.x3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isTimeLeft ? (
          <Typography variant="title" style={{ marginTop: "auto" }}>
            Potrai riscattare altri crediti tra {getDiffTime()} ore
          </Typography>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: isTimeLeft ? "center" : "" }}>
            <CustomIcon icon={isSuccess ? icons.CHECK : icons.XMARK} color={palette.primary.main} size="4x" />
            {isSuccess && <Typography variant="title">Hai riscattato {freeCredits} crediti! </Typography>}
            {isSuccess && (
              <Typography variant="smallTitle" style={{ marginTop: styleConstant.margin.x2 }}>
                Potrai riscattare altri crediti tra {getDiffTime()} ore
              </Typography>
            )}
            {!isSuccess && <Typography variant="title">Non è stato possibile riscattare i crediti</Typography>}
          </div>
        )}

        <ContainedButton style={{ marginTop: "auto" }} onClick={onClose} text="Chiudi" />
      </div>
    </Modal>
  );
};

export default FreePurchaseModal;
