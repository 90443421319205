import React from "react";
import { palette } from "../assets/styles/constant/palette";
import main_logo from "../assets/images/main_logo.svg";
import { Typography } from "@mui/material";
import FormSkeleton from "../core/components/input/FormSkeleton";
import CustomTextField from "../core/components/input/CustomTextField";
import { loginStyle, styleConstant } from "../assets/styles/constant/styleConstants";
import ContainedButton from "../core/components/buttons/ContainedButton";
import CustomIcon from "../core/components/CustomIcon";
import { regexNameAndSurname, regexEmail, regexPassword } from "../service/constant/regex";
import { icons } from "../assets/styles/constant/general";
import TextButton from "../core/components/buttons/TextButton";
import { useNavigate } from "react-router-dom";
import SocialButton from "../core/components/input/SocialButton";
import { connect } from "react-redux";
import { loginUserApple, loginUserFacebook, loginUserGoogle, registerUser } from "../store/actions";

const Registrazione = ({ mobile, onRegister, onLoginGoogle, onLoginApple, onLoginFacebook, errorCode }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [nome, setNome] = React.useState("");
  const [cognome, setCognome] = React.useState("");
  const [emailHelperText, setEmailHelperText] = React.useState(null);
  const [passwordHelperText, setPasswordHelperText] = React.useState(null);
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    console.log("Registration error", errorCode);
    if (errorCode && !disableButton()) {
      switch (errorCode) {
        case "auth/email-already-in-use":
          setEmailError(true);
          setEmailHelperText({ text: "Email non disponibile", color: "#f74848" });
          break;
        default:
          break;
      }
    }
  }, [errorCode]);

  const isEmailValid = (email) => {
    if (!regexEmail.test(email) && email.length !== 0) return false;
    return true;
  };

  const isPasswordValid = (password) => {
    if (!regexPassword.test(password) && password.length !== 0) return false;
    return true;
  };

  const onSubmit = () => {
    const emailCheck = isEmailValid(email);
    const passwordCheck = isPasswordValid(password);
    if (!emailCheck) {
      setEmailHelperText({ text: "Email invalida", color: "#f74848" });
      setEmailError(true);
    }
    if (!passwordCheck) {
      setPasswordHelperText({ text: "Password invalida", color: "#f74848" });
      setPasswordError(true);
    }
    if (emailCheck) {
      setEmailError(false);
      setEmailHelperText(null);
    }
    if (passwordCheck) {
      setPasswordError(false);
      setPasswordHelperText(null);
    }
    if (emailCheck && passwordCheck) {
      const user = { email, password, nome, cognome };
      onRegister(user);
    }
  };

  const disableButton = () => {
    // console.log(nome.length === 0 ||cognome.length === 0 || email.length === 0 || password.length === 0);
    return nome.length === 0 || cognome.length === 0 || email.length === 0 || password.length === 0;
  };

  const gotoLogin = () => {
    console.log("Ennnnnn");
    setEmailError(false);
    setPasswordError(false);
    setEmailHelperText(null);
    setPasswordHelperText(null);
    navigate("/login");
  };

  return (
    <div
      style={{
        background: palette.grey.grey_600,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        overflowY: "overlay",
      }}
      id="custom-scrollbar"
    >
      <div
        style={{
          maxHeight: "100vh",
          marginTop: "auto",
          marginBottom: "auto",
          width: mobile && "100vw",
        }}
      >
        <div style={mobile ? formContainerMobileStyle : formContainerDesktopStyle}>
          <img src={main_logo} style={{ maxHeight: "150px", maxWidth: "150px", alignSelf: "center" }} />
          <Typography style={{ ...loginStyle, marginTop: styleConstant.margin.x7 }}>Registrazione</Typography>
          <FormSkeleton label="Nome" style={{ marginTop: styleConstant.margin.x4, flex: "0" }}>
            <CustomTextField onChange={(newNome) => setNome(newNome)} />
          </FormSkeleton>
          <FormSkeleton label="Cognome" style={{ marginTop: styleConstant.margin.x2, flex: "0" }}>
            <CustomTextField onChange={(newCognome) => setCognome(newCognome)} />
          </FormSkeleton>
          <FormSkeleton label="Email" style={{ marginTop: styleConstant.margin.x2, flex: "0" }} helperText={emailHelperText}>
            <CustomTextField content={email} onChange={(email) => setEmail(email)} className={emailError ? "error" : ""} type="email" />
          </FormSkeleton>
          <FormSkeleton label="Password" style={{ marginTop: styleConstant.margin.x2, flex: "0" }} helperText={passwordHelperText}>
            <CustomTextField password content={password} onChange={(password) => setPassword(password)} className={passwordError ? "error" : ""} />
          </FormSkeleton>
          <ContainedButton text={"Registrati"} style={{ marginTop: styleConstant.margin.x5, height: "50px" }} onClick={onSubmit} disabled={disableButton()} />
          <Typography style={{ color: "grey", fontWeight: "bold", margin: "auto", marginTop: styleConstant.margin.x3 }}>Oppure</Typography>
          <SocialButton
            icon={<CustomIcon icon={icons.GOOGLE} color={palette.black.main} />}
            text={"Continua con Google"}
            style={{ marginTop: styleConstant.margin.x3, height: "50px" }}
            onClick={onLoginGoogle}
          />
          <SocialButton
            icon={<CustomIcon icon={icons.FACEBOOK} color={palette.black.main} />}
            text={"Continua con Facebook"}
            style={{ marginTop: styleConstant.margin.x2, height: "50px" }}
            onClick={onLoginFacebook}
          />
          <SocialButton
            icon={<CustomIcon icon={icons.APPLE} color={palette.black.main} />}
            text={"Continua con Apple"}
            style={{ marginTop: styleConstant.margin.x3, height: "50px" }}
            onClick={onLoginApple}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: styleConstant.margin.x1,
          }}
        >
          <Typography variant="smallTitle" style={loginStyle.outerText}>
            Hai già un account?
          </Typography>
          <TextButton text={"Accedi"} action={gotoLogin} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ Register }) => ({
  errorCode: Register.registrationError,
});

const mapDispatchToProps = (dispatch) => ({
  onRegister: (user) => dispatch(registerUser(user)),
  onLoginGoogle: () => dispatch(loginUserGoogle()),
  onLoginFacebook: () => dispatch(loginUserFacebook()),
  onLoginApple: () => dispatch(loginUserApple()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registrazione);

const formContainerMobileStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: "0px 20px",
};
const formContainerDesktopStyle = {
  minWidth: "500px",
  minheight: "600px",
  background: palette.grey.grey_500,
  display: "flex",
  flexDirection: "column",
  padding: styleConstant.padding.x5,
  borderRadius: styleConstant.borderRadius.x2,
};
