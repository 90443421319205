import {
  LOAD_PROFILE,
  LOAD_PROFILE_SUCCESS,
  LOAD_PROFILE_ERROR,
  EDIT_PROFILE,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR,
  DELETE_PROFILE,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_ERROR,
  LOAD_APP_DATA,
  LOAD_APP_DATA_SUCCESS,
  LOAD_APP_DATA_ERROR,
  UPDATE_PURCHASE_DATA,
  RELOAD_USER_APP_DATA_SUCCESS,
  RELOAD_USER_FANTA_TEAMS_SUCCESS,
  RELOAD_USER_CREDITS_SUCCESS,
} from "./actionTypes";

const initialState = {
  userAuthInfo: null,
  loadingProfile: true,
  userProfile: null,
  loadProfileError: null,
  loadingEditProfile: false,
  editProfileSuccess: null,
  editProfileError: null,
  loadingDeleteProfile: false,
  deleteProfileError: null,
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_PROFILE:
      state = {
        ...state,
        loadProfileError: null,
        loadingProfile: true,
        userAuthInfo: action.payload.userAuthInfo, //TODO: non credo che serva più trasportarti la userAuthInfo in quanto risiede all'interno di userProfile
      };
      break;
    case LOAD_PROFILE_SUCCESS:
      state = {
        ...state,
        loadingProfile: false,
        loadProfileError: null,
        userProfile: action.payload.userProfile,
      };
      break;
    case LOAD_PROFILE_ERROR:
      state = {
        ...state,
        loadingProfile: false,
        loadProfileError: action.payload.error,
      };
      break;
    case EDIT_PROFILE:
      state = {
        ...state,
        editProfileError: "",
        loadingEditProfile: true,
      };
      break;
    case EDIT_PROFILE_SUCCESS:
      state = {
        ...state,
        loadingEditProfile: false,
        editProfileSuccess: true,
        editProfileError: null,
        userProfile: {
          ...state.userProfile,
          ...action.payload.userProfile,
        },
      };
      break;
    case EDIT_PROFILE_ERROR:
      state = {
        ...state,
        loadingEditProfile: false,
        editProfileSuccess: false,
        editProfileError: action.payload.error,
      };
    case DELETE_PROFILE:
      state = {
        ...state,
        deleteProfileError: null,
        loadingDeleteProfile: true,
      };
      break;
    case DELETE_PROFILE_SUCCESS:
      state = {
        ...state,
        loadingDeleteProfile: false,
      };
      break;
    case DELETE_PROFILE_ERROR:
      state = {
        ...state,
        loadingDeleteProfile: false,
        deleteProfileError: action.payload.error,
      };
      break;
    case UPDATE_PURCHASE_DATA:
      state = {
        ...state,
        userProfile: {
          ...state.userProfile,
          appData: {
            ...state.userProfile.appData,
            credit: action.payload.credit,
            noAdsTs: action.payload.noAdsTs,
            payments_historycal: action.payload.payments_historycal,
            freeRewardTs: action.payload.freeRewardTs,
            cumulativeFreeCoins: action.payload.cumulativeFreeCoins,
            oldestFreeRewardTs: action.payload.oldestFreeRewardTs,
          },
        },
      };
      break;
      case RELOAD_USER_FANTA_TEAMS_SUCCESS:
        state = {
          ...state,
          userProfile: {
            ...state.userProfile,
            appData: {
              ...state.userProfile.appData,
              fantaTeams: action.payload.fantaTeams
            }
          }
        }
        break;
      case RELOAD_USER_CREDITS_SUCCESS:
        state = {
          ...state,
          userProfile: {
            ...state.userProfile,
            appData: {
              ...state.userProfile.appData,
              credit: action.payload.credit
            }
          }
        }
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
