import { Stack, Typography } from '@mui/material';
import TextButton from '../../../core/components/buttons/TextButton';
import CustomIcon from '../../../core/components/CustomIcon';
import { icons } from '../../../assets/styles/constant/general'
import CustomIconButton from '../../../core/components/buttons/CustomIconButton';
import React from 'react';
import { palette } from '../../../assets/styles/constant/palette';
import TeamsListSection from './TeamsListSection';
import TeamsConfigSection from './TeamsConfigSection';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectFantaTeam } from '../../../store/fanta/actions';

const TeamConfig = (props) => {
  const navigate = useNavigate();
  const { userAppData, selectedFantaTeamId } = props;
  const fantaTeams = userAppData?.fantaTeams;

  React.useEffect(() => {
    if (fantaTeams && !selectedFantaTeamId) {
      const firstFantaTeam = Object.values(fantaTeams)[0];
      props.selectFantaTeamId(firstFantaTeam.id)
    }
  }, []);

  const renderBackButton = () => (
    <Stack 
      alignItems={'self-start'}>
      <TextButton iconLeft
        action={() => navigate('/fantacalcio')}
        text={'Torna al Fantacalcio'} 
        icon={<CustomIcon icon={icons.ARROW_LEFT}/>} />
    </Stack>
  )

  const renderHeader = () => (
    <Stack 
      direction={'column'}
      justifyContent={'flex-start'}>
        <Typography variant='bigTitle'>Gestione rose</Typography>
        <Typography 
          variant='smallTitle'
          fontWeight={'800'}
          color={palette.grey.grey_100}>
            Crea - Modifica - Elimina
        </Typography>
    </Stack>
  )

  return (
    <Stack flex={1}
      paddingY={'24px'}
      height={'100%'}
      paddingX={'32px'}>
      {renderBackButton()}
      {renderHeader()}
      <Stack columnGap={'32px'}
        height={'100%'}
        marginTop={'16px'}
        direction={'row'}>
        <Stack 
          height={'100%'}
          width={'30%'}>
          <TeamsListSection />
        </Stack>
        <Stack flex={1}>
          <TeamsConfigSection />
        </Stack>
      </Stack>
    </Stack>
  )
}

const mapStateToProps = ({ Fanta, Profile }) => ({
  selectedFantaTeamId: Fanta.selectedFantaTeamId,
  userAppData: Profile.userProfile.appData,
});

const mapDispatchToProps = dispatch => ({
  //loadFantaPlayers: () => dispatch(fetchFantaPlayers()),
  //loadFantaSatistics: () => dispatch(fetchFantaStatistics()),
  selectFantaTeamId: (fantaTeamId) => dispatch(selectFantaTeam(fantaTeamId)), 
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamConfig);