import React from "react";
import { connect } from "react-redux";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import AppLayout from "../core/layout/AppLayout";
import CalculatorPage from "../screens/CalculatorPage";
import LoginPage from "../screens/LoginPage";
import RegistrazionePage from "../screens/RegistrazionePage";
import TermsAndConditionsPage from "../screens/TermsAndConditionsPage";
import ProfiloPage from "../screens/ProfiloPage";
import InfoPage from "../screens/InfoPage";
import DashboardPage from "../screens/DashboardPage";
import Info from "../screens/Info";
import FantaStatistics from "../screens/Fantacalcio/FantaStatistics";
import TeamsConfig from "../screens/Fantacalcio/TeamsManger";
import Tipster from "../screens/Tipster";

const NavigationHandler = (props) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const locationData = window.location;
    const baseUrl = locationData.protocol + "//" + locationData.host;
    const urlPath = "/" + locationData.pathname.split("/")[1];
    console.log(urlPath);
    let nextRoute = "/";
    if (!props.userProfile) {
      if (urlPath == "/login" || urlPath == "/register") {
        const newUrl = locationData.href.slice(baseUrl.length);
        nextRoute = newUrl;
      }
    }
    if (props.userProfile && props.userProfile?.appData?.termsAndConditions?.accepted) {
      if (urlPath == "/calculator" || urlPath == "/dashboard" || urlPath == "/profilo" || urlPath == "/informazioni" || urlPath == "/fantacalcio" || urlPath == "/fantacalcio_rose" || urlPath == "/tipster") {
        const newUrl = locationData.href.slice(baseUrl.length);
        nextRoute = newUrl;
      }
    }
    if (props.userProfile && !props.userProfile?.appData?.termsAndConditions?.accepted) {
      if (urlPath == "/terms_and_conditions") {
        const newUrl = locationData.href.slice(baseUrl.length);
        nextRoute = newUrl;
      }
    }
    navigate(nextRoute);
  }, [props.userProfile]);

  return (
    <Routes>
      {!props.userProfile && (
        <>
          <Route path="/" element={<Outlet />}>
            <Route index element={<Navigate to="/login" />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegistrazionePage />} />
          </Route>
        </>
      )}
      {props.userProfile && !props.userProfile?.appData?.termsAndConditions?.accepted && (
        <>
          <Route path="/" element={<Outlet />}>
            <Route index element={<Navigate to="/terms_and_conditions" />} />
            <Route path="/terms_and_conditions" element={<TermsAndConditionsPage />} />
          </Route>
        </>
      )}
      {props.userProfile && props.userProfile?.appData?.termsAndConditions?.accepted && (
        <>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<Navigate to="/dashboard" />} />
            <Route path="/calculator/*" element={<CalculatorPage />} />
            <Route path="/fantacalcio" element={<FantaStatistics />} />
            <Route path="/fantacalcio_rose" element={<TeamsConfig />} />
            <Route path="/tipster" element={<Tipster />} />
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/profilo" element={<ProfiloPage />} />
            <Route path="/informazioni" element={<InfoPage />} />
          </Route>
        </>
      )}
      <Route
        path="/payment_accepted"
        element={
          <div>
            <h1>payment_accepted</h1>
          </div>
        }
      />
      <Route
        path="/payment_error"
        element={
          <div>
            <h1>payment_error</h1>
          </div>
        }
      />
    </Routes>
  );
};

const mapStateToProps = ({ Profile }) => ({
  userProfile: Profile.userProfile,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(NavigationHandler);
