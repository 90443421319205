import React from "react";
import CalculatorHeader from "./CalculatorDesktopHeader";
import CalculatorSettings from "./CalculatorSettings";
import { palette } from "../../../../assets/styles/constant/palette";
import ConfigSection from "../../../components/CalculatorSections/ConfigSection";
import ScenariosSection from "../../../components/CalculatorSections/ScenariosSection";
import CalculationSection from "../../../components/CalculatorSections/CalculationSection";
import { styleConstant } from "../../../../assets/styles/constant/styleConstants";
import { connect } from "react-redux";
import { resetCalculator } from "../../../../store/actions";

const CalculatorDesktopLayout = (props) => {
  const [settingsOpen, setSettingsOpen] = React.useState(false);

  const onResetClick = () => {
    console.log("RESET CLICK");
    props.resetCalculator();
  };

  const calculatorSections = () => {
    return (
      <div
        style={{
          display: "flex",
          gap: styleConstant.gap.big,
          // marginTop: styleConstant.margin.x3,
          // flex: '1 1',
          height: "100%",
          overflowY: "auto",
        }}
      >
        <ConfigSection />
        <div
          style={{
            display: "flex",
            flex: 1,
            background: palette.grey.grey_500,
            borderRadius: styleConstant.borderRadius.x2,
            maxHeight: "100%",
          }}
        >
          <CalculationSection />
          <ScenariosSection />
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        padding: `${styleConstant.padding.x3} ${styleConstant.padding.x4}`,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <CalculatorHeader
            title={"Calcolatore"}
            subtitle={props.tools[props.settings.tool].nome + " - " + props.subjects[props.settings.subject].nome}
            action={onResetClick}
          />
        </div>
        <div
          style={{
            marginTop: "20px",
            flex: "1 1",
            width: "100%",
            overflowY: "auto",
          }}
        >
          {calculatorSections()}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ CalculationWork, Calculation }) => ({
  settings: CalculationWork.settings,
  subjects: Calculation.subjects,
  tools: Calculation.tools,
});

const mapDispatchToProps = (dispatch) => ({
  resetCalulator: () => dispatch(resetCalculator()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalculatorDesktopLayout);
