import { ButtonBase, Modal, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../../assets/styles/constant/palette";
import { creditPurchase, styleConstant, textSecondaryStyle } from "../../../assets/styles/constant/styleConstants";
import ContainedButton from "../buttons/ContainedButton";
import TextButton from '../buttons/TextButton';
import CustomTextField from '../input/CustomTextField';
import PurchaseCard from "./PurchaseCard";
import "../../../assets/styles/scrollbar.css";
import SumUp from "./SumUp";
import CustomIconButton from "../buttons/CustomIconButton";
import CustomIcon from "../CustomIcon";
import { icons } from "../../../assets/styles/constant/general";
import { getPlans, getUserCredits } from "../../../service/calculationData";
import { connect } from "react-redux";
import { getFirebaseBackend } from "../../../service/common/firebase";
import { editProfile, loadAppData, updatePurchaseUserData } from "../../../store/actions";
import { getUserAppData } from "../../../service/userProfile";
import FreePurchaseModal from "../FreePurchaseModal";
import { freeRewardPurchase } from "../../../service/userCredits";
import { Stack } from "@mui/material";
import FormSkeleton from "../input/FormSkeleton";
import { brkRef } from "../../../helpers/util";
import { fontWeight } from "@mui/system";
import { CircularProgress } from "@mui/material";

const firebaseBackend = getFirebaseBackend();

const CreditPurchase = ({
  open,
  handleClose,
  title,
  subtitle,
  mobile = false,
  userEmail,
  userUid,
  updatePurchaseData,
  plans,
  freeRewardTs,
  oldestFreeRewardTs,
  cumulativeFreeCoins,
}) => {
  const idFreePlan = "202205101121";
  const [type, setType] = React.useState("credits"); // "credits" o "ads"
  const [selected, setSelected] = React.useState(null);
  const [sumUp, setSumUp] = React.useState(false);
  const [plansObject, setPlansObject] = React.useState(null);
  const [purchaseCompleted, setPurchaseCompleted] = React.useState(false);
  const [purchaseError, setPurchaseError] = React.useState(false);
  const [purchaseLoadin, setPurchaseLoading] = React.useState(false);

  // Codici sconto
  const [promoCodeInputVisible, setPromoCodeInputVisible] = React.useState(false);
  const [promoCodeLoading, setPromoCodeLoading] = React.useState(false);
  const [activePromoCode, setActivePromoCode] = React.useState(null);
  const [promoCodeError, setPromoCodeError] = React.useState(null);
  const [promoCode, setPromoCode] = React.useState('');

  const [isTimeLeft, setIsTimeLeft] = React.useState(false);
  const [freePurchaseCompleted, setFreePurchaseCompleted] = React.useState(false);
  const [freePurchaseSuccess, setFreePurchaseSuccess] = React.useState(false);

  const textFieldRef = React.useRef();

  React.useEffect(async () => {
    setPlansObject(brkRef(plans));
  }, []);

  React.useEffect(() => {
    if (!plansObject) return;
    const defaultSelected = getDefaultSelected(type);
    setSelected(defaultSelected);
  }, [type]);

  React.useEffect(() => {
    if (plansObject && !selected) {
      const newSelected = getDefaultSelected(type);
      setSelected(newSelected);
    }
  }, [plansObject]);

  const getDefaultSelected = (type) => {
    let selectedId;
    const plansObjectValues = Object.values(plansObject);
    for (let obj of plansObjectValues) {
      if (type == "credits" && !obj?.noAdsTime && obj.highlighted) {
        selectedId = obj.id;
        break;
      }
      if (type == "ads" && obj?.noAdsTime && obj.highlighted) {
        selectedId = obj.id;
        break;
      }
    }
    return selectedId;
  };

  const closeModal = () => {
    handleClose();
    setIsTimeLeft(false);
    setFreePurchaseCompleted(false);
    setFreePurchaseSuccess(false);
    setPurchaseLoading(false);
    discardPromoCode();
    setPromoCodeInputVisible(false);
    if (sumUp) setSumUp(false);
  };

  const onTypeSet = (newType) => {
    setSelected(null);
    setType(newType);
  };

  const onConfirm = () => {
    if (selected === "202205101121") {
      getFreeReward();
    } else {
      setSumUp(true);
    }
  };

  const disableButton = () => {
    return !selected;
  };

  const getFreeReward = async () => {
    try {
      if (freeRewardTs > Date.now()) {
        setIsTimeLeft(true);
        setFreePurchaseCompleted(true);
        return;
      }
      let freePlan = null;
      for (let planId in plans) {
        if (plans[planId].title === "Free") {
          freePlan = plans[planId];
          break;
        }
      }
      setPurchaseLoading(true);
      const response = await freeRewardPurchase(userUid, freePlan);
      if (response.data.outcome === "purchase success") {
        console.log("Acquisto avvenuto con successo");
        const userAppData = await getUserAppData(userUid);
        updatePurchaseData(
          userAppData.credit,
          userAppData.noAdsTs,
          userAppData.payments_historycal,
          userAppData.freeRewardTs,
          userAppData.cumulativeFreeCoins,
          userAppData.oldestFreeRewardTs
        );
        setFreePurchaseCompleted(true);
        setFreePurchaseSuccess(true);
        setPurchaseLoading(false);
      }
    } catch (error) {
      setFreePurchaseSuccess(false);
      setFreePurchaseCompleted(true);
      setPurchaseLoading(false);
      console.log(error);
    }
  };

  const isFreeRewardPeriodPassed = () => {
    const freeCoinsPurchasePeriod = plans[idFreePlan].freeCoinsPurchasePeriod;
    return Date.now() - freeCoinsPurchasePeriod > oldestFreeRewardTs;
  };

  const isFreeCoinsButtonDisabled = () => {
    const maxPurchasableFreeCoins = plans[idFreePlan].maxPurchasableFreeCoins;
    const freeCoinsPurchasePeriod = plans[idFreePlan].freeCoinsPurchasePeriod;
    const planCoins = plans[idFreePlan].coins;
    if (typeof freeCoinsPurchasePeriod != "number" || typeof maxPurchasableFreeCoins != "number") return true;
    return !isFreeRewardPeriodPassed() && cumulativeFreeCoins + planCoins > maxPurchasableFreeCoins;
  };

  const getFreeCoinsButtonText = () => {
    if (purchaseLoadin) return "Caricamento...";
    const freeCoinsPurchasePeriod = plans[idFreePlan].freeCoinsPurchasePeriod;
    const remainingDays = (freeCoinsPurchasePeriod - (Date.now() - oldestFreeRewardTs)) / 86400000;
    const formattedDays = Math.ceil(remainingDays);
    if (isFreeCoinsButtonDisabled()) return "Potrai riscattare i tuoi crediti tra " + formattedDays + " giorni";
    return plans[idFreePlan].coins;
  };

  const applyPromoCode = async(promoCode) => {
    try {
      setPromoCodeLoading(true);
      const cfPayload = {uid: userUid, promoCode};
      const cfRes = await firebaseBackend.callCloudFunction('checkPromoCodeValidity', cfPayload);
      const outcomeObject = cfRes.data;
      console.log('cf response', outcomeObject);
      if (outcomeObject.outcome === 'success') {
        setActivePromoCode({
          code: promoCode,
          type: outcomeObject.type,
          value: outcomeObject.value,
        });
        updatePlansObject(outcomeObject);
      }else {
        handleInvalidPromoCode(outcomeObject.error);
      }
      //textFieldRef.current.blur();
      //window.focus();
      //document.getElementById('scriptedd').focus();
      //document.activeElement.blur()
      setPromoCodeLoading(false);
    } catch (error) {
      console.log('ERRORE CF', error);
      setPromoCodeLoading(false);
    }
  }

  const getDiscountedPrice = (planPrice, percentage = 0) => {
    const discount = planPrice * (percentage / 100);
    const discountedPrice = parseFloat((planPrice - discount).toFixed(2));
    return discountedPrice;
  }

  const updatePlansObject = (promoCodeObj) => {
    console.log(promoCodeObj)
    let newPlansObject = brkRef(plansObject);
    Object.values(plansObject).map((plan) => {
      const promoCodePlans = promoCodeObj?.plans || [];
      if (promoCodePlans.indexOf(plan.id) >= 0) {
        const updatedPlan = applyPromoCodeChanges(plan, promoCodeObj);
        console.log('UPDATED PLAN', updatedPlan);
        newPlansObject[plan.id] = updatedPlan;
      }
    });
    return setPlansObject(newPlansObject)
  }

  const applyPromoCodeChanges = (plan, promoCodeObj) => {
    let newPlan = brkRef(plan);
    let promoData = null;
    const promoType = promoCodeObj.type;
    if (promoType === 'price') {
      const finalPrice = getDiscountedPrice(plan.price, promoCodeObj.value);
      promoData = { price: finalPrice, coins: plan.coins, promoType };
    }else {
      const coinsIncrement = promoCodeObj.value;
      const finalCoins = plan.coins + coinsIncrement;
      promoData = { price: plan.price, coins: finalCoins, promoType};
    }
    newPlan.isPromoActive = true;
    newPlan.promoData = promoData;
    return newPlan;
  }

  const handleInvalidPromoCode = (error) => {
    setPromoCodeError(error);
    setActivePromoCode(null);
  }

  const discardPromoCode = () => {
    setPromoCode('');
    setPlansObject(brkRef(plans));
    setPromoCodeError(null);
    setActivePromoCode(null);
  }

  const createOrder = (data, actions) => {
    const planObject = plansObject[selected];
    const packetId = planObject.id;
    const packetTitle = planObject.title;
    const promoCode = plansObject[selected]?.isPromoActive 
      ? activePromoCode.code
      : null;
    const unitPrice = planObject.isPromoActive 
      ? planObject.promoData.price
      : planObject.price;
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: unitPrice,
            breakdown: {
              item_total: {
                currency_code: "EUR",
                value: unitPrice,
              },
            },
          },
          items: [
            {
              name: packetTitle,
              unit_amount: {
                currency_code: "EUR",
                value: unitPrice,
              },
              quantity: "1",
            },
          ],
          custom_id: packetId,
          description: promoCode,
        },
      ],
      application_context: {
        brand_name: "Football Vision",
        locale: "it-IT",
        shipping_preference: "NO_SHIPPING",
        user_action: "PAY_NOW",
        payment_method: {
          payer_selected: "PAYPAL",
          payee_preferred: "IMMEDIATE_PAYMENT_REQUIRED",
        },
      },
    });
  };

  const onApprove = async (data, actions) => {
    console.log("On approve data", data);
    const { orderID, facilitatorAccessToken } = data;
    const cfData = {
      tsPayment: new Date().getTime(),
      orderID: orderID,
      accessToken: facilitatorAccessToken,
      uid: userUid,
    };
    console.log("CF DATA: ", cfData);
    setPurchaseLoading(true);
    firebaseBackend.callCloudFunction("paypalPurchaseProduction", cfData).then(async (res) => {
      if (res.data == "COMPLETED") {
        const userAppData = await getUserAppData(userUid);
        updatePurchaseData(userAppData.credit, userAppData.noAdsTs, userAppData.payments_historycal, userAppData.freeRewardTs);
        discardPromoCode();
        setPurchaseLoading(false);
        setPurchaseCompleted(true);
        setPromoCodeInputVisible(false);
      } else {
        setPurchaseLoading(false);
        setPurchaseError(true);
        discardPromoCode();
        setPromoCodeInputVisible(false);
      }
    });
  };

  const getOrderTotal = () => {
    let total = plansObject[selected].price;
    const isPromoActive = plansObject[selected].isPromoActive;
    if (activePromoCode && activePromoCode.type === 'price' && isPromoActive) {
      total = getDiscountedPrice(plansObject[selected].price, activePromoCode.value);
    }
    return total;
  }

  const renderNavBar = () => (
    <div
      style={{
        display: "flex",
        background: palette.grey.grey_300,
        height: "50px",
        borderRadius: styleConstant.borderRadius.x1,
        padding: "5px",
        gap: "5px",
        marginTop: styleConstant.margin.x4,
        marginRight: mobile ? styleConstant.margin.x3 : styleConstant.margin.x5,
      }}
    >
      <ButtonBase
        style={{
          display: "flex",
          flex: "1",
          background: type === "credits" ? palette.grey.grey_200 : "transparent",
          borderRadius: styleConstant.borderRadius.x1,
          justifyContent: "center",
          alignItems: "center",
        }}
        // onClick={() => onTypeSet("credits")}
      >
        <Typography style={{ ...creditPurchase.navMenu, color: type === "credits" ? palette.primary.main : "#FFF" }}>Standard</Typography>
      </ButtonBase>
      {/* <ButtonBase
        style={{
          display: "flex",
          flex: "1",
          background: type === "ads" ? palette.grey.grey_200 : "transparent",
          borderRadius: styleConstant.borderRadius.x1,
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => onTypeSet("ads")}
      >
        <Typography style={{ ...creditPurchase.navMenu, color: type === "ads" ? palette.primary.main : "#FFF" }}>Rimozione pubblicità</Typography>
      </ButtonBase> */}
    </div>
  );

  const Header = () => (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: mobile ? styleConstant.margin.x3 : styleConstant.margin.x5,
        }}
      >
        <Typography variant="title" style={{ ...creditPurchase.title }}>
          {title}
        </Typography>
        <CustomIconButton icon={<CustomIcon icon={icons.XMARK} size="1x" />} onClick={closeModal} />
      </div>
      <Typography variant="subtitle" style={{ ...creditPurchase.subtitle, marginRight: mobile ? styleConstant.margin.x3 : styleConstant.margin.x5 }}>
        {subtitle}
      </Typography>
      {/* {renderNavBar()} */}
    </>
  );

  const Body = ({ children }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1",
        marginTop: styleConstant.margin.x3,
        position: "relative",
      }}
    >
      {children}
    </div>
  );

  const FreePurchaseScreen = ({ isSuccess = false, freeCredits = 0, nextFreeRewardTs = Date.now(), isTimeLeft = false }) => {
    const getDiffTime = () => {
      const diffTime = new Date(nextFreeRewardTs).getTime() - Date.now();
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      return diffHours;
    };
    return (
      <div
        style={{
          paddingRight: styleConstant.padding.x5,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
        }}
      >
        {isTimeLeft ? (
          <Typography variant="title" style={{ textAlign: "center" }}>
            Potrai riscattare altri crediti tra {getDiffTime()} ore
          </Typography>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", width: "100%", alignItems: "center", justifyContent: isTimeLeft ? "center" : "" }}>
            <CustomIcon icon={isSuccess ? icons.CHECK : icons.XMARK} color={palette.primary.main} size="4x" />
            {isSuccess && <Typography variant="title">Hai riscattato {freeCredits} crediti! </Typography>}
            {isSuccess && (
              <Typography variant="smallTitle" style={{ marginTop: styleConstant.margin.x2 }}>
                Potrai riscattare altri crediti tra {getDiffTime()} ore
              </Typography>
            )}
            {!isSuccess && <Typography variant="title">Non è stato possibile riscattare i crediti</Typography>}
          </div>
        )}

        <ContainedButton style={{ marginTop: styleConstant.margin.x3 }} onClick={closeModal} text="Chiudi" />
      </div>
    );
  };

  const renderCards = () => {
    let packetsToDisplay = {};
    for (let packetId in plansObject) {
      const packetPosition = plansObject[packetId].position;
      if (type == "ads") {
        if (plansObject[packetId]?.noAdsTime) packetsToDisplay[packetPosition] = plansObject[packetId];
      }
      if (type == "credits") {
        if (!plansObject[packetId]?.noAdsTime) packetsToDisplay[packetPosition] = plansObject[packetId];
      }
    }
    return (
      <>
        <PurchaseCard
          type={"credits"}
          selected={false}
          onSelect={() => {}}
          creditAmount={"100"}
          hideCredits
          highlighted={false}
          title={"Apri l'app mobile per riscattare " + plans[idFreePlan].coins + " crediti gratuiti"}
          mobile={mobile}
          disabled={true}
          titleStyle={{ fontWeight: "600", fontSize: "24px", lineHeight: "32px" }}
          style={{ marginLeft: mobile ? "5px" : "" }}
        />
        {Object.keys(packetsToDisplay).map((key) => {
          const plan = packetsToDisplay[key];
          const promoActive = plan.isPromoActive;
          if (type === "ads") {
            if (plan?.noAdsTime) {
              return (
                <PurchaseCard
                  key={plan.id}
                  type={type}
                  promoData={promoActive ? plan.promoData : null}
                  selected={selected === plan.id}
                  onSelect={() => setSelected(plan.id)}
                  creditAmount={plan.coins}
                  fiatAmount={plan.price + " €"}
                  title={plan.title}
                  mobile={mobile}
                  highlighted={plan.highlighted}
                  noAdsPeriod={plan?.noAdsTime ? `${Math.floor(plan.noAdsTime / 2592000000)} mesi ` : null}
                />
              );
            }
          } else {
            if (!plan?.noAdsTime && plan.id !== "202205101121") {
              return (
                <PurchaseCard
                  key={plan.id}
                  type={type}
                  promoData={promoActive ? plan.promoData : null}
                  selected={selected === plan.id}
                  onSelect={() => setSelected(plan.id)}
                  creditAmount={plan.coins}
                  fiatAmount={plan.id === idFreePlan && isFreeCoinsButtonDisabled() ? "" : plan.price + " €"}
                  highlighted={plan.highlighted}
                  title={plan.id === idFreePlan ? getFreeCoinsButtonText() : plan.title}
                  mobile={mobile}
                  disabled={plan.id === "202205101121"}
                  style={{ marginLeft: mobile ? "5px" : "" }}
                />
              );
            }
          }
        })}
      </>
    );
  };

  const getPromoCodeTextInputColor = () => {
    let color = '#fff';
    if (activePromoCode) color = '#10CB80';
    if (promoCodeError) color = '#F6465D';
    return color;
  }

  const renderPromoCodeSection = () => (
    !promoCodeInputVisible ? 
      <TextButton 
        text={'Hai un codice sconto/coupon ?'} 
        style={{marginRight: 'auto', marginLeft:'auto'}}
        action={() => setPromoCodeInputVisible(true)}/>
    : <div style={{marginRight: styleConstant.margin.x5, position: 'relative'}}>
      {!promoCodeError && !activePromoCode && 
        (!promoCodeLoading 
          ? <TextButton style={{position: 'absolute', bottom: promoCodeError ? 21 : 3, right: 8, zIndex: 1}} 
              text='APPLICA' 
              disabled={promoCode.length === 0}
              action={() => applyPromoCode(promoCode)}/>
          : <CircularProgress 
              size={'30px'}
              color={'white'}
              style={{
                position: 'absolute', 
                bottom: promoCodeError ? 21 : 9, 
                right: 16, 
                zIndex: 1
              }}/>
        )
      }
      {promoCodeError && <CustomIcon icon={icons.CIRCLE_X_MARK} 
        style={{position: 'absolute', bottom: 9, right: 24, zIndex: 1}}
        color={'#F6465D'}
        size='4x'/>}
      {activePromoCode && <CustomIcon icon={icons.CHECK} 
        style={{position: 'absolute', bottom: -8, right: 24, zIndex: 1}}
        color={'#10CB80'}
        size='4x'/>}
      <FormSkeleton label="Codice sconto/coupon" 
        actionLabel="Chiudi"
        action={() => {
          setPromoCodeInputVisible(false);
          discardPromoCode();
        }}
        helperText={{text: promoCodeError, color: '#F6465D'}}
        actionButtonStyle={{padding: 0}}>
        <CustomTextField style={{
          "& .MuiOutlinedInput-input": { paddingRight: "88px" },
          fontWeight:'700'
          }}
          onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
              //applyPromoCode(promoCode);
              ev.preventDefault();
            }
          }}
          inputRef={textFieldRef}
          inputPropsStyle={{fontWeight:'700'}}
          textColor={getPromoCodeTextInputColor()}
          onFocus={() => {
            setPromoCodeError(null);
            discardPromoCode();
          }}
          content={promoCode} 
          onChange={setPromoCode}/>
      </FormSkeleton>
    </div>
  )

  return (
    <>
      {plansObject && (
        <>
          {!mobile ? (
            <Modal open={open} onClose={closeModal}>
              <div
                style={{
                  background: palette.grey.grey_500,
                  width: "600px",
                  minHeight: sumUp || freePurchaseCompleted ? "300px" : "500px",
                  height: sumUp || freePurchaseCompleted ? "65%" : "85%",
                  maxHeight: (sumUp || freePurchaseCompleted) ? '600px' : '900px',
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  padding: `${styleConstant.padding.x5} 0px ${styleConstant.padding.x5} ${styleConstant.padding.x5}`,
                  top: "0",
                  left: "0",
                  right: "0",
                  bottom: "0",
                  margin: "auto",
                  borderRadius: styleConstant.borderRadius.x3,
                }}
              >
                {!sumUp && !freePurchaseCompleted ? (
                  <>
                    <Header />
                    <Body>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          position: "absolute",
                          gap: styleConstant.gap.medium,
                          top: "0px",
                          bottom: "0px",
                          left: "0px",
                          right: "0px",
                          overflowY: "overlay",
                          paddingRight: styleConstant.padding.x5,
                        }}
                        id="custom-scrollbar"
                      >
                        {renderCards()}
                      </div>
                    </Body>
                    {renderPromoCodeSection()}
                    <ContainedButton
                      style={{
                        marginTop: "auto",
                        height: "50px",
                        marginTop: styleConstant.margin.x3,
                        marginRight: styleConstant.margin.x5,
                      }}
                      disabled={disableButton() || purchaseLoadin}
                      text="Procedi all'acquisto"
                      onClick={onConfirm}
                    />
                  </>
                ) : (
                  <>
                    {sumUp && (
                      <SumUp
                        onBack={() => setSumUp(false)}
                        packet={plansObject[selected].title}
                        coinAmount={plansObject[selected].coins}
                        total={getOrderTotal()}
                        activePromoCode={plansObject[selected].isPromoActive ? activePromoCode : null}
                        onClose={closeModal}
                        discardPromo={plansObject[selected].isPromoActive ? discardPromoCode : () => {}}
                        noAdsPeriod={plansObject[selected].noAdsTime ? `${Math.floor(plansObject[selected].noAdsTime / 2592000000)} mesi ` : null}
                        createOrder={(data, actions) => createOrder(data, actions)}
                        onApprove={(data, actions) => onApprove(data, actions)}
                        onError={() => setPurchaseError(true)}
                        purchaseCompleted={purchaseCompleted}
                        purchaseError={purchaseError}
                        loading={purchaseLoadin}
                        setNoError={() => setPurchaseError(false)}
                        setNoCompleted={() => setPurchaseCompleted(false)}
                      />
                    )}
                    {freePurchaseCompleted && (
                      <FreePurchaseScreen
                        isSuccess={freePurchaseSuccess}
                        freeCredits={plansObject["202205101121"].coins}
                        isTimeLeft={isTimeLeft}
                        nextFreeRewardTs={freeRewardTs}
                      />
                    )}
                  </>
                )}
              </div>
            </Modal>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                background: palette.grey.grey_500,
                flex: "1",
                padding: `${styleConstant.padding.x3} 0px ${styleConstant.padding.x3} ${styleConstant.padding.x3}`,
              }}
            >
              {!sumUp && !freePurchaseCompleted ? (
                <>
                  <Header />
                  <Body>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        gap: styleConstant.gap.medium,
                        top: "0px",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                        overflowY: "overlay",
                        paddingRight: mobile ? styleConstant.padding.x3 : styleConstant.padding.x5,
                      }}
                      id="custom-scrollbar"
                    >
                      {renderCards()}
                    </div>
                  </Body>
                  {renderPromoCodeSection()}
                  <ContainedButton
                    style={{
                      marginTop: "auto",
                      height: "50px",
                      marginTop: styleConstant.margin.x3,
                      marginRight: mobile ? styleConstant.margin.x3 : styleConstant.margin.x5,
                    }}
                    text="Procedi all'acquisto"
                    disabled={disableButton() || purchaseLoadin}
                    onClick={onConfirm}
                  />
                </>
              ) : (
                <>
                  {freePurchaseCompleted && (
                    <FreePurchaseScreen
                      isSuccess={freePurchaseSuccess}
                      freeCredits={plansObject["202205101121"].coins}
                      isTimeLeft={isTimeLeft}
                      nextFreeRewardTs={freeRewardTs}
                    />
                  )}
                  {sumUp && (
                    <SumUp
                      onBack={() => setSumUp(false)}
                      packet={plansObject[selected].title}
                      coinAmount={plansObject[selected].coins}
                      total={getOrderTotal()}
                      activePromoCode={plansObject[selected].isPromoActive ? activePromoCode : null}
                      onClose={closeModal}
                      discardPromo={plansObject[selected].isPromoActive ? discardPromoCode : () => {}}
                      noAdsPeriod={plansObject[selected].noAdsTime ? `${Math.floor(plansObject[selected].noAdsTime / 2592000000)} mesi ` : null}
                      createOrder={(data, actions) => createOrder(data, actions)}
                      onApprove={(data, actions) => onApprove(data, actions)}
                      purchaseCompleted={purchaseCompleted}
                      purchaseError={purchaseError}
                      onError={() => setPurchaseError(true)}
                      loading={purchaseLoadin}
                      setNoError={() => setPurchaseError(false)}
                      setNoCompleted={() => setPurchaseCompleted(false)}
                      mobile
                    />
                  )}
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = ({ Profile, Calculation }) => ({
  userEmail: Profile.userAuthInfo.email,
  userUid: Profile.userAuthInfo.uid,
  userCredits: Profile.userProfile.appData.credit,
  plans: Calculation.plans,
  freeRewardTs: Profile.userProfile.appData.freeRewardTs,
  oldestFreeRewardTs: Profile.userProfile.appData.oldestFreeRewardTs,
  cumulativeFreeCoins: Profile.userProfile.appData.cumulativeFreeCoins,
});

const mapDispatchToProps = (dispatch) => ({
  updatePurchaseData: (credit, noAdsTs, payments_historycal, freeRewardTs, cumulativeFreeCoins, oldestFreeRewardTs) =>
    dispatch(updatePurchaseUserData(credit, noAdsTs, payments_historycal, freeRewardTs, cumulativeFreeCoins, oldestFreeRewardTs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditPurchase);
