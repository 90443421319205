import { Tooltip, tooltipClasses } from "@mui/material";
import React from "react";
import { styled } from "@mui/material/styles";
import { palette } from "../../assets/styles/constant/palette";

const CustomToolTip = ({
  children,
  title,
  color = palette.primary.main,
  textColor = "black",
  hasArrow = false,
  placement = "bottom",
  enterDelay = 600,
  leaveDelay = 100,
  hidden = false,
  followCursor = false,
}) => {
  const StyledToolTip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      followCursor={followCursor}
      classes={{ popper: className }}
      arrow={hasArrow}
      placement={placement}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
    />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: color,
      color: textColor,
    },
    [`& .${tooltipClasses.arrow}`]: {
      "&::before": { backgroundColor: color },
    },
  });

  return (
    hidden
      ? <div style={{ display: "inline-block" }}>{children}</div>
      : <StyledToolTip title={title}>
        <div style={{ display: "inline-block" }}>{children}</div>
      </StyledToolTip>
  );
};

export default CustomToolTip;
