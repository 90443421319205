import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { palette } from "../../assets/styles/constant/palette";
import { styleConstant } from "../../assets/styles/constant/styleConstants.js";

const CustomIcon = ({ icon, className, color = null, fontSize = null, size = null, style = null }) => {
  return (
    <FontAwesomeIcon
      className={className}
      icon={icon}
      color={color ? color : palette.primary.main}
      fontSize={fontSize ? fontSize : styleConstant.fontSize.x2}
      size={size && size}
      style={{ ...style }}
      width={fontSize ? fontSize : styleConstant.fontSize.x2}
      height={fontSize ? fontSize : styleConstant.fontSize.x2}
    />
  );
};

export default CustomIcon;
