import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import CustomIconButton from "../buttons/CustomIconButton";
import { Avatar, Typography } from "@mui/material";
import { drawerProfileLabel, textSecondaryStyle } from "../../../assets/styles/constant/styleConstants";
import { styleConstant } from "../../../assets/styles/constant/styleConstants";
import CustomToolTip from "../CustomToolTip";

const DrawerProfileItem = ({ profileImage, name, email, open = true, onIconClick }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: open ? "space-between" : "center",
      }}
    >
      {open ? (
        <>
          <div
            style={{
              display: "flex",
              gap: styleConstant.gap.medium,
              alignItems: "center",
            }}
          >
            <Avatar
              alt={name}
              style={{ width: "40px", height: "40px" }}
              src={profileImage} />
            <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
              <Typography variant="smallTitle" style={drawerProfileLabel.name}>
                {name}
              </Typography>
              <Typography variant="smallTitle" style={drawerProfileLabel.email}>
                {email}
              </Typography>
            </div>
          </div>
          <CustomToolTip title="Logout">
            <CustomIconButton icon={<LogoutIcon fontSize="small" />} onClick={onIconClick} />
          </CustomToolTip>
        </>
      ) : (
        <Avatar
          alt={name}
          style={{ width: "40px", height: "40px" }}
          src={profileImage} />
      )}
    </div>
  );
};

export default DrawerProfileItem;
