import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { palette } from '../../assets/styles/constant/palette';
import { brkRef } from '../../helpers/util';
import ConfigurationSection from './ConfigurationSection';
import ListSection from './ListSection';
import ResultSection from './ResultSection';
import { reloadUserCredit } from '../../store/profile/actions'
import { calculationTipster } from '../../service/calculation';
import CustomIconButton from '../../core/components/buttons/CustomIconButton';
import CustomIcon from '../../core/components/CustomIcon';
import { icons } from '../../assets/styles/constant/general';
import CustomDialog from '../../core/components/CustomDialog';
import TextButton from '../../core/components/buttons/TextButton';
import CalculusLoading from '../../core/components/CalculatorSections/CalculusLoading';

const SINGLE_MODE = 0;
const MULTIPLE_MODE = 1;
const BEST_MODE = 2;

const CF_DATA = {
	mode: '',
	price: 0,
	uid: null,
	payload: {
		idUtente: '',
		app: 'web',
		idMigliori: {},
		idEventi: {},
	}
};

const Tipster = (props) => {
	const [activeMode, setActiveMode] = React.useState(SINGLE_MODE);
	const [eventList, setEventList] = React.useState([]);
	const [result, setResult] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [isGuideOpen, setIsGuidesOpen] = React.useState(false);
	const [alertDialogOpen, setAlertDialogOpen] = React.useState(false);
	const [resultMessage, setResultMessage] = React.useState(null);

	const onActiveModeChange = (newActiveMode) => {
		if (activeMode !== newActiveMode) {
			setActiveMode(newActiveMode);
			setEventList([]);
			setResult(null);
			setResultMessage(null);
		}
	}

	const calculateTipster = async(cfData) => {
		console.log(cfData)
		try {
			setLoading(true);
			const cfResponse = await calculationTipster(cfData);
			if (cfResponse.outcome === 'error') throw cfResponse.error;
			const resultObject = cfResponse.data;
			if (!resultObject.esito) {
				setResultMessage(resultObject.messaggio);
				setAlertDialogOpen(true);
				setLoading(false);
				return;
			}
			setResult(prev => {
				if (activeMode === 2) return resultObject.risultato_migliori;
				else return resultObject.risultato_eventi;
			});
			setLoading(false);
			props.reloadUserCredits(props.uid);
		} catch (error) {
			console.log('call cf error', error);
			setLoading(false);
		}
	}

	const startSingolaCalc = (eventId, price) => {
		const _cfData = getEventsCfData([eventId], price);
		calculateTipster(_cfData)
	}

	const startMultiplaCalc = (price) => {
		if (eventList.length === 0) return;
		let idEventi = [];
		eventList.map(el => {
			const { event } = el;
			idEventi.push(event.value);
		});
		const _cfData = getEventsCfData(idEventi, price);
		calculateTipster(_cfData)
	}

	const startMiglioriCalc = (idMigliori, price) => {
		const _cfData = getMiglioriCfData(idMigliori, price);
		calculateTipster(_cfData)
	}

	const getEventsCfData = (idEventi, price) => {
		return {
			...CF_DATA,
			uid: props.uid,
			mode: activeMode,
			price: price,
			payload: {
				...CF_DATA.payload,
				idUtente: props.uid,
				idEventi: idEventi,
			}
		};
	}

	const getMiglioriCfData = (idMigliori, price) => {
		return {
			...CF_DATA,
			uid: props.uid,
			mode: activeMode,
			price,
			payload: {
				...CF_DATA.payload,
				idUtente: props.uid,
				idMigliori: idMigliori,
			}
		};
	}

	const createEvent = ({ eventGroup, subEventGroup, statistic, event }) => {
		setEventList(prev => {
			let newList = brkRef(prev);
			newList.push({
				group: eventGroup, 
				subGroup: subEventGroup, 
				statistic: statistic, 
				event: event
			});
			return newList
		})
	}

	const deleteEvent = (idx) => {
		setEventList(prev => {
			let newList = brkRef(prev);
			newList.splice(idx, 1);
			return newList;
		})
	}

	const Header = ({ title, subtitle }) => (
		<Stack direction={'row'} gap={'8px'}>
			<Typography variant='title'
				fontWeight={'700'}>
				{title}
			</Typography>
			<CustomIconButton
				icon={<CustomIcon
					icon={icons.QUESTION} 
					color={palette.primary.main} 
				/>} 
				onClick={() => setIsGuidesOpen(true)} />
		</Stack>
	)

	const renderLeftSideSections = () => (
		<Stack minWidth={activeMode === MULTIPLE_MODE ? '66%' : '33%'}
			borderRadius={'16px'}
			style={{ background: palette.grey.grey_500 }}
			minHeight={'95%'}
			direction={'row'}
			maxHeight={'100%'} >
				<ConfigurationSection
					loading={loading}
					activeMode={activeMode}
					createEvent={createEvent}
					execSingolaCalc={startSingolaCalc}
					execMiglioriCalc={startMiglioriCalc}
					resetResult={() => {
						setResult(null);
						setResultMessage(null);
					}}
					onActiveModeChange={onActiveModeChange} />
				{activeMode === MULTIPLE_MODE && 
					<ListSection
						loading={loading}
						resetResult={() => {
							setResult(null);
							setResultMessage(null);
						}}
						execCalc={startMultiplaCalc}
						onEventDelete={deleteEvent}
						eventList={eventList} />}
		</Stack>
	)

	const renderRightSideSections = () => (
		<Stack minWidth={'30%'}
			minHeight={'95%'}
			borderRadius={'16px'}
			maxHeight={'100%'}
			style={{background: palette.grey.grey_500 }}>
				<ResultSection activeMode={activeMode} result={result} resultMessage={resultMessage}/>
		</Stack>
	)

	const getGuideContent = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", padding: '24px', maxHeight: "50%", overflowY: "auto", minWidth: '15vw' }} id="custom-scrollbar">
        <Typography variant="smallTitle">{props.guides.descrizione}</Typography>
        <TextButton text="Chiudi" action={() => setIsGuidesOpen(false)} style={{ marginTop: '24px' }} />
      </div>
    );
  };

	const getAlertContent = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", padding: '24px', maxHeight: "50%", overflowY: "auto", minWidth: '15vw' }} id="custom-scrollbar">
        <Typography variant="smallTitle">{resultMessage}</Typography>
        <TextButton text="Chiudi" action={() => setAlertDialogOpen(false)} style={{ marginTop: '24px' }} />
      </div>
    );
  };

  return (
		<Stack height={'100%'}
			width={'100%'} px={'24px'} py={'32px'}>
				<Header title={'Tipster'} />
				<Stack width={'100%'} height={'100%'}
					marginTop={'32px'}
					direction='row'
					gap={'24px'}
					alignItems={'center'}
					justifyContent={'flex-start'}>
						{renderLeftSideSections()}
						{renderRightSideSections()}
				</Stack>
				<CustomDialog
        	open={isGuideOpen}
        	handleClose={() => setIsGuidesOpen(false)}
        	backDropClickClose
        	style={{ zIndex: 10004 }}
        	title={"Serve aiuto?"}
        	customContent
        	icon={icons.LIFE_RING}
        	titlePosition="center"
        	roundCorners
     	 	>
        	{getGuideContent()}
      </CustomDialog>
			<CustomDialog
        open={alertDialogOpen}
        handleClose={() => {
					setIsGuidesOpen(false);
					setResultMessage(null);
				}}
        backDropClickClose
        style={{ zIndex: 10004 }}
        title={"Attenzione"}
        customContent
        icon={icons.EXCLAMATION}
        titlePosition="center"
        roundCorners >
        	{getAlertContent()}
      </CustomDialog>
			{loading && <CalculusLoading />}
		</Stack>
	)
}

const mapStateToProps = ({ Profile, Calculation }) => ({
	uid: Profile.userAuthInfo.uid,
	guides: Calculation.guides.tipster,
});

const mapDispatchToProps = dispatch => ({
	reloadUserCredits: (uid) => dispatch(reloadUserCredit(uid)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Tipster);