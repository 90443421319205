import { Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { icons } from "../../../assets/styles/constant/general";
import { palette } from "../../../assets/styles/constant/palette";
import { configSectionStyle, styleConstant, textSecondaryStyle } from "../../../assets/styles/constant/styleConstants";
import CustomIconButton from "../buttons/CustomIconButton";
import CustomIcon from "../CustomIcon";
import FormSkeleton from "../input/FormSkeleton";
import InputSelect from "../input/InputSelect";
import { convertArrayToObject, formatDays, generateSelectListValues, getStatisticsFromPlayer } from "../../../helpers/util";
import { createCalculationObject, loadDays, loadGreaterThan, loadPlayers, loadStatistics, loadTeams, updateCalculationObject } from "../../../store/actions";
import { GREATER_THAN, PLAYER, STATISTIC, DAYS, TEAM, SEASON, OPERATION } from "../../../helpers/constants/entities";
import ContainedButton from "../buttons/ContainedButton";
import "../../../assets/styles/scrollbar.css";
import TextButton from "../buttons/TextButton";
import CustomToolTip from "../CustomToolTip";
import CustomDialog from "../CustomDialog";

let _CONFIGURATION_OBJ = null;

const ConfigSection = (props) => {
  const [statisticsListValues, setStatisticsListValues] = React.useState([]);
  const [teamsListValues, setTeamsListValues] = React.useState([]);
  const [daysListValues, setDaysListValues] = React.useState([]);
  const [playersListValues, setPlayersListValues] = React.useState([]);
  const [greaterThanListValues, setGreaterThanListValues] = React.useState([]);
  const [operationListValues, setOperationListValues] = React.useState([]);
  const [seasonListValues, setSeasonListValues] = React.useState([]);
  const [selectedStatistic, setSelectedStatistic] = React.useState("");
  const [selectedTeam, setSelectedTeam] = React.useState("");
  const [selectedDays, setSelectedDays] = React.useState([]);
  const [selectedPlayer, setSelectedPlayer] = React.useState("");
  const [selectedGreaterThan, setSelectedGreaterThan] = React.useState("");
  const [selectedOperation, setSelectedOperation] = React.useState("");
  const [selectedSeason, setSelectedSeason] = React.useState("");
  const [isGuidesOpen, setIsGuidesOpen] = React.useState(false);
  const [statisticsGuidesOpen, setStasticsGuidesOpen] = React.useState(false);
  const [daysSelectOpen, setDaysSelectOpen] = React.useState(false);

  //INIT LIST VALUES:
  React.useEffect(() => {
    if (props.selectedCalculus) return;
    startupProcess();
  }, []);

  //onSelectChange EVENT LISTENERS:
  React.useEffect(() => {
    onSelectedValue(selectedStatistic, STATISTIC);
  }, [selectedStatistic]);

  React.useEffect(() => {
    onSelectedValue(selectedDays, DAYS);
    if (!selectedStatistic) return;
  }, [selectedDays]);

  React.useEffect(() => {
    onSelectedValue(selectedTeam, TEAM);
  }, [selectedTeam]);

  React.useEffect(() => {
    onSelectedValue(selectedPlayer, PLAYER);
  }, [selectedPlayer]);

  React.useEffect(() => {}, [selectedGreaterThan]);

  React.useEffect(() => {
    onSelectedValue(selectedSeason, SEASON);
  }, [selectedSeason]);

  React.useEffect(() => {}, [selectedOperation]);

  React.useEffect(() => {}, [daysListValues]);

  //UPDATE LIST VALUES CALL BACK FUNCTIONS
  React.useEffect(() => {
    if (props.selectedCalculus) {
      loadConfiguration(props.selectedCalculus.configuration);
    } else {
      _CONFIGURATION_OBJ = null;
      resetSection();
    }
  }, [props.selectedCalculus]);

  React.useEffect(() => {
    if (props.days) setSelectorDays(props.days);
  }, [props.days]);

  React.useEffect(() => {
    if (props.teams) {
      const sortedTeams = Object.values(props.teams).sort((current, next) => (current.nome.toUpperCase() > next.nome.toUpperCase() ? 1 : -1));
      setSelectorTeams(convertArrayToObject(sortedTeams));
    }
  }, [props.teams]);

  React.useEffect(() => {
    if (props.players) {
      const sortedPlayers = Object.values(props.players).sort((current, next) => (current.nome.toUpperCase() > next.nome.toUpperCase() ? 1 : -1));
      setSelectorPlayers(convertArrayToObject(sortedPlayers));
    }
  }, [props.players]);

  React.useEffect(() => {
    if (props.greaterThan) setSelectorGreaterThan(props.greaterThan, selectedDays.length);
  }, [props.greaterThan]);

  React.useEffect(() => {
    if (props.operations && props.settings.tool == 2) setSelectorOperations(props.operations);
  }, [props.operations]);

  React.useEffect(() => {
    if (props.settings) {
      startupProcess();
    }
  }, [props.settings]);

  const startupProcess = () => {
    setSelectorStatistics(props.statistics);
    setSelectorSeasons(props.seasons);
    if (props.settings.tool == 2) setSelectorOperations(props.operations);
  };

  const loadConfiguration = (configurationObject) => {
    _CONFIGURATION_OBJ = configurationObject;
    startupProcess();
  };

  const getInitValue = (listValues, _CONFIGURATION_OBJ, entity, defaultValue = []) => {
    // priorità alle configurazioni che devono essere caricate
    if (!_CONFIGURATION_OBJ || !_CONFIGURATION_OBJ[entity]) {
      if (defaultValue.length > 0) {
        if (entity === DAYS) {
          let initValue = [];
          defaultValue.map((element) => {
            let index = listValues.map((item) => item.value).indexOf(element.value);
            initValue.push(listValues[index]);
          });
          return initValue;
        }
      } else {
        return listValues[0];
      }
    }
    let initValue;
    // se non è giornate
    if (!Array.isArray(_CONFIGURATION_OBJ[entity])) {
      let initValueIndex = listValues.map((element) => element.value).indexOf(_CONFIGURATION_OBJ[entity].value);
      initValue = listValues[initValueIndex];
    } else {
      initValue = [];
      _CONFIGURATION_OBJ[entity].map((element) => {
        const value = element.value;
        let index = listValues.map((item) => item.value).indexOf(value);
        initValue.push(listValues[index]);
      });
    }
    let newConfigurationObject = { ..._CONFIGURATION_OBJ };
    newConfigurationObject[entity] = null;
    _CONFIGURATION_OBJ = newConfigurationObject;
    return initValue;
  };

  const setSelectorStatistics = (unformattedStatisticsList) => {
    let newListValues = generateSelectListValues(unformattedStatisticsList, "nome", "id");
    setStatisticsListValues(newListValues);
    if (newListValues.length === 0) return;
    const initValue = getInitValue(newListValues, _CONFIGURATION_OBJ, STATISTIC);
    setSelectedStatistic(initValue);
  };

  const setSelectorTeams = (unformattedTeamsList) => {
    let newListValues = generateSelectListValues(unformattedTeamsList, "nome", "id");
    setTeamsListValues(newListValues);
    if (_CONFIGURATION_OBJ && _CONFIGURATION_OBJ[TEAM]) {
      if (newListValues.length == 0) return;
      const initValue = getInitValue(newListValues, _CONFIGURATION_OBJ, TEAM);
      setSelectedTeam(initValue);
    } else {
      setSelectedTeam("");
    }
  };

  const setSelectorDays = (unformattedDaysList) => {
    let newUnformatted = formatDays(unformattedDaysList, props.settings.tool, selectedSeason.value);
    let newListValues = generateSelectListValues(convertArrayToObject(newUnformatted), "numero", "id", "squadre_disponibili");
    let defaultSelectedDays = {};
    Object.keys(unformattedDaysList).map((key) => {
      if (unformattedDaysList[key].defaultSelected) {
        defaultSelectedDays[key] = unformattedDaysList[key];
      }
    });
    let unformattedDefaultSelected = formatDays(defaultSelectedDays, props.settings.tool, selectedSeason.value);
    let newDefaultListValues = generateSelectListValues(convertArrayToObject(unformattedDefaultSelected), "numero", "id", "squadre_disponibili");
    // const actualListValues = [{ value: -1, label: "Selezionate tutte" }].concat(newListValues);
    setDaysListValues(newListValues);
    if (newListValues.length === 0) return;
    let initValue = getInitValue(newListValues, _CONFIGURATION_OBJ, DAYS, newDefaultListValues);
    if (!Array.isArray(initValue)) initValue = [initValue];
    setSelectedDays(initValue);
  };

  const setSelectorPlayers = (unformattedPlayersList) => {
    const playerList = Object.values(unformattedPlayersList);
    let newListValues = generateSelectListValues(playerList, "nome", "id", "statistiche");
    setPlayersListValues(newListValues);
    if (_CONFIGURATION_OBJ && _CONFIGURATION_OBJ[PLAYER]) {
      if (newListValues.length == 0) return;
      const initValue = getInitValue(newListValues, _CONFIGURATION_OBJ, PLAYER);
      setSelectedPlayer(initValue);
    } else {
      setSelectedPlayer("");
    }
  };

  const setSelectorSeasons = (unformattedSeasonsList) => {
    const seasonsList = Object.values(unformattedSeasonsList);
    let newListValues = generateSelectListValues(seasonsList, "nome", "id");
    setSeasonListValues(newListValues);
    if (_CONFIGURATION_OBJ && _CONFIGURATION_OBJ[SEASON]) {
      const initValue = getInitValue(newListValues, _CONFIGURATION_OBJ, SEASON);
      setSelectedSeason(initValue);
      return;
    }
    //Attualmente prende sempre l'ultima astagione, da implementare la stagione di default con configObj
    if (newListValues.length > 0) setSelectedSeason(newListValues[newListValues.length - 1]);
  };

  const setSelectorOperations = (unformattedOperationsList) => {
    let newListValues = generateSelectListValues(unformattedOperationsList, "nome", "id");
    setOperationListValues(newListValues);
    if (_CONFIGURATION_OBJ && _CONFIGURATION_OBJ[OPERATION]) {
      const initValue = getInitValue(newListValues, _CONFIGURATION_OBJ, OPERATION);
      setSelectedOperation(initValue);
      return;
    }
    if (newListValues.length > 0) setSelectedOperation(newListValues[0]);
  };

  const setSelectorGreaterThan = (unformattedGreaterThanList, selectedDaysNumber) => {
    if (!unformattedGreaterThanList || selectedDaysNumber === 0) {
      setGreaterThanListValues([]);
      setSelectedGreaterThan([]);
      return;
    }
    //TODO: trasformare in un metodo ciclo for (getGreaterThanObj)
    let greaterThanObj;
    for (let greaterThanItem of unformattedGreaterThanList) {
      if (greaterThanItem && greaterThanItem.numero_giornate_selezionate === selectedDaysNumber) {
        greaterThanObj = greaterThanItem;
        break;
      }
    }
    //controllare se è giocatore o squadra
    const max = greaterThanObj["max"][props.settings.subject].max;
    const idInterval = greaterThanObj.id_intervallo;
    // GENERATE LIST VALUES
    let newListValues = [];
    for (let i = 0; i <= max; i++) {
      newListValues.push({ label: i.toString(), value: i, additional: idInterval });
    }
    setGreaterThanListValues(newListValues);
    if (newListValues.length > 0) {
      let initValue = getInitValue(newListValues, _CONFIGURATION_OBJ, GREATER_THAN);
      setSelectedGreaterThan(initValue);
    }
  };

  const onSelectedValue = (selectedValue, entity) => {
    switch (entity) {
      case STATISTIC:
        const idStatistic = selectedValue.value;
        if (selectedDays.length !== 0) props.loadGreaterThan(idStatistic);
        //controllo che ci sia almeno una giornata selezionata e una squadra selezionata
        if (!selectedSeason.value) break;
        if (selectedDays.length == 0) break;
        if (!selectedTeam.value || selectedTeam.value === "") break;
        let days = selectedDays.map((day) => day.value);
        setSelectedPlayer("");
        props.loadPlayers(selectedSeason.value, selectedTeam.value, days, selectedStatistic.value);
        break;
      case SEASON:
        if (!selectedValue || typeof selectedValue === "string") return;
        const idSeason = selectedValue.value;
        props.loadDays(idSeason);
        break;
      case DAYS:
        if (selectedValue.length === 0) {
          setGreaterThanListValues([]);
          setSelectedGreaterThan("");
          setTeamsListValues([]);
          setSelectedTeam("");
          setPlayersListValues([]);
          setSelectedPlayer("");
          break;
        }
        if (selectedStatistic?.value) props.loadGreaterThan(selectedStatistic.value);
        let teamsListValuesIds = [];
        selectedValue.map((day) => {
          teamsListValuesIds = [...new Set([...teamsListValuesIds, ...day.additional])];
        });
        props.loadTeams(teamsListValuesIds);
        // if (!selectedTeam.value || selectedTeam.value === "") break;
        // let daysIds = selectedDays.map((day) => day.value);
        // props.loadPlayers(selectedSeason.value, selectedTeam.value, daysIds, selectedStatistic.value);
        break;
      case TEAM:
        setSelectedPlayer("");
        if (!selectedValue?.value || selectedValue == "") {
          setPlayersListValues([]);
          break;
        }
        const idTeam = selectedValue.value;
        if (props.settings.subject == 1) {
          let idDays = selectedDays.map((day) => day.value);
          props.loadPlayers(selectedSeason.value, idTeam, idDays, selectedStatistic.value);
          break;
        }
      case GREATER_THAN:
        break;
      case PLAYER:
        break;
      default:
        break;
    }
  };

  const createNewCalculus = (statistic, greaterThan, days, team, player, season, operation) => {
    let configuration = null;
    if (props.settings.tool == 1) {
      if (props.settings.subject == 1) {
        configuration = { statistic, days, greaterThan, team, player, season };
      } else {
        configuration = { statistic, days, greaterThan, team, season };
      }
    } else {
      if (props.settings.subject == 1) {
        configuration = { statistic, days, team, player, season, operation };
      } else {
        configuration = { statistic, days, team, season, operation };
      }
    }
    if (!configuration) throw "ERROR IN CREATING CONFIGURATION";
    if (props.mobile) props.closeMobileDialog();
    props.createCalculus(configuration);
    return;
  };

  const updateCalculus = (id, statistic, greaterThan, days, team, player, season, operation) => {
    if (!id) return;
    let configuration = null;
    if (props.settings.tool == 1) {
      if (props.settings.subject == 1) {
        configuration = { statistic, days, greaterThan, team, player, season };
      } else {
        configuration = { statistic, days, greaterThan, team, season };
      }
    } else {
      if (props.settings.subject == 1) {
        configuration = { statistic, days, team, player, season, operation };
      } else {
        configuration = { statistic, days, team, season, operation };
      }
    }
    if (!configuration) throw "ERROR IN EDITING CONFIGURATION";
    if (props.mobile) props.closeMobileDialog();
    props.updateCalculus(id, configuration);
    return;
  };

  //SETTER METHODS
  const selectStatistic = (statistics) => {
    setSelectedStatistic(statistics);
  };

  const selectDays = (days) => {
    setSelectedDays(days);
  };

  const selectTeam = (team) => {
    setSelectedTeam(team);
  };

  const selectPlayer = (player) => {
    setSelectedPlayer(player);
  };

  const selectGreaterThan = (greaterThan) => {
    setSelectedGreaterThan(greaterThan);
  };

  const selectSeason = (season) => {
    setSelectedSeason(season);
  };

  const selectOperation = (operation) => {
    setSelectedOperation(operation);
  };

  const resetSection = () => {
    setStatisticsListValues([]);
    setSelectedStatistic("");
    setTeamsListValues([]);
    setSelectedTeam("");
    setDaysListValues([]);
    setSelectedDays([]);
    setPlayersListValues([]);
    setSelectedPlayer("");
    setGreaterThanListValues([]);
    setSelectedGreaterThan("");
    setOperationListValues([]);
    setSelectedOperation("");
    setSeasonListValues([]);
    setSelectedSeason("");
    startupProcess();
  };

  const getGuideContent = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", padding: styleConstant.padding.x3, maxHeight: "50%", overflowY: "auto" }} id="custom-scrollbar">
        <Typography variant="title">Statistica</Typography>
        <Typography variant="smallTitle">{props.guides.menu.statistica.descrizione}</Typography>
        <Typography variant="title" style={{ marginTop: styleConstant.margin.x3 }}>
          Giornate
        </Typography>
        <Typography variant="smallTitle">{props.guides.menu.giornate.descrizione}</Typography>
        {props.settings.tool == 1 ? (
          <>
            <Typography variant="title" style={{ marginTop: styleConstant.margin.x3 }}>
              Maggiore di
            </Typography>
            <Typography variant="smallTitle">{props.guides.menu.maggiore_di.descrizione}</Typography>
          </>
        ) : (
          <>
            <Typography variant="title" style={{ marginTop: styleConstant.margin.x3 }}>
              Operazione
            </Typography>
            <Typography variant="smallTitle">{props.guides.menu.operazioni.descrizione}</Typography>
            <Typography variant="title" style={{ marginTop: styleConstant.margin.x3 }}>
              Stagione
            </Typography>
            <Typography variant="smallTitle">{props.guides.menu.stagione.descrizione}</Typography>
          </>
        )}
        <TextButton text="Chiudi" action={() => setIsGuidesOpen(false)} style={{ marginTop: styleConstant.margin.x3 }} />
      </div>
    );
  };

  const getStatisticGuideContent = () => {
    return (
      <>
        <div
          style={{ display: "flex", flexDirection: "column", padding: styleConstant.padding.x3, maxHeight: "500px", overflowY: "auto", minWidth: "500px" }}
          id="custom-scrollbar"
        >
          {props.statistics.map((statistic, index) => {
            const [statId, statName] = [statistic.id, statistic.nome];
            return (
              <React.Fragment key={index}>
                {props.guides.statistiche[statId] && props.guides.statistiche[statId][props.settings.tool] && (
                  <>
                    <Typography variant="title" style={{ marginTop: index !== 0 ? styleConstant.margin.x3 : "" }}>
                      {statName}
                    </Typography>
                    <Typography variant="smallTitle">{props.guides.statistiche[statId][props.settings.tool][props.settings.subject]?.descrizione}</Typography>
                  </>
                )}
              </React.Fragment>
            );
          })}
        </div>
        <div style={{ marginTop: styleConstant.margin.x3, display: "flex", marginBottom: styleConstant.margin.x3 }}>
          <TextButton text="Chiudi" action={() => setStasticsGuidesOpen(false)} style={{ marginLeft: "auto", marginRight: "auto" }} />
        </div>
      </>
    );
  };

  ////////RENDER
  const ConfigHeader = ({ title, subtitle, mobile }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingRight: styleConstant.padding.x3,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" /* , gap: styleConstant.gap.small */ }}>
        <Typography variant="title" style={configSectionStyle.title}>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="smallTitle" style={configSectionStyle.subtitle}>
            {subtitle}
          </Typography>
        )}
      </div>
    </div>
  );

  const ConfigForm = ({ children }) => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </div>
  );

  //Aggiungere controllo anche su operazione
  const disableAddButton = () => {
    if (props.settings.tool == 1) {
      if (props.settings.subject == 1) {
        let a = selectedPlayer?.value;
        return (
          selectedStatistic?.value &&
          selectedDays.length > 0 &&
          selectedGreaterThan?.value != null &&
          selectedTeam?.value &&
          selectedPlayer?.value &&
          selectedSeason?.value
        );
      } else {
        return selectedStatistic?.value && selectedDays.length > 0 && selectedGreaterThan?.value != null && selectedTeam?.value && selectedSeason?.value;
      }
    } else {
      if (props.settings.subject == 1) {
        return (
          selectedStatistic?.value &&
          selectedDays.length > 0 &&
          selectedGreaterThan?.value != null &&
          selectedTeam?.value &&
          selectedPlayer?.value &&
          selectedSeason?.value
        );
      } else {
        return selectedStatistic?.value && selectedDays.length > 0 && selectedGreaterThan?.value != null && selectedTeam?.value && selectedSeason?.value;
      }
    }
  };

  const disableEditButton = () => {
    return !!props.selectedCalculus && disableAddButton();
  };

  const disabledTeamSelect = () => {
    return selectedDays.length == 0 && selectedGreaterThan?.value == null;
  };

  const disabledPlayerSelect = () => {
    return selectedTeam?.value == null;
  };

  const getConfigHeaderSubtitle = () => {
    let s = "";
    if (props.selectedCalculus != null) {
      s = s + (props.settings.subject == 1 ? "Giocatore " : "Squadra ") + "#" + props.selectedCalculus.id;
      return s;
    }
    return s;
  };

  return (
    <>
      <CustomDialog
        open={isGuidesOpen}
        handleClose={() => setIsGuidesOpen(false)}
        backDropClickClose
        style={{ zIndex: 10004 }}
        title={"Serve aiuto?"}
        customContent
        icon={icons.LIFE_RING}
        titlePosition="center"
        roundCorners
      >
        {getGuideContent()}
      </CustomDialog>
      <CustomDialog
        open={statisticsGuidesOpen}
        handleClose={() => setStasticsGuidesOpen(false)}
        backDropClickClose
        style={{ zIndex: 10004 }}
        title={"Serve aiuto?"}
        customContent
        icon={icons.LIFE_RING}
        titlePosition="center"
        roundCorners
      >
        {getStatisticGuideContent()}
      </CustomDialog>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          background: palette.grey.grey_500,
          borderRadius: `${!props.mobile && styleConstant.borderRadius.x2}`,
          width: props.mobile ? "100%" : "30%",
          padding: `${styleConstant.padding.x3} 0px ${styleConstant.padding.x3} ${styleConstant.padding.x3}`,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingRight: styleConstant.padding.x3 }}>
          <ConfigHeader title={"Configuratore calcolo"} subtitle={getConfigHeaderSubtitle()} />

          {props.mobile && (
            <CustomIconButton
              icon={<CustomIcon icon={icons.XMARK} color={palette.primary.main} size={props.mobile ? "1x" : null} />}
              onClick={() => props.closeMobileDialog()}
            />
          )}
        </div>
        <div
          style={{
            overflowY: "overlay",
            paddingRight: styleConstant.padding.x3,
            marginBottom: styleConstant.margin.x1,
            paddingTop: styleConstant.padding.x3,
            paddingBottom: styleConstant.padding.x3,
          }}
          id="custom-scrollbar"
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="title" style={{ ...configSectionStyle.formTitle }}>
              Imposta statistica
            </Typography>
            <CustomIconButton icon={<CustomIcon icon={icons.QUESTION} color={palette.primary.main} />} onClick={() => setIsGuidesOpen(true)} />
          </div>
          <ConfigForm>
            <FormSkeleton
              label="Statistica"
              actionIcon={<CustomIcon icon={icons.QUESTION} style={{ fontSize: "13px" }} />}
              actionIconAction={() => setStasticsGuidesOpen(true)}
            >
              <InputSelect inputSelectItem={statisticsListValues} selectedValue={selectedStatistic} onSelectChange={selectStatistic} />
            </FormSkeleton>
            {props.settings.tool === 2 && (
              <FormSkeleton label="Operazione">
                <InputSelect inputSelectItem={operationListValues} selectedValue={selectedOperation} onSelectChange={selectOperation} />
              </FormSkeleton>
            )}
            {props.settings.tool === 1 && (
              <FormSkeleton label="Giornate">
                <InputSelect
                  flag
                  multiple
                  inputSelectItem={daysListValues}
                  selectedValue={selectedDays}
                  onSelectChange={selectDays}
                  // controlledOpenState
                  // openState={daysSelectOpen}
                  // handleOpen={() => setDaysSelectOpen(true)}
                  // handleClose={() => setDaysSelectOpen(false)}
                />
              </FormSkeleton>
            )}

            {props.settings.tool === 1 ? (
              <FormSkeleton label="Maggiore di">
                <InputSelect selectedValue={selectedGreaterThan} inputSelectItem={greaterThanListValues} onSelectChange={selectGreaterThan} />
              </FormSkeleton>
            ) : (
              <>
                <FormSkeleton label="Stagione">
                  <InputSelect inputSelectItem={seasonListValues} selectedValue={selectedSeason} onSelectChange={selectSeason} />
                </FormSkeleton>
                <FormSkeleton label="Giornate">
                  <InputSelect
                    multiple
                    inputSelectItem={daysListValues}
                    selectedValue={selectedDays}
                    onSelectChange={selectDays}
                    // controlledOpenState
                    // openState={daysSelectOpen}
                    // handleOpen={() => setDaysSelectOpen(true)}
                    // handleClose={() => setDaysSelectOpen(false)}
                  />
                </FormSkeleton>
              </>
            )}

            <Typography variant="title" style={{ ...configSectionStyle.formTitle, marginTop: "24px" }}>
              Imposta {props.settings.subject == 1 ? "giocatore" : "squadra"}
            </Typography>
            <FormSkeleton label="Squadra">
              <CustomToolTip
                hidden={!disabledTeamSelect()}
                followCursor={true}
                title={props.settings.tool === 1 ? 'Seleziona "Maggiore di"' : 'Seleziona "Giornate"'}
              >
                <InputSelect disabled={disabledTeamSelect()} inputSelectItem={teamsListValues} selectedValue={selectedTeam} onSelectChange={selectTeam} />
              </CustomToolTip>
            </FormSkeleton>
            {props.settings.subject === 1 && (
              <FormSkeleton label="Giocatore">
                <CustomToolTip hidden={!disabledPlayerSelect()} followCursor={true} title={'Seleziona "Squadra"'}>
                  <InputSelect
                    disabled={disabledPlayerSelect()}
                    selectedValue={selectedPlayer}
                    inputSelectItem={playersListValues}
                    onSelectChange={selectPlayer}
                  />
                </CustomToolTip>
              </FormSkeleton>
            )}
          </ConfigForm>
        </div>
        <div style={{ display: "flex", flexDirection: "column", paddingRight: styleConstant.padding.x3, marginTop: "auto" }}>
          {props.mobile ? (
            <>
              {!props.isCalcEditable && (
                <ContainedButton
                  text={props.settings.subject == 1 ? "Crea giocatore" : "Crea squadra"}
                  onClick={() =>
                    createNewCalculus(selectedStatistic, selectedGreaterThan, selectedDays, selectedTeam, selectedPlayer, selectedSeason, selectedOperation)
                  }
                  fullHeihgt={true}
                  style={{ marginTop: styleConstant.margin.x1 }}
                  disabled={!disableAddButton()}
                  id={"custom-contained-button"}
                />
              )}
            </>
          ) : (
            <ContainedButton
              text={props.settings.subject == 1 ? "Crea giocatore" : "Crea squadra"}
              onClick={() =>
                createNewCalculus(selectedStatistic, selectedGreaterThan, selectedDays, selectedTeam, selectedPlayer, selectedSeason, selectedOperation)
              }
              fullHeihgt={true}
              style={{ marginBottom: styleConstant.margin.x1 }}
              disabled={!disableAddButton()}
            />
          )}
          {props.mobile ? (
            <>
              {props.isCalcEditable && (
                <ContainedButton
                  text={props.settings.subject == 1 ? "Modifica giocatore" : "Modifica squadra"}
                  onClick={() =>
                    updateCalculus(
                      props.selectedCalculus?.id,
                      selectedStatistic,
                      selectedGreaterThan,
                      selectedDays,
                      selectedTeam,
                      selectedPlayer,
                      selectedSeason,
                      selectedOperation
                    )
                  }
                  id={props.mobile ? "custom-contained-button" : ""}
                  fullHeihgt={true}
                  disabled={!disableEditButton()}
                />
              )}
            </>
          ) : (
            <TextButton
              text={props.settings.subject == 1 ? "Modifica giocatore" : "Modifica squadra"}
              action={() =>
                updateCalculus(
                  props.selectedCalculus?.id,
                  selectedStatistic,
                  selectedGreaterThan,
                  selectedDays,
                  selectedTeam,
                  selectedPlayer,
                  selectedSeason,
                  selectedOperation
                )
              }
              id={props.mobile ? "custom-contained-button" : ""}
              fullHeihgt={true}
              disabled={!disableEditButton()}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ Calculation, CalculationWork }) => ({
  statistics: Calculation.statistics,
  seasons: Calculation.seasons,
  days: Calculation.days,
  teams: Calculation.teams,
  players: Calculation.players,
  greaterThan: Calculation.greaterThan,
  selectedCalculus: CalculationWork.selectedCalculus,
  settings: CalculationWork.settings,
  operations: Calculation.operations,
  calculationList: CalculationWork.calculationList,
  guides: Calculation.guides,
});

const mapDispatchToProps = (dispatch) => ({
  loadTeams: (idsTeams) => dispatch(loadTeams(idsTeams)),
  loadPlayers: (idSeason, idTeam, idDays, idStatistic) => dispatch(loadPlayers(idSeason, idTeam, idDays, idStatistic)),
  loadDays: (idSeason) => dispatch(loadDays(idSeason)),
  loadStatistics: (idStatistics) => dispatch(loadStatistics(idStatistics)),
  loadGreaterThan: (idStatistic) => dispatch(loadGreaterThan(idStatistic)),
  createCalculus: (configurationObject) => dispatch(createCalculationObject(configurationObject)),
  updateCalculus: (id, configurationObject) => dispatch(updateCalculationObject(id, configurationObject)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigSection);
