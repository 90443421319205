import { Typography } from '@mui/material';
import { Stack } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { icons } from '../../assets/styles/constant/general';
import { palette } from '../../assets/styles/constant/palette';
import ContainedButton from '../../core/components/buttons/ContainedButton';
import CustomIcon from '../../core/components/CustomIcon';
import InputRadio from '../../core/components/input/InputRadio';
import InputSelect from '../../core/components/input/InputSelect';
import { brkRef, convertArrayToObject } from '../../helpers/util';

const radioItems = [
	{label: 'Singola', value: 0},
	{label: 'Multipla', value: 1},
	{label: 'Migliori', value: 2},
];

const ConfigurationSection = ({
	activeMode = 0,
	onActiveModeChange = () => {},
	createEvent = () => {},
	execSingolaCalc = () => {},
	execMiglioriCalc = () => {},
	resetResult = () => {},
	tipsterGroups,
	tipsterMigliori,
	tipsterEvents,
	tipsterStatistics,
	loading = false,
}) => {

	// Grupo eventi
	const [eventGroup, setEventGroup] = React.useState('');
	const [eventGroupsListItem, setEventGroupListItem] = React.useState([]);
	// Sottoeventi
	const [subEventGroup, setSubEventGroup] = React.useState('');
	const [subEventGroupListItems, setSubEventGroupListItem] = React.useState([]);
	// Statistica
	const [statistic, setStatistic] = React.useState('');
	const [statisticListItem, setStatisticListItem] = React.useState([]);
	// Evento
	const [event, setEvent] = React.useState('');
	const [eventListItem, setEventListItem] = React.useState([]);
	// Migliori gruppo eventi
	const [miglioriEventGroup, setMiglioriEventGroup] = React.useState('');
	const [miglioriEventGroupListValue, setMiglioriEventGroupListValue] = React.useState([])

	React.useEffect(() => {
		if (activeMode !== 2) init();
		else initMigliori();
	}, [activeMode]);

	const initMigliori = () => {
		let _miglioriEventGroupListItem = [];
		let miglioriEventGroupIds = Object.keys(tipsterMigliori).sort((a, b) => {
			if (a < b) return -1;
			return b < a ? 1 : 0;
		});
		miglioriEventGroupIds.map(evtId => {
			const obj = tipsterMigliori[evtId];
			const { id, nome } = obj;
			_miglioriEventGroupListItem.push({ label: nome, value: id });
		});
		setMiglioriEventGroupListValue(_miglioriEventGroupListItem);
		if (_miglioriEventGroupListItem.length === 0) return;
		onMiglioriEventGroupChange(_miglioriEventGroupListItem[0]);
	}

	const init = () => {
		const _tipsterGroups = brkRef(tipsterGroups);
		let _eventGroupListItem = [];
		const eventGroupIds = Object.keys(_tipsterGroups).sort((a, b) => {
			if (a < b) return -1;
			return b < a ? 1 : 0;
		});
		eventGroupIds.map(evtGroupId => {
			const eventGroupObject = _tipsterGroups[evtGroupId];
			const { id, nome } = eventGroupObject;
			_eventGroupListItem.push({ label: nome, value: id });
		});
		setEventGroupListItem(_eventGroupListItem);
		if (_eventGroupListItem.length === 0) return;
		onEventGroupChange(_eventGroupListItem[0]);
	}

	const onEventGroupChange = (value) => {
		resetResult();
		let _subEventGroupListItem = [];
		const eventGroupId = value.value;
		const eventGroupObj = brkRef(tipsterGroups[eventGroupId]);
		const subEventGroups = convertArrayToObject(eventGroupObj.filtri);
		const filterIds = Object.keys(subEventGroups).sort((x, y) => (parseInt(x) - parseInt(y)));
		filterIds.map(filterId => {
			const obj = subEventGroups[filterId];
			const { id, nome } = obj;
			_subEventGroupListItem.push({ label: nome, value: id });
		});
		setEventGroup(value);
		setSubEventGroupListItem(_subEventGroupListItem);
		if (_subEventGroupListItem.length === 0) return;
		onSubEventGroupChange(_subEventGroupListItem[0], eventGroupId);
	}

	const onSubEventGroupChange = (value, eventGroupId) => {
		resetResult();
		let _statisticListItem = [];
		const subEventGroupId = value.value;
		const _eventGroupId = eventGroupId || eventGroup.value;
		const subEventGroupObj = tipsterGroups[_eventGroupId].filtri[subEventGroupId];
		const statistics = convertArrayToObject(subEventGroupObj.statistiche);
		const statsIds = Object.keys(statistics).sort((x, y) => (parseInt(x) - parseInt(y)));
		statsIds.map(statId => {
			const statObj = tipsterStatistics[statId];
			const { id, nome } = statObj;
			_statisticListItem.push({ label: nome, value: id });
		})
		setSubEventGroup(value);
		setStatisticListItem(_statisticListItem);
		if (_statisticListItem.length === 0) return;
		onStatisticsChange(_statisticListItem[0], eventGroupId, subEventGroupId);
	}

	const onStatisticsChange = (value, eventGroupId, subEventGroupId) => {
		resetResult();
		let _eventListItem = [];
		const statisticId = value.value;
		const _eventGroupId = eventGroupId || eventGroup.value;
		const _subEventGroupId = subEventGroupId || subEventGroup.value;
		const statObj = tipsterGroups[_eventGroupId].filtri[_subEventGroupId].statistiche[statisticId];
		const eventsIds = statObj.eventi;
		eventsIds.map(eventId => {
			const eventObj = tipsterEvents[eventId];
			const { id, nome } = eventObj;
			_eventListItem.push({ label: nome, value: id });
		});
		setStatistic(value);
		setEventListItem(_eventListItem);
		if (_eventListItem.length === 0) return;
		onEventChange(_eventListItem[0]);
	}

	const onEventChange = (value) => {
		setEvent(value);
		resetResult();
	}

	const onMiglioriEventGroupChange = (value) => {
		setMiglioriEventGroup(value);
		resetResult();
	}

	const resetSection = () => {
		setEventGroup('');
		setEventGroupListItem([]);
		setSubEventGroup('');
		setSubEventGroupListItem([]);
		setStatistic('');
		setStatisticListItem([]);
		setEvent('');
		setEventListItem([]);
	}

	const onRadioClick = (value) => {
		if (value !== activeMode) resetSection();
		onActiveModeChange(value);
	}

	const startCalc = () => {
		if (activeMode === 0) {
			const eventId = event.value;
			const price = tipsterEvents[eventId].prezzo;
			execSingolaCalc(eventId, price);
		} else {
			const eventId = miglioriEventGroup.value;
			const price = tipsterMigliori[eventId].prezzo;
			execMiglioriCalc(eventId, price);
		}
	}

	const getDisabledButtonState = () => {
		if (loading) return true;
		if (activeMode === 2) return !miglioriEventGroup.value;
		return !event.value;
	}

	const getCalcAddButtonText = () => {
		if (activeMode === 1) return 'Aggiungi';
		else {
			if (loading) return 'Sto calcolando ...';
			else {
				let price = 0;
				if (event.value) price = tipsterEvents[event.value].prezzo;
				else if (miglioriEventGroup.value) price = tipsterMigliori[miglioriEventGroup.value].prezzo;
				return 'Calcola ' + price;
			}
		}
	}

	const ScrollContainer = ({children}) => (
		<div id='custom-scrollbar' style={{
			position: 'absolute',
			right: 0, left: 0, bottom: 0, top: 0,
			paddingRight: '24px',
			overflow: 'auto',
			flexDirection: 'column',
			gap: '8px',
			flex: 1
		}}>{children}</div>
	)

	const renderRadioButtons = () => (
		<Stack pr={'24px'} mt={'16px'}>
				<Typography fontWeight={'700'}
					color={palette.grey.grey_100}>
					Modalità
				</Typography>
				<InputRadio
					selectedValue={activeMode}
					handleSelect={onRadioClick}
					radioItem={radioItems} />
		</Stack>
	)

	const renderForm = () => (
		<Stack flex={1}
			mt={'24px'}
			position={'relative'}>
				<ScrollContainer>
					<Typography fontWeight={'700'}
						color={palette.grey.grey_100}>
						Gruppo eventi
					</Typography>
					<Stack gap={'8px'}>
						<InputSelect selectedValue={eventGroup}
							onSelectChange={onEventGroupChange}
							inputSelectItem={eventGroupsListItem}/>
						<InputSelect inputSelectItem={subEventGroupListItems}
							selectedValue={subEventGroup}
							onSelectChange={onSubEventGroupChange}
							style={{background: '#2B3139'}}/>
					</Stack>
					<Typography fontWeight={'700'}
						color={palette.grey.grey_100}
						style={{marginTop: '32px'}}>
						Statistiche
					</Typography>
					<InputSelect inputSelectItem={statisticListItem}
						selectedValue={statistic}
						style={{background: '#2B3139'}}
						onSelectChange={onStatisticsChange}/>
					<Typography fontWeight={'700'}
						color={palette.grey.grey_100}
						style={{marginTop: '8px'}}>
						Evento
					</Typography>
					<InputSelect inputSelectItem={eventListItem}
						selectedValue={event}
						onSelectChange={onEventChange}
						style={{background: '#2B3139'}}/>
				</ScrollContainer>
		</Stack>
	)

	const renderMiglioriForm = () => (
		<Stack flex={1}
			mt={'24px'}
			position={'relative'}>
				<ScrollContainer>
					<Typography fontWeight={'700'}
						color={palette.grey.grey_100}>
						Gruppo eventi
					</Typography>
					<InputSelect selectedValue={miglioriEventGroup}
						onSelectChange={onMiglioriEventGroupChange}
						inputSelectItem={miglioriEventGroupListValue}/>
				</ScrollContainer>
		</Stack>
	)

  return (
		<Stack flex={1}
			borderRadius={'16px'}
			p={'24px 0px 24px 24px'}
			boxShadow={'rgb(0 0 0 / 20%) 4px 0px 22px 0px'}
			style={{background: palette.grey.grey_400}}>
				{/* <Typography
					color={'#FFF'}
					fontWeight={'500'}
					fontSize={'24px'}>
						Configura {activeMode === 0 ? 'evento' : 'eventi'}
				</Typography> */}
				{renderRadioButtons()}
				{
					activeMode !== 2
				 		? renderForm()
				 		: renderMiglioriForm()
				}
				<ContainedButton
					text={getCalcAddButtonText()}
					onClick={activeMode === 1 
						? () => createEvent({eventGroup, subEventGroup, statistic, event})
						: () => startCalc()
					}
					icon={!loading && activeMode !== 1 && <CustomIcon icon={icons.COINS} color='#000' />}
					disabled={getDisabledButtonState()}
					style={{marginTop: 'auto', marginRight: '24px', height: '50px' }}/>
		</Stack>
	)
}

const mapStateToProps = ({ Tipster }) => ({
  tipsterGroups: Tipster.groups,
	tipsterEvents: Tipster.events,
	tipsterStatistics: Tipster.statistics,
	tipsterMigliori: Tipster.migliori,
});

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigurationSection);