import {
  ACCOUNT_EXISTS_DIFFERENT_CREDENTIAL,
  AUTH_EMAIL_ALREADY_IN_USE,
  ERROR_NOT_MANAGED,
  GENERIC_ERROR,
} from "./common/codeError";
import { getFirebaseBackend } from "./common/firebase";

let fireBaseBackend = getFirebaseBackend();

export function createUserEmailPassword(user) {
  const { email, password, nome, cognome } = user;
  const displayName = nome + "/" + cognome;
  return fireBaseBackend
    .createUserWithEmailAndPassword(email, password)
    .then((auth) => {
      return updateUserAuth(displayName)
        .then(() => {
          let userAuthInfo = _getUserAuthInfo(auth);
          userAuthInfo.displayName = displayName;
          return userAuthInfo;
        })
        .catch((error) => {
          throw _handleError(error);
        });
    })
    .catch((error) => {
      if (error?.code === AUTH_EMAIL_ALREADY_IN_USE) {
        throw AUTH_EMAIL_ALREADY_IN_USE;
      }
      throw _handleError(error);
    });
}

export function loginEmailPassword(email, password) {
  return fireBaseBackend
    .signInWithEmailAndPassword(email, password)
    .then((auth) => {
      //console.log("auth", auth)
      return _getUserAuthInfo(auth);
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export async function logOut() {
  try {
    await fireBaseBackend.signOut();
  } catch (error) {
    throw _handleError(error);
  }
}

export function socialLogin(type) {
  return fireBaseBackend
    .socialLoginUser(type)
    .then((auth) => {
      return _getUserAuthInfo(auth);
    })
    .catch((error) => {
      //when user close login proces
      console.log("erroreeee", error.code);
      if (error == -5 || error === "User cancelled the login process" || error === ERROR_NOT_MANAGED) {
        throw ERROR_NOT_MANAGED;
      }

      if (error?.code === ACCOUNT_EXISTS_DIFFERENT_CREDENTIAL) {
        throw ACCOUNT_EXISTS_DIFFERENT_CREDENTIAL;
      }

      throw GENERIC_ERROR;
    });
}

export function updateUserAuth(displayName) {
  return fireBaseBackend
    .updateAuthProfile({ displayName })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export function deleteUserAuth() {
  return fireBaseBackend
    .deleteUser()
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export function forgetPassword(email) {
  return fireBaseBackend
    .forgetPassword(email)
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

function _getUserAuthInfo(auth) {
  let userAuthInfo = JSON.parse(JSON.stringify(auth.user));
  return userAuthInfo;
}

function _handleError(error) {
  var errorObj = {};
  if (typeof error === "object" && error !== null) {
    errorObj = {
      code: error?.code,
      message: error?.message,
    };
  } else {
    errorObj = {
      code: error,
      message: error,
    };
  }

  console.log("errorObj UserService", errorObj);
  return errorObj;
}
