import firebase from "firebase/app";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/functions";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID,
};

class FirebaseAuthBackend {
  googleProvider;
  facebookProvider;
  appleProvider;

  constructor() {
    if (firebaseConfig) {
      firebase.initializeApp(firebaseConfig);
    }
    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.appleProvider = new firebase.auth.OAuthProvider("apple.com");
  }

  onAuthStateChanged = (callBack) => {
    return firebase.auth().onAuthStateChanged(callBack);
  };

  dbOnceRead = (ref) => {
    return firebase.database().ref(ref).once("value");
  };

  dbSet = (ref, obj) => {
    return firebase.database().ref(ref).set(obj);
  };

  dbGetNewRef = (ref) => {
    return firebase.database().ref(ref).push();
  }

  dbUpdate = (ref, update) => {
    return firebase.database().ref(ref).update(update);
  }

  signInWithEmailAndPassword = (email, password) => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  signOut = () => {
    return firebase.auth().signOut();
  };

  socialLoginUser = async (type) => {
    let credential = {};
    try {
      switch (type) {
        case "apple":
          credential = await this._getAppleCredential();
          break;
        case "google":
          credential = await this._getGoogleCredential();
          break;
        case "facebook":
          credential = await this._getFacebookCredential();
          break;
      }
    } catch (error) {
      console.error(error);
    }

    if (!!credential) {
      return firebase.auth().signInWithCredential(credential);
    } else {
      throw "ERRORE AUTH";
    }
  };

  _getGoogleCredential = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(this.googleProvider);
      return result.credential;
    } catch (error) {
      console.error(error);
    }
  };

  _getFacebookCredential = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(this.facebookProvider);
      return result.credential;
    } catch (error) {
      console.error(error);
    }
  };

  _getAppleCredential = async () => {
    try {
      const result = await firebase.auth().signInWithPopup(this.appleProvider);
      return result.credential;
    } catch (error) {
      console.error(error);
    }
  };

  createUserWithEmailAndPassword = (email, password) => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };
  updateAuthProfile = (updates) => {
    const currentUser = firebase.auth().currentUser;
    return currentUser.updateProfile(updates);
  };

  deleteUser = () => {
    const currentUser = firebase.auth().currentUser;
    return currentUser.delete();
  };

  forgetPassword = (email) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          reject(this._handleError(error));
        });
    });
  };

  //TODO: deprecato...scollegare e rimuovere dai metodi firebase
  _handleError(error) {
    var errorObj = {};
    if (typeof error === "object" && error !== null) {
      errorObj = {
        code: error?.code,
        message: error?.message,
      };
    } else {
      errorObj = {
        code: error,
        message: error,
      };
    }

    console.log(errorObj);
    return errorObj;
  }

  _getUserAuthInfo(auth) {
    let userAuthInfo = JSON.parse(JSON.stringify(auth.user));
    userAuthInfo.isNewUser = auth?.additionalUserInfo?.isNewUser;
    return userAuthInfo;
  }

  callCloudFunction = (name, payload, emulator = false) => {
    return new Promise((resolve, reject) => {
      const defaultApp = firebase.app();
      const functionsForRegion = defaultApp.functions("europe-west1");
      if (emulator) functionsForRegion.useEmulator("localhost", 5002);
      //var call =  firebase.app().functions().httpsCallable(name);
      var call = functionsForRegion.httpsCallable(name);
      call(payload)
        .then((result) => {
          console.log("response", result);
          resolve(result);
        })
        .catch((error) => {
          console.log("ERROR CALL", JSON.stringify(error));
          reject(this._handleError(error));
        });
    });
  };
}

let _fireBaseBackend = null;

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = () => {
  if (!_fireBaseBackend) {
    try {
      _fireBaseBackend = new FirebaseAuthBackend();
    } catch (error) {
      console.log("ERRORE FIREBASE", error);
    }
  }
  return _fireBaseBackend;
};

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  if (_fireBaseBackend) {
    return _fireBaseBackend;
  }
  return initFirebaseBackend();
};

export { initFirebaseBackend, getFirebaseBackend };
