import React from "react";
import { connect } from "react-redux";
import CalculatorDesktopLayout from "../core/layout/calculatorPageLayout/calculatorDesktopLayout/CalculatorDesktopLayout";
import CalculatorMobileLayout from "../core/layout/calculatorPageLayout/calculatorMobileLayout/CalculatorMobileLayout";
import Profile from "./Profilo";

const ProfiloPage = (props) => {
  return (
    <>
      {props.windowBreakpoint === "LARGE" && <Profile />}
      {props.windowBreakpoint === "SMALL" && <Profile mobile />}
    </>
  );
};

const mapStateToProps = ({ Screen }) => ({
  windowBreakpoint: Screen.breakpoint,
});

export default connect(mapStateToProps)(ProfiloPage);
