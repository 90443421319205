import { getFirebaseBackend } from "./common/firebase";

const CF_OUTCOM_PAYMENT_FREE = "freePurchaseWeb";

const fireBaseBackend = getFirebaseBackend();

export const freeRewardPurchase = (uid, plan) => {
  const payload = {
    uid,
    plan: plan.id,
  };
  console.log("payload", payload);
  return new Promise((resolve, reject) => {
    return fireBaseBackend
      .callCloudFunction(CF_OUTCOM_PAYMENT_FREE, payload)
      .then((response) => {
        console.log("response", response);
        resolve(response);
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};
