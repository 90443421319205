import React, { Component, Profiler } from "react";
import { connect } from "react-redux";
import { PlayerInfo, ScenarioInfo } from "../core/components";
import ContainedButton from "../core/components/buttons/ContainedButton";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import TextButton from "../core/components/buttons/TextButton";
import CustomToolTip from "../core/components/CustomToolTip";
import { palette } from "../assets/styles/constant/palette";
import CustomIconButton from "../core/components/buttons/CustomIconButton";
import {
  Box,
  ButtonBase,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import FormSkeleton from "../core/components/input/FormSkeleton";
import InputSelect from "../core/components/input/InputSelect";
import ExpandMoreIcon from "../core/components/icons/ExpandMoreIcon";
import InputRadio from "../core/components/input/InputRadio";
import CustomDrawer from "../core/components/CustomDrawer/CustomDrawer";

class Temp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calcNumber: 1,
      calcObjectName: "Manuel Locatelli",
      teamName: "Juventus",
      calcPerc: null,
      calcStat: "GOL SEGNATI",
      calcGreaterThan: 2,
      calcDays: [21, 22, 23, 24],
      isActive: false,
      calcPrice: 60,
      scenarioGiornata: 21,
      scenarioTitolarita: "TITOLARE",
      scenarioMinutes: 15,
      scenarioRuolo: "MC NEL 3-4-2-1",
      scenarioRigorista: "SI",
      isExpanded: false,
      isDrawerOpen: false,
    };
  }

  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState) => {};

  componentWillUnmount = () => {};

  onPlayerInfoClick = () => {
    this.setState({
      isActive: !this.state.isActive,
    });
  };

  onPlayerInfoDelete = () => {
    console.log("ELIMINA CALCOLO =>", this.state.calcNumber);
  };

  onPlayerInfoDuplicate = () => {
    console.log("DUPLICA CALCOLO =>", this.state.calcNumber);
  };

  calcSimulation = (newCalcPerc) => {
    this.setState(
      {
        calcPerc: newCalcPerc,
      },
      () => {
        console.log("SIMULAZIONE CALCOLO EFFETTUATA");
      }
    );
  };

  resetCalc = () => {
    this.setState(
      {
        calcPerc: null,
      },
      () => {
        console.log("CALC RESET");
      }
    );
  };

  onExpandClick = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  toggleDrawer = () => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen,
    });
  };

  render() {
    const { state } = this.state;
    return (
      <div
        style={{
          backgroundColor: "#000",
          height: "100vh",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
          }}
        >
          <CustomDrawer
            open={this.state.isDrawerOpen}
            action={this.toggleDrawer}
          />
          <div style={{ flex: 1, padding: "50px" }}>
            <PlayerInfo
              calcNumber={this.state.calcNumber}
              calcObjectName={this.state.calcObjectName}
              teamName={this.state.teamName}
              calcPerc={this.state.calcPerc}
              calcStat={this.state.calcStat}
              calcGreaterThan={this.state.calcGreaterThan}
              calcDays={this.state.calcDays}
              isActive={this.state.isActive}
              calcPrice={this.state.calcPrice}
              onClick={this.onPlayerInfoClick}
              onDelete={this.onPlayerInfoDelete}
              onDuplicate={this.onPlayerInfoDuplicate}
            />
            <div
              style={{
                display: "flex",
                gap: "40px",
                marginTop: "50px",
                marginBottom: "50px",
              }}
            >
              <ContainedButton
                onClick={() => this.calcSimulation(23.4)}
                text="Simulate calc"
              />
              <ContainedButton
                onClick={() => this.resetCalc()}
                text="Reset calc"
              />
            </div>
            <ScenarioInfo
              scenarioGiornata={this.state.scenarioGiornata}
              scenarioTitolarita={this.state.scenarioTitolarita}
              scenarioMinutes={this.state.scenarioMinutes}
              scenarioRuolo={this.state.scenarioRuolo}
              scenarioRigorista={this.state.scenarioRigorista}
              isExpanded={this.state.isExpanded}
              onExpandClick={this.onExpandClick}
            />
            <div
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "50px",
              }}
            >
              <FormSkeleton label={"Label"}>
                <InputSelect multiple={true} />
              </FormSkeleton>
              <FormSkeleton label={"Label"}>
                <InputSelect />
              </FormSkeleton>
            </div>
            <div
              style={{
                display: "flex",
                gap: "20px",
                marginTop: "50px",
              }}
            >
              <FormSkeleton label={"Label"}>
                <InputRadio row={true} />
              </FormSkeleton>
              <FormSkeleton label={"Label"}>
                <InputRadio row={true} />
              </FormSkeleton>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "50px",
                gap: "30px",
              }}
            >
              <ContainedButton text="Esegui tutti" />

              <CustomToolTip
                title={"Text button"}
                color={palette.primary.main}
                textColor="black"
                hasArrow={true}
              >
                <ContainedButton text="Modifica giocatore #3" disabled={true} />
              </CustomToolTip>
              <ContainedButton
                text="Esegui tutti 80"
                icon={<MonetizationOnOutlinedIcon />}
                adaptToParentContainer={true}
                // adaptToParentContainer={true}
              />
            </div>
            <CustomToolTip
              title={"Text button"}
              color={palette.primary.main}
              textColor="black"
              hasArrow={true}
            >
              <TextButton text={"Text button"} />
            </CustomToolTip>
            <CustomToolTip
              title="icon button"
              color={palette.primary.main}
              textColor={palette.black.main}
            >
              <CustomIconButton icon={<MonetizationOnOutlinedIcon />} />
            </CustomToolTip>
            <Tooltip title="ddshibh">
              <IconButton>
                <MonetizationOnOutlinedIcon color="primary" />
              </IconButton>
            </Tooltip>
            <ExpandMoreIcon isExpanded={this.state.isExpanded} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Temp);
