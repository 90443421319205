// FUNZIONI DI SERIVIZI PER FANTACALCIO

import { getFirebaseBackend } from "./common/firebase";

const fireBaseBackend = getFirebaseBackend();
const FANTA_NAME = '/name';
const FANTA_TYPE = '/fantaType';
const LEAGUE_NAME = '/leagueName';

export async function createFantaTeam(uid, name = 'Rosa', fantaType, leagueName) {
	try {
		const newRef = await fireBaseBackend.dbGetNewRef('/users/' + uid + '/fantaTeams');
		const fantaTeamId = newRef.key;
		const tsCreated = new Date().getTime();
		const fantaTeamObj = { id: fantaTeamId, name, fantaType, leagueName, tsCreated };
		return newRef.set(fantaTeamObj).then(() => fantaTeamId);
	} catch (error) {
		console.log(error)
	}
}

export function editFantaTeamData(uid, teamId, dataObj) {
	if (!teamId) throw 'Invalid team id';
	const refPath = '/users/' + uid + '/fantaTeams/' + teamId;
	const { name, fantaType, leagueName } = dataObj;
	const updates = {
		[FANTA_NAME]: name,
		[FANTA_TYPE]: fantaType,
		[LEAGUE_NAME]: leagueName,
	}
	return fireBaseBackend.dbUpdate(refPath, updates);
}

export function deleteFantaTeam(uid, teamId) {
	const refPath = '/users/' + uid + '/fantaTeams/' + teamId;
	return fireBaseBackend.dbSet(refPath, null);
}

export function addPlayerToFantaTeam(uid, teamId, playerId) {
	const refPath = '/users/' + uid + '/fantaTeams/' + teamId + '/players/' + playerId;
	return fireBaseBackend.dbSet(refPath, playerId);
}

export function removePlayerFromFantaTeam(uid, teamId, playerId) {
	const refPath = '/users/' + uid + '/fantaTeams/' + teamId + '/players/' + playerId;
	return fireBaseBackend.dbSet(refPath, null);
}