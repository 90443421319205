import { createTheme } from "@mui/material";
import { palette } from "./constant/palette";
import { styleConstant } from "./constant/styleConstants";

export const select = createTheme({
  components: {
    MuiSelect: {
      variants: [
        {
          props: { variant: "standard", className: "input-select" },
          style: {
            color: "white",
            position: "relative",
            fontWeight: "medium",
            background: `${palette.grey.grey_200}`,
            borderRadius: styleConstant.borderRadius.x1,
            // minHeight: "48px",
            // maxHeight: styleConstant.maxHeight.x6,
            flex: 1,
            "& .MuiSelect-select.MuiSelect-standard.MuiSelect-multiple.MuiInput-input.MuiInputBase-input.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input": {
              // padding: styleConstant.padding.buttonBaseSelect,
              background: "transparent",
              minHeight: styleConstant.minHeight.x4,
            },
            "& .MuiSelect-select.MuiSelect-standard.MuiInput-input.MuiInputBase-input": {
              // padding: styleConstant.padding.buttonBaseSelect,
              padding: styleConstant.padding.selectPadding,
              background: "transparent",
              minHeight: styleConstant.minHeight.x4,
              display: "flex",
              alignItems: "center",
            },
          },
        },
        {
          props: { variant: "standard", className: "input-select", disabled: true },
          style: {
            color: "white",
            position: "relative",
            fontWeight: "medium",
            background: `${palette.grey.grey_400}`,
          },
        },
      ],
    },
  },
});
