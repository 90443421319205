import { ButtonBase, Typography } from "@mui/material";
import React from "react";
import { creditLabelStyle, profilePageStyle, styleConstant, textSecondaryStyle } from "../assets/styles/constant/styleConstants";
import drawer_logo from "../assets/images/main_logo.svg";
import bluniteam_logo from "../assets/images/blunìTeam_logo.png";
import { palette } from "../assets/styles/constant/palette";
import CustomToolTip from "../core/components/CustomToolTip";
import CustomIconButton from "../core/components/buttons/CustomIconButton";
import CustomIcon from "../core/components/CustomIcon";
import { icons } from "../assets/styles/constant/general";
import TextButton from "../core/components/buttons/TextButton";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import { display } from "@mui/system";

const Info = (props) => {
  const email = "supporto@footballvision.it";
  const termAndConditionsLink = "https://www.iubenda.com/termini-e-condizioni/76374144";
  const privacyPolicyLink = "https://www.iubenda.com/privacy-policy/76374144";
  const handleSendEmail = () => {
    window.open(`mailto:${email}?subject=Richiesta%20supporto`);
  };

  const goToDevWebsite = () => {
    window.open("https://bluniteam.com/");
  };

  const renderAppInfo = () => (
    <div
      style={{
        display: !props.mobile ? "flex" : "",
        flexDirection: !props.mobile ? "row" : "",
        alignItems: !props.mobile ? "center" : "",
        width: "100%",
        marginTop: styleConstant.margin.x3,
      }}
    >
      <img
        src={drawer_logo}
        style={{
          // maxWidth: "150px",
          width: "auto",
          minHeight: "100px",
          height: props.mobile ? "90px" : "200px",
          maxHeight: "200px",
          alignSelf: "center",
          padding: !props.mobile ? styleConstant.padding.x2 : "",
          float: props.mobile ? "right" : "",
          margin: props.mobile ? "16px" : "",
          marginTop: props.mobile ? styleConstant.margin.x3 : "",
        }}
      />
      {props.mobile ? (
        <>
          <Typography variant="title">Chi siamo</Typography>
          <br />
          <br />
          <Typography
            variant="body"
            style={{
              marginTop: styleConstant.margin.x1,
            }}
          >
            Siamo un team appassionato di calcio composto da data analysts, ingegneri e marketers con l'obiettivo di integrare sempre più l'intelligenza artificiale e lo sport per migliorarne le analisi ed i risultati.
            <br />
            Football Vision è un'app che si basa su di una serie di modelli di machine learning che migliorano col passare del tempo nonché sullo studio delle distribuzioni di probabilità delle variabili calcistiche.
            <br />
            Football Vision fornisce uno strumento per calcolare sia le probabilità di accadimento di un determinato evento in una partita di Serie A, sia di analizzare le performance attraverso degli indici appositamente ideati.
            <br />
            Lo sviluppo ha richiesto oltre tre anni di lavoro nei quali i nostri data analysts hanno analizzato dati forniti da Wyscout, al fine di sviluppare un sistema di algoritmi basati sulle reti neurali che permette di calcolare le probabilità ed analizzare le performance.
          </Typography>
        </>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: styleConstant.margin.x5,
          }}
        >
          <Typography variant="title">Chi siamo</Typography>
          <Typography
            variant="body"
            style={{
              marginTop: styleConstant.margin.x1,
            }}
          >
            Siamo un team appassionato di calcio composto da data analysts, ingegneri e marketers con l'obiettivo di integrare sempre più l'intelligenza artificiale e lo sport per migliorarne le analisi ed i risultati.
            <br />
            Football Vision è un'app che si basa su di una serie di modelli di machine learning che migliorano col passare del tempo nonché sullo studio delle distribuzioni di probabilità delle variabili calcistiche.
            <br />
            Football Vision fornisce uno strumento per calcolare sia le probabilità di accadimento di un determinato evento in una partita di Serie A, sia di analizzare le performance attraverso degli indici appositamente ideati.
            <br />
            Lo sviluppo ha richiesto oltre tre anni di lavoro nei quali i nostri data analysts hanno analizzato dati forniti da Wyscout, al fine di sviluppare un sistema di algoritmi basati sulle reti neurali che permette di calcolare le probabilità ed analizzare le performance.
          </Typography>
        </div>
      )}
    </div>
  );

  const renderSupportButton = () => (
    <ButtonBase
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        padding: `20px ${styleConstant.padding.x3}`,
        background: palette.grey.grey_300,
        borderRadius: styleConstant.borderRadius.x2,
        marginTop: styleConstant.margin.x5,
      }}
      onClick={() => handleSendEmail()}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="smallTitle" style={{ ...textSecondaryStyle, ...creditLabelStyle, textAlign: "initial" }}>
          Hai bisogno di assistenza? Inviaci una e-mail a
        </Typography>
        <Typography variant="title">{email}</Typography>
      </div>
      <CustomToolTip title="Contatta il supporto">
        {/* <CustomIconButton icon={<CustomIcon icon={icons.ANGLE_RIGHT} />} onClick={() => handleSendEmail()} /> */}
        <CustomIcon icon={icons.ANGLE_RIGHT} />
      </CustomToolTip>
    </ButtonBase>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "100%",
        justifyContent: "space-between",
        padding: `${styleConstant.padding.x4} ${styleConstant.padding.x4}`,
        overflowY: props.mobile ? "auto" : "",
        background: props.mobile ? palette.grey.grey_600 : "",
      }}
    >
      <div>
        <Typography style={profilePageStyle.title}>Informazioni</Typography>

        {renderAppInfo()}
        {renderSupportButton()}
      </div>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <Typography variant="title" style={{ marginTop: styleConstant.margin.x3 }}>
          Aggiornamento dati
        </Typography>
        <Typography
          variant="body"
          style={{
            marginTop: styleConstant.margin.x1,
          }}
        >
          Le statistiche predittive (probabilità e valori attesi degli indici) vengono costantemente aggiornate fino all'inizio della prima partita della giornata. I dati sulle partite giocate vengono caricati il giorno dopo l'ultima partita della giornata.
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center", columnGap: styleConstant.gap.medium, marginTop: styleConstant.margin.x2 }}>
        <TextButton text={"Termini e Condizioni"} action={() => window.open(termAndConditionsLink)} />
        <TextButton text={"Privacy Policy"} action={() => window.open(privacyPolicyLink)} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: props.mobile ? "column" : "row",
          alignItems: "center",
          background: palette.grey.grey_300,
          borderRadius: styleConstant.borderRadius.x2,
          padding: !props.mobile ? styleConstant.padding.x4 : styleConstant.padding.x3,
          marginTop: !props.mobile ? "auto" : styleConstant.margin.x2,
        }}
      >
        <img
          src={bluniteam_logo}
          style={{
            width: "auto",
            // minHeight: "85px",
            height: "70px",
            maxHeight: "85px",
            alignSelf: "center",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: !props.mobile ? styleConstant.margin.x5 : "",
          }}
        >
          <Typography
            variant="smallTitle"
            style={{ ...textSecondaryStyle, fontSize: "14px", fontWeight: 700, marginTop: props.mobile ? styleConstant.margin.x2 : "" }}
          >
            Info sviluppatori
          </Typography>
          <Typography variant="title">BlunìTeam</Typography>
          <Typography
            variant="body"
            style={{
              marginTop: styleConstant.margin.x1,
            }}
          >
            Siamo un Team di Ingegneri Informatici esperti nello sviluppo di applicazioni Mobile (IOS e Android) e Web. Aiutiamo Aziende e Startup nella
            realizzazione di applicazioni innovative utilizzando tecnologie moderne.
          </Typography>
        </div>
        <div
          style={{
            marginLeft: styleConstant.margin.x3,
          }}
        >
          <TextButton text={"Sito web"} icon={<CustomIcon icon={icons.ANGLE_RIGHT} />} action={() => goToDevWebsite()} />
        </div>
      </div>
    </div>
  );
};

export default Info;
