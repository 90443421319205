import { takeEvery, put, call } from "redux-saga/effects";
import { LOGIN_USER, LOGIN_USER_APPLE, LOGIN_USER_FACEBOOK, LOGIN_USER_GOOGLE, LOGOUT_USER, RESEND_EMAIL_VERIFICATION } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError, loginError } from "./actions";
import { getFirebaseBackend } from "../../../service/common/firebase";
import * as UserAuth from "../../../service/UserAuth";
import { logError } from "../../../service/handleError";
import {
  APPLE_LOGIN_ERROR,
  AUTH_LOGIN_ERROR,
  FACEBOOK_LOGIN_ERROR,
  GOOGLE_LOGIN_ERROR,
  LOGOUT_ERROR,
  RESEND_EMAIL_VERIFICATION_ERROR,
} from "../../../service/constant/errorCodes";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user } }) {
  try {
    const userAuthInfo = yield call(UserAuth.loginEmailPassword, user.email, user.password);
    if (userAuthInfo == null) {
      logError(AUTH_LOGIN_ERROR);
      yield put(loginError(AUTH_LOGIN_ERROR));
    } else {
      yield put(loginSuccess(userAuthInfo));
    }
  } catch (error) {
    yield put(loginError(AUTH_LOGIN_ERROR, error.code));
  }
}

function* loginUserApple() {
  try {
    const userAuthInfo = yield call(UserAuth.socialLogin, "apple");
    if (userAuthInfo == null) {
      logError(APPLE_LOGIN_ERROR);
      yield put(loginError(APPLE_LOGIN_ERROR));
    } else {
      yield put(loginSuccess(userAuthInfo));
    }
  } catch (error) {
    yield put(loginError(error));
  }
}

function* loginUserGoogle() {
  try {
    const userAuthInfo = yield call(UserAuth.socialLogin, "google");
    if (userAuthInfo == null) {
      logError(GOOGLE_LOGIN_ERROR);
      yield put(loginError(GOOGLE_LOGIN_ERROR));
    } else {
      yield put(loginSuccess(userAuthInfo));
    }
  } catch (error) {
    yield put(loginError(error));
  }
}

function* loginUserFacebook() {
  try {
    const userAuthInfo = yield call(UserAuth.socialLogin, "facebook");
    if (userAuthInfo == null) {
      logError(FACEBOOK_LOGIN_ERROR);
      yield put(loginError(FACEBOOK_LOGIN_ERROR));
    } else {
      yield put(loginSuccess(userAuthInfo));
    }
  } catch (error) {
    yield put(loginError(error));
  }
}

function* resendEmailVerification() {
  try {
    const response = yield call(fireBaseBackend.sendEmailVerification);
    if (response) {
      yield put(resendEmailVerification());
    } else {
      logError(RESEND_EMAIL_VERIFICATION_ERROR);
      yield put(apiError(RESEND_EMAIL_VERIFICATION_ERROR));
    }
  } catch (error) {
    yield put(apiError(RESEND_EMAIL_VERIFICATION_ERROR));
  }
}

function* logoutUser() {
  try {
    const response = yield call(UserAuth.logOut);
    yield put(logoutUserSuccess(response));
  } catch (error) {
    logError(LOGOUT_ERROR);
    yield put(apiError(LOGOUT_ERROR));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGIN_USER_APPLE, loginUserApple);
  yield takeEvery(LOGIN_USER_GOOGLE, loginUserGoogle);
  yield takeEvery(LOGIN_USER_FACEBOOK, loginUserFacebook);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(RESEND_EMAIL_VERIFICATION, resendEmailVerification);
}

export default authSaga;
