export const LOAD_BASIC_CALC_DATA = "LOAD_BASIC_CALC_DATA";
export const LOAD_BASIC_CALC_DATA_SUCCESS = "LOAD_BASIC_CALC_DATA_SUCCESS";
export const LOAD_BASIC_CALC_DATA_ERROR = "LOAD_BASIC_CALC_DATA_ERROR";

export const LOAD_TEAMS = "LOAD_TEAMS";
export const LOAD_TEAMS_SUCCESS = "LOAD_TEAM_SUCCESS";
export const LOAD_TEAMS_ERROR = "LOAD_TEAM_ERROR";

export const LOAD_PLAYERS = "LOAD_PLAYERS";
export const LOAD_PLAYERS_SUCCESS = "LOAD_PLAYERS_SUCCESS";
export const LOAD_PLAYERS_ERROR = "LOAD_PLAYERS_ERROR";

export const LOAD_GREATER_THAN = "LOAD_GREATER_THAN";
export const LOAD_GREATER_THAN_SUCCESS = "LOAD_GREATER_THAN_SUCCESS";
export const LOAD_GREATER_THAN_ERROR = "LOAD_GREATER_THAN_ERROR";

export const LOAD_STATISTICS = "LOAD_STATISTICS";
export const LOAD_STATISTICS_SUCCESS = "LOAD_STATISTICS_SUCCESS";
export const LOAD_STATISTICS_ERROR = "LOAD_STATISTICS_ERROR";

export const LOAD_DAYS = "LOAD_DAYS";
export const LOAD_DAYS_SUCCESS = "LOAD_DAYS_SUCCESS";
export const LOAD_DAYS_ERROR = "LOAD_DAYS_ERROR";

export const LOAD_OPERATIONS = "LOAD_OPERATIONS";

export const LOAD_SEASONS = "LOAD_SEASONS";
