import { createTheme } from "@mui/material";
import { palette } from "./constant/palette";
import { styleConstant } from "./constant/styleConstants";

export const chip = createTheme({
  components: {
    MuiChip: {
      variants: [
        {
          props: { variant: "input-select" },
          style: {
            background: palette.grey.grey_300,
            borderRadius: styleConstant.borderRadius.x1,
            color: palette.white.main,
            fontFamily: "Roboto",
            // zIndex: 50,
          },
        },
      ],
    },
  },
});
