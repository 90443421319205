export const FETCH_TIPSTER_GRUPPI = 'FETCH_TIPSTER_GRUPPI';
export const FETCH_TIPSTER_GRUPPPI_SUCCESS = 'FETCH_TIPSTER_GRUPPPI_SUCCESS';
export const FETCH_TIPSTER_GRUPPI_ERROR = 'FETCH_TIPSTER_GRUPPI_ERROR';

export const FETCH_TIPSTER_EVENTI = 'FETCH_TIPSTER_EVENTI';
export const FETCH_TIPSTER_EVENTI_SUCCESS = 'FETCH_TIPSTER_EVENTI_SUCCESS';
export const FETCH_TIPSTER_EVENTI_ERROR = 'FETCH_TIPSTER_EVENTI_ERROR';

export const FECTH_TIPSTER_MIGLIORI = 'FECTH_TIPSTER_MIGLIORI';
export const FETCH_TIPSTER_MIGLIORI_SUCCESS = 'FETCH_TIPSTER_MIGLIORI_SUCCESS';
export const FETCH_TIPSTER_MIGLIORI_ERROR = 'FETCH_TIPSTER_MIGLIORI_ERROR';

export const FETCH_TIPSTER_STATISTICHE = 'FETCH_TIPSTER_STATISTICHE';
export const FETCH_TIPSTER_STATISTICHE_SUCCESS = 'FETCH_TIPSTER_STATISTICHE_SUCCESS';
export const FETCH_TIPSTER_STATISTICHE_ERROR = 'FETCH_TIPSTER_STATISTICHE_ERROR';

export const FETCH_TIPSTER_DATA = 'FETCH_TIPSTER_DATA';
export const FETCH_TIPSTER_DATA_SUCCESS = 'FETCH_TIPSTER_DATA_SUCCESS';
export const FETCH_TIPSTER_DATA_ERROR = 'FETCH_TIPSTER_DATA_ERROR';