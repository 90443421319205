import { ButtonBase } from "@mui/material";
import React from "react";
import { icons } from "../../../../assets/styles/constant/general";
import { palette } from "../../../../assets/styles/constant/palette";
import { styleConstant } from "../../../../assets/styles/constant/styleConstants";
import CustomIcon from "../../../components/CustomIcon";

const CalculatorMobileFooter = ({ action }) => {
  const renderButton = () => (
    <ButtonBase
      style={{
        height: "56px",
        width: "56px",
        background: palette.primary.main,
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
      }}
      onClick={action}
    >
      <CustomIcon icon={icons.PLUS} color={palette.black.main} />
    </ButtonBase>
  );
  return (
    <div
      style={{
        position: "absolute",
        width: "80px",
        padding: `0px ${styleConstant.padding.x3} ${styleConstant.padding.x3} 0px`,
        bottom: "0px",
        right: "0px",
        display: "flex",
        justifyContent: "flex-end",
        zIndex: 2,
      }}
    >
      {renderButton()}
    </div>
  );
};

export default CalculatorMobileFooter;
