import { ButtonBase, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../../assets/styles/constant/palette";
import {
  drawerLinkItemStyle,
  styleConstant,
} from "../../../assets/styles/constant/styleConstants";
import { icons } from "../../../assets/styles/constant/general";
import CustomIcon from "../CustomIcon";

const LinkToPageItem = ({
  icon,
  action,
  label,
  open = false,
  selected = false,
}) => {
  const renderIcon = () =>
    React.cloneElement(icon, {
      color: selected ? palette.primary.main : palette.grey.grey_100,
    });

  return (
    <ButtonBase
      style={{
        display: "flex",
        justifyContent: open ? "space-between" : "center",
        alignItems: "center",
        padding: !open
          ? `${styleConstant.padding.x3} ${styleConstant.padding.x2}`
          : styleConstant.padding.x2,
        borderRadius: styleConstant.borderRadius.x2,
        color: "white",
        background: selected && palette.grey.grey_300,
      }}
      onClick={action}
    >
      {open ? (
        <>
          <div
            style={{
              display: "flex",
              gap: styleConstant.gap.large,
              alignItems: "center",
            }}
          >
            {renderIcon()}
            <Typography variant="smallTitle" style={drawerLinkItemStyle}>
              {label}
            </Typography>
          </div>
          <CustomIcon icon={icons.ANGLE_RIGHT} fontSize={"18px"} />
        </>
      ) : (
        renderIcon()
      )}
    </ButtonBase>
  );
};

export default LinkToPageItem;
