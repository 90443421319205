import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { Stack } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { icons } from '../../assets/styles/constant/general';
import { palette } from '../../assets/styles/constant/palette';
import ContainedButton from '../../core/components/buttons/ContainedButton';
import CustomIconButton from '../../core/components/buttons/CustomIconButton';
import CustomIcon from '../../core/components/CustomIcon';

const ListSection = ({
	eventList = [],
	onEventDelete = () => {},
	execCalc = () => {},
	tipsterEvents,
	loading = false,
	resetResult = () => {},
}) => {

	const [totalPrice, setTotalPrice] = React.useState(0);

	React.useEffect(() => {
		const newTotalPrice = calculateTotalPrice(eventList);
		setTotalPrice(newTotalPrice);
		resetResult();
	}, [eventList]);

	const calculateTotalPrice = (events) => {
		let _totalPrice = 0;
		events.map(el => {
			const { event } = el;
			const eventId = event.value;
			const eventPrice = parseInt(tipsterEvents[eventId].prezzo);
			if (eventPrice && !isNaN(eventPrice)) _totalPrice += eventPrice;
		});
		return _totalPrice;
	}

	const EventCard = ({
		eventObj,
		onDelete = () => {},
		order = '1',
	}) => {
		const { group, subGroup, statistic, event } = eventObj;
		return <Stack width={'100%'}
			style={{background: palette.grey.grey_300}}
			borderRadius={'16px'}
			position={'relative'}
			gap={'8px'}
			padding={'16px'}>
				<CustomIconButton
					onClick={onDelete}
					style={{position: 'absolute', top: '8px', right: '16px'}}
					icon={<CustomIcon icon={icons.TRASH_CAN}/>}/>
				<Stack direction={'row'} gap={'4px'} alignItems={'center'}>
					<CustomIcon icon={icons.HASHTAG} color={palette.grey.grey_100} fontSize={'16px'} />
					<Typography variant="smallTitle" fontWeight={'700'} color={palette.grey.grey_100}>
          	{order}
        	</Typography>
					{
						!isNaN(parseInt(tipsterEvents[event.value].prezzo)) &&
						<React.Fragment>
							<Typography variant="smallTitle" 
							style={{marginLeft: '16px'}}
							fontWeight={'700'} 
							color={'#FFF'}>
							{tipsterEvents[event.value].prezzo}
						</Typography>
						<CustomIcon icon={icons.COINS} color={'#FFF'} fontSize={'12px'} />
					</React.Fragment>
					}
				</Stack>
				<Stack>
					<Typography fontWeight={'700'} 
						color={'#FFF'}
						my={'auto'}
						fontSize={'20px'}>
							{event.label}
					</Typography>
					<Typography fontWeight={'700'}
						color={palette.grey.grey_100}>
							{statistic.label}
					</Typography>
				</Stack>
				<Stack gap={'12px'} 
					justifyContent={'space-between'}
					direction={'row'}>
					<Stack maxWidth={'45%'}>
						<Typography fontWeight={'500'}
							fontSize={'14px'}
							color={palette.grey.grey_100}>
								Gruppo
						</Typography>
						<Typography fontWeight={'500'}
							overflow={'hidden'}
							maxWidth={'100%'}
							noWrap
							textOverflow={'ellipsis'}
							fontSize={'14px'}
							color={'#FFF'}>
								{group.label}
						</Typography>
					</Stack>
					<Stack maxWidth={'45%'}>
						<Typography fontWeight={'500'}
							mt={'auto'}
							overflow={'hidden'}
							maxWidth={'100%'}
							noWrap
							textOverflow={'ellipsis'}
							fontSize={'14px'}
							color={'#FFF'}>
								{subGroup.label}
						</Typography>
					</Stack>
				</Stack>
		</Stack>
	}

	const ScrollContainer = ({children}) => (
		<div id='custom-scrollbar' style={{
			position: 'absolute',
			right: 0, left: 0, bottom: 0, top: 0,
			paddingRight: '24px',
			overflow: 'auto',
			flexDirection: 'column',
			gap: '8px',
			flex: 1
		}}>{children}</div>
	)

	const renderEventsList = () => (
		<Stack flex={1} pr={'24px'} mt={'24px'}
			position={'relative'}>
			<ScrollContainer>
				<Stack flex={1} gap={'16px'}>
					{eventList.map((el, idx) => {
						return <EventCard
							key={idx}
							eventObj={el}
							onDelete={() => onEventDelete(idx)}
							order={idx + 1} />
					})}
				</Stack>
			</ScrollContainer>
		</Stack>
	)

	return (
		<Stack flex={1}
			borderRadius={'16px'}
			p={'24px 0px 24px 24px'}>
				<Stack justifyContent={'space-between'}
					paddingRight={'24px'}
					direction={'row'} >
						<Typography color={'#FFF'}
							fontWeight={'500'}
							fontSize={'24px'}>
								Lista eventi
						</Typography>
						<ContainedButton
							disabled={eventList.length === 0 || loading}
							onClick={() => execCalc(totalPrice)}
							icon={!loading && <CustomIcon icon={icons.COINS} color={'#000'}/>}
							text={loading ? 'Sto calcolando...' : `Calcola ${totalPrice}`} />
				</Stack>
				{renderEventsList()}
		</Stack>
	)
}

const mapStateToProps = ({ Tipster }) => ({
  tipsterGroups: Tipster.groups,
	tipsterEvents: Tipster.events,
	tipsterStatistics: Tipster.statistics,
	tipsterMigliori: Tipster.migliori,
});

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ListSection);