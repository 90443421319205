import { iconButtonClasses, TextField } from "@mui/material";
import React from "react";
import { icons } from "../../../assets/styles/constant/general";
import { palette } from "../../../assets/styles/constant/palette";
import CustomIconButton from "../buttons/CustomIconButton";
import CustomIcon from "../CustomIcon";

const CustomTextField = ({
  autofocus = false,
  password = false,
  onChange = null,
  content,
  onBlur = () => {},
  onFocus = () => {},
  onClick = () => {},
  textColor = palette.white.main,
  inputPropsStyle = {},
  className = null,
  disabled = false,
  type = "text",
  placeholder = "",
  autoComplete = 'on',
  onKeyPress = () => {},
  customProps = {},
  style = {},
  inputRef = null,
}) => {
  const [textVisible, setTextVisible] = React.useState(password ? false : true);

  const handleToggleVisible = () => {
    // console.log(document.activeElement);
    // console.log(!(document.activeElement === textInput.current));
    // if (!(document.activeElement === textInput.current)) textInput.current.focus();
    // console.log(document.activeElement);
    setTextVisible(!textVisible);
  };

  const renderAdornment = () => (
    <CustomIconButton
      icon={<CustomIcon icon={!textVisible ? icons.EYE_SLASH : icons.EYE} size={"xs"} />}
      style={{ position: "absolute", top: "6px", right: "10px" }}
      onClick={handleToggleVisible}
    />
  );

  const onChangee = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange(e.target.value);
  };

  return (
    <div style={{ position: "relative", display: "flex", flex: 1 }}>
      <TextField
        onClick={onClick}
        onKeyPress={onKeyPress}
        disabled={disabled}
        autoComplete={autoComplete}
        className={className}
        InputProps={{ endAdornment: password && renderAdornment(), style: inputPropsStyle }}
        variant="outlined"
        inputRef={inputRef}
        autoFocus={autofocus}
        type={password ? (!textVisible ? "password" : "text") : type}
        onBlur={onBlur}
        onFocus={onFocus}
        sx={{
          input: { color: textColor },
          ...style,
        }}
        style={{ flex: 1 }}
        onChange={(e) => onChangee(e)}
        value={content}
        placeholder={placeholder}
      />
    </div>
  );
};

export default CustomTextField;
