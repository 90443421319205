export const USER = "users/:uid";
export const USER_TERMS_CONDITIONS = "users/:uid/termsAndConditions";

export const CALCULATION_PLANS = "new_plans";

export const CALCULATION_DATA = "calculation_data/structure";
export const CALCULATION_DATA_V2 = "calculation_data_v2/structure";

export const CALCULATION_SUBJECTS = `${CALCULATION_DATA}/oggetti`;
export const CALCULATION_SUBJECTS_V2 = `${CALCULATION_DATA_V2}/oggetti`;

export const CALCULATION_METHOD = `${CALCULATION_DATA}/modalità`;
export const CALCULATION_METHOD_V2 = `${CALCULATION_DATA_V2}/modalita`;

export const CALCULATION_STATISTICS = `${CALCULATION_DATA}/statistiche`;
export const CALCULATION_STATISTICS_V2 = `${CALCULATION_DATA_V2}/statistiche`;
export const CALCULATION_AVAILABLE_STATISTICS = `${CALCULATION_DATA_V2}/strumenti/:idTool/statistiche/:idSubject`;
export const CALCULATION_STATISTIC_V2 = `${CALCULATION_DATA_V2}/statistiche/:idStatistic`;

export const CALCULATION_DAYS = `${CALCULATION_DATA}/giornate`;
export const CALCULATION_DAY_V2 = `${CALCULATION_DATA_V2}/giornate/lista_giornate/:idDay`;
export const CALCULATION_AVAILABLE_DAYS_v2 = `${CALCULATION_DATA_V2}/giornate/presenti/:idTool/:idSeason`;
export const CALCULATION_DEFAULT_SELECTED_DAYS = `${CALCULATION_DATA_V2}/giornate/selezionate/:idTool/:idSeason`;

export const CALCULATION_GREATER_THAN = `${CALCULATION_DATA}/maggiore_di/:idStatistic`;
export const CALCULATION_GREATER_THAN_V2 = `${CALCULATION_DATA_V2}/maggiore_di/:idStatistic`;

export const CALCULATION_DATA_TS_CREATED = `${CALCULATION_DATA_V2}/ts_created`;

export const CALCULATION_TEAM = `${CALCULATION_DATA}/squadre/:idTeam`;
export const CALCULATION_TEAM_V2 = `${CALCULATION_DATA_V2}/squadre/:idTeam`;

export const CALCULATION_PLAYERS = `${CALCULATION_DATA}/giocatori_squadra/:idTeam`;
export const CALCULATION_PLAYERS_V2 = `${CALCULATION_DATA_V2}/giocatori/:idTool/:idSeason/:idTeam`;

export const CALCULATION_STATISTIC_SCENARIOS = `${CALCULATION_DATA}/scenari_statistica`;
export const CALCULATION_STATISTIC_SCENARIOS_V2 = `${CALCULATION_DATA_V2}/scenari_statistica`;

export const CALCULATION_SCENARIOS = `${CALCULATION_DATA}/scenari`;
export const CALCULATION_SCENARIOS_V2 = `${CALCULATION_DATA_V2}/scenari`;

export const CALCULATION_TOOLS_V2 = `${CALCULATION_DATA_V2}/strumenti`;

export const CALCULATION_SEASONS_V2 = `${CALCULATION_DATA_V2}/stagioni`;
export const CALCULATION_SEASON_V2 = `${CALCULATION_DATA_V2}/stagioni/:idSeason`;
export const CALCULATION_AVAILABLE_SEASONS = `${CALCULATION_DATA_V2}/strumenti/:idTool/stagioni`;

export const CALCULATION_AVAILABLE_OPERATIONS = `${CALCULATION_DATA_V2}/oggetti/:idSubject/operazioni`;
export const CALCULATION_OPERATIONS = `${CALCULATION_DATA_V2}/operazioni/:idOperation`;

export const CALCULATION_PRICE = `${CALCULATION_DATA_V2}/prezzi/:idStatistica/:idTool/:idSubject`;

export const CALCULATION_GUIDES = `${CALCULATION_DATA_V2}/guida`;

//per test scritture
export const USER_CREDITS = "users/:uid/credit";


export const FANTA_SETTINGS = `${CALCULATION_DATA_V2}/fantacalcio_impostazioni`;
export const FANTA_PLAYERS = `${CALCULATION_DATA_V2}/fantacalcio_giocatori`;
export const FANTA_STATISTICS = `${CALCULATION_DATA_V2}/fantacalcio_statistiche`;
export const FANTA_EVALUATE_GUIDE = `${CALCULATION_DATA_V2}/fantacalcio_valuta_guida`;
export const FANTA_EVALUATE_PRICE = `${CALCULATION_DATA_V2}/fantacalcio_valuta_prezzo`;
export const FANTA_EVALUATE_SETTINGS = `${CALCULATION_DATA_V2}/fantacalcio_valuta_impostazioni`;

export const TIPSTER_GROUPS = `${CALCULATION_DATA_V2}/tipster_gruppi`;
export const TIPSTER_EVENTS = `${CALCULATION_DATA_V2}/tipster_eventi`;
export const TIPSTER_MIGLIORI = `${CALCULATION_DATA_V2}/tipster_migliori`;
export const TIPSTER_STATISTICS = `${CALCULATION_DATA_V2}/tipster_statistiche`;
