import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import NavigationHandler from "../routes/NavigationHandler";
import { initScreenSizeManager } from "../service/screenSize";
initScreenSizeManager();

const RoutingTemp = (props) => {
  React.useEffect(() => {
    console.log("NAVIGATION STATE CHANGED");
  });

  return (
    <Router>
      <NavigationHandler />
    </Router>
  );
};

const mapStateToProps = ({ Profile }) => ({
  //userProfile: Profile.userProfile,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(RoutingTemp);
