import { all, fork } from "redux-saga/effects";

/* IMPORT ALL SAGA-FILE HERE */
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./profile/saga";
import calculationSaga from "./calculation/saga";
import calculationWorkSaga from "./calculationWork/saga";
import fantaSaga from "./fanta/saga";
import tipsterSaga from "./tipster/saga";

export default function* rootSaga() {
  yield all([
    /* FORK SAGA FILE INTO ONE ROOT */
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(calculationSaga),
    fork(calculationWorkSaga),
    fork(fantaSaga),
    fork(tipsterSaga),
  ]);
}
