import React from "react";
import { connect } from "react-redux";
import CalculatorDesktopLayout from "../core/layout/calculatorPageLayout/calculatorDesktopLayout/CalculatorDesktopLayout";
import CalculatorMobileLayout from "../core/layout/calculatorPageLayout/calculatorMobileLayout/CalculatorMobileLayout";

const CalculatorPage = (props) => {
  React.useEffect(() => console.log("calc page", props.windowBreakpoint));

  return (
    <>
      {props.windowBreakpoint === "LARGE" && <CalculatorDesktopLayout />}
      {props.windowBreakpoint === "SMALL" && <CalculatorMobileLayout />}
    </>
  );
};

const mapStateToProps = ({ Screen }) => ({
  windowBreakpoint: Screen.breakpoint,
});

export default connect(mapStateToProps)(CalculatorPage);
