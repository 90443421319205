import { Button, Tooltip, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../../assets/styles/constant/palette";
import { styleConstant } from "../../../assets/styles/constant/styleConstants";

const TextButton = ({ text, icon = null, action = null, style = null, disabled = false, iconLeft = false }) => {
  return (
    <Button variant="textButton" onClick={action} disabled={disabled} style={{ ...style }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: `${styleConstant.gap.small}`,
        }}
      >
        {iconLeft && (
          <>
            {icon}
            <Typography variant="smallTitle" color="primary" noWrap>
              {text}
            </Typography>
          </>
        )}
        {!iconLeft && (
          <>
            <Typography variant="smallTitle" color="primary" noWrap>
              {text}
            </Typography>
            {icon}
          </>
        )}
      </div>
    </Button>
  );
};

export default TextButton;
