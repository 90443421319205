import { createTheme } from "@mui/material";
import { palette } from "./constant/palette";
import { styleConstant } from "./constant/styleConstants";

export const textField = createTheme({
  components: {
    MuiTextField: {
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            background: palette.grey.grey_200,
            borderRadius: styleConstant.borderRadius.x1,
            borderColor: palette.grey.grey_200,
            "& .MuiOutlinedInput-root": {
              maxHeight: "50px",
              borderRadius: styleConstant.borderRadius.x1,
              borderColor: palette.grey.grey_200,

              "&:hover fieldset": {
                borderColor: "transparent",
                // borderColor: palette.primary.main,
                borderRadius: styleConstant.borderRadius.x1,
              },
              "&.Mui-focused fieldset": {
                borderColor: palette.primary.main,
                borderWidth: "2px",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: `0.5px solid transparent`,
            },
            "& .MuiOutlinedInput-input": { maxHeight: "50px", padding: "13px" },
            // "& .Mui-disabled": {
            //   color: "white",
            //   background: palette.grey.grey_200 + "80",
            //   borderRadius: styleConstant.borderRadius.x1,
            //   borderColor: "transparent",
            // },
          },
        },
        {
          props: { variant: "outlined", className: "error" },
          style: {
            background: palette.grey.grey_200,
            borderRadius: styleConstant.borderRadius.x1,
            borderColor: "#f74848",
            "& .MuiOutlinedInput-root": {
              maxHeight: "50px",
              borderRadius: styleConstant.borderRadius.x1,
              borderColor: "#f74848",

              "&:hover fieldset": {
                borderColor: "#f74848",
                // borderColor: palette.primary.main,
                borderRadius: styleConstant.borderRadius.x1,
              },
              "&.Mui-focused fieldset": {
                borderColor: "#f74848",
                borderWidth: "2px",
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "2px solid #f74848",
            },
            "& .MuiOutlinedInput-input": { maxHeight: "50px", padding: "13px" },
          },
        },
      ],
    },
  },
});
