import {
  LOAD_BASIC_CALC_DATA,
  LOAD_BASIC_CALC_DATA_SUCCESS,
  LOAD_BASIC_CALC_DATA_ERROR,
  LOAD_TEAMS,
  LOAD_TEAMS_SUCCESS,
  LOAD_TEAMS_ERROR,
  LOAD_PLAYERS,
  LOAD_PLAYERS_SUCCESS,
  LOAD_PLAYERS_ERROR,
  LOAD_GREATER_THAN,
  LOAD_GREATER_THAN_SUCCESS,
  LOAD_GREATER_THAN_ERROR,
  LOAD_DAYS,
  LOAD_STATISTICS,
  LOAD_SEASON,
  LOAD_SEASONS,
  LOAD_DAYS_SUCCESS,
  LOAD_DAYS_ERROR,
  LOAD_DAYS_V2,
  LOAD_DAYS_SUCCESS_V2,
  LOAD_DAYS_ERROR_V2,
  LOAD_STATISTICS_SUCCESS,
  LOAD_STATISTICS_ERROR,
  LOAD_OPERATION,
  LOAD_OPERATIONS,
} from "./actionTypes";

export const loadBasicCalcData = () => {
  return {
    type: LOAD_BASIC_CALC_DATA,
    payload: null,
  };
};

export const loadBasicCalcDataSuccess = ({
  subjects,
  methods,
  statistics,
  days,
  greaterThanDescription,
  tsCreatedData,
  scenarios,
  statisticScenarios,
  seasons,
  tools,
  operations,
  guides,
  plans,
}) => {
  return {
    type: LOAD_BASIC_CALC_DATA_SUCCESS,
    payload: {
      subjects,
      methods,
      statistics,
      days,
      greaterThanDescription,
      tsCreatedData,
      scenarios,
      statisticScenarios,
      seasons,
      tools,
      operations,
      guides,
      plans,
    },
  };
};

export const loadBasicCalcDataError = (error) => {
  return {
    type: LOAD_BASIC_CALC_DATA_ERROR,
    payload: { error },
  };
};

export const loadTeams = (idsTeams) => {
  return {
    type: LOAD_TEAMS,
    payload: { idsTeams },
  };
};

export const loadTeamsSuccess = (teams) => {
  return {
    type: LOAD_TEAMS_SUCCESS,
    payload: { teams },
  };
};

export const loadTeamsError = (error) => {
  return {
    type: LOAD_TEAMS_ERROR,
    payload: { error },
  };
};

export const loadPlayers = (idSeason, idTeam, idDays, idStatistic) => {
  return {
    type: LOAD_PLAYERS,
    payload: { idSeason, idTeam, idDays, idStatistic },
  };
};

export const loadPlayersSuccess = (players) => {
  return {
    type: LOAD_PLAYERS_SUCCESS,
    payload: { players },
  };
};

export const loadPlayersError = (error) => {
  return {
    type: LOAD_PLAYERS_ERROR,
    payload: { error },
  };
};

export const loadGreaterThan = (idStatistic) => {
  return {
    type: LOAD_GREATER_THAN,
    payload: { idStatistic },
  };
};

export const loadGreaterThanSuccess = (greaterThan) => {
  return {
    type: LOAD_GREATER_THAN_SUCCESS,
    payload: { greaterThan },
  };
};

export const loadGreaterThanError = (error) => {
  return {
    type: LOAD_GREATER_THAN_ERROR,
    payload: { error },
  };
};

export const loadStatistics = (ids) => {
  return {
    type: LOAD_STATISTICS,
    payload: { ids },
  };
};

export const loadStatisticsSuccess = (statistics) => {
  return {
    type: LOAD_STATISTICS_SUCCESS,
    payload: { statistics },
  };
};

export const loadStatisticsError = (error) => {
  return {
    type: LOAD_STATISTICS_ERROR,
    payload: { error },
  };
};

// export const loadDays = (days) => {
//   return {
//     type: LOAD_DAYS,
//     payload: { days },
//   };
// };

export const loadDays = (idSeason) => {
  return {
    type: LOAD_DAYS,
    payload: { idSeason },
  };
};

export const loadDaysSuccess = (days) => {
  return {
    type: LOAD_DAYS_SUCCESS,
    payload: { days },
  };
};

export const loadDaysError = (error) => {
  return {
    type: LOAD_DAYS_ERROR,
    payload: { error },
  };
};

export const loadSeasons = (seasons) => {
  return {
    type: LOAD_SEASONS,
    payload: { seasons },
  };
};

export const loadOperations = (operations) => {
  return {
    type: LOAD_OPERATIONS,
    payload: { operations },
  };
};
