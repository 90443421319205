import React from "react";
import { Checkbox } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";

const CustomCheckBox = ({ checked, stopPropagation = false, squared = false, onClick, size, disabled=false }) => {
  const handleClick = (e) => {
    e.stopPropagation();
    onClick();
  };
  return (
    <Checkbox
      disabled={disabled}
      checked={checked}
      size={size}
      color="primary"
      icon={!squared ? <CircleOutlinedIcon color="white" /> : <CropSquareIcon color="white" />}
      checkedIcon={!squared ? <CheckCircleOutlineIcon color="primary" /> : <CheckBoxOutlinedIcon color="primary" />}
      onClick={stopPropagation ? (e) => handleClick(e) : onClick}
    />
  );
};

export default CustomCheckBox;
