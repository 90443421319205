import { Button, Card, CardActionArea, CardContent, Typography } from "@mui/material";
import React from "react";
import { LabelValueInfo } from "../index";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomIconButton from "../buttons/CustomIconButton";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { styleConstant, textSecondaryStyle } from "../../../assets/styles/constant/styleConstants";
import CustomIcon from "../CustomIcon";
import { icons } from "../../../assets/styles/constant/general";
import { palette } from "../../../assets/styles/constant/palette";
import CustomToolTip from "../CustomToolTip";

const PlayerInfo = ({
  calcNumber = 1,
  calcObjectName = "Manuel Locatelli",
  teamName = "Juventus",
  calcPerc = null,
  calcStat = "GOL SEGNATI",
  calcGreaterThan = 2,
  calcDays,
  isActive = false,
  calcPrice = 60,
  onClick,
  onDelete,
  onDuplicate,
  onEdit,
  isLast,
  tool = 1,
  subject = 1,
  calcOperation = "Somma",
  calcSeason = "2020/2021",
  onCardClik,
  mobile = false,
}) => {
  const calcDaysToString = () => {
    if (calcDays.length > 3) {
      return calcDays.slice(0, 3).join(", ") + ", ...";
    }
    return calcDays.join(", ");
  };

  const onCalcDelete = (e) => {
    if (mobile) e.stopPropagation();
    onDelete();
  };

  const onCalcEdit = (e) => {
    if (mobile) e.stopPropagation();
    onEdit();
  };

  const onCalcDuplicate = (e) => {
    if (mobile) e.stopPropagation();
    onDuplicate();
  };

  return (
    <Card variant="playerCard" className={isLast ? "is-last" : ""} onClick={mobile ? onCardClik : null}>
      {isActive && <div className="active-banner"></div>}
      <div style={iconsBoxStyle}>
        {mobile && (
          <CustomToolTip title="Modifica">
            <CustomIconButton icon={<CustomIcon icon={icons.PENCIL} />} onClick={(e) => onCalcEdit(e)} />
          </CustomToolTip>
        )}
        <CustomToolTip title="Elimina calcolo">
          <CustomIconButton icon={<CustomIcon icon={icons.TRASH_CAN} />} onClick={(e) => onCalcDelete(e)} />
        </CustomToolTip>
        <CustomToolTip title="Duplica calcolo">
          <CustomIconButton icon={<CustomIcon icon={icons.CLONE} />} onClick={(e) => onCalcDuplicate(e)} />
        </CustomToolTip>
      </div>
      <CardActionArea className="outer-content" onClick={onClick}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: `${subject == 1 ? styleConstant.gap.medium : styleConstant.gap.large}`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: `${styleConstant.gap.medium}`,
            }}
          >
            <div style={{ display: "flex", gap: `${styleConstant.gap.small}` }}>
              <Typography variant="smallTitle" style={{ ...textSecondaryStyle, fontWeight: 700 }}>
                <CustomIcon icon={icons.HASHTAG} color={textSecondaryStyle.color} fontSize={styleConstant.fontSize.x2} /> {calcNumber}
              </Typography>
              {!calcPerc && (
                <>
                  <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
                    <Typography className="price" variant="smallTitle">
                      {calcPrice}
                    </Typography>
                    <CustomIcon icon={icons.COINS} fontSize={"12px"} color={palette.white.main} />
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "70%",
              }}
            >
              <Typography noWrap variant="title" className="calc-object-name">
                {calcObjectName}
              </Typography>
              {subject !== 2 && (
                <Typography variant="smallTitle" style={textSecondaryStyle} className="team-name">
                  {teamName}
                </Typography>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {calcPerc != null ? (
                <Typography variant={calcPerc ? "title" : "bigTitle"} style={calcPerc != null ? null : textSecondaryStyle}>
                  {calcPerc}
                </Typography>
              ) : (
                <div>
                  <CustomIcon color={textSecondaryStyle.color} fontSize={styleConstant.fontSize.x3} icon={icons.MINUS} />
                </div>
              )}
              {tool == 1 ? (
                <CustomIcon
                  style={{
                    marginLeft: styleConstant.margin.x1,
                  }}
                  color={calcPerc != null ? null : textSecondaryStyle.color}
                  fontSize={styleConstant.fontSize.x3}
                  icon={icons.PERCENT}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            {tool == 1 && (
              <>
                <LabelValueInfo label="Statistica" text={calcStat} />
                <LabelValueInfo label="Maggiore di" text={calcGreaterThan} />
                <LabelValueInfo label="Giornate" text={calcDaysToString()} />
              </>
            )}
            {tool == 2 && (
              <>
                <LabelValueInfo label="Operazione" text={calcOperation} />
                <LabelValueInfo label="Statistica" text={calcStat} />
                <LabelValueInfo label={calcSeason} text={calcDaysToString()} />
              </>
            )}
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
};

export default PlayerInfo;

const iconsBoxStyle = {
  display: "flex",
  position: "absolute",
  top: `${styleConstant.top.playerInfoIcon}`,
  right: `${styleConstant.right.playerInfoIcon}`,
  justifyContent: "space-between",
  gap: `${styleConstant.gap.small}`,
  zIndex: 1,
};
