import { ButtonBase, Stack, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { creditLabelStyle, profilePageStyle, styleConstant, textSecondaryStyle } from "../assets/styles/constant/styleConstants";
import drawer_logo from "../assets/images/main_logo.svg";
import fantacalcio_image from '../assets/images/fantacalcio_black_v2.png';
import stats_image from '../assets/images/stats_black_v2.png';
import tipster_image from '../assets/images/tipster_black.png';
import ContainedButton from "../core/components/buttons/ContainedButton";
import { icons } from "../assets/styles/constant/general";
import CustomIcon from "../core/components/CustomIcon";
import { palette } from "../assets/styles/constant/palette";
import { useNavigate } from "react-router-dom";
import { updatePurchaseUserData } from "../store/actions";
import { freeRewardPurchase } from "../service/userCredits";
import { getUserAppData } from "../service/userProfile";
import FreePurchaseModal from "../core/components/FreePurchaseModal";

const DashboardPage = (props) => {
  const idFreePlan = "202205101121";
  const navigate = useNavigate();
  const [purchaseCompleted, setPurchaseCompleted] = React.useState(false);
  const [loadingPurchase, setLoadingPurchase] = React.useState(false);
  const [purchaseSuccessful, setPurchaseSuccessful] = React.useState(false);
  const [isTimeLeft, setIsTimeLeft] = React.useState(false);

  const socialList = [
    {
      icon: icons.INSTAGRAM,
      header: "Seguici su instagram",
      title: "@football_vision_app",
      link: "https://www.instagram.com/football_vision_app/",
    },
    {
      icon: icons.FACEBOOK,
      header: "Seguici su Facebook",
      title: "@footballvisionapp",
      link: "https://www.facebook.com/footballvisionapp/",
    },
    // {
    //   icon: icons.TWITTER,
    //   header: "Seguici su Twitter",
    //   title: "@fvstat",
    //   link: "",
    // },
  ];

  const openSocial = (link) => {
    if (link != null) {
      window.open(link);
    }
  };

  const getFreeReward = async () => {
    try {
      if (isFreeCoinsButtonDisabled()) return;
      if (props.freeRewardTs > Date.now()) {
        setIsTimeLeft(true);
        setPurchaseCompleted(true);
        return;
      }
      let freePlan = null;
      for (let planId in props.plans) {
        if (props.plans[planId].title === "Free") {
          freePlan = props.plans[planId];
          break;
        }
      }
      setLoadingPurchase(true);
      const response = await freeRewardPurchase(props.uid, freePlan);
      if (response.data.outcome === "purchase success") {
        console.log("Acquisto avvenuto con successo");
        const userAppData = await getUserAppData(props.uid);
        props.updatePurchaseData(
          userAppData.credit,
          userAppData.noAdsTs,
          userAppData.payments_historycal,
          userAppData.freeRewardTs,
          userAppData.cumulativeFreeCoins,
          userAppData.oldestFreeRewardTs
        );
        setPurchaseSuccessful(true);
        setPurchaseCompleted(true);
        setLoadingPurchase(false);
      }
    } catch (error) {
      setPurchaseSuccessful(false);
      setPurchaseCompleted(true);
      setLoadingPurchase(false);
      console.log(error);
    }
  };

  const isFreeRewardPeriodPassed = () => {
    const { plans, oldestFreeRewardTs } = props;
    const freeCoinsPurchasePeriod = plans[idFreePlan].freeCoinsPurchasePeriod;
    return Date.now() - freeCoinsPurchasePeriod > oldestFreeRewardTs;
  };

  const isFreeCoinsButtonDisabled = () => {
    const { plans, cumulativeFreeCoins } = props;
    const maxPurchasableFreeCoins = plans[idFreePlan].maxPurchasableFreeCoins;
    const freeCoinsPurchasePeriod = plans[idFreePlan].freeCoinsPurchasePeriod;
    const planCoins = plans[idFreePlan].coins;
    if (typeof freeCoinsPurchasePeriod != "number" || typeof maxPurchasableFreeCoins != "number") return true;
    return !isFreeRewardPeriodPassed() && cumulativeFreeCoins + planCoins > maxPurchasableFreeCoins;
  };

  const getFreeCoinsButtonText = () => {
    if (loadingPurchase) return "Caricamento...";
    const { plans, oldestFreeRewardTs } = props;
    const freeCoinsPurchasePeriod = plans[idFreePlan].freeCoinsPurchasePeriod;
    const remainingDays = (freeCoinsPurchasePeriod - (Date.now() - oldestFreeRewardTs)) / 86400000;
    const formattedDays = Math.ceil(remainingDays);
    if (isFreeCoinsButtonDisabled()) return "Potrai riscattare i tuoi crediti tra " + formattedDays + " giorni";
    return "Riscatta pacchetto gratuito da " + props.plans[idFreePlan].coins + " crediti";
  };

  const closeModal = () => {
    setPurchaseCompleted(false);
    setPurchaseSuccessful(false);
    setIsTimeLeft(false);
  };

  const NavigationButton = ({title = '', subtitle = '', image = null, onClick = () => {}}) => (
    <ButtonBase style={{
      borderRadius: '8px',
      background: palette.primary.main,
      height: '40%',
      minHeight: '290px',
      width: '28%',
      marginTop: 'auto',
      marginBottom: 'auto',
    }}
      onClick={onClick}>
      <Stack flex={1} 
        p={'16px'}>
        <img
          src={image || fantacalcio_image}
          style={{
            width: "auto",
            // minHeight: "85px",
            height: "130px",
            maxHeight: "180px",
            alignSelf: "center",
            marginBottom: '32px',
          }} />
        <Typography 
         fontSize={'32px'}
         fontWeight={'700'}
         color={'#000'}>
           {title}
        </Typography>
        <Typography 
          fontSize={'16px'}
          fontWeight={'500'}
          color={'#000'}>
            {subtitle}
        </Typography>
      </Stack>
    </ButtonBase>
  )

  const renderMobileContent = () => (
    <>
      <img
        src={drawer_logo}
        style={{
          width: "auto",
          // minHeight: "85px",
          height: "150px",
          maxHeight: "180px",
          alignSelf: "center",
        }}
      />
      <ContainedButton
        text="Inizia un nuovo calcolo"
        style={{
          height: "70px",
          width: props.windowBreakpoint === "SMALL" ? "100%" : "70%",
          marginTop: styleConstant.margin.x5,
        }}
        onClick={() => navigate("/calculator")}
      />
      
      <Typography 
        fontSize={'32px'}
        fontWeight={'700'}
        color={'#FFF'}
        style={{marginTop: styleConstant.margin.x5}}>
        Fantacalcio
      </Typography>
      <Typography 
        fontSize={'16px'}
        fontWeight={'500'}
        color={'#FFF'}>
        Calcola le statistiche della tua rosa
      </Typography>
      <ContainedButton
        text="Gestione rose"
        style={{
          height: "70px",
          width: props.windowBreakpoint === "SMALL" ? "100%" : "70%",
        }}
        onClick={() => navigate("/fantacalcio")}
      />

      <Typography 
        fontSize={'32px'}
        fontWeight={'700'}
        color={'#FFF'}
        style={{marginTop: styleConstant.margin.x5}}>
        Tipster
      </Typography>
      <Typography 
        fontSize={'16px'}
        fontWeight={'500'}
        color={'#FFF'}>
        Calcola la probabilità di una combinazione di eventi
      </Typography>
      <ContainedButton
        text="Tipster"
        style={{
          height: "70px",
          width: props.windowBreakpoint === "SMALL" ? "100%" : "70%",
        }}
        onClick={() => navigate("/tipster")}
      />
      {/* <ContainedButton
        text={getFreeCoinsButtonText()}
        onClick={() => getFreeReward()}
        style={{
          backgroundColor: palette.grey.grey_300,
          height: "70px",
          width: props.windowBreakpoint === "SMALL" ? "100%" : "70%",
          marginTop: styleConstant.margin.x2,
        }}
        textStyle={{
          color: palette.primary.main,
        }}
        noWrap={false}
        disabled={loadingPurchase || isFreeCoinsButtonDisabled()}
      /> */}
    </>
  );

  const renderSocialButton = () => {
    return socialList.map((el, idx) => (
      <ButtonBase
        key={idx}
        style={{
          display: "flex",
          flexDirection: "row",
          // width: '100%',
          flex: "1 1",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: `10px ${styleConstant.padding.x3}`,
          background: palette.grey.grey_300,
          borderRadius: styleConstant.borderRadius.x2,
          marginTop: styleConstant.margin.x1,
          gap: styleConstant.gap.medium,
        }}
        onClick={() => openSocial(el.link)}
      >
        <CustomIcon fontSize={styleConstant.fontSize.x3} icon={el.icon} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="smallTitle" style={{ ...textSecondaryStyle, ...creditLabelStyle }}>
            {el.header}
          </Typography>
          <Typography variant="title">{el.title}</Typography>
        </div>
      </ButtonBase>
    ));
  };

  const renderDesktopContent = () => (
    <React.Fragment>
      <img
        src={drawer_logo}
        style={{
          width: "auto",
          // minHeight: "85px",
          height: "150px",
          maxHeight: "180px",
          alignSelf: "center",
          marginBottom: '32px',
        }} />
      <Stack direction={'row'}
        flex={1} mb={'16px'} gap={'32px'} 
        justifyContent={'center'}>
        <NavigationButton 
          onClick={() => navigate("/calculator")}
          image={stats_image}
          title="Statistiche"
          subtitle='Calcola le probabilità e gli indici di performance di giocatori e squadre'/>
        <NavigationButton 
          onClick={() => navigate("/fantacalcio")}
          image={fantacalcio_image}
          title="Fantacalcio"
          subtitle='Calcola i fantaindex e le statistiche delle tue rose' />
        <NavigationButton 
          onClick={() => navigate("/tipster")}
          image={tipster_image}
          title="Tipster"
          subtitle='Scopri le combinazioni degli eventi più probabili' />
      </Stack>
    </React.Fragment>
  )

  return (
    <>
      <FreePurchaseModal
        isOpen={purchaseCompleted}
        onClose={closeModal}
        isSuccess={purchaseSuccessful}
        freeCredits={props.plans[idFreePlan].coins}
        nextFreeRewardTs={props.freeRewardTs}
        isTimeLeft={isTimeLeft}
        mobile={props.windowBreakpoint === "SMALL"}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: "100%",
          //justifyContent: "space-between",
          padding: `${styleConstant.padding.x4} ${styleConstant.padding.x4}`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: styleConstant.gap.medium,
          }}
        >
          <div>
            <img src={props.profilePhoto} style={{ width: "48px", height: "48px", borderRadius: "50%" }} />
          </div>
          <div>
            <Typography
              variant="smallTitle"
              style={{
                ...textSecondaryStyle,
                fontWeight: 700,
              }}
            >
              Bentornato
            </Typography>
            <Typography style={profilePageStyle.title}>
              {props.nome != null ? props.nome : ""} {props.cognome != null ? props.cognome : ""}
            </Typography>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            height: '100%',
            marginTop: '16px',
            //backgroundColor: 'red',
            alignItems: props.windowBreakpoint === "SMALL" && 'center',
            display: "flex",
            flexDirection: "column",
          }}
        >
          {
            props.windowBreakpoint === "SMALL"
            ? renderMobileContent()
            : renderDesktopContent()
          }
        </div>
        <div
          style={{
            width: "100%",
            // height: '100px',
            display: "flex",
            flexDirection: "row",
            marginTop: 'auto',
            gap: styleConstant.gap.medium,
            flexWrap: "wrap",
          }}
        >
          {renderSocialButton()}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ Profile, Screen, Calculation }) => ({
  nome: Profile.userProfile.name,
  uid: Profile.userProfile.uid,
  freeRewardTs: Profile.userProfile.appData.freeRewardTs,
  cognome: Profile.userProfile.surname,
  profilePhoto: Profile.userProfile.photoURL,
  windowBreakpoint: Screen.breakpoint,
  plans: Calculation.plans,
  oldestFreeRewardTs: Profile.userProfile.appData.oldestFreeRewardTs,
  cumulativeFreeCoins: Profile.userProfile.appData.cumulativeFreeCoins,
});

const mapDispatchToProps = (dispatch) => ({
  updatePurchaseData: (credit, noAdsTs, payments_historycal, freeRewardTs, cumulativeFreeCoins, oldestFreeRewardTs) =>
    dispatch(updatePurchaseUserData(credit, noAdsTs, payments_historycal, freeRewardTs, cumulativeFreeCoins, oldestFreeRewardTs)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
