import {
  EDIT_PROFILE,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_ERROR,
  LOAD_PROFILE,
  LOAD_PROFILE_SUCCESS,
  LOAD_PROFILE_ERROR,
  DELETE_PROFILE,
  DELETE_PROFILE_SUCCESS,
  DELETE_PROFILE_ERROR,
  UPDATE_PURCHASE_DATA,
  RELOAD_USER_APP_DATA,
  RELOAD_USER_APP_DATA_SUCCESS,
  RELOAD_USER_APP_DATA_ERROR,
  RELOAD_USER_FANTA_TEAMS,
  RELOAD_USER_FANTA_TEAMS_SUCCESS,
  RELOAD_USER_FANTA_TEAMS_ERROR,
  RELOAD_USER_CREDITS,
  RELOAD_USER_CREDITS_SUCCESS,
  RELOAD_USER_CREDITS_ERROR,
} from "./actionTypes";

export const loadProfile = (userAuthInfo) => {
  return {
    type: LOAD_PROFILE,
    payload: { userAuthInfo },
  };
};
export const loadProfileSuccess = (userProfile) => {
  return {
    type: LOAD_PROFILE_SUCCESS,
    payload: { userProfile },
  };
};
export const loadProfileError = (error) => {
  return {
    type: LOAD_PROFILE_ERROR,
    payload: { error },
  };
};

export const editProfile = (userProfile) => {
  return {
    type: EDIT_PROFILE,
    payload: { userProfile },
  };
};
export const editProfileSuccess = (userProfile) => {
  return {
    type: EDIT_PROFILE_SUCCESS,
    payload: { userProfile },
  };
};
export const editProfileError = (error) => {
  return {
    type: EDIT_PROFILE_ERROR,
    payload: { error },
  };
};

export const deleteProfile = (userProfile) => {
  return {
    type: DELETE_PROFILE,
    payload: { userProfile },
  };
};
export const deleteProfileSuccess = () => {
  return {
    type: DELETE_PROFILE_SUCCESS,
    payload: null,
  };
};
export const deleteProfileError = (error) => {
  return {
    type: DELETE_PROFILE_ERROR,
    payload: { error },
  };
};

export const updatePurchaseUserData = (credit, noAdsTs, payments_historycal, freeRewardTs, cumulativeFreeCoins, oldestFreeRewardTs) => {
  return {
    type: UPDATE_PURCHASE_DATA,
    payload: { credit, noAdsTs, payments_historycal, freeRewardTs, cumulativeFreeCoins, oldestFreeRewardTs },
  };
};

export const reloadUserFantaTeams = (uid, selectedId) => {
  return {
    type: RELOAD_USER_FANTA_TEAMS,
    payload: { uid, selectedId }
  }
}

export const reloadUserFantaTeamsSuccess = (fantaTeams) => {
  return {
    type: RELOAD_USER_FANTA_TEAMS_SUCCESS,
    payload: { fantaTeams }
  }
}

export const reloadUserFantaTeamsError = (error) => {
  return {
    type: RELOAD_USER_FANTA_TEAMS_ERROR,
    payload: { error }
  }
}

export const reloadUserCredit = (uid) => {
  return {
    type: RELOAD_USER_CREDITS,
    payload: { uid }
  }
}

export const reloadUserCreditSuccess = (credit) => {
  return {
    type: RELOAD_USER_CREDITS_SUCCESS,
    payload: { credit }
  }
}

export const reloadUserCreditError = (error) => {
  return {
    type: RELOAD_USER_CREDITS_ERROR,
    payload: { error }
  }
}
