import { ButtonBase, Checkbox, MenuItem, Select, Typography } from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";
import CustomChip from "./CustomChip";
import ExpandMoreIcon from "../icons/ExpandMoreIcon";
import { palette } from "../../../assets/styles/constant/palette";
import DoneIcon from "@mui/icons-material/Done";
import "../../../assets/styles/scrollbar.css";
import { styleConstant } from "../../../assets/styles/constant/styleConstants";
import CustomCheckBox from "./CustomCheckBox";
import { brkRef } from "../../../helpers/util";

const selectAllMenuItem = { value: -1, label: "Selezionate tutte" };
const deleteAllMenuItem = { value: -2, label: "Deseleziona tutte" };

// chipTextValue è la proprietà dell'oggetto selezionato che contiene il testo da mostrare nella chip

// showTextContent a true significa che quando la input select è multiple non vengono renderizzate le chip, ma solo dei valori di testo separati da una virgola.

const InputSelect = ({
  inputSelectItem = [],
  multiple = false,
  selectedValue = [],
  onSelectChange = null,
  flag = false,
  disabled = false,
  controlledOpenState = false,
  openState = false,
  handleOpen,
  chipTextValue=null,
  menuStyle={},
  menuItemStyle={},
  showTextContent = false,
  style={},
  handleClose,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [_selectedValue, _setSelectedValue] = React.useState(selectedValue);
  const handleDelete = (value) => {
    let newSelected = [];
    for (let idx in selectedValue) {
      if (JSON.stringify(selectedValue[idx]) === JSON.stringify(value)) continue;
      newSelected.push(selectedValue[idx]);
    }
    onSelectChange(newSelected);
  };

  React.useEffect(() => {
    if (multiple) {
      _setSelectedValue(selectedValue);
    }
  }, [selectedValue]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    let isSpecialSelected = null;
    if (multiple) {
      let newValue = value;
      for (let obj of value) {
        if (obj.value == -1) {
          isSpecialSelected = -1;
          break;
        } else if (obj.value == -2) {
          isSpecialSelected = -2;
          break;
        }
      }
      if (isSpecialSelected) {
        if (isSpecialSelected == -1) newValue = inputSelectItem.filter((obj) => obj.value != -1);
        if (isSpecialSelected == -2) newValue = [];
      }
      _setSelectedValue(newValue);
    } else {
      if (value.value != -1) onSelectChange(value);
    }
  };

  const handleSelectClose = () => {
    if (multiple) onSelectChange(_selectedValue);
    setIsOpen(false);
  };

  const getMenuProps = () => {
    return {
      PaperProps: {
        sx: {
          maxHeight: "250px",
          bgcolor: palette.grey.grey_600,
          // marginTop: styleConstant.margin.x1,
          borderRadius: styleConstant.borderRadius.x1,
          ...menuStyle,
          "& .MuiMenuItem-root": {
            // width: '100%',
            // padding: styleConstant.padding.x1,
            marginTop: styleConstant.margin.x2,
            //...menuItemStyle,
          },
        },
      },
    };
  };

  const renderIconComponent = () => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        right: "4px",
        top: "50%",
        zIndex: 1000,
        transform: "translate(0, -50%)",
      }}
    >
      {disabled && <ExpandMoreIcon isExpanded={controlledOpenState ? openState : isOpen} onClick={() => {}} />}
      {!disabled && controlledOpenState && <ExpandMoreIcon isExpanded={openState} onClick={() => handleOpen()} />}
      {!disabled && !controlledOpenState && <ExpandMoreIcon isExpanded={isOpen} onClick={() => setIsOpen(!isOpen)} />}
    </div>
  );

  const renderMultipleSelectedValue = (selected) => {
    if (!showTextContent) {
      return <Box sx={{ display: "flex", flexWrap: "wrap", gap: styleConstant.gap.small }}>
        {selected.map((value, index) => {
          if (value.value == -1) return;
          let displayValue = value.label;
          if (chipTextValue) displayValue = value[chipTextValue];
          return <CustomChip key={index} label={displayValue} onClick={() => handleDelete(value)} />;
        })}
      </Box>
    }else {
      let textVal = '';
      selected.map((item, idx) => {
        let displayValue = item.label;
        if (chipTextValue) displayValue = item[chipTextValue];
        if (idx !== 0) textVal += ', ' + displayValue;
        else textVal += displayValue;
      })
      return <Typography 
        variant="smallTitle" 
        overflow={'hidden'}
        textOverflow={'ellipsis'}>
          {textVal}
        </Typography>
    }
  };

  const renderSingleSelectedValue = (selected) => <Typography variant="smallTitle">{selected.label}</Typography>;

  const renderTick = (item) => {
    if (multiple) {
      let values = [];
      selectedValue.map((element) => {
        values.push(element.value);
      });
      if (values.indexOf(item.value) >= 0) return <DoneIcon color="primary" className="icon-item" />;
    } else {
      if (item.value === selectedValue.value) return <DoneIcon color="primary" className="icon-item" />;
    }
  };

  const isItemSelected = (item) => {
    const isSelected = _selectedValue.filter((value) => value.value == item.value);
    if (isSelected.length > 0) return true;
    return false;
  };

  const renderSelectedStyle = (item) => {
    if (multiple) {
      let values = [];
      _selectedValue.map((element) => {
        values.push(element.value);
      });
      if (values.indexOf(item.value) >= 0) return { background: palette.grey.grey_300 };
    } else {
      if (item.value === selectedValue.value) return { background: palette.grey.grey_300 };
    }
  };

  const renderMenuItem = () => {
    return inputSelectItem?.map((item, index) => {
      return (
        <MenuItem
          key={index}
          value={item}
          style={{
            ...renderSelectedStyle(item),
            color: "white",
            margin: styleConstant.margin.x1,
            borderRadius: styleConstant.borderRadius.x1,
            display: "flex",
            justifyContent: "space-between",
            position: "relative",
          }}
        >
          <div style={{wordWrap: 'break-word', whiteSpace: 'normal'}}>
            {item?.label || "---"}
           </div>
          {!multiple && renderTick(item)}
          {multiple && item.value != -1 && <CustomCheckBox squared checked={isItemSelected(item)} onClick={() => {}} />}
        </MenuItem>
      );
    });
  };

  const getSelectedValue = () => {
    if (multiple) return _selectedValue.length > 0 ? _selectedValue : [];
    return selectedValue?.value != null ? selectedValue : "";
  };

  return (
    <div style={{ position: "relative", display: "flex" }}>
      {renderIconComponent()}
      <Select
        sx={{...style}}
        // style={flag ? { maxHeight: null } : { maxHeight: "40px" }}
        variant="standard"
        className="input-select"
        disabled={disabled}
        defaultValue={""}
        open={isOpen}
        MenuProps={getMenuProps()}
        disableUnderline
        multiple={multiple}
        value={getSelectedValue()}
        onChange={handleChange}
        onClose={handleSelectClose}
        onOpen={() => setIsOpen(true)}
        native={false}
        IconComponent={() => <></>}
        renderValue={multiple ? (selected) => renderMultipleSelectedValue(selected) : (selected) => renderSingleSelectedValue(selected)}
      >
        {multiple && _selectedValue.length !== inputSelectItem.length && (
          <MenuItem
            style={{
              ...renderSelectedStyle(selectAllMenuItem),
              color: "white",
              margin: styleConstant.margin.x1,
              borderRadius: styleConstant.borderRadius.x1,
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
            value={selectAllMenuItem}
          >
            {selectAllMenuItem.label || "---"}
          </MenuItem>
        )}
        {multiple && _selectedValue.length === inputSelectItem.length && (
          <MenuItem
            style={{
              ...renderSelectedStyle(deleteAllMenuItem),
              color: "white",
              margin: styleConstant.margin.x1,
              borderRadius: styleConstant.borderRadius.x1,
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
            value={deleteAllMenuItem}
          >
            {deleteAllMenuItem.label || "---"}
          </MenuItem>
        )}
        {renderMenuItem()}
      </Select>
    </div>
  );
};

export default InputSelect;
