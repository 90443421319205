import React from "react";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import CustomIconButton from "../buttons/CustomIconButton";
import CustomIcon from "../CustomIcon";
import { icons } from "../../../assets/styles/constant/general";

const ExpandMoreIcon = ({ isExpanded = true, onClick }) => {
  const renderExpandMoreIcon = () => (
    <CustomIcon
      className={`init ${isExpanded && "expand"}`}
      icon={icons.ANGLE_DOWN} />
  );

  return (
    <>
      <style>{`

      .init {
        transform: rotate(0deg);
        transition: transform 300ms ease-in-out;
      }

      .init.expand {
        transform: rotate(180deg);
      }
            
        `}</style>

      <CustomIconButton font icon={renderExpandMoreIcon()} onClick={onClick} />
    </>
  );
};

export default ExpandMoreIcon;
