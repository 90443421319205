import { createTheme } from "@mui/material";
import { palette } from "./constant/palette";
import { styleConstant } from "./constant/styleConstants";

export const Typography = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        style: {
          fontFamily: "Roboto",
        },
      },
      variants: [
        {
          props: { variant: "bigTitle" },
          style: {
            fontSize: styleConstant.fontSize.x4,
            lineHeight: styleConstant.lineHeight.x5,
            fontWeight: styleConstant.fontWeight.typographyDefaultFontWeight,
            color: palette.white.main,
          },
        },

        {
          props: { variant: "title" },
          style: {
            fontSize: styleConstant.fontSize.x3,
            lineHeight: styleConstant.lineHeight.x4,
            fontWeight: styleConstant.fontWeight.typographyDefaultFontWeight,
            color: palette.white.main,
          },
        },

        {
          props: { variant: "smallTitle" },
          style: {
            fontSize: styleConstant.fontSize.x2,
            lineHeight: styleConstant.lineHeight.x3,
            fontWeight: styleConstant.fontWeight.medium,
            color: palette.white.main,
            // letterSpacing: "0.02em",
            textTransform: "none",
          },
        },

        {
          props: { variant: "subtitle" },
          style: {
            fontSize: styleConstant.fontSize.subtitleFontSize,
            lineHeight: styleConstant.lineHeight.x2,
            fontWeight: styleConstant.fontWeight.typographyDefaultFontWeight,
            color: palette.white.main,
          },
        },

        {
          props: { variant: "body" },
          style: {
            fontSize: styleConstant.fontSize.x2,
            lineHeight: styleConstant.lineHeight.x3,
            fontWeight: styleConstant.fontWeight.medium,
            color: palette.white.main,
          },
        },
      ],
    },
  },
});
