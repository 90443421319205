import { takeEvery, put, call } from 'redux-saga/effects';

// Login Redux States
import { FORGET_PASSWORD } from './actionTypes';
import { userForgetPasswordSuccess, userForgetPasswordError } from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../service/common/firebase';
import { logError } from '../../../service/handleError';
import { FORGET_PSW_ERROR } from '../../../service/constant/errorCodes';

const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { email, history } }) {
  try {
    const response = yield call(fireBaseBackend.forgetPassword, email);
    if (response) {
      yield put(
        userForgetPasswordSuccess(
          'Reset link are sended to your mailbox, check there first',
        ),
      );
    } else {
      logError(FORGET_PSW_ERROR);
      yield put(userForgetPasswordError(FORGET_PSW_ERROR));
    }
  } catch (error) {
    yield put(userForgetPasswordError(FORGET_PSW_ERROR));
  }
}

function* forgetPasswordSaga() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export default forgetPasswordSaga;
