import { getPathWithParams, getSplittedDisplayName } from "../helpers/util";
import { GENERIC_ERROR } from "./common/codeError";
import { getFirebaseBackend } from "./common/firebase";
import { USER, USER_TERMS_CONDITIONS, USER_WATCH_TUTORIAL } from "./constant/refDb";
import { deleteUserAuth, updateUserAuth } from "./UserAuth";

const fireBaseBackend = getFirebaseBackend();

const APP_DATA_STRUCTURE = {
  email: null,
  credit: 2020,
  termsAndConditions: {
    accepted: null,
    tsAccepted: null,
  },
  tsCreated: null,
};

const CUSTOM_SPLITTER = "/";

export const getDisplayNameSplitter = () => {
  return CUSTOM_SPLITTER;
};

export function getUserProfile(userAuthInfo) {
  const splitDisplayName = userAuthInfo.displayName
    ? getSplittedDisplayName(userAuthInfo.displayName, CUSTOM_SPLITTER)
    : ["", ""];
  const userProfile = {
    uid: userAuthInfo.uid,
    name: splitDisplayName[0],
    surname: splitDisplayName[1] ? splitDisplayName[1] : null,
    email: userAuthInfo?.email,
    photoURL: userAuthInfo?.photoURL,
    creationTime: userAuthInfo?.metadata?.creationTime,
    appData: {},
  };

  return getAppData(userAuthInfo.uid)
    .then(async (appData) => {
      if (appData === null) {
        appData = await _createAppData(userAuthInfo.uid, userAuthInfo.email);
      }
      userProfile.appData = appData;
      return userProfile;
    })
    .catch((error) => {
      throw _handleError(GENERIC_ERROR);
    });
}

//TODO: Credo debba finire in userAuth.js in quanto in questo momento aggiorna solo l'autenticazione
export function editUserProfile(userProfile) {
  let partialUserAuth = _getPartialUserAuth(userProfile);
  console.log("PartialUserAuth", partialUserAuth);
  return updateUserAuth(partialUserAuth.displayName)
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw _handleError(GENERIC_ERROR);
    });
}

export function deleteUserProfile(uid) {
  return deleteUserAuth()
    .then(() => {
      return _deleteAppData(uid)
        .then(() => {
          return true;
        })
        .catch((error) => {
          throw _handleError(error);
        });
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export function setAcceptTermsAndConditions(uid) {
  if (!uid) {
    throw _handleError("UID obbligatorio");
  }
  let termsAndConditions = Object.assign({}, APP_DATA_STRUCTURE.termsAndConditions);
  termsAndConditions.accepted = true;
  termsAndConditions.tsAccepted = new Date().getTime();
  const timestamp = new Date().getTime();
  return fireBaseBackend
    .dbSet(getPathWithParams(USER_TERMS_CONDITIONS, uid), termsAndConditions)
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export const getUserAppData = (uid) => {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(USER, uid))
    .then((snapshot) => {
      return snapshot.val();
    })
    .catch((error) => {
      throw _handleError(error);
    });
};

function _createAppData(uid, email) {
  const appData = {
    ...APP_DATA_STRUCTURE,
    email: email,
    tsCreated: new Date().getTime(),
  };

  return fireBaseBackend
    .dbSet(getPathWithParams(USER, uid), appData)
    .then(() => {
      return appData;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

export function getAppData(uid) {
  return fireBaseBackend
    .dbOnceRead(getPathWithParams(USER, uid))
    .then((snapshot) => {
      const appData = snapshot.val();
      return appData;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

function _deleteAppData(uid) {
  return fireBaseBackend
    .dbSet(getPathWithParams(USER, uid), null)
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw _handleError(error);
    });
}

function _getPartialUserAuth(userProfile) {
  const partialUserAuth = {
    displayName: userProfile.name + CUSTOM_SPLITTER + userProfile.surname,
    photoURL: userProfile.photoURL,
  };
  return partialUserAuth;
}

export function fetchCredit(uid) {}

export function purchaseCredit(plan) {}

export function purchaseCalculation(ammountCredits) {}

function _handleError(error) {
  var errorObj = {};
  if (typeof error === "object" && error !== null) {
    errorObj = {
      code: error?.code,
      message: error?.message,
    };
  } else {
    errorObj = {
      code: error,
      message: error,
    };
  }

  console.log(errorObj);
  return errorObj;
}
