import {
  faTimes,
  faAngleRight,
  faHome,
  faPlay,
  faUser,
  faLifeRing,
  faPlus,
  faBars,
  faCoins,
  faArrowRotateLeft,
  faQuestion,
  faList,
  faToggleOff,
  faToggleOn,
  faTrashCan,
  faClone,
  faXmark,
  faEyeSlash,
  faEye,
  faChevronRight,
  faPeopleGroup,
  faPersonRunning,
  faPencil,
  faGear,
  faCheck,
  faObjectGroup,
  faFilter,
  faCaretDown,
  faArrowLeft,
  faExclamation,
  faInfo,
  faAngleDown,
  faHashtag,
  faPercent,
  faMinus,
  faHourglassHalf,
  faMagnifyingGlass,
  faFutbol,
  faSort,
  faSortUp,
  faSortDown,
  faRectangleList
} from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { faGoogle, faFacebookF, faApple, faPaypal, faInstagram, faTwitter, faAndroid, faGooglePay, faApplePay, faCcPaypal } from "@fortawesome/free-brands-svg-icons";

export const icons = {
  TIMES: faTimes,
  ANGLE_RIGHT: faAngleRight,
  ANGLE_DOWN: faAngleDown,
  HOME: faHome,
  PLAY: faPlay,
  USER: faUser,
  LIFE_RING: faLifeRing,
  PLUS: faPlus,
  BARS: faBars,
  COINS: faCoins,
  ARROW_ROTATE_LEFT: faArrowRotateLeft,
  QUESTION: faQuestion,
  LIST: faList,
  TOGGLE_OFF: faToggleOff,
  TOGGLE_ON: faToggleOn,
  TRASH_CAN: faTrashCan,
  CLONE: faClone,
  XMARK: faXmark,
  GOOGLE: faGoogle,
  FACEBOOK: faFacebookF,
  EYE: faEye,
  EYE_SLASH: faEyeSlash,
  CHEVRON_RIGHT: faChevronRight,
  PEOPLE_GROUP: faPeopleGroup,
  PERSON_RUNNING: faPersonRunning,
  PENCIL: faPencil,
  GEAR: faGear,
  APPLE: faApple,
  CHECK: faCheck,
  OBJECT_GROUP: faObjectGroup,
  FILTER: faFilter,
  CARET_DOWN: faCaretDown,
  // PAYPAL: faPaypal,
  PAYPAL: faCcPaypal,
  ARROW_LEFT: faArrowLeft,
  EXCLAMATION: faExclamation,
  INFORMATION: faInfo,
  INSTAGRAM: faInstagram,
  FACEBOOK: faFacebookF,
  TWITTER: faTwitter,
  HASHTAG: faHashtag,
  PERCENT: faPercent,
  MINUS: faMinus,
  ANDROID: faAndroid,
  APPLE: faApple,
  GOOGLE_PAY: faGooglePay,
  APPLE_PAY: faApplePay,
  PENDING: faHourglassHalf,
  CIRCLE_X_MARK: faCircleXmark,
  MAGNIFYING_GLASS: faMagnifyingGlass,
  FOOTBALL: faFutbol,
  SORT: faSort,
  SORT_UP: faSortUp,
  SORT_DOWN: faSortDown,
  RECTANGLE_LIST: faRectangleList,
};
