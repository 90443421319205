import React from "react";
/* Redux */
import { Provider } from "react-redux";
import store from "./store";
/* Generic import */
import Startup from "./Startup";
import CustomThemeProvider from "./core/provider/CustomThemeProvider";
import { CssBaseline } from "@mui/material";

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {};

  render() {
    return (
      <Provider store={store}>
        <CustomThemeProvider>
          <CssBaseline />
          <Startup />
        </CustomThemeProvider>
      </Provider>
    );
  }
}

export default App;
