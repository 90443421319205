import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { palette } from "../../assets/styles/constant/palette";
import { styled } from "@mui/material";
import CustomIcon from "./CustomIcon";
import { icons } from "../../assets/styles/constant/general";
import { styleConstant } from "../../assets/styles/constant/styleConstants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomDialog({
  open = false,
  icon = null,
  title = "Fornire titolo",
  description = null,
  primaryActionText = "Azione primaria",
  secondaryActionText = "Azione secondaria",
  handlePrimaryAction,
  handleSecondaryAction,
  handleClose,
  style = null,
  customContent = false,
  children,
  backDropClickClose = false,
  titlePosition = "start",
  roundCorners = false,
}) {
  const MyDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      paddingTop: icon != null ? styleConstant.padding.x3 : 0,
      backgroundColor: palette.grey.grey_600,
      borderRadius: roundCorners ? styleConstant.borderRadius.x2 : "",
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));

  const onClose = (event, reason) => {
    if (!backDropClickClose && reason && reason == "backdropClick" && "escapeKeyDown") return;
    handleClose();
  };

  return (
    <MyDialog open={open} TransitionComponent={Transition} keepMounted onClose={(event, reason) => onClose(event, reason)} style={style}>
      <div>
        {icon != null && (
          <div
            style={{
              marginBottom: styleConstant.margin.x2,
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CustomIcon icon={icon} fontSize={styleConstant.fontSize.x4} />
          </div>
        )}
        <DialogTitle style={{ color: palette.white.main, textAlign: titlePosition }}>{title}</DialogTitle>
        {!customContent ? (
          <>
            {description != null && (
              <DialogContent>
                <DialogContentText style={{ color: palette.white.main }}>{description}</DialogContentText>
              </DialogContent>
            )}
            <DialogActions>
              {secondaryActionText != null && <Button onClick={handleSecondaryAction}>{secondaryActionText}</Button>}
              {primaryActionText != null && <Button onClick={handlePrimaryAction}>{primaryActionText}</Button>}
            </DialogActions>
          </>
        ) : (
          children
        )}
      </div>
    </MyDialog>
  );
}
