import { Stack, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { icons } from '../../../assets/styles/constant/general';
import { palette } from '../../../assets/styles/constant/palette';
import CustomIconButton from '../../../core/components/buttons/CustomIconButton';
import TextButton from '../../../core/components/buttons/TextButton';
import CustomIcon from '../../../core/components/CustomIcon';
import TeamCard from '../../../core/components/TeamCard';
import { deleteFantaTeam } from '../../../service/fanta';
import { selectFantaTeam } from '../../../store/fanta/actions';
import { reloadUserFantaTeams } from '../../../store/profile/actions'

const TeamsListSection = (props) => {

  const { userAppData, selectedFantaTeamId, uid } = props;
  const fantaTeams = userAppData?.fantaTeams || {};
  const fantaTeamsNumber = fantaTeams ? Object.keys(fantaTeams).length : 0;

  const removeFantaTeam = async(teamId) => {
    try {
      await deleteFantaTeam(uid, teamId);
      props.reloadUserFantaTeams(uid);
      props.selectFantaTeamId(null);
    } catch (error) {
      console.log('Error removing fanta team', error)
    }
  }

  const TeamCardsContainer = ({children}) => (
    <div id='custom-scrollbar' style={{
      position: 'absolute',
      right: 0, left: 0, bottom: 0, top: 0,
      paddingRight: '24px',
      overflow: 'auto',
      flexDirection: 'column',
      gap: '8px',
      flex: 1
    }}>
      {children}
    </div>
  )

  const addFantaTeam = () => {
    if (fantaTeamsNumber < 10) {
      props.selectFantaTeamId(null);
    }
  }

  const renderHeader = () => (
    <Stack>
      <Stack direction={'row'}
      paddingRight={'24px'}
      marginBottom={'16px'}
      justifyContent={'space-between'}>
        <Typography
          fontWeight={'600'}
          color={'#FFF'}
          fontSize={24}>
            Le mie Rose
        </Typography>
      </Stack>
      <Typography
          fontWeight={'600'}
          color={palette.grey.grey_100}
          fontSize={16}>
            Totale rose: {fantaTeamsNumber} / 10
        </Typography>
    </Stack>
  )
  return (
		<Stack
      paddingY={'24px'}
      height={'100%'}
      borderRadius={'16px'}
      paddingLeft={'24px'}
      style={{ background: palette.grey.grey_500 }}
      direction={'column'}>
        {renderHeader()}
      <Stack flex={1}
        position={'relative'}
        marginTop={'16px'}>
          <TeamCardsContainer>
            {Object.values(fantaTeams).map(teamObject => {
              const teamId = teamObject.id;
              const isActive = teamId=== selectedFantaTeamId;
              return <TeamCard 
                key={teamId}
                teamObject={teamObject} 
                onClick={() => props.selectFantaTeamId(teamId)}
                onDelete={() => removeFantaTeam(teamId)}
                isActive={isActive} />
            })}
          </TeamCardsContainer>
      </Stack>
      <TextButton
        action={addFantaTeam}
        disabled={fantaTeamsNumber >= 10}
        style={{marginRight: 'auto', marginLeft: 'auto'}}
        text={'Aggiungi rosa'}/>
    </Stack>
	)
}

const mapStateToProps = ({ Fanta, Profile }) => ({
  uid: Profile.userProfile.uid,
  userAppData: Profile.userProfile.appData,
  selectedFantaTeamId: Fanta.selectedFantaTeamId,
  fantaStatistics: Fanta.fantaStatistics,
  fantaPlayers: Fanta.fantaPlayers,
  fantaSettings: Fanta.fantaSettings,
});

const mapDispatchToProps = dispatch => ({
  //loadFantaPlayers: () => dispatch(fetchFantaPlayers()),
  //loadFantaSatistics: () => dispatch(fetchFantaStatistics()),
  selectFantaTeamId: (fantaTeamId) => dispatch(selectFantaTeam(fantaTeamId)),
  reloadUserFantaTeams: (uid) => dispatch(reloadUserFantaTeams(uid)),
})

export default connect(mapStateToProps, mapDispatchToProps)(TeamsListSection);