export const FETCH_FANTA_SETTINGS = 'FETCH_FANTA_SETTINGS';
export const FETCH_FANTA_SETTINGS_SUCCESS = 'FETCH_FANTA_SETTINGS_SUCCESS';
export const FETCH_FANTA_SETTINGS_ERROR = 'FETCH_FANTA_SETTINGS_ERROR';

export const FETCH_FANTA_PLAYERS = 'FETCH_FANTA_PLAYERS';
export const FETCH_FANTA_PLAYERS_SUCCESS = 'FETCH_FANTA_PLAYERS_SUCCESS';
export const FETCH_FANTA_PLAYERS_ERROR = 'FETCH_FANTA_PLAYERS_ERROR';

export const FETCH_FANTA_STATISTICS = 'FETCH_FANTA_STATISTICS';
export const FETCH_FANTA_STATISTICS_SUCCESS = 'FETCH_FANTA_STATISTICS_SUCCESS';
export const FETCH_FANTA_STATISTICS_ERROR = 'FETCH_FANTA_STATISTICS_ERROR';

export const SELECT_FANTA_TEAM = 'SELECT_FANTA_TEAM';

export const FETCH_FANTA_EVALUATE_GUIDE = 'FETCH_FANTA_EVALUATE_GUIDE';
export const FETCH_FANTA_EVALUATE_GUIDE_SUCCESS = 'FETCH_FANTA_EVALUATE_GUIDE_SUCCESS';
export const FETCH_FANTA_EVALUATE_GUIDE_ERROR = 'FETCH_FANTA_EVALUATE_GUIDE_ERROR';

export const FETCH_FANTA_EVALUATE_SETTINGS = 'FETCH_FANTA_EVALUATE_SETTINGS';
export const FETCH_FANTA_EVALUATE_SETTINGS_SUCCESS = 'FETCH_FANTA_EVALUATE_SETTINGS_SUCCESS';
export const FETCH_FANTA_EVALUATE_SETTINGS_ERROR = 'FETCH_FANTA_EVALUATE_SETTINGS_ERROR';

export const FETCH_FANTA_EVALUATE_PRICE = 'FETCH_FANTA_EVALUATE_PRICE';
export const FETCH_FANTA_EVALUATE_PRICE_SUCCESS = 'FETCH_FANTA_EVALUATE_PRICE_SUCCESS';
export const FETCH_FANTA_EVALUATE_PRICE_ERROR = 'FETCH_FANTA_EVALUATE_PRICE_ERROR';
