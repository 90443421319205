import { takeEvery, put, call, select } from "redux-saga/effects";
import { getTipsterEvents, getTipsterGroups, getTipsterMigliori, getTipsterStatistics } from "../../service/tipster";
import { fetchTipsterDataError, fetchTipsterDataSuccess, fetchTipsterGruppiError, fetchTipsterGruppiSuccess } from "./actions";
import { FETCH_TIPSTER_DATA, FETCH_TIPSTER_GRUPPI } from "./actionTypes";

function* fetchTipsterGruppi() {
  try {
		const groups = yield call(getTipsterGroups);
		console.log('saga fetched groups', groups);
		yield put(fetchTipsterGruppiSuccess(groups));
	} catch (error) {
		console.log('Error saga fetch tipster gruppi', error);
		yield put(fetchTipsterGruppiError(error));
	}
}

function* fetchTipsterData() {
	try {
		const groups = yield call(getTipsterGroups);
		const statistics = yield call(getTipsterStatistics);
		const events = yield call (getTipsterEvents);
		const migliori = yield call(getTipsterMigliori);
		yield put(fetchTipsterDataSuccess(groups, statistics, events, migliori))
	} catch (error) {
		console.log('Error saga fetch tipster data', error);
		yield put(fetchTipsterDataError(error))
	}
}

function* tipsterSaga() {
  yield takeEvery(FETCH_TIPSTER_GRUPPI, fetchTipsterGruppi);
	yield takeEvery(FETCH_TIPSTER_DATA, fetchTipsterData);
}
  
export default tipsterSaga;