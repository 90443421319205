import { getPathWithParams } from "../helpers/util";
import { getFirebaseBackend } from "./common/firebase";
import { CALCULATION_PRICE, USER_CREDIT } from "./constant/refDb";

const fireBaseBackend = getFirebaseBackend();
const CF_DO_CALCULATION = "calculationPayments";
const CF_DO_CALCULATION_AVARAGE = "calculationAveragePayments";
const CF_FANTA_STATISTICS = "calculationFantaStatistics";
const CF_TIPSTER = "calculationTipster";
const CF_FANTA_VALUE = 'calculationEvaluatePlayers';

export const createSelectedDataTool1 = (calcList, subject) => {
  let newSelectedData = {
    id: null,
    idSubject: subject,
    idMethod: 1,
    calculations: {},
  };
  for (let calc of calcList) {
    let daysObj = {};
    //Elaborazione giornate scenari
    if (subject != 2) {
      Object.values(calc.scenarios).map((day) => {
        daysObj[day.day] = { scenarios: {} };
        Object.values(day.selected).map((selectedScenario) => {
          if (selectedScenario.id == 1 || selectedScenario.id == 4) {
            daysObj[day.day].scenarios[selectedScenario.id] = { idValues: selectedScenario.value };
          } else {
            daysObj[day.day].scenarios[selectedScenario.id] = { idValues: selectedScenario.value.value };
          }
        });
      });
    } else {
      calc.configuration.days.map((day) => {
        daysObj[day.value] = { scenarios: {} };
      });
    }
    const calcGreaterThan = parseInt(calc.configuration.greaterThan.label);
    let calculationObject = {
      id: calc.id,
      idStatistic: calc.configuration.statistic.value,
      days: daysObj,
      greaterThan: calcGreaterThan,
      idSquad: calc.configuration.team.value,
      idPlayer: subject == 2 ? null : calc.configuration.player.value,
    };
    newSelectedData.calculations[calc.id] = calculationObject;
  }
  console.log(newSelectedData);
  return newSelectedData;
};

export const createSelectedDataTool2 = (calcList, subject, idUtente = null) => {
  let newSelectedData = {
    idUtente,
    id: null,
    idOggetto: subject,
    idModalita: 1,
    calcoli: {},
  };
  for (let calc of calcList) {
    let daysArray = calc.configuration.days.map((day) => day.value);
    let calculationObject = {
      id: calc.id,
      idStatistica: calc.configuration.statistic.value,
      idOperazione: calc.configuration.operation.value,
      idStagione: calc.configuration.season.value,
      idGiornate: daysArray,
      idSquadra: calc.configuration.team.value,
      idGiocatore: subject == 2 ? null : calc.configuration.player.value,
    };
    newSelectedData.calcoli[calc.id] = calculationObject;
  }
  return newSelectedData;
};

export const createSelectedDataFromCalculusTool1 = (calculationObject, subject) => {
  let newSelectedData = {
    id: null,
    idSubject: subject,
    idMethod: 1,
    calculations: {},
  };
  let daysObj = {};
  Object.values(calculationObject.scenarios).map((day) => {
    daysObj[day.day] = { scenarios: {} };
    Object.values(day.selected).map((selectedScenario) => {
      if (selectedScenario.id == 1 || selectedScenario.id == 4) {
        daysObj[day.day].scenarios[selectedScenario.id] = { idValues: selectedScenario.value };
      } else {
        daysObj[day.day].scenarios[selectedScenario.id] = { idValues: selectedScenario.value.value };
      }
    });
  });
  const calcGreaterThan = parseInt(calculationObject.configuration.greaterThan.label);
  newSelectedData.calculations[calculationObject.id] = {
    id: calculationObject.id,
    idStatistic: calculationObject.configuration.statistic.value,
    days: daysObj,
    greaterThan: calcGreaterThan,
    idSquad: calculationObject.configuration.team.value,
    idPlayer: calculationObject.configuration.player.value,
  };
  return newSelectedData;
};

export const createSelectedDataFromCalculusTool2 = (calculationObject, subject, idUtente = null) => {
  let newSelectedData = {
    idUtente,
    id: null,
    idOggetto: subject,
    idModalita: 1,
    calcoli: {},
  };
  let daysArray = calculationObject.configuration.days.map((day) => day.value);
  newSelectedData.calcoli[calculationObject.id] = {
    id: calculationObject.id,
    idStatistica: calculationObject.configuration.statistic.value,
    idOperazione: calculationObject.configuration.operation.value,
    idStagione: calculationObject.configuration.season.value,
    idGiornate: daysArray,
    idSquadra: calculationObject.configuration.team.value,
    idGiocatore: calculationObject.configuration.player.value,
  };
  return newSelectedData;
};

export const getTotalPriceCalculationTool1 = (calculationObjectsArray, idSubject) => {
  let calculations = [];
  calculationObjectsArray.map((calculationObject) => {
    const calc = {
      id: calculationObject.id,
      idStatistic: calculationObject.configuration.statistic.value,
    };
    calculations.push(calc);
  });
  return new Promise((resolve, reject) => {
    let allPromise = [];
    calculations.forEach((obj, idx) => {
      allPromise[idx] = fireBaseBackend
        .dbOnceRead(getPathWithParams(CALCULATION_PRICE, obj.idStatistic, 1, idSubject))
        .then((snapshot) => {
          if (!snapshot.val()?.valore_prezzo) throw "PREZZO NON TROVATO";
          obj.price = snapshot.val()?.valore_prezzo;
          return obj;
        })
        .catch((error) => {
          reject(error);
        });
    });

    Promise.all(allPromise).then((partial) => {
      const obj = {
        amount: partial.reduce((partial_sum, p) => partial_sum + p.price, 0),
        partial,
      };
      resolve(obj);
    });
  }).catch((error) => {
    throw _handleError(error);
  });
};

export const getTotalPriceCalculationTool2 = (calculationObjectsArray, idSubject) => {
  let calculations = [];
  calculationObjectsArray.map((calculationObject) => {
    const calc = {
      id: calculationObject.id,
      idStatistic: calculationObject.configuration.statistic.value,
    };
    calculations.push(calc);
  });
  return new Promise((resolve, reject) => {
    let allPromise = [];
    calculations.forEach((obj, idx) => {
      allPromise[idx] = fireBaseBackend
        .dbOnceRead(getPathWithParams(CALCULATION_PRICE, obj.idStatistic, 2, idSubject))
        .then((snapshot) => {
          if (!snapshot.val()?.valore_prezzo) throw "PREZZO NON TROVATO";
          obj.price = snapshot.val()?.valore_prezzo;
          return obj;
        })
        .catch((error) => {
          reject(error);
        });
    });

    Promise.all(allPromise).then((partial) => {
      const obj = {
        amount: partial.reduce((partial_sum, p) => partial_sum + p.price, 0),
        partial,
      };
      resolve(obj);
    });
  }).catch((error) => {
    throw _handleError(error);
  });
};

export function doCalculations(selectedData, credit_amount, idUser, tool) {
  var clone_selectedData = JSON.parse(JSON.stringify(selectedData));
  delete clone_selectedData.id;
  delete clone_selectedData.database;

  if (tool == 1) {
    for (let idCalculus in clone_selectedData.calculations) {
      clone_selectedData.calculations[idCalculus].id = idCalculus;
    }
  } else {
    for (let idCalculus in clone_selectedData.calcoli) {
      clone_selectedData.calcoli[idCalculus].id = idCalculus;
    }
  }

  //app:"web"
  const obj = {
    selectedData: clone_selectedData,
    credit_amount,
    idUser,
    app: "web",
  };
  const cfName = tool == 1 ? CF_DO_CALCULATION : CF_DO_CALCULATION_AVARAGE;
  console.log(cfName);
  return fireBaseBackend
    .callCloudFunction(cfName, obj)
    .then((response) => {
      return response.data;
      // return _createResultCalculationDTO(clone_selectedData.calculations, response);
    })
    .catch((error) => {
      console.log("FIREBASE ERROR", error);
      throw error;
    });
}

export function calculationFantaStatistics(payload, uid, price) {
  return new Promise(async(resolve, reject) => {
    try {
      const cfData = {uid, payload, price};
      const cfResponse = await fireBaseBackend.callCloudFunction(CF_FANTA_STATISTICS, cfData);
      const cfResponseData = cfResponse.data;
      resolve(cfResponseData);
    } catch (error) {
      reject(error)
    }
  })
}

export function calculationTipster(cfData) {
  return new Promise(async(resolve, reject) => {
    try {
      const cfResponse = await fireBaseBackend.callCloudFunction(CF_TIPSTER, cfData);
      const cfResponseData = cfResponse.data;
      resolve(cfResponseData);
    } catch (error) {
      reject(error)
    }
  })
}

export function calculationPlayerEvaluation(cfData) {
  return new Promise(async(resolve, reject) => {
    try {
      const cfResponse = await fireBaseBackend.callCloudFunction(CF_FANTA_VALUE, cfData);
      const cfResponseData = cfResponse.data;
      resolve(cfResponseData);
    } catch (error) {
      reject(error)
    }
  })
}

function _createResultCalculationDTO(in_calculations, response) {
  const data = response.data;
  let calculations = JSON.parse(JSON.stringify(in_calculations));
  for (let idCalculus in calculations) {
    calculations[idCalculus].result = data[idCalculus]?.risultato;
  }
  return calculations;
}

function _handleError(error) {
  var errorObj = {};
  if (typeof error === "object" && error !== null) {
    errorObj = {
      code: error?.code,
      message: error?.message,
    };
  } else {
    errorObj = {
      code: error,
      message: error,
    };
  }

  console.log(errorObj);
  return errorObj;
}
